import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-symbols',
  templateUrl: './symbols.component.html',
  styleUrls: ['./symbols.component.scss'],
})
export class SymbolsComponent implements OnInit {
  allSymbols: any[] = [];
  dostExplanins:any[]=[]
  addDrawer: boolean = false;
  editDrawer: boolean = false;
  tableLoading: boolean = true;
  fallbackImage: string = 'assets/fallback.webp';
  storageUrl = environment.STORAGE_URL;
  fileList: NzUploadFile[] = []; // For nz-upload
  uploadedLogo: string | ArrayBuffer | null = null;
  uploadedLogoName: string = '';
  industries: any[] = [];
  exchanges: any[] = [];
  symbolLogoFile: File | null = null;
  symbolLogoPreview: string | ArrayBuffer | null = null;

  sectors: any[] = [];
  categories:any[]=[]
  searchQuery: string = '';
  addSymbolForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    symbol: new FormControl(''),
    name: new FormControl(''),
     dost_explains: new FormControl([]), 
    dost_alerts: new FormControl(''),
    description: new FormControl(''),
    symbol_logo: new FormControl(''),
    sector_id: new FormControl(''),
    quiz_id:new FormControl(''),
    exchange_id: new FormControl(''),
    category_id: new FormControl(''),
    industry_id: new FormControl(''),
    active_status_id: new FormControl(''),
  });

  editSymbolForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    symbol: new FormControl(''),
    name: new FormControl(''),
    symbol_id: new FormControl(''),
    sector_id: new FormControl(''),
    description: new FormControl(''),
    quiz_id:new FormControl(''),
    dost_explains: new FormControl(''),
    dost_alerts: new FormControl(''),
    symbol_logo: new FormControl(''),
    exchange_id: new FormControl([]),
    industry_id: new FormControl(''),
    category_id: new FormControl(''),
    active_status_id: new FormControl(''),
  });

  constructor(
    private apiService: FetchApiService,
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}

  ngOnInit(): void {
    this.fetchAllSymbols();
    this.fetchInitialData();
    this.fetchDostExplanins()
  }
  submitAddForm() {
    const apiUrl = `${environment.API_URL}add_symbol`;
    
    // Create FormData to handle file and other form fields
    const formData = new FormData();
    
    // Append form values to FormData
    Object.keys(this.addSymbolForm.value).forEach((key) => {
      formData.append(key, this.addSymbolForm.get(key)?.value);
    });
  
    // Append symbol_logo if it's selected
    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }
  
    formData.append('user_id', localStorage.getItem('userId') || '');
  
    // Make the HTTP request
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols()
            .then(() => {
              this.message.success('Ticker added');
              this.addSymbolForm.reset();
              this.closeAddDrawer();
            })
            .catch((error) => {
              this.message.error('Error occurred, please try later');
              console.log(error);
            });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  submitEditForm() {
    const apiUrl = `${environment.API_URL}edit_symbol`;
  
    // Create FormData to handle file and other form fields
    const formData = new FormData();
  
    // Append form values to FormData
    Object.keys(this.editSymbolForm.value).forEach((key) => {
      formData.append(key, this.editSymbolForm.get(key)?.value);
    });
  
    // Append symbol_logo if it's selected
    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }
  
    formData.append('user_id', localStorage.getItem('userId') || '');
  
    // Make the HTTP request
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {

            this.editSymbolForm.reset();
            this.closeEditDrawer();
            this.message.success('Ticker updated');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }

  onFileChange(event: Event, isEdit: boolean = false): void {
    const file = (event.target as HTMLInputElement).files?.[0]; // Use optional chaining here
    if (file) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const result = e.target?.result; // Use optional chaining here
            if (result) {
                this.symbolLogoPreview = result as string;
                this.symbolLogoFile = file; // Set the file to the class variable
                this.addSymbolForm.get('symbol_logo')?.setValue(file); // Set the file in the form control
            }
        };
        reader.readAsDataURL(file);
    }
}
  removeUploadedLogo() {
  
  }


  currentEditQuizId?: number;
  openEditDrawer(symbolId: number) {
    const selectedSymbol = this.allSymbols.find(symbol => symbol.symbol_id === symbolId);
    
    if (selectedSymbol) {
      this.editSymbolForm.patchValue({
        symbol: selectedSymbol.symbol,
        name: selectedSymbol.name,
        dost_explains: selectedSymbol.dost_explains,
        dost_alerts: selectedSymbol.dost_alerts,
        quiz_id:selectedSymbol.quiz_id,
        description: selectedSymbol.description,
        sector_id: selectedSymbol.sector_id,
        exchange_id: selectedSymbol.exchange_id,
        industry_id: selectedSymbol.industry_id,
        category_id: selectedSymbol.category_id,
        active_status_id: selectedSymbol.active_status_id,
        symbol_id: selectedSymbol.symbol_id,
        symbol_logo: selectedSymbol.symbol_logo  // Patch symbol_logo
      });
  
      // Optionally, set the logo preview if it's available
      this.symbolLogoPreview = selectedSymbol.symbol_logo ? `${this.storageUrl}${selectedSymbol.symbol_logo}` : null;
      
      this.editDrawer = true; // Open the drawer
    }
  }
  


  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const industriesDropdown$ = this.fetchApiService.getIndustryDropdown();
      const ExchangeDropdown$ = this.fetchApiService.getExchangeDropdown();
      const SectorDropdown$ = this.fetchApiService.getSectorDropdown();
      const CategoryDropdown$ = this.fetchApiService.getCategoryDropdown();
      forkJoin([
        industriesDropdown$,
        ExchangeDropdown$,
        SectorDropdown$,
        CategoryDropdown$
      ]).subscribe({
        next: ([indusTriesResponse, exchangereponse, sectorResponse,categories]: any) => {
          console.log('industriesDropdown: ', indusTriesResponse);
          console.log('ExchangeDropdown: ', exchangereponse);
          console.log('SectorDropdown ', sectorResponse);
          this.industries = indusTriesResponse;
          this.exchanges = exchangereponse;
          this.sectors = sectorResponse;
          this.categories=categories
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }


  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.allSymbols = this.allSymbols.filter(symbol =>
        symbol.symbol.toLowerCase().includes(query)
      );
    } else {
      // Reset the symbols array to show all symbols when search query is empty
      this.fetchAllSymbols(); // This will reload the symbols from the API
    }
  }

  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_all_stocks`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  
  fetchDostExplanins(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}courses_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.dostExplanins = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }


  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_symbol`;

    const requestData = {
      symbol_id: [id],
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {
            this.message.success('Ticker deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  cancel(): void {}
  closeEditDrawer(): void {
    this.editDrawer = false;
  }


  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }


  sortByTicker: NzTableSortFn<any> = (a, b) => a.symbol.localeCompare(b.symbol);
  sortByName: NzTableSortFn<any> = (a, b) => a.name.localeCompare(b.name);
  sortByStatus: NzTableSortFn<any> = (a, b) => a.active_status_id - b.active_status_id;
  sortByLogo: NzTableSortFn<any> = (a, b) => a.symbol_logo.localeCompare(b.symbol_logo || '');
  sortBySector: NzTableSortFn<any> = (a, b) => a.sector.localeCompare(b.sector);
  sortByExchange: NzTableSortFn<any> = (a, b) => a.exchange.localeCompare(b.exchange);
  sortByIndustry: NzTableSortFn<any> = (a, b) => a.industry.localeCompare(b.industry);
  sortByCategory: NzTableSortFn<any> = (a, b) => a.category_name.localeCompare(b.category_name);
  sortByCreatedOn: NzTableSortFn<any> = (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  sortByUpdatedOn: NzTableSortFn<any> = (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  sortByUpdatedBy: NzTableSortFn<any> = (a, b) => a.updated_by_name.localeCompare(b.updated_by_name);
  sortByUpdatedAt: NzTableSortFn<any> = (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
}
