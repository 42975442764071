import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  userDetails: any = {};
  posts: any[] = [];
  editUserProfile = false;
  dostUserID: string | null = null;
  userID = localStorage.getItem('userId');
   roleID = parseInt(localStorage.getItem('role_id') || '0', 10);  postForm: FormGroup;
  commentVisible: { [key: number]: boolean } = {};
  isModalVisible = false;
  userQuestionAnsList: any[] = [];
  updatedRoleID:any;
  activeTabIndex = 0;
  watchlistTickers: any[] = [];
  private subscriptions: Subscription = new Subscription();
  storageUrl = environment.STORAGE_URL;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {
    this.postForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.dostUserID = this.activatedRoute.snapshot.paramMap.get('user_id');
  
    this.fetchUserProfile().then(() => {
      this.fetchAllContnetList(this.updatedRoleID);
    });
    this.fetchAuthorDostportfolio(this.activeTabIndex);
  }
  userProfileImg: string | ArrayBuffer | null = null;
  edituserForm: FormGroup = new FormGroup({
    user_id: new FormControl(this.userID),
    bio: new FormControl(''),
    username: new FormControl(''),
    name: new FormControl(''),
    profile_pic: new FormControl(''),
    relevant_tags: new FormControl(),
  });

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  isLoading = false; 
  fetchUserProfile(): Promise<void> {
    if (!this.dostUserID) {
      this.message.error('User ID is missing');
      return Promise.reject(new Error('User ID is missing'));
    }
  
    const requestData = {
      user_id: this.dostUserID,
      auth_user_id: this.userID,
    };
  
    const apiUrl = `${environment.API_URL}fetch_user_profile`;
  
    this.isLoading = true; // Set loading to true
  
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          try {
            this.userDetails = response;
            this.posts = response.posts ?? []; // Default to an empty array if posts is null/undefined
            this.updatedRoleID = this.userDetails?.role_id?.[0] ?? null; // Handle role_id safely
            resolve();
          } catch (error) {
            console.error('Error processing response:', error);
            this.message.error('Failed to process user details');
            reject(error);
          } finally {
            this.isLoading = false; // Set loading to false
          }
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          this.message.error('Failed to fetch user details');
          this.isLoading = false; // Set loading to false
          reject(error);
        },
      });
    });
  }
  allTags: any[] = [];
  fetchAllTags(): Promise<void> {
    // console.log(id);
    const apiUrl = `${environment.API_URL}tag_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allTags = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  likeButton(post: any): void {
    const apiUrl = `${environment.API_URL}like_post`;
    const requestData = {
      post_id: post.diary_post_id,
      like_status: post.like_count ? 0 : 1,
      user_id: this.userID,
    };

    const subscription = this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Like updated');
        this.fetchUserProfile();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update like status');
      },
    });

    this.subscriptions.add(subscription);
  }

  toggleCommentSection(postId: number): void {
    this.commentVisible[postId] = !this.commentVisible[postId];
  }

  openShareModal(): void {
    this.isModalVisible = true;
  }

  share(platform: string): void {
    this.message.success(`Sharing on ${platform}`);
  }

  handleCancel(): void {
    this.isModalVisible = false;
    this.isVisible = false;
    this.editUserProfile = false;
  }

  onCommentSubmit(): void {
    if (this.postForm.valid) {
      const comment = this.postForm.value.comment;
      // Handle the comment submission logic here
      console.log('Comment:', comment);
    } else {
      this.message.error('Please enter a comment.');
    }
  }
  fetchAuthorDostportfolio(tabIndex: number) {
    this.activeTabIndex = tabIndex;
    const categoryId = tabIndex === 0 ? 1 : 2;
    let apiUrl = `${environment.API_URL}author_dostportfolio`;
    let requestBody: any = {
      category_id: categoryId,
      logged_user_id: this.userID,
      user_id: this.dostUserID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.watchlistTickers = res; // Assign API response to latestArticles array
      },
      error: (err) => {
        console.error('Error fetching latest articles', err);
      },
    });
  }
  deleteWatchlisTtickerId(id: number) {
    const apiUrl = `${environment.API_URL}delete_watch_list_ticker`;
    const requestData = {
      watch_list_ticker_id: id,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Dostportfolio deleted');
        this.fetchAuthorDostportfolio(this.activeTabIndex + 1);
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  isVisible = false;

  showModal(): void {
    this.fetchOnboardingQuestions();
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  fetchOnboardingQuestions(): void {
    if (this.dostUserID) {
      const requestData = {
        user_id: this.userID,
      };

      const subscription = this.http
        .post(`${environment.API_URL}fetch_user_answer`, requestData)
        .subscribe({
          next: (response: any) => {
            const ArrayData: any[] = response;
            // Ensure response is an array and map over it to transform data
            this.userQuestionAnsList = ArrayData.map((question: any) => {
              if (
                question.question_type_id === 1 &&
                Array.isArray(question.already_answered_option)
              ) {
                question.already_answered_option =
                  question.already_answered_option.length > 0
                    ? question.already_answered_option[0]
                    : null;
              }
              return question; // Return the transformed question object
            });

            console.log(this.userQuestionAnsList);
          },
          error: (error) => {
            console.error('Error fetching user details:', error);
            this.message.error('Failed to fetch user details');
          },
        });

      this.subscriptions.add(subscription);
    } else {
      this.message.error('User ID is missing');
    }
  }

  submitAnswers() {
    // Filter only modified answers
    const answers = this.userQuestionAnsList
      .filter((question) => {
        // Check if the answer has changed for any question type
        if (
          question.question_type_id === 1 &&
          question.already_answered_option !== question.original_answer
        )
          return true;
        if (
          question.question_type_id === 2 &&
          this.hasMultiChoiceChanged(question)
        )
          return true;
        if (
          question.question_type_id === 3 &&
          question.already_answered !== question.original_answer
        )
          return true;
        return false;
      })
      .map((question) => {
        const { question_type_id, question_id } = question;
        let optionIds = null;
        let answer = null;

        // Process single choice
        if (question_type_id === 1) {
          optionIds = question.already_answered_option
            ? [question.already_answered_option]
            : null;
        }

        // Process multi-choice
        if (question_type_id === 2) {
          optionIds = this.getMultiChoiceOptions(question);
        }

        // Process short answer
        if (question_type_id === 3) {
          answer = question.already_answered;
        }

        return {
          question_type_id,
          question_id,
          user_option_id: optionIds || null,
          answer: answer || null,
        };
      });

    // Stop if no data to submit
    if (answers.length === 0) {
      this.message.info('No changes to submit.');
      return;
    }

    const filteredData = answers.filter(
      (item) => item.user_option_id !== null || item.answer !== null
    );

    // Final payload
    const requestData = {
      user_id: this.userID, // Replace with actual user ID
      answers: filteredData,
    };

    // API call
    const apiUrl = `${environment.API_URL}start_user_onboard`;
    this.http.post(apiUrl, requestData).subscribe({
      next: () => this.message.success('Submit Success'),
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  // Helper function to check if multi-choice options changed
  private hasMultiChoiceChanged(question: any): boolean {
    const selectedOptions = question.options
      ?.filter((option: { selected: boolean }) => option.selected)
      .map((option: { user_option_id: number }) => option.user_option_id);
    return (
      JSON.stringify(selectedOptions) !==
      JSON.stringify(question.original_selected_options)
    );
  }

  // Helper function to get multi-choice options
  private getMultiChoiceOptions(question: any): number[] | null {
    const selectedOptions = question.options
      ?.filter((option: any) => option.selected)
      .map((option: any) => option.user_option_id);
    return selectedOptions?.length ? selectedOptions : null;
  }

  onMultiChoiceChange(question: any, option: any): void {
    // Update selected options for the question
    question.options = question.options.map((opt: any) => {
      if (opt.user_option_id === option.user_option_id) {
        opt.selected = option.selected;
      }
      return opt;
    });
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      // Set the profile image preview
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.userProfileImg = e.target.result;
      };
      reader.readAsDataURL(file);

      // Append file to FormControl
      this.edituserForm.get('profile_pic')?.setValue(file);
    }
  }

  removeUploadedLogo() {
    const apiUrl = `${environment.API_URL}delete_user_image`;

    const requestData = {
      user_id: this.dostUserID,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('User Profile Deleted');
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  edituserProfile(userDetails: any) {
    this.fetchAllTags(); // Fetch tags (if needed)
    this.editUserProfile = true; // Show modal

    // Patch form values with userDetails
    this.edituserForm.patchValue({
      user_id: userDetails.user_id, // Assuming user_id exists in userDetails
      bio: userDetails.bio,
      name: userDetails.name,
      username: userDetails.username,
      profile_pic: userDetails.profile_pic,
      relevant_tags: userDetails.relevant_tags.map(
        (tag: { tag_id: any }) => tag.tag_id
      ), // Assuming relevant_tags is an array
    });

    // Set the userProfileImg for image preview (if profile_pic exists)
    if (userDetails.profile_pic) {
      this.userProfileImg = userDetails.profile_pic
        ? `${this.storageUrl}${userDetails.profile_pic}`
        : 'assets/fallback.webp';
    }

    console.log(userDetails); // Debugging user details
  }

  submitUserProfile(): void {
    if (this.edituserForm.invalid) {
      this.message.error('Please fill in all required fields.');
      return;
    }
  
    const formData = new FormData();
    const formValue = this.edituserForm.value;
  
    // Append form values to FormData
    formData.append('user_id', this.edituserForm.get('user_id')?.value ?? '');
    formData.append('bio', formValue.bio || '');
    formData.append('name', formValue.name || '');
    formData.append('username', formValue.username || '');
  
    // Handle relevant tags array
    const relevantTags = formValue.relevant_tags; // Assuming relevant_tags is part of the form
    if (relevantTags && Array.isArray(relevantTags)) {
      relevantTags.forEach((value: any, index: number) =>
        formData.append(`relevant_tags[${index}]`, value)
      );
    }
  
    // Handle profile picture
    const profilePic = this.edituserForm.get('profile_pic')?.value;
    if (profilePic instanceof File) {
      formData.append('profile_pic', profilePic, profilePic.name);
    }
  
    // Make the HTTP request to update the user profile
    this.http.post(`${environment.API_URL}edit_user_profile`, formData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Profile updated successfully.');
          this.fetchUserProfile(); // Refresh user details
          this.editUserProfile = false;
        } else {
          this.message.error(response.message || 'Failed to update profile.');
        }
      },
      error: (error) => {
        console.error('Error updating profile:', error);
        this.message.error('An error occurred while updating your profile.');
      },
    });
  }
  followButton(): void {
    const apiUrl = `${environment.API_URL}follow_user`;
    const requestData = {
      follower_id: this.userID,
      followed_id: this.dostUserID,
    };
  
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        const followMessage = this.userDetails.already_following
          ? 'Unfollowed successfully'
          : 'Followed successfully';
        this.message.success(followMessage);
        this.fetchUserProfile();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update follow status');
      },
    });
  }
  
  onNotifyContentClick(): void {
    // Collect all selected notify_content_ids
    const selectedContentIds = this.notificationContnetList
      .filter(content => content.selected)  // Filter to get only selected items
      .map(content => content.notify_content_id); // Extract the ids
  
    if (selectedContentIds.length > 0) {
      console.log('Notifying for Content IDs:', selectedContentIds);
    
      // Prepare API request data
      const apiUrl = `${environment.API_URL}notify_me`;
      const requestData = {
        user_id: this.userID,
        notified_to: this.dostUserID,
        notify_content_id: selectedContentIds  // Pass the selected IDs in an array
      };
    
      // Send POST request
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.message.success('Notification Sent');
          this.fetchUserProfile(); // Update user details after notification
        },
        error: (error) => {
          console.error('Error:', error);
          this.message.error('Failed to send notification');
        },
      });
    } else {
      this.message.warning('Please select at least one content to notify');
    }
  }
  reportBlockMuteuser(reportContentId: number, actionName: string): void {
    const apiUrl = `${environment.API_URL}report_user`;
    const requestData = {
      user_id: this.userID,
      report_user_id: this.dostUserID,
      report_content_id: reportContentId
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success(`${actionName} to ` + this.userDetails.name);
        this.fetchUserProfile(); 
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to send notification');
      },
    });
  }
  notificationContnetList:any[]=[];
  fetchAllContnetList(ID:any ): Promise<void> {
 
  console.log(ID);
  
    const requestData = {
      role_id: [ID]
    };
    const apiUrl = `${environment.API_URL}notify_content_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.notificationContnetList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          
          return reject();
        },
        complete: () => {
          
          return resolve();
        },
      });
    });
  }

  isOptionSelected(questionId: any, optionId: number): boolean {
    // Filter the answers for the specific questionId
    console.log(optionId);

    const filteredAnswer = this.userQuestionAnsList.find(
      (item) => item.question_id === questionId
    );

    // Check if the optionId is part of already_answered_option
    if (filteredAnswer) {
      return filteredAnswer.already_answered_option
        ? filteredAnswer.already_answered_option.includes(optionId)
        : false;
    }
    debugger;
    return false;
  }
}
