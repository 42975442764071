import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cryptopage',
  templateUrl: './cryptopage.component.html',
  styleUrls: ['./cryptopage.component.scss'],
})
export class CryptopageComponent {
  allSymbols: any[] = [];

  dostExplanins: any[] = [];
  similarStocks: any[] = [];
  quizz: any[] = [];
  tags: any[] = [];
  chainList: any[] = [];
  chainsContactList: any[] = [];
  sectors: any[] = [];
  isVisible = false; // Controls the visibility of the modal
  isEditMode = false;
  industries: any[] = [];
  exchanges: any[] = [];
  symbolId: any;
  selectedTabIndex = 0;
  fileList: NzUploadFile[] = []; // For nz-upload
  isEditVisible = false;
  addDrawer: boolean = false;
  editDrawer: boolean = false;
  tableLoading: boolean = true;
  fallbackImage: string = 'assets/fallback.webp';
  userId: any = localStorage.getItem('userId');
  storageUrl = environment.STORAGE_URL;
  uploadedLogo: string | ArrayBuffer | null = null;
  uploadedLogoName: string = '';
  symbolLogoFile: File | null = null;
  symbolLogoPreview: string | ArrayBuffer | null = null;

  searchQuery: string = '';
  cryptoForm: FormGroup = new FormGroup({
    symbol: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    active_status_id: new FormControl(1),
    description: new FormControl('', Validators.required),
    contact_address: new FormControl('', Validators.required),
    website: new FormControl(''),
    sector_id: new FormControl(null, Validators.required),
    similar_symbols: new FormControl(null),
    quiz_id: new FormControl(null),
    chain_id: new FormControl(null),
    dost_explains: new FormControl(null),
    relevant_tags: new FormControl(null),
    symbol_logo: new FormControl('', Validators.required),
    category_id: new FormControl(2),
    symbol_id: new FormControl(''),
  });
  constructor(private http: HttpClient, private message: NzMessageService) {}

  ngOnInit(): void {
    this.fetchAllData();
  }

  submitCryptoForm(): void {
    const apiUrl = this.isEditMode
      ? `${environment.API_URL}edit_symbol`
      : `${environment.API_URL}add_symbol`;
    const formData = new FormData();
    const {
      similar_symbols,
      dost_explains,
      quiz_id,
      contact_address,
      website,
      sector_id,
      chain_id,
      relevant_tags,
      symbol,
      name,
      description,
      active_status_id,
      symbol_id,
    } = this.cryptoForm.value;

    if (similar_symbols && Array.isArray(similar_symbols)) {
      similar_symbols.forEach((value: any, index: number) =>
        formData.append(`similar_symbols[${index}]`, value)
      );
    }
    if (dost_explains && Array.isArray(dost_explains)) {
      dost_explains.forEach((value: any, index: number) =>
        formData.append(`dost_explains[${index}]`, value)
      );
    }
    if (sector_id && Array.isArray(sector_id)) {
      sector_id.forEach((value: any, index: number) =>
        formData.append(`sector_id[${index}]`, value)
      );
    }
    if (chain_id && Array.isArray(chain_id)) {
      chain_id.forEach((value: any, index: number) =>
        formData.append(`chain_id[${index}]`, value)
      );
    }
    if (relevant_tags && Array.isArray(relevant_tags)) {
      relevant_tags.forEach((value: any, index: number) =>
        formData.append(`relevant_tags[${index}]`, value)
      );
    }

    // Append other values to formData
    formData.append('category_id', '2');
    formData.append('user_id', this.userId);
    formData.append('symbol', symbol);
    formData.append('name', name);
    formData.append('website', website);
    formData.append('contact_address', contact_address);
    formData.append('description', description);
    formData.append('active_status_id', active_status_id);
    formData.append('quiz_id', quiz_id);

    if (this.isEditMode && symbol_id) {
      formData.append('symbol_id', symbol_id);
    }

    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }

    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.symbolId = response.symbol_id ?? this.cryptoForm.value.symbol_id;
          if (this.symbolId) {
            this.selectedTabIndex = 1;
            this.fetchAllData();
          }
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  addChainContractAddress() {
    const apiUrl = `${environment.API_URL}add_chain_contact_address`;
    const requestBody = {
      chain_id: this.cryptoForm.get('chain_id')?.value,
      contact_address: this.cryptoForm.controls['contact_address']?.value,
      symbol_id: this.isEditVisible
        ? this.cryptoForm.get('symbol_id')?.value
        : this.symbolId,
      user_id: this.userId,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.fetchAllData();
        this.cryptoForm.reset(); // Reset form in both cases
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.allSymbols = this.allSymbols.filter((symbol) =>
        symbol.symbol.toLowerCase().includes(query)
      );
    } else {
      this.fetchAllData();
    }
  }

  setAsprimary(ID: number): void {
    const apiUrl = `${environment.API_URL}set_primary_chain_contact`;
    const requestData = { log_id: ID };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response?.success) {
          this.message.success(
            response.message || 'Primary contact set successfully.'
          );
        } else {
          this.message.error(
            response?.message || 'Error occurred, please try later.'
          );
        }
      },
      error: (error) => {
        console.error('API Error: ', error);
        this.message.error('Error occurred, please try later.');
      },
    });
  }
  onFileChange(event: Event, isEdit: boolean = false): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;
        if (result) {
          this.symbolLogoPreview = result as string;
          this.symbolLogoFile = file;
          this.cryptoForm.get('symbol_logo')?.setValue(file);
        }
      };
      reader.readAsDataURL(file);
    }
  }
  removeUploadedLogo() {
    this.symbolLogoPreview = '';
  }

  currentEditQuizId?: number;
  fetchAllData(symbolId?: number): Promise<void> {
    this.tableLoading = true;

    const requestData = {
      sector_type_id: 2,
    };

    const apiUrlSectors = `${environment.API_URL}sector_dropdown`;
    const apiUrlCourses = `${environment.API_URL}courses_dropdown`;
    const apiUrlSymbols = `${environment.API_URL}fetch_all_cryptos`;
    const apiUrlChainLogs = `${environment.API_URL}fetch_chain_contact_log`;
    const apiUrlSimilarStocks = `${environment.API_URL}cryptos_dropdown`;
    const apiUrlTags = `${environment.API_URL}tags_dropdown`;
    const apiUrlChains = `${environment.API_URL}fetch_all_chains`;
    const apiUrlQuizzes = `${environment.API_URL}fetch_quizzes`;

    // Create an array of all the observables
    const requests = [
      this.http.post(apiUrlSectors, requestData),
      this.http.get(apiUrlCourses),
      this.http.get(apiUrlSymbols),
      this.http.post(apiUrlChainLogs, {
        symbol_id: symbolId || this.cryptoForm.controls['symbol_id']?.value,
      }),
      this.http.get(apiUrlSimilarStocks),
      this.http.get(apiUrlTags),
      this.http.get(apiUrlChains),
      this.http.get(apiUrlQuizzes),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(requests).subscribe({
        next: (responses: any) => {
          // Assuming order of responses matches the order of requests
          this.sectors = responses[0];
          this.dostExplanins = responses[1];
          this.allSymbols = responses[2];
          this.chainsContactList = responses[3];
          this.similarStocks = responses[4];
          this.tags = responses[5];
          this.chainList = responses[6];

          // Handling the quiz API response
          if (responses[7]?.success) {
            // Simply assign the quiz data without parsing 'category_id' and 'symbol_id'
            this.quizz = responses[7].data;
          } else {
            console.error('Error fetching quizzes data');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  openModalOrEditDrawer(symbolId?: number): void {
    this.fetchAllData(symbolId);
    if (!symbolId) {
      this.isEditMode = false;
      this.cryptoForm.reset();
      this.symbolLogoPreview = '';
      this.symbolId = null;
    } else {
      this.isEditMode = true;
      this.symbolId = symbolId;

      const selectedSymbol = this.allSymbols.find(
        (symbol) => symbol.symbol_id === symbolId
      );
      if (selectedSymbol) {
        this.cryptoForm.patchValue({
          symbol: selectedSymbol.symbol,
          name: selectedSymbol.name,
          description: selectedSymbol.description,
          sector_id: selectedSymbol.sector_id,
          website: selectedSymbol.website,
          contact_address: selectedSymbol.contact_address,
          chain_id: selectedSymbol.chain_id,
          relevant_tags: selectedSymbol.relevant_tags,
          dost_explains: selectedSymbol.dost_explains,
          quiz_id: selectedSymbol.quiz_id,
          similar_symbols: selectedSymbol.similar_symbols,
          active_status_id: selectedSymbol.active_status_id,
          symbol_id: selectedSymbol.symbol_id,
          symbol_logo: selectedSymbol.symbol_logo,
        });
        this.chainsContactList = selectedSymbol.chain;
        this.symbolLogoPreview = selectedSymbol.symbol_logo
          ? `${this.storageUrl}${selectedSymbol.symbol_logo}`
          : null;

        console.log('Edit Symbol', this.cryptoForm.value);
      }
    }
    this.isVisible = true; // Show modal
  }

  deleteRecord(id: number, type: string) {
    let apiUrl: string = ''; // Initialize apiUrl with an empty string
    let requestData: any = {}; // Initialize requestData as an empty object

    // Set API endpoint and request data based on 'type'
    if (type === 'symbol') {
      apiUrl = `${environment.API_URL}delete_symbol`;
      requestData = { symbol_id: [id] };
    } else if (type === 'contact') {
      apiUrl = `${environment.API_URL}delete_chain_contact_address`;
      requestData = { log_id: id };
    } else {
      // Optional: You can throw an error or return early if the type is invalid
      console.error('Invalid type provided for delete operation');
      return;
    }

    console.log('Request Data:', requestData);

    // Perform the HTTP request
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response:', response);
        if (response.success) {
          this.fetchAllData().then(() => {
            const successMessage =
              type === 'symbol' ? 'Crypto deleted' : 'Contact address deleted';
            this.message.success(successMessage);
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  cancel(): void {}

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.isEditVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isEditVisible = false;
  }
  sortByField =
    (field: string, isDate: boolean = false): NzTableSortFn<any> =>
    (a, b) =>
      isDate
        ? new Date(a[field]).getTime() - new Date(b[field]).getTime()
        : a[field]?.localeCompare(b[field]);
  sortByTicker = this.sortByField('symbol');
  sortByName = this.sortByField('name');
  sortByStatus = this.sortByField('active_status_id');
  sortByLogo = this.sortByField('symbol_logo');
  sortBySector = this.sortByField('sector');
  sortByExchange = this.sortByField('exchange');
  sortByIndustry = this.sortByField('industry');
  sortByCategory = this.sortByField('category_name');
  sortByCreatedOn = this.sortByField('created_at', true);
  sortByUpdatedOn = this.sortByField('updated_at', true);
  sortByUpdatedBy = this.sortByField('updated_by_name');
  sortByUpdatedAt = this.sortByField('updated_at', true);
}
