import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Tag } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  tags: Tag[] = [];
  filteredTags = [...this.tags];
  similarCryptos: any[] = [];
  approvedTags: any[] = [];
  categoryList: any[] = [];
  similarStocks: any[] = [];
  stockCategoryId = 1;
  cryptoCategoryId = 2;
  tableLoading = true;
  isModalVisible = false;
  isEditMode = false;
  modalTitle = 'Add Tag';
  tagForm: FormGroup;
  addDrawer = false;
  editDrawer = false;
  searchQuery = '';
  isStockSelected = false;
  isCryptoSelected = false;
  useAdminLayout = false;

  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.tagForm = this.fb.group({
      tag_name: [''],
      description: [''],
      related_tag_id: [[]],
      tag_category_id: [[]],
      related_stock_id: [[]],
      related_crypto_id: [[]],
      tag_id: [null], // Only used for edit mode
    });
  }

  ngOnInit() {
    const role = sessionStorage.getItem('role');
    this.useAdminLayout = role === 'admin';

    this.fetchInitialData();
    this.fetchSimilarStocks();
    this.fetchSimilarCryptos();
    this.fetchTagCategory();
    this.fectchApprovedTags();

    this.tagForm.get('tag_category_id')?.valueChanges.subscribe(() => {
      this.updateRelatedFields();
    });
  }

  openModal(isEdit = false, tagData: Tag | null = null) {
    this.isModalVisible = true;
    this.isEditMode = isEdit;
    this.modalTitle = isEdit ? 'Edit Tag' : 'Add Tag';

    if (isEdit && tagData) {
      this.tagForm.patchValue(tagData);
    } else {
      this.tagForm.reset();
    }
  }

  closeModal() {
    this.isModalVisible = false;
    this.tagForm.reset();
  }

  openAddDrawer(): void {
    this.isModalVisible = true;
  }

  confirm(id: number): void {
    this.deleteTag(id);
  }

  updateApprovalStatus(ID: number, isApproved: boolean): void {
    const apiUrl = `${environment.API_URL}approve_tag`;
    const requestData = {
      updated_by: localStorage.getItem('userId'),
      tag_id: ID,
      is_approved: isApproved ? 1 : 0,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Tag approval status updated');
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: () => {
        this.message.error('Error occurred, please try later');
      },
    });
  }

  submitForm() {
    const apiUrl = this.isEditMode
      ? `${environment.API_URL}edit_tag`
      : `${environment.API_URL}add_tag_in_setup`;
    const requestData = {
      ...(this.isEditMode
        ? { updated_by: sessionStorage.getItem('userId') }
        : { created_by: localStorage.getItem('userId') }),
      ...this.tagForm.value,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success(
            this.isEditMode
              ? 'Tag updated successfully'
              : 'Tag added successfully'
          );
          this.closeModal();
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: () => {
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchInitialData();
      },
    });
  }

  updateRelatedFields() {
    const tagCategories = this.tagForm.controls['tag_category_id'].value || [];
    const includesStock = tagCategories.includes(this.stockCategoryId);
    const includesCrypto = tagCategories.includes(this.cryptoCategoryId);

    if (!includesStock) {
      this.tagForm.controls['related_stock_id'].setValue([]);
    }
    if (!includesCrypto) {
      this.tagForm.controls['related_crypto_id'].setValue([]);
    }
  }
  fetchSimilarStocks(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}stocks_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.similarStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchSimilarCryptos(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.similarCryptos = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fectchApprovedTags(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}tag_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.approvedTags = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchTagCategory(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}category_dropdown
`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.categoryList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  cancel(): void {}
  deleteTag(id: number) {
    const apiUrl = `${environment.API_URL}delete_tag`;
    const requestData = {
      tag_id: id,
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('Tag deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  sortByName = (a: any, b: any): number => {
    return (a.tag_name || '').localeCompare(b.tag_name || '');
  };

  sortByCreatedAt = (a: any, b: any): number => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  sortByUpdatedAt = (a: any, b: any): number => {
    return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  };

  sortByApproved = (a: any, b: any): number => {
    return a.is_approved - b.is_approved;
  };
  filterTags() {
    const query = this.searchQuery.toLowerCase();
    this.filteredTags = this.tags.filter((tag) =>
      tag.tag_name.toLowerCase().includes(query)
    );
  }

  fetchInitialData(): Promise<any> {
    this.apiService.getTags().subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.tags = response.data;
        console.log(this.tags);

        this.filteredTags = [...this.tags]; // Initialize filteredTags with the fetched data
        this.filterTags();
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }
}
