<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item>My Activity</nz-breadcrumb-item>
    </nz-breadcrumb>
    <div class="container my-5">
        <div class="row g-4">
            <h2>My Followers</h2>
            <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6" *ngFor="let allfollowerLists of allFollowwerLists">
                <div class="card  border-light author-card h-100 shadow-sm">
                    <div class="card-body text-center">
      
                        <nz-avatar  [nzSize]="64" 
                        [nzSrc]="allfollowerLists.profile_pic ? (storageUrl + allfollowerLists.profile_pic) : 'assets/dummy-images/dummy_user_profile.jpg'"  
                        nz-comment-avatar 
                        nzIcon="user">
                      </nz-avatar>
                      <p class="card-text text-muted">
                        Followers: {{ allfollowerLists.followers_count }} Following: {{ allfollowerLists.following || 0 }}
                    </p>
                        <h5 class="card-title">{{ allfollowerLists.followed_to_name }}</h5>
                        <button nz-button nzType="dashed">View Profile</button>

                    </div>
                </div>
            </div>
        </div>
        
      <div class="row">
        <div class="posts-container" *ngIf="allLikePosts && allLikePosts.length > 0">
            <h2>Posts</h2>
          
            <div class="posts-scroll">
              <div *ngFor="let allPosts of allLikePosts" class="col-md-12">
                <div class="card shadow-sm border-light d-flex flex-column h-100">
                  <div class="card-body">
                    <h5 class="card-title">
                      <p nz-paragraph [innerHTML]="allPosts.caption"></p>
                    </h5>
                
                  </div>
                
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div>
            <h2>Articles</h2>
            <app-article-card-design [articleData]="allLikedArticles" [showSlideButtons]="true"
                (slideLeftEvent)="slideLeft()" (slideRightEvent)="slideRight()" (refresh)="fetchAllLikedArtilces()">
            </app-article-card-design>
        </div>
      </div>
    </div>
</app-main-fiminize-layout>
