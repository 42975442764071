import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Chain } from '@angular/compiler';
@Component({
  selector: 'app-look-ups',
  templateUrl: './look-ups.component.html',
  styleUrls: ['./look-ups.component.scss'],
})
export class LookUpsComponent implements OnInit {
  industries: any[] = [];
  exchanges: any[] = [];
  sectors: any[] = [];
  chains:any[]=[]; 
  selectedCategory:any[]=[]

  filteredIndustries: any[] = [];
  filteredExchanges: any[] = [];
  filteredSectors: any[] = [];
  filteredChains: any[] = [];

  industrySearchText: string = '';
  exchangeSearchText: string = '';
  sectorSearchText: string = '';
  chainSearchText: string = '';
  isDrawerVisible = false;
  drawerType: 'Industry' | 'Exchange' | 'Sector' | 'Chain' = 'Industry';
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.createForms();
    this.fetchAllData();
  }
  openDrawer(type: 'Industry' | 'Exchange' | 'Sector' | 'Chain'): void {
    this.drawerType = type;
    this.isDrawerVisible = true;
  } 

 
  filterData(type: 'industry' | 'exchange' | 'sector' | 'chain'): void {
    const searchText =
      type === 'industry'
        ? this.industrySearchText
        : type === 'exchange'
        ? this.exchangeSearchText
        : type === 'sector'
        ? this.sectorSearchText
        : this.chainSearchText;

    switch (type) {
      case 'industry':
        this.filteredIndustries = this.industries.filter((item) =>
          item.industry.toLowerCase().includes(searchText.toLowerCase())
        );
        break;
      case 'exchange':
        this.filteredExchanges = this.exchanges.filter((item) =>
          item.exchange.toLowerCase().includes(searchText.toLowerCase())
        );
        break;
      case 'sector':
        this.filteredSectors = this.sectors.filter((item) =>
          item.sector.toLowerCase().includes(searchText.toLowerCase())
        );
        break;
      case 'chain':
        this.filteredChains = this.chains.filter((item) =>
          item.chain.toLowerCase().includes(searchText.toLowerCase())
        );
        break;
    }
  }
  selectedSectorType: number = 1;

  // Handle tab change and update sector type
  onTabChange(index: number): void {
    this.selectedSectorType = index === 0 ? 1 : 2;
    this.fetchAllData(); // Fetch dropdown data when the tab changes
  }
  
  fetchAllData(): void {
    forkJoin({
      industries: this.apiService.getIndustries(),
      exchanges: this.apiService.getExchanges(),
      sectors: this.apiService.getSectors(this.selectedSectorType),
      chains: this.apiService.getChains(),
      categoryDropdown:this.apiService.getSelectedDropdown(),
    }).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.industries = response.industries;
        this.exchanges = response.exchanges;
        this.sectors = response.sectors;
        this.chains = response.chains;
        this.selectedCategory=response.categoryDropdown
        this.filteredIndustries = [...this.industries];
        this.filteredExchanges = [...this.exchanges];
        this.filteredSectors = [...this.sectors];
        this.filteredChains = [...this.chains];
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Failed to load data');
      },
      complete: () => {
        console.log('Data fetch complete');
      },
    });
  }

  filterIndustries(): void {
    this.filteredIndustries = this.industries.filter((industry) =>
      industry.industry
        .toLowerCase()
        .includes(this.industrySearchText.toLowerCase())
    );
  }
  filterChains(): void {
    this.filteredChains = this.chains.filter((chain) =>
      chain.chain.toLowerCase().includes(this.chainSearchText.toLowerCase())
    );
  }
  filterExchanges(): void {
    this.filteredExchanges = this.exchanges.filter((exchange) =>
      exchange.exchange
        .toLowerCase()
        .includes(this.exchangeSearchText.toLowerCase())
    );
  }

  filterSectors(): void {
    this.filteredSectors = this.sectors.filter((sector) =>
      sector.sector.toLowerCase().includes(this.sectorSearchText.toLowerCase())
    );
  }
  confirm(id: number, type: 'industry' | 'exchange' | 'sector' | 'chain'): void {
    if (type === 'industry') {
      this.deleteIndustry(id);
    } else if (type === 'exchange') {
      this.deleteExchange(id);
    } else if (type === 'sector') {
      this.deleteSector(id);
    } else if (type === 'chain') {
      this.deleteChain(id);
    }
  }
  deleteIndustry(id: number): void {
    const apiUrl = `${environment.API_URL}delete_industry`;
    const requestData = { industry_id: [id] };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Industry deleted successfully');
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchAllData();
      },
    });
  }

  deleteExchange(id: number): void {
    const apiUrl = `${environment.API_URL}delete_exchange`;
    const requestData = { exchange_id: [id] };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Exchange deleted successfully');
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchAllData();
      },
    });
  }
  deleteChain(id: number): void {
    const apiUrl = `${environment.API_URL}delete_chains`;
    const requestData = { chain_id: [id] };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Chain deleted successfully');
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchAllData();
      },
    });
  }
  deleteSector(id: number): void {
    const apiUrl = `${environment.API_URL}delete_sector`;
    const requestData = { sector_id: [id] };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Sector deleted successfully');
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchAllData();
      },
    });
  }

  industryForm!: FormGroup;
  exchangeForm!: FormGroup;
  sectorForm!: FormGroup;
  chainForm!: FormGroup;
  createForms(): void {
    this.industryForm = new FormGroup({
      industry: new FormControl(null, Validators.required),
    });

    this.exchangeForm = new FormGroup({
      exchange: new FormControl(null, Validators.required),
      
    });

    this.sectorForm = new FormGroup({
      sector: new FormControl(null, Validators.required),
      sector_type_id:new FormControl(null, Validators.required),
    });
    this.chainForm = new FormGroup({
      chain: new FormControl(null, Validators.required),
    });
  }
  closeDrawer(): void {
    this.isDrawerVisible = false;
    this.industryForm.reset();
    this.exchangeForm.reset();
    this.sectorForm.reset();
    this.chainForm.reset();
  }
  handleSave(): void {
    let apiUrl: string | undefined;
    let requestData: any;
  
    // Assign the appropriate API URL and request data based on the drawerType
    if (this.drawerType === 'Industry') {
      apiUrl = `${environment.API_URL}add_industry`;
      requestData = {
        user_id: 1,
        industry: this.industryForm.value.industry,
      };
    } else if (this.drawerType === 'Exchange') {
      apiUrl = `${environment.API_URL}add_exchange`;
      requestData = {
        user_id: 3,
        exchange: this.exchangeForm.value.exchange,
      };
    } else if (this.drawerType === 'Sector') {
      apiUrl = `${environment.API_URL}add_sector`;
      requestData = {
        user_id: 1,
        sector: this.sectorForm.value.sector,
        sector_type_id:this.sectorForm.value.sector_type_id,
      };
    } else if (this.drawerType === 'Chain') {
      apiUrl = `${environment.API_URL}add_chains`;
      requestData = {
        user_id: 1,
        chain: this.chainForm.value.chain,
      };
    }
  
    // Check if apiUrl is assigned (valid drawerType)
    if (!apiUrl) {
      console.error('Invalid drawer type:', this.drawerType);
      this.message.error('Invalid operation, please try again');
      return;
    }
  
    // Perform the API request
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.closeDrawer();
        this.message.success(
          `${this.drawerType.charAt(0).toUpperCase() + this.drawerType.slice(1)} added successfully`
        );
        this.fetchAllData(); // Fetch updated data
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  
  
  getFormGroup(): FormGroup {
    switch (this.drawerType) {
      case 'Industry':
        return this.industryForm;
      case 'Exchange':
        return this.exchangeForm;
      case 'Sector':
        return this.sectorForm;
        case 'Chain':
          return this.chainForm;
      default:
        return new FormGroup({});
    }
  }
  sortBy(property: string, isDate: boolean = false): (a: any, b: any) => number {
    return (a: any, b: any): number => {
      if (isDate) {
        return new Date(a[property]).getTime() - new Date(b[property]).getTime();
      }
      return a[property].localeCompare(b[property]);
    };
  }
  sortByIndustry = this.sortBy('industry');
  sortByIndustryCreatedBy = this.sortBy('created_by_name');
  sortByIndustryCreatedAt = this.sortBy('created_at', true);

  sortByExchange = this.sortBy('exchange');
  sortByExchangeCreatedBy = this.sortBy('created_by_name');
  sortByExchangeCreatedAt = this.sortBy('created_at', true);

  sortBySector = this.sortBy('sector');
  sortBySectorCreatedBy = this.sortBy('created_by_name');
  sortBySectorCreatedAt = this.sortBy('created_at', true);
}
