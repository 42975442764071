import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookmark-articles',
  templateUrl: './bookmark-articles.component.html',
  styleUrls: ['./bookmark-articles.component.scss']
})
export class BookmarkArticlesComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  allBookmarkList:any[]=[];
  allDostAlertList:any;
  allbookmakrPostsList:any[]=[]
  loggedIn: boolean = !!this.userId;
  bookmakrArticles:any[]=[]
  bookmarkComments:any[]=[];
  dostAlertVisible:boolean=false;
  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();
  ngOnInit(): void {
   this.fetchallBookmarks();
  }
  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }
  selectedDostAlert: any; 
  openDostAlertModal(dostAlert: any) {
    this.dostAlertVisible = true; 
    this.selectedDostAlert = dostAlert; // Store the selected alert for display
    // Show the modal
  }
  fetchallBookmarks() {
    const apiUrl = `${environment.API_URL}fetch_user_bookmarks`;
    const requestBody = { user_id: this.userId };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.allBookmarkList = response.article_bookmarks;
       this. allDostAlertList=response.dost_alert_bookmarks;
       this.allbookmakrPostsList=response.post_bookmarks;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch alerts.');
      },
    });
  }
  updateBookmark(id: number, type: 'dost_alert' | 'post') {
    let selectedItem: any;
    if (type === 'dost_alert') {
      selectedItem = this.allDostAlertList.find(
        (alert: { dost_alert_id: number }) => alert.dost_alert_id === id
      );
    } else if (type === 'post') {
      selectedItem = this.allbookmakrPostsList.find(
        (post: { diary_post_id: number }) => post.diary_post_id === id
      );
    }
  
    if (!selectedItem) {
      this.message.error(`${type === 'dost_alert' ? 'Alert' : 'Post'} not found.`);
      return;
    }
  
    if (!selectedItem.bookmark_status) {
      this.message.warning('This item is not bookmarked.');
      return;
    }
  
    // Determine the API endpoint based on the type
    const apiUrl =
      type === 'dost_alert'
        ? `${environment.API_URL}delete_dost_alert_bookmark`
        : `${environment.API_URL}delete_bookmark`;
  
    // Prepare the request body
    const requestBody = {
      user_id: this.userId,
      [type === 'dost_alert' ? 'dost_alert_id' : 'diary_post_id']: id
    };
  
    // Make the API request to remove the bookmark
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Update the bookmark status locally
          selectedItem.bookmark_status = false;
  
          // Optionally refresh the bookmark list
          this.fetchallBookmarks();
  
          // Display a success message
          this.message.success('Bookmark removed successfully.');
        } else {
          this.message.error('Failed to remove bookmark.');
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to remove bookmark.');
      },
    });
  }
  
  
  
closeDrawer() {
  this.dostAlertVisible = false;
}
}
