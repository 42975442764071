<div *ngIf="!loadingData" class="frame_article">

    <h2 class="mt-2">{{ title }}</h2>
    <p class="mt-2 mb-2">{{ description }}</p>
    <div class="latest_article pb-2">
        <div class="position-relative">
            <div class="scrollable-cards-container position-relative">

                <button nz-button nzType="default" class="btn btn-outline-primary position-absolute previous-btn"
                    (click)="scroll('left')" nzShape="circle">
                    <span nz-icon nzType="left"></span>
                </button>
                <div #scrollContainer class="scrollable-cards mb-4 d-flex overflow-hidden" style="cursor: pointer;">
                    <div class="article" *ngFor="let item of articleData">
                        <div class="card" style="width: 250px; height: 380px; border-radius: 20px;">
                            <img class="article-image" alt="example"
                                [src]="item.featured_img_path ? (storageUrl + item.featured_img_path) : fallbackImage" />

                            <div class="card-body" [routerLink]="['/article', item.slug, item.id]">
                                <span *ngIf="item.category_string" style="cursor: pointer;"
                                    [routerLink]="['/category_articles', item.category_string | hyphenatePipe, item.category_id]"
                                    class="inline-flex p-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{
                                    item.category_string }}</span>
                                <div>
                                    <p style="margin-bottom: 0px; font-family: Heading;">
                                        {{ item.title | truncate: 50 | titlecase }}
                                    </p>
                                </div>
                                <div class="description_text">
                                    <p style="margin-top: 0px; font-size: 14px;">
                                        {{ item.meta_description | truncate: 50 }}
                                    </p>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="d-flex bd-highlight mb-3">
                                    <div class="p-2 bd-highlight">
                                        <span style="cursor: pointer;"
                                            [routerLink]="['/author-details', item.created_by]"
                                            class="d-flex align-items-center">
                                            <nz-avatar
                                                [nzSrc]="item.profile_pic ? (storageUrl + item.profile_pic) : 'assets/dummy-images/dummy_user_profile.jpg'"
                                                nzSize="small" nzIcon="user" class="me-2"></nz-avatar>
                                            <h5 class="mb-0 text-muted">{{ item.created_by_string }}</h5>
                                        </span>
                                        <span *ngIf="item.post_type_name" style="cursor: pointer;">
                                            <nz-tag class="rounded mt-1" nzColor="purple"> {{ item.post_type_name
                                                }}</nz-tag>

                                        </span>
                                    </div>


                                    <div class="ms-auto p-2 bd-highlight">
                                        <button (click)="$event.stopPropagation();"
                                            (click)="toggleBookmark(item.id, item.bookmark_status)">
                                            <i
                                                [class]=" item.bookmark_status === 0 ? 'fa-regular fa-bookmark': 'fa-solid fa-bookmark'"></i>
                                        </button>
                                        <i *ngIf="item.is_free_artical === 1" class="fa-solid fa-lock"
                                            style="margin-left: 8px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button nz-button nzType="default" class="btn btn-outline-primary position-absolute next-btn"
                    (click)="scroll('right')" nzShape="circle">
                    <span nz-icon nzType="right"></span>
                </button>
            </div>
        </div>