import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, interval } from 'rxjs';
import { Article } from 'src/app/Interfaces/Content';
import { Course, Lecture } from 'src/app/Interfaces/Course';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-read-article',
  templateUrl: './read-article.component.html',
  styleUrls: ['./read-article.component.scss'],
})
export class ReadArticleComponent {
  userRole: string = ''; // Replace with the actual user's role
  fallbackImage: string = 'assets/fallback.webp';
  featuredArticles: Article[] = [];
  editorsPicksArticles: Article[] = [];
  trendingArticles: Article[] = [];
  latestArticles: Article[] = [];
  likeCount: number = 0;
  isLiked: boolean = false;
  randomImageUrl = '';
  articleId: any;
  addDrawer: boolean = false;
  storageUrl = environment.STORAGE_URL;
  comments: any[] = [];
  isUserLoggedIn: boolean = false;
  quizQuestions: any[] = [];
  roleID = localStorage.getItem('role_id');
  isLoggedIn = localStorage.getItem('isLoggedIn');
  time: string = new Date().toDateString();
  mainArticle: any = {};
  userId = localStorage.getItem('userId');

  isLoading: boolean = true;
  isCourseModalLoading: boolean = false;

  inputValue = '';
  isCourseModalVisible: boolean = false;
  characterCount: number = 0;
  modalCourseFullLink: string | null = null; // Variable to store the course link
  modalCourseSlug: string | null = null; // Variable to store the course slug
  commentForm: FormGroup;
  modalCourseData?: Course;
  modalCourseLectureData?: Lecture;
  currentLectureNumber: number = 1;
  currentQuestionIndex: number = 0;
  constructor(
    private apiService: FetchApiService,
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
    private fetchApiService: FetchApiService,
    private fb: FormBuilder,
    private modal: NzModalService
  ) {
    this.commentForm = this.fb.group({
      comment_text: ['', Validators.required],
    });
  }

  ngOnInit() {
    const role = sessionStorage.getItem('role');
    this.userRole = role ?? '';

    this.route.params.subscribe((params) => {
      this.articleId = +params['id']; // Convert the parameter to a number
      console.log(this.articleId);
      this.fetchInitialData(this.articleId);
      this.fetchOtherData();
      this.loadComments();
    });
  }

  Content: any[] = [];
  fetchInitialData(id: number): Promise<any> {
    const requestData = {
      article_id: this.articleId,
      user_id: localStorage.getItem('userId'),
      subscription_plan_id: localStorage.getItem('subscription_plan_id'),
    };
    console.log(requestData);

    this.isLoading = true;
    const apiUrl = `${environment.API_URL}article_details`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log(response.data);
          let sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(
            response.data.content
          );
          response.data.content = sanitizedContent;
          this.mainArticle = response.data;
          this.Content =
            response.data.content.changingThisBreaksApplicationSecurity;
          console.log(this.Content);

          this.populateRandomImageUrls();
          resolve(true);
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.isLoading = false;
          reject();
        },
      });
    });
  }

  fetchOtherData() {
    this.isLoading = true;
    const editorsPicksArticles$ = this.fetchApiService.getEditorsPicks();
    forkJoin([editorsPicksArticles$]).subscribe((results) => {
      this.editorsPicksArticles = results[0].data;
      this.isLoading = false;
      // console.log(results);
    });
  }

  goBack() {
    this.location.back();
  }
  updateCharacterCount(): void {
    this.characterCount =
      this.commentForm.get('comment_text')?.value.length || 0;
  }
  populateRandomImageUrls() {
    const randomIndex = Math.floor(Math.random() * 8); // 8 images preset in dummy-images folder
    // this.randomImageUrl = `assets/dummy-images/dumyimg${randomIndex + 1}.jpg`;
    this.randomImageUrl = `${environment.STORAGE_URL}${this.mainArticle.featured_img_path}`;
  }

  // Function to sanitize a URL
  sanitizeURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // Below Function is used to fetch data of a particular lecture from a course slug
  fetchCourseLectureData(
    lectureNumber: number = this.currentLectureNumber
  ): Promise<any> {
    this.isCourseModalLoading = true;
    const apiUrl = `${environment.API_URL}lecture_data`;
    return new Promise((resolve, reject) => {
      this.http
        .post(apiUrl, {
          use_slug: true,
          course_slug: this.modalCourseSlug,
          course_id: null,
          lecture_number: lectureNumber,
        })
        .subscribe({
          next: (response: any) => {
            let courseData = response[0];
            let lectureData = response[0].courseSections[0]?.lectures[0];

            this.modalCourseLectureData = lectureData;
            this.modalCourseData = courseData;

            console.log(this.modalCourseData);
            console.log(this.modalCourseLectureData);
            resolve(true);
          },
          error: (error) => {
            console.error('Error: ', error);
            this.isCourseModalLoading = false;
            reject();
          },
          complete: () => {
            this.isCourseModalLoading = false;
          },
        });
    });
  }

  extractCourseSlug(url: string): string | null {
    const regex = /\/course\/(.*?)\/preview/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }
  modalHtmlContent: SafeHtml = '';

  onLinkClick(event: MouseEvent): void {
    let anchor: HTMLAnchorElement | null = null;
    const target = event.target as HTMLAnchorElement;
    if (target.href && target.href.includes('/course/')) {
      event.preventDefault();
      this.isCourseModalVisible = true;

      this.modalCourseFullLink = target.href;
      this.modalCourseSlug = this.extractCourseSlug(target.href);
      console.log(this.modalCourseFullLink);
      console.log(this.modalCourseSlug);
      this.fetchCourseLectureData(1);
    }
  }

  likeButton(ID: any) {
    const requestData = {
      article_id: ID,
      user_id: localStorage.getItem('userId'),
    };

    this.isCourseModalLoading = true;
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.API_URL}like_article`, requestData)
        .subscribe({
          next: (response: any) => {
            if (response && response.success) {
              resolve(true);
            } else {
              console.error(
                'Failed to add like:',
                response && response.message
              );
              reject();
            }
          },
          error: (error) => {
            console.error('Error: ', error);
            reject();
          },
          complete: () => {
            this.fetchInitialData(this.mainArticle.id);
            this.isCourseModalLoading = false;
          },
        });
    });
  }
  addBookmarks(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, artical_id: id };
    this.apiService.addBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        this.fetchInitialData(id);
        this.refreshData();
      },
    });
  }
  refreshData() {
    throw new Error('Method not implemented.');
  }

  deleteBookmarkedArticle(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, artical_id: id };
    this.apiService.deleteBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.fetchInitialData(id);
          this.refreshData();
        } else {
          this.message.error('Error occurred while deleting bookmark.');
        }
      },
    });
  }
  loadComments() {
    const newTicker = {
      user_id: this.userId,
      id: this.articleId,
    };

    this.http
      .post(`${environment.API_URL}fetch_article_comment`, newTicker)
      .subscribe({
        next: (response: any) => {
          this.comments = response;
        },
        error: (error) => {
          console.error('Error handling ticker:', error);
          this.message.error('Failed to update the ticker. Please try again.');
        },
      });
  }

  saveData(event: any) {
    console.log('hi');
  }
  addComment() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const requestData = {
        id: this.articleId,
        user_id: userId,
        comment_text: this.commentForm.controls['comment_text']!.value,
      };
      console.log(requestData);

      this.fetchApiService.addArticleComment(requestData).subscribe(
        (response) => {
          if (response.success) {
            console.log('Comment added successfully!');
            this.loadComments(); // Reload comments to include the new one
            this.commentForm.reset(); // Reset the form
          } else {
            console.error('Failed to add comment:', response.message);
          }
        },
        (error) => {
          console.error('Failed to add comment:', error);
        }
      );
    } else {
      this.message.error('Please login to add a comment');
    }
  }

  deleteArticleComment(id: number): void {
    this.deleteComment(id);
  }
  cancel(): void {}
  deleteComment(commentId: number) {
    const roleID = localStorage.getItem('role_id');
    if (roleID === '1' || roleID === '2') {
      this.fetchApiService.deleteArticleComment(commentId).subscribe(
        (response) => {
          if (response.success) {
            console.log('Comment deleted successfully!');
            this.loadComments();
          } else {
            console.error('Failed to delete comment:', response.message);
          }
        },
        (error) => {
          console.error('Failed to delete comment:', error);
        }
      );
    } else {
      console.error(
        'Permission denied: You do not have the required role to delete comments.'
      );
    }
  }
  handlePreviousLectureClick() {
    if (this.currentLectureNumber > 1) {
      this.currentLectureNumber--;
    }
    this.fetchCourseLectureData();
  }
  handleNextLectureClick() {
    this.currentLectureNumber++;
    this.fetchCourseLectureData();
  }

  remainingTime: number = 60;

  quizzTimer(): void {
    const timer = interval(1000);
    timer.subscribe(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.remainingTime = 60;
      }
    });
  }
  isVisible = false;
  modalType: 'quiz' | 'share' = 'quiz';
  modalTitle = '';

  fetchQuizQuestions(quizId: number): void {
    const requestData = { quiz_id: quizId };

    this.fetchApiService.getAllQuizQuestions(requestData).subscribe(
      (response: any) => {
        console.log('Quiz Questions:', response);
        this.quizQuestions = response;
        this.currentQuestionIndex = 0;
        this.quizzTimer();
        this.showModal('quiz');
      },
      (error) => {
        console.error('Error fetching quiz questions:', error);
      }
    );
    console.log('Quizz ID:', quizId);
  }
  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.handleCancel();
  }

  showModal(type: 'quiz' | 'share'): void {
    this.modalType = type;
    this.modalTitle =
      type === 'quiz' ? 'Attend Quiz Questions' : 'Share on Social Media';
    this.isVisible = true;
  }
  nextQuestion(): void {
    if (this.currentQuestionIndex < this.quizQuestions.length - 1) {
      this.currentQuestionIndex++;
    }
  }

  previousQuestion(): void {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }
  addToDostPortfolio(symbolId: any) {
    const action = this.mainArticle.already_added ? 'remove' : 'add';

    const newTicker = {
      user_id: this.userId,
      watch_list_id: null,
      symbol_id: symbolId,
      sentiment_id: null,
      date: new Date().toISOString().split('T')[0],
      risk_id: null,
      horizon_id: null,
      reminder: 0,
      trade_id: null,
      is_public: 0,
      action: action,
    };

    this.http
      .post(`${environment.API_URL}add_watch_list`, newTicker)
      .subscribe({
        next: (response) => {
          console.log('API Response:', response);
          this.message.success('Symbol added to watch list.');
          this.fetchInitialData(this.articleId);
        },
        error: (error) => {
          console.error('Error handling ticker:', error);
          this.message.error('Failed to update the ticker. Please try again.');
        },
      });
  }
  commentLikebutton(commentId: any) {
    const action = this.mainArticle.already_added ? 'remove' : 'add';

    const newTicker = {
      user_id: this.userId,
      comment_id: commentId,
    };

    this.http
      .post(`${environment.API_URL}like_article_comment`, newTicker)
      .subscribe({
        next: (response) => {
          console.log('API Response:', response);
          this.message.success('Symbol added to watch list.');
          this.loadComments()
        },
        error: (error) => {
          console.error('Error handling ticker:', error);
          this.message.error('Failed to update the ticker. Please try again.');
        },
      });
  }
}
