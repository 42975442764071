<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>
<ng-template #content>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
        <nz-breadcrumb-item>Quiz Answers</nz-breadcrumb-item>
    </nz-breadcrumb>
    <nz-card>
        <nz-tabset (nzSelectedIndexChange)="fetchusersymbolanswer($event === 0 ? 1 : 2)">
            <nz-tab nzTitle="Stocks"></nz-tab>
            <nz-tab nzTitle="Cryptos"></nz-tab>
        </nz-tabset>

        <nz-table #basicTable [nzData]="questionList" [nzLoading]="tableLoading" [nzBordered]="true">
            <thead>
                <tr>
                    <th nzWidth="25%">User Name</th>
                    <th nzWidth="15%">Symbol Name</th>
                    <th nzWidth="15%">Total Questions Solved</th>
                    <th nzWidth="15%">Total Correct</th>
                    <th nzWidth="15%">Total Incorrect</th>
                    <th nzWidth="15%">Total Points Earned</th>
                  
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let question of questionList"> 
                    <tr  (click)="openModal(question)">
                        <td>{{ question.user_name }}</td>
                        <td>{{ question.symbol_name }}</td>
                        <td>{{ question.total_questions_solved }}</td>
                        <td>{{ question.total_correct }}</td>
                        <td>{{ question.total_incorrect }}</td>
                        <td>{{ question.total_points_earned }}</td>
                       
                    </tr>
                </ng-container>
            
            </tbody>
        </nz-table>
    </nz-card>


    <nz-modal [nzWidth]="800"   [nzFooter]="null" (nzOnCancel)="handleCancel()" [(nzVisible)]="isQustionModalOpen" [nzTitle]="'User Answers'"  >
    <ng-container *nzModalContent>
        <nz-table [nzData]="quizAnswers" [nzBordered]="true" [nzLoading]="tableLoading">
            <thead>
                <tr>
                    <th nzWidth="20%">User Name</th>
                    <th nzWidth="25%">Question</th>
                    <th nzWidth="25%">Selected Option</th>
                    <th nzWidth="15%">Is Correct</th>
                    <th nzWidth="15%">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let answer of quizAnswers">
                    <td>{{ answer.user_name }}</td>
                    <td>{{ answer.quiz_question }}</td>
                    <td>{{ answer.quiz_option }}</td>
                    <td>{{ answer.is_correct }}</td>
                    <td>{{ answer.points }}</td>
                </tr>
            </tbody>
        </nz-table>
    </ng-container>
</nz-modal>


</ng-template>