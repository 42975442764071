<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>DostPortfolio</nz-breadcrumb-item>
  </nz-breadcrumb>
  <div class="container-fluid mt-3">
    <button nz-button nzType="primary" (click)="openDrawer()">Add Ticker</button>
    <div class="table-responsive">
      <nz-tabset (nzSelectedIndexChange)="onTabChange($event)">
        <nz-tab nzTitle="Stocks"></nz-tab>
        <nz-tab nzTitle="Cryptos"></nz-tab>
      </nz-tabset>
      <nz-table #basicTable [nzData]="watchlistTickers">
        <thead>
          <tr>
            <th nzWidth="50%">{{ selectedTab === 0 ? 'Stock' : 'Crypto' }}</th>
            <th nzWidth="120px">Updated</th>
            <th nzWidth="120px">Sentiment</th>
            <th nzWidth="120px">Risk</th>
            <th nzWidth="120px">Horizon</th>
            <th nzWidth="120px">Trade</th>
            <th nzWidth="120px">Status</th>
            <th nzWidth="120px">Review Date</th>
            <th nzWidth="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let ticker of watchlistTickers" (click)="getTickerDataToEdit(ticker)">
            <td>
              <span class="bg-light fw-bolder p-2 px-3">{{ ticker.symbol }}</span>
            </td>
            <td>{{ ticker.date }}</td>
            <td>{{ ticker.sentiment_name || '-' }}</td>
            <td>{{ ticker.risk_name || '-' }}</td>
            <td>{{ ticker.horizon_name || '-'}}</td>
            <td>{{ ticker.trade_name || '-' }}</td>
            <td>{{ ticker.is_public === 1 ? 'Public' : 'Private' }}</td>
            <td>{{ ticker.review_date | date: 'yyyy-MM-dd' }}</td>
            <td nzAlign="center" (click)="$event.stopPropagation()">
              <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this record?"
                (nzOnConfirm)="deleteWatchlisTtickerId(ticker.watch_list_ticker_id)">
                <span nz-icon nzType="delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="py-4">
   
      <h2 class="mb-4">DostAlert<sup>™</sup></h2>
      <nz-tabset nzType="card" (nzSelectedIndexChange)="onAlertTypeChange($event)">
        <nz-tab nzTitle="Fundamental "></nz-tab>
        <nz-tab nzTitle="Technical "></nz-tab>
    </nz-tabset>
      <div *ngIf="!dostPortfolioAlertList || dostPortfolioAlertList.length === 0">
        <div class="alert alert-warning shadow-sm">
          <p class="mt-3 mb-0" style="font-size: 1rem; font-weight: bold; color: #333;">
            No alerts available at the moment.
          </p>
        </div>
      </div>
      <div class="row g-4" *ngIf="dostPortfolioAlertList && dostPortfolioAlertList.length > 0">
        <div *ngFor="let alertlist of dostPortfolioAlertList" class="col-md-3">
          <div class="card shadow-sm border-light d-flex flex-column h-100">
            <div class="card-body" (click)="openDostAlertModal(alertlist)">
              <h5 class="card-title">{{ alertlist.text || 'No Text Available' }}</h5>
              <p class="card-text text-muted">UPDATED {{ alertlist.created_at | date:'dd/MM/yyyy HH:mm' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4" *ngIf="dostportfolioArtilces && dostportfolioArtilces.length > 0">
      <h2 class="mb-4">Articles</h2>
      <div class="position-relative">
        <div class="scrollable-cards-container position-relative">
          <button nz-button nzType="default" class="btn btn-outline-primary previous-btn position-absolute"
            (click)="scrollLeft()" nzShape="circle">
            <span nz-icon nzType="left"></span>
          </button>
      
          <div class="scrollable-cards mb-4 d-flex overflow-hidden" style="cursor: pointer; scroll-behavior: smooth;" #scrollContainer>
            <div class="article" *ngFor="let article of dostportfolioArtilces">
              <ng-container>
                <div class="card mx-1" style="width:250px; height: 380px; border-radius: 20px;">
                  <img class="article-image" alt="example"
                    [src]="article.featured_img_path ? (storageUrl + article.featured_img_path) : 'assets/profile-images/profile-image.png'" />
      
                  <div class="card-body" [routerLink]="['/article', article.slug, article.id]">
                    <span *ngIf="article.category_string" style="cursor: pointer;"
                      class="inline-flex p-1 articles-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{article.category_string}}
                    </span>
                    <div class="title_text">
                      <p style="margin-bottom: 0px; font-family: Heading;"> {{article.title | truncate:50 | titlecase}}
                      </p>
                    </div>
                    <div class="description_text">
                      <p style="margin-top: 0px;font-size: 14px;">{{article.meta_description | truncate:50}}
                      </p>
                    </div>
                  </div>
                  <div nz-popover nzPopoverTitle="{{article.name}}" class="blockquote-footer"
                  [routerLink]="['/author-details', article.created_by]">
      
                    <nz-avatar nzSize="small" [nzSrc]="storageUrl + '/' + article.profile_pic" nzIcon="user"></nz-avatar>
                    {{ article.created_by_name }} {{ article.created_at | date: 'dd MMM yyyy' }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <button nz-button nzType="default" class="btn btn-outline-primary next-btn position-absolute"
            (click)="scrollRight()" nzShape="circle">
            <span nz-icon nzType="right"></span>
          </button>
        </div>
      </div>
      
    </div>
  </div>

  <nz-modal [nzVisible]="drawerVisible" [nzTitle]="isEditMode ? 'Edit Ticker' : 'Add Ticker'" [nzFooter]="null"
    [nzWidth]="500" (nzOnCancel)="closeDrawer()">
  
    <ng-container *nzModalContent>
      <form [formGroup]="tickerForm" (ngSubmit)="saveTicker(isEditMode)">
  
        <nz-form-item *ngIf="selectedTab === 0">
          <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Stocks" formControlName="symbol_id">
            <nz-option *ngFor="let stocks of similarStocks" [nzValue]="stocks.symbol_id"
              [nzLabel]="stocks.symbol"></nz-option>
          </nz-select>
        </nz-form-item>
  
        <nz-form-item *ngIf="selectedTab === 1">
          <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Crypto" formControlName="symbol_id">
            <nz-option *ngFor="let cryptos of similarCryptos" [nzValue]="cryptos.symbol_id"
              [nzLabel]="cryptos.symbol"></nz-option>
          </nz-select>
        </nz-form-item>
  
  
        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="sentiment_id" nzPlaceHolder="Select sentiment" id="sentiment">
                  <nz-option *ngFor="let sentiment of sentiments" [nzValue]="sentiment.sentiment_id"
                    [nzLabel]="sentiment.sentiment"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="trade_id" nzPlaceHolder="Select trade" id="trade">
                  <nz-option *ngFor="let trade of trades" [nzValue]="trade.trade_id" [nzLabel]="trade.trade"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="risk_id" nzPlaceHolder="Select risk" id="risk">
                  <nz-option *ngFor="let risk of risks" [nzValue]="risk.risk_id" [nzLabel]="risk.risk"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzAllowClear formControlName="horizon_id" nzPlaceHolder="Select horizon" id="horizon">
                  <nz-option *ngFor="let horizon of horizons" [nzValue]="horizon.horizon_id"
                    [nzLabel]="horizon.horizon"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-radio-group formControlName="is_public">
                <label nz-radio [nzValue]="1">Public</label>
                <label nz-radio [nzValue]="0">Private</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-date-picker nzBlock formControlName="review_date" nzPlaceHolder="Select Review Date"
                [nzFormat]="'yyyy-MM-dd'" [ngStyle]="{ width: '100%' }"></nz-date-picker>
            </nz-form-item>
          </div>
        </div>
  
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [disabled]="!tickerForm.valid">{{ isEditMode ? 'Update' : 'Add' }}
              Ticker</button>
          </nz-form-control>
        </nz-form-item>
  
      </form>
    </ng-container>
  </nz-modal>
  <nz-modal [nzWidth]="500" [nzFooter]="null" [(nzVisible)]="dostAlertVisible" nzTitle="DostAlert™"
    (nzOnCancel)="closeDrawer()">
    <ng-container *nzModalContent>
      <div class="card shadow-sm rounded border-0 mb-4">
        <div class="card-header bg-light border-bottom-0">
          <p class="card-title mb-0">{{ dostAlert.text }}</p>
        </div>
        <div class="card-body">
          <p [innerHTML]="dostAlert?.description"></p>
        </div>
        <div class="d-flex justify-content-between align-items-center px-3 py-2 border-top">
  
          <div class="d-flex align-items-center">
            <nz-avatar nzSize="small"
              [nzSrc]="dostAlert.profile_pic ? (storageUrl + dostAlert.profile_pic) : 'assets/profile-images/profile-image.png'"
              nzIcon="user"></nz-avatar>
            <b class="ms-2">{{ dostAlert?.created_by_name }}</b>
          </div>
          <div class="d-flex align-items-center">
  
            <i (click)="addTobookmark(dostAlert.dost_alert_id)" [class.fa-solid]="dostAlert.is_bookmark"
              [class.fa-regular]="!dostAlert.is_bookmark" class="fa-bookmark fa-lg me-2">
            </i>
  
            <span (click)="reportDostalert(dostAlert.dost_alert_id)" *ngIf="!dostAlert.already_reported" nz-icon
              nzType="exclamation-circle" nzTheme="outline">
            </span>
  
            <span *ngIf="dostAlert.already_reported" nz-icon nzType="exclamation-circle" nzTheme="fill">
            </span>
          </div>
  
        </div>
  
      </div>
    </ng-container>
  </nz-modal>
</app-main-fiminize-layout>