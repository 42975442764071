<nz-layout style="height: 100vh;">

  <div class="container-fluid vh-100 d-flex align-items-center justify-content-center">
    <nz-card style="width: 500px;">
      <div class="text-center mb-4">
        <img [src]="headerLogo" alt="Logo">
      </div>
      <nz-divider nzText="Create Your Account"></nz-divider>

      <form nz-form [formGroup]="sendOtp">
        <!-- Step 1: Username and Email -->
        <div *ngIf="currentStep === 1">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your name!">
              <nz-input-group nzPrefixIcon="user">
                <input type="text" nz-input formControlName="username" placeholder="Username" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input a valid email address!">
              <nz-input-group nzPrefixIcon="mail">
                <input type="email" nz-input formControlName="email" placeholder="Email" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" [nzBlock]="true" (click)="goToNextStep()">Next</button>
        </div>

        <!-- Step 2: Addition Challenge -->
        <div *ngIf="currentStep === 2">
          <nz-form-item>
            What is {{num1}} + {{num2}}?
            <nz-form-control nzErrorTip="What is {{num1}} + {{num2}}?">
              <nz-input-group nzPrefixIcon="calculator">
                <input type="number" nz-input formControlName="challengeAnswer" placeholder="Answer" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" [nzBlock]="true" (click)="goToNextStep()">Next</button>
        </div>

        <!-- Step 3: Enter OTP -->
        <div *ngIf="currentStep === 3">
          <nz-form-item>
            Please enter OTP!
            <nz-form-control nzErrorTip="Please enter OTP!">
              <nz-input-group nzPrefixIcon="key">
                <input type="text" nz-input formControlName="otp" placeholder="OTP" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" [nzBlock]="true" [disabled]="this.sendOtp.controls['otp']?.value"
            (click)="goToNextStep()">Next</button>
        </div>

        <!-- Step 4: Password and Confirm Password -->
        <div *ngIf="currentStep === 4">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your password!">
              <nz-input-group nzPrefixIcon="lock">
                <input type="password" nz-input formControlName="password" placeholder="Password" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please confirm your password!">
              <nz-input-group nzPrefixIcon="lock">
                <input type="password" nz-input formControlName="confirm_password" placeholder="Confirm Password" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="primary" [nzBlock]="true" (click)="goToNextStep()">Submit</button>
        </div>

      </form>
    </nz-card>
  </div>



  <nz-footer class="border-top">
    <div class="d-flex">
      <span>&#169; {{ currentYear }} Version 1.0.3 </span>
      <span class="ms-auto"> InvestDost | Development | Designed By
        <a class="ms-1" target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
          Technologies</a>
      </span>
    </div>
  </nz-footer>
</nz-layout>

<!-- <div class="col-md-6 px-3">
                  <form nz-form nzLayout="vertical" [formGroup]="sendOtp" (ngSubmit)="sendOtpToUser()">

                    <div class="addUsers" *ngIf="!sendFourDigitOtp">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="username">Username  <span class="mx-2" nz-icon nz-tooltip nzTooltipTitle="Ex: all letters in lowercase,(!@#$%^&*()_+=?<>]*$) this special characters are allowed, numbers are allowed, Space are not allowed" nzType="info-circle"></span></nz-form-label>

                        <nz-form-control nzSpan="15">
                            <nz-input-group nzPrefixIcon="user">
                                <input type="text" nz-input formControlName="username" placeholder="Username" />
                            </nz-input-group>
                            <div *ngIf="sendOtp.get('username')?.invalid && sendOtp.get('username')?.touched" style="color: red;">
                              {{ getErrorMessage('username') }}
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="email">Email</nz-form-label>

                      <nz-form-control nzSpan="15" >
                          <nz-input-group nzPrefixIcon="user"  >
                              <input type="email" nz-input formControlName="email" placeholder="email" />
                          </nz-input-group>
                          <div *ngIf="sendOtp.get('email')?.invalid && sendOtp.get('email')?.touched" style="color: red;">
                            {{ getErrorMessage('email') }}
                          </div>
                      </nz-form-control>
                  </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="password">Password <span class="mx-2" nz-icon nz-tooltip nzTooltipTitle="Ex: all letters in lowercase,(!@#$%^&*()_+=?<>]*$) this special char" nzType="info-circle"></span></nz-form-label>
                        <nz-form-control nzSpan="15" >
                            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                                    formControlName="password" placeholder="Password" />
                            </nz-input-group>
                            <div *ngIf="sendOtp.get('password')?.invalid && sendOtp.get('password')?.touched" style="color: red;">
                              {{ getErrorMessage('password') }}
                            </div>
                        </nz-form-control>
                        <ng-template #suffixTemplate>
                            <span
                              nz-icon
                              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                              (click)="passwordVisible = !passwordVisible"
                            ></span>
                          </ng-template>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="password">Confirm Password</nz-form-label>
                      <nz-form-control nzSpan="15"  nzStatus="error">
                          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                              <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                                  formControlName="confirm_password" placeholder="Confirm Password" />
                          </nz-input-group>
                          <div *ngIf="sendOtp.get('confirm_password')?.invalid && sendOtp.get('confirm_password')?.touched" style="color: red;">
                            {{ getErrorMessage('confirm_password') }}
                          </div>

                      </nz-form-control>
                      <ng-template #suffixTemplate>
                          <span
                            nz-icon
                            [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                            (click)="passwordVisible = !passwordVisible"
                          ></span>
                        </ng-template>
                  </nz-form-item>
                    <div nz-row class="mb-4">
                        <div nz-col [nzSpan]="7">
                            <label nz-checkbox formControlName="remember">
                                <span>Remember me</span>
                            </label>
                        </div>
                        <div nz-col [nzSpan]="8">
                            <a class="float-end">Forgot password?</a>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit" [disabled]="!sendOtp.valid || loginButtonDisable">register now!</button>  
                    </div>
                </form>

                <form nz-form nzLayout="vertical" [formGroup]="otpSubmitForm" (ngSubmit)="submitForm()">
                  <div class="SendOtp"      *ngIf="sendFourDigitOtp">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="OTP">OTP </nz-form-label>

                      <nz-input-number-group  nzCompact>
                        <nz-input-number formControlName="otp"   [nzMin]="1000" [nzMax]="9999" style="width: calc(100% - 40%)"></nz-input-number>
                        <button nz-button nzType="primary">Submit</button>
                      </nz-input-number-group>
                  </nz-form-item>
                  <div>
                    <button nz-button nzType="primary" nzGhost (click)="sendOtpToUser()">Resend OTP</button>
                    <p> you have get OTP on This mail  {{this.otpSubmitForm.controls['email'].value}}</p>
                  </div>
                  </div>
                </form>
                </div> -->