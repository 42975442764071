import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  MinLengthValidator,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { sendOtp } from 'src/app/Interfaces/allForm';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  currentYear: number = new Date().getFullYear();
  headerLogo: SafeUrl;
  currentRoute: string = '';
  passwordVisible = false;
  password?: string;
  roleId:any;
  num1: number=0;
  num2: number=0;
  correctAnswer: number=0;
  currentStep: number = 1;
  sendFourDigitOtp: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private http: HttpClient,
    private message: NzMessageService
  ) {
    this.headerLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/InvestDostFull.png'
    );
    this.generateChallenge();
  }

  sendOtp = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.maxLength(25),
      Validators.pattern('[a-z0-9!@#$%^&*()_+=?<>]*$'),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    otp: new FormControl('', [Validators.required]),
    role_id: new FormControl(3, [Validators.required]),
    remember: new FormControl(false),
  } as { [key in keyof sendOtp]: any });

  otpSubmitForm = new FormGroup({
    username: new FormControl(localStorage.getItem('username')),
    email: new FormControl(localStorage.getItem('email')),

    password: new FormControl(localStorage.getItem('password')),
    role_id: new FormControl(3, [Validators.required]),
    otp: new FormControl(null, [Validators.required]),
    remember: new FormControl(false),
  } as { [key in keyof sendOtp]: any });

  loginButtonDisable: boolean = false;
  sendOtpToUser() {
    this.loginButtonDisable = true;
    const { username, password, role_id, email } = this.sendOtp.value;
    const apiUrl = `${environment.API_URL}register_as_user`;
    try {
      this.http
        .post(apiUrl, { username, password, role_id, email })
        .subscribe({
          next: (response: any) => {
            console.log(response);
            if (response.success) {
              localStorage.setItem('role_id', role_id)
              localStorage.setItem('isLoggedIn', 'true')
              localStorage.setItem('email', email)
              localStorage.setItem('password', password)
              localStorage.setItem('username', username)
             this.currentStep === 3;
              this.message.success(response.message);
              this.sendFourDigitOtp = true;
            } else {
              this.message.success(response.message);
              // this.message.error('Incorrect OTP Please Try Again ');
            }
          },
          error: (error) => {
            console.error('Login API error:', error);
            this.message.error('Login failed');
          },
        });
    } catch (error) {
      console.error('Error in API call:', error);
      this.message.error('Login failed');
    }
  }

  submitForm() {
    let obj={
      email:     localStorage.getItem('email'),
      otp:      this.otpSubmitForm.controls['otp'].value
    }
    const apiUrl = `${environment.API_URL}verify_email_otp_for_user `;
    // console.log("login data",username,password,role_id,email,otp);
    console.log(obj);
    

    try {
      this.http
        .post(apiUrl, obj)
        .subscribe({
          next: (response: any) => {
            console.log(response);
            if (response.success) {
              this.message.success('Login Successful ');
              localStorage.setItem('token', response.token)
              localStorage.setItem('userId', response.user_id)


              this.message.success('Login Successful ');
             this.roleId  = localStorage.getItem('role_id');
              switch (this.roleId) {

                
                case '1':
                  ///super-admin
                  this.router.navigate(['/admin_dashboard']);
                  sessionStorage.setItem('userId', response.data.user_id);
                  sessionStorage.setItem(
                    'displayName',
                    `${response.data.fname} ${response.data.lname}`
                  );
                  break;

                case '2':
                  //admin
                  this.router.navigate(['/admin_dashboard']);
                  sessionStorage.setItem('userId', response.data.user_id);
                  sessionStorage.setItem('displayName', `${response.data.fname} ${response.data.lname}`);
                  break;

                case '3':
                  //user
                  this.router.navigate(['/']);
                  sessionStorage.setItem('userId', response.user_id);
                  sessionStorage.setItem(  'displayName', `${response.data.fname} ${response.data.lname}`
                  );
                  break;

                case '4':
                  //author
                  this.router.navigate(['/author_dashboard']);
                  sessionStorage.setItem('userId', response.data.user_id);
                  sessionStorage.setItem('displayName', 'Neeraj Author');
                  break;
                default:
                  // Handle the default case, or perform a default action
                  break;
              }
            } else {
              this.message.error('Login failed');
            }
          },
          error: (error) => {
            console.error('Login API error:', error);
            this.message.error('Login failed');
          },
        });
    } catch (error) {
      console.error('Error in API call:', error);
      this.message.error('Login failed');
    }
  }

  generateChallenge() {
    this.num1 = Math.floor(Math.random() * 91) + 10; // Random number between 10 and 100
    this.num2 = Math.floor(Math.random() * 91) + 10; // Random number between 10 and 100
    this.correctAnswer = this.num1 + this.num2;
  }
  goToNextStep() {
    if (this.currentStep === 1) {
      if (this.sendOtp.get('username')?.valid && this.sendOtp.get('email')?.valid) {
        this.currentStep = 2;
      } else {
        // Handle validation error (e.g., show error messages)
        this.sendOtp.markAllAsTouched(); // Highlights invalid fields
      }
    } else if (this.currentStep === 2) {
      const answer = this.sendOtp.get('challengeAnswer')?.value;
      if (answer === this.correctAnswer) {
        this.sendOtpToUser(); // Call function to send OTP
        this.currentStep = 3;
      } else {
        // Handle incorrect answer
        console.error('Incorrect challenge answer');
      }
    } else if (this.currentStep === 4) {
      // Validate password and confirm password
      if (this.sendOtp.get('password')?.valid && this.sendOtp.get('confirm_password')?.valid) {
        if (this.sendOtp.get('password')?.value === this.sendOtp.get('confirm_password')?.value) {
          this.submitForm(); // Submit the form
        } else {
          // Handle password mismatch error
          console.error('Passwords do not match');
        }
      } else {
        // Handle validation error
        this.sendOtp.markAllAsTouched(); // Highlights invalid fields
      }
    }
  }
  
  
  


  validateChallenge() {
    const answer = this.sendOtp.get('challengeAnswer')?.value;
    if (answer == this.correctAnswer) {
      this.sendOtpToUser(); // Call function to send OTP
    } else {
      this.message.error('Incorrect answer. Please try again.');
    }
  }
 
}
