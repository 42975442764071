<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>DostDiary</nz-breadcrumb-item>
  </nz-breadcrumb>

  <div class="row">
    <div class="col-md-3 sticky-sidebar ">
      <app-events></app-events>
    </div>
    <div class="col-md-6 scrollable-content ">
      <div class="header d-flex justify-content-between mb-3">
        <button nzBlock nz-button nzType="default" nzShape="round" class="custom-warning me-2">
          My Dost Diary+
        </button>
        <button nzBlock nz-button nzShape="round" nzType="default">
          Timeline
        </button>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <button nzBlock nz-button nzShape="round" nzType="default" class="custom-danger me-2">
          Add Post
        </button>
        <button nzBlock nz-button nzType="primary" nzShape="round" class="me-2">
          Joined Dost Dairy
        </button>
        <button nzBlock nz-button nzType="default">
          Liked & Saved
        </button>
      </div>


      <div class="bordered-header">
        <form [formGroup]="postForm" (ngSubmit)="addPost()">
          <nz-form-item>
            <nz-form-control nzErrorTip="Enter Caption">
              <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="caption"
                placeholder="Enter post caption..." style="width: 100%;"></quill-editor>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <div class="row">
              <div class="col-md-4">
                <nz-form-label>Is featured post</nz-form-label>
                <nz-radio-group formControlName="is_public">
                  <label nz-radio [nzValue]="0">No</label>
                  <label nz-radio [nzValue]="1">Yes</label>
                </nz-radio-group>
              </div>

              <div class="col-md-4">
                <nz-form-label>Visibility</nz-form-label>
                <nz-radio-group formControlName="is_featured_post">
                  <label nz-radio [nzValue]="0">Public</label>
                  <label nz-radio [nzValue]="1">Private</label>
                </nz-radio-group>
              </div>

              <div class="col-md-4">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Select Ticker">
                    <nz-form-label>Select Ticker</nz-form-label>
                    <nz-select nzShowSearch formControlName="symbol_id" [nzMaxTagCount]="3"
                      nzPlaceHolder="Please select">
                      <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id"
                        [nzLabel]="item.symbol"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

            </div>

          </nz-form-item>
          <div class="row">
            <div class="col-md-12">
              <button type="submit" [disabled]="formSubmitLoading || !postForm.get('caption')?.value"
                [nzLoading]="formSubmitLoading" nz-button nzType="dashed" type="submit" nz-button nzType="primary"
                nzBlock>Add Post </button>

            </div>
          </div>
        </form>
      </div>
      <ng-container>
        <ng-container *ngFor="let post of posts">
          <nz-page-header class="site-page-header bordered-header"
            [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
            <nz-avatar
              [routerLink]="post.role_id === 3 ? ['/userprofile', post.user_id] : ['/author-details', post.user_id]"
              nz-page-header-avatar
              [nzSrc]="post.profile_pic ? (storageUrl + post.profile_pic) : 'assets/profile-images/profile-image.png'"
              nzIcon="user"></nz-avatar>

            <nz-page-header-subtitle   [routerLink]="post.role_id === 3 ? ['/userprofile', post.user_id] : ['/author-details', post.user_id]">{{ post.created_by_name }}, {{
              post.created_at | date: 'dd MMM yyyy'
              }}</nz-page-header-subtitle>

            <nz-page-header-extra>
              <nz-space>
                <button *nzSpaceItem nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu"
                  nzPlacement="bottomRight" style="border: none; padding: 0">
                  <span nz-icon nzType="more" nzTheme="outline" style="font-size: 20px; vertical-align: top;"></span>
                </button>
              </nz-space>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this record?"
                      (nzOnConfirm)="deletePost(post.diary_post_id)" (nzOnCancel)="cancel()">
                      <span nz-icon nzType="delete"></span>
                    </button>
                  </li>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzShape="circle" (click)="fetchPostData(post)">
                      <span nz-icon nzType="edit"></span>
                    </button>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-page-header-extra>

            <nz-page-header-content>
              <div>
                <div class="content">
                  <!-- Edit mode -->
                  <div *ngIf="isEditMode[post.diary_post_id]; else viewMode">
                    <form [formGroup]="editPostForm">
                      <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="caption"
                        placeholder="Enter post caption..." style="width: 100%;"></quill-editor>

                      <nz-divider></nz-divider>
                      <nz-form-item>
                        <nz-form-label>How are you feeling about this post?</nz-form-label>
                        <nz-radio-group formControlName="polling_indicator_id">
                          <label nz-radio [nzValue]="1">Bullish</label>
                          <label nz-radio [nzValue]="2">Bearish</label>
                          <label nz-radio [nzValue]="3">Neutral</label>
                        </nz-radio-group>
                      </nz-form-item>
                      <nz-divider></nz-divider>
                      <div class="d-flex justify-content-end">
                        <button (click)="submitPost(post.diary_post_id)" nz-button nzType="default">
                          <span nz-icon nzType="save"></span>
                          Update Post
                        </button>
                        <button class="me-2" (click)="cancelEdit(post.diary_post_id)" nz-button nzType="default">
                          <span nz-icon nzType="close-circle"></span>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- View mode -->
                  <ng-template #viewMode>
                    <p nz-paragraph [innerHTML]="post.caption"></p>
                    <nz-divider></nz-divider>

                    <nz-form-item>
                      <nz-form-label>How are you feeling about this post?</nz-form-label>
                      <nz-radio-group [(ngModel)]="post.polling_indicator_id"
                        (ngModelChange)="onPollingIndicatorChange($event, post)">
                        <label nz-radio [nzValue]="1">Bullish</label>
                        <label nz-radio [nzValue]="2">Bearish</label>
                        <label nz-radio [nzValue]="3">Neutral</label>
                      </nz-radio-group>
                    </nz-form-item>
                    <nz-divider></nz-divider>

                    <div class="d-flex align-items-center justify-content-between mt-1">
                      <div class="flex-grow-1 d-flex align-items-center">
                        <button (click)="likeButton(post)" nz-button nzType="dashed" nzShape="circle">
                          <span nz-icon [nzType]="post.already_liked ? 'like' : 'like-o'"
                            [nzTheme]="post.already_liked ? 'fill' : 'outline'"></span>
                        </button>
                        <span class="me-2"><b>{{ post.like_count }}</b></span>
                        <button nz-button nzType="text" nzShape="circle">
                          <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>
                        </button>
                        <span class="me-2"><b>{{ post.comment_count }}</b></span>
                        <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>

                        <button nz-button nzType="text">
                          <i (click)="addTobookmark(post.diary_post_id)" [class.fa-solid]="post.is_bookmark"
                            [class.fa-regular]="!post.is_bookmark" class="fa-bookmark fa-lg me-2">
                          </i>
                        </button>
                        <!-- Pushes the lock icon to the end -->
                        <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                          <i class="fa-solid fa-lock"></i>
                        </button>
                      </div>
                      <div *ngFor="let symbol of post.symbols" class="ms-2">

                        <nz-tag nz-popover [nzPopoverTitle]="'Add ' + symbol.symbol_name + ' in dostportfolio'"
                          [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover"
                          [routerLink]="[symbol.ticker_category === 'Crypto' ? '/crypto' : '/stocks', symbol.symbol]"
                          class="symbol-item" nz-tooltip [nzTooltipTitle]="symbol.symbol_name">
                          {{ symbol.symbol }}
                          <span nz-icon [nzType]="post.ticker_already_added ? 'check-circle' : 'plus-circle'"
                            [nzTheme]="symbol.added_in_portfolio ? 'fill' : 'outline'" class="px-1 py-1"
                            [style.color]="post.ticker_already_added ? 'green' : 'inherit'" style="font-size: 1rem;">
                          </span>
                        </nz-tag>
                        <ng-template #contentTemplate>
                          <button (click)="addToDostPortfolio(symbol.symbol_id)" nz-button nzType="dashed">
                            <span nz-icon [nzType]="post.ticker_already_added ? 'close-circle' : 'plus-circle'"></span>
                            {{ post.ticker_already_added? 'Remove from DostPortfolio' : 'Add to DostPortfolio' }}
                          </button>
                        </ng-template>
                      </div>
                    </div>

                    <div *ngIf="commentVisible[post.diary_post_id]">
                      <!-- Main Comment Form -->
                      <nz-comment nzAuthor="{{ post.created_by_name }}"
                        nzDatetime="{{ post.created_at | date:'short' }}" nzContentStyle="padding: 12px;"
                        nzCommentContentStyle="background: #f9f9f9; border-radius: 4px; padding: 12px;">
                        <nz-avatar nz-comment-avatar nzIcon="user"
                          nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" nzSize="large"></nz-avatar>
                        <nz-comment-content>
                          <form [formGroup]="postForm" (ngSubmit)="submitCommentOrReply(post.diary_post_id, null)">
                            <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="comment"
                              placeholder="Enter post comment" style="width: 100%;"></quill-editor>
                            <button nz-button nzType="dashed" type="submit">
                              <span nz-icon nzType="send"></span>
                            </button>
                          </form>
                        </nz-comment-content>
                      </nz-comment>

                      <ng-template #commentTemplate let-comment="comment">
                        <nz-comment [nzAuthor]="comment.created_by_string"
                          nzDatetime="{{ comment.created_at | date:'short' }}" nzContentStyle="padding: 12px;"
                          nzCommentContentStyle="background: #f9f9f9; border-radius: 4px; padding: 12px;">
                          <nz-avatar nz-comment-avatar nzIcon="user"
                            nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            nzSize="large"></nz-avatar>
                          <nz-comment-content>
                            <p>{{ comment.comment }}</p>

                            <button nz-button nzType="link" (click)="prepareReply(comment.comment_id)">
                              <span nz-icon nzType="edit"></span> Reply
                            </button>
                            <button nz-button nzType="link" (click)="deleteComment(comment.comment_id)">
                              <span nz-icon nzType="delete"></span>
                            </button>
                            <div *ngIf="replyingToCommentId === comment.comment_id">
                              <form [formGroup]="postForm"
                                (ngSubmit)="submitCommentOrReply(post.diary_post_id, comment.comment_id)">
                                <nz-input-group [nzSuffix]="suffixIconReply">
                                  <input type="text" nz-input formControlName="comment"
                                    placeholder="Enter your reply" />
                                </nz-input-group>
                                <ng-template #suffixIconReply>
                                  <button type="submit" nz-button nzType="primary" nzShape="circle">
                                    <span nz-icon nzType="send"></span>
                                  </button>
                                </ng-template>
                              </form>
                            </div>
                            <div *ngIf="comment.children && comment.children.length > 0">
                              <ng-container *ngFor="let child of comment.children | filterComments: post.diary_post_id">
                                <ng-container
                                  *ngTemplateOutlet="commentTemplate; context: { comment: child }"></ng-container>
                              </ng-container>
                            </div>
                            <div *ngIf="!comment.children || (comment.children && !comment.childrenFullyLoaded)">
                              <button nz-button nzType="link"
                                (click)="loadMoreChildComments(post.diary_post_id, comment.comment_id)">
                                <span nz-icon nzType="eye"></span> View More
                              </button>
                            </div>
                          </nz-comment-content>
                        </nz-comment>
                      </ng-template>

                      <ng-container *ngFor="let comment of comments | filterComments: post.diary_post_id">
                        <ng-container *ngTemplateOutlet="commentTemplate; context: { comment: comment }"></ng-container>
                      </ng-container>
                    </div>


                  </ng-template>
                </div>
              </div>
            </nz-page-header-content>
          </nz-page-header>
        </ng-container>
      </ng-container>



      <ng-template #noPosts>
        <div class="alert alert-info text-center" role="alert">
          <h4 class="alert-heading">No Posts Available</h4>
          <p class="mb-0">It looks like there are no posts yet. Check back later or
            <span style="color: #007bff; cursor: pointer; text-decoration: underline;">
              create a new post!
            </span>
          </p>
        </div>
        <div class="text-center">
          <nz-spin nzSimple></nz-spin>
        </div>
      </ng-template>

    </div>
    <div class="col-md-3 sticky-sidebar">
      <app-tasks></app-tasks>
    </div>
  </div>
  <nz-modal [(nzVisible)]="isModalVisible" nzTitle="Share this Post" (nzOnCancel)="handleCancel()">
    <div nz-modal-body>
      <ng-container *nzModalContent>
        <button nz-button nzType="text" (click)="share('facebook')">
          <i nz-icon nzType="facebook"></i> Facebook
        </button>
        <button nz-button nzType="text" (click)="share('twitter')">
          <i nz-icon nzType="twitter"></i> Twitter
        </button>
        <button nz-button nzType="text" (click)="share('linkedin')">
          <i nz-icon nzType="linkedin"></i> LinkedIn
        </button>
        <button nz-button nzType="text" (click)="share('Instagram')">
          <i nz-icon nzType="instagram"></i> Instagram
        </button>
      </ng-container>

    </div>
    <div nz-modal-footer>
      <button nz-button nzType="default" (click)="handleCancel()">Close</button>
    </div>
  </nz-modal>
</app-main-fiminize-layout>