<app-main-fiminize-layout>
  <div class="container">
    <div class="card py-3 w-75 mx-auto">
      <div class="container-fluid">
        <h2>#{{ tagsArticles.tag || 'UNKNOWN TAG' }}</h2>
        <p class="lead text-muted">{{ tagsArticles.description}}</p>
        <div class="d-flex align-items-center">
          <span class="me-3">
            <span>👍</span>
            <span class="ms-1">{{ tagsArticles.likes }}</span>
          </span>
          <span class="me-2">
            <span>📈</span>
          </span>


          <span *ngFor="let crypto of tagsArticles.tag_categories">
            <nz-tag [nzColor]="'geekblue'">{{ crypto.tag_category }}</nz-tag>

          </span>
        </div>
        <button (click)="likeTags()" class="btn follow-btn position-absolute top-0 end-0 mt-2 me-2"> {{
          tagsArticles.alreadyLiked ? 'Liked' : '+ Like' }}</button>
      </div>
    </div>

    <div class=" mt-4">
      <div class="row">
        <div class="col-lg-8">
          <div class="card mb-4" *ngFor="let article of articles" [routerLink]="['/article', article.slug, article.id]">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <small class="text-muted">Tags Mentioned {{ article.tag_names?.join(', ') || 'UNKNOWN TAG' }}</small>
                <span class="badge bg-secondary">{{ article.view_count || 0 }} VIEWS</span>
              </div>
              <hr>
              <ng-container *ngIf="article.categories && article.categories.length">
                <ng-container *ngFor="let category of article.categories">
                  <nz-tag nzColor="#108ee9" class="mx-2 rounded">{{ category }}</nz-tag>
                </ng-container>
              </ng-container>
          
              <h2 class="mt-2" style="cursor: pointer;">{{ article.title }}</h2>
              <p class="mt-3">
                {{ article.meta_description || 'No description available.' }}
              </p>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div [routerLink]="['/author-details', article.created_by]" class="d-flex align-items-center">
                  <nz-avatar nzSize="small"
                    [nzSrc]="article.profile_pic ? (storageUrl + article.profile_pic) : 'assets/profile-images/profile-image.png'"
                    nz-comment-avatar nzIcon="user">
                  </nz-avatar>
                  <span class="ml-2">{{ article.created_by_string || 'Unknown User' }}</span>
                </div>
                <div (click)="$event.stopPropagation()" class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-3">
                    <span (click)="likeButton(article.id)" style="cursor: pointer;">
                      <span nz-icon nzType="like" [nzTheme]="article.article_already_liked ? 'fill' : 'outline'"></span>
                    </span>
                    <span class="ml-1">{{ article.like_count || 0 }}</span>
                  </div>
                  <div (click)="$event.stopPropagation()"
                    (click)="article.comment_count !== 0 ? toggleCommentSection(article.id) : null"
                    class="d-flex align-items-center" [ngClass]="{'disabled': article.comment_count === null}"
                    [style.cursor]="article.comment_count === null ? 'not-allowed' : 'pointer'">
                    <span nz-icon nzType="comment" nzTheme="outline"></span>
                    <span class="ml-1">{{ article.comment_count || 0 }}</span>
                  </div>
                </div>
              </div>
              <div (click)="$event.stopPropagation()" *ngIf="isCommentSectionVisible(article.id)">
                <nz-divider></nz-divider>
               
                <nz-comment *ngFor="let comment of comments" nzAuthor="{{comment.user_name}}">
                  <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="storageUrl + '/' + comment.profile_pic"></nz-avatar>
                  <nz-comment-content>
                    <p>{{ comment.comment_text }}</p>
                  </nz-comment-content>
                  <nz-comment-action>
                    <button (click)="commentLikebutton(article.id, comment.comment_id)" nz-button nzType="dashed" nzShape="circle">
                      <span nz-icon [nzType]="comment.already_liked ? 'like' : 'like-o'" [nzTheme]="comment.already_liked ? 'fill' : 'outline'"></span>
                    </button>
                    <span>{{ comment.comment_like_count }}</span>
                  </nz-comment-action>
                </nz-comment>
              </div>

            </div>
          </div>

        </div>
        <div class="col-lg-4 ">
          <div class="card" *ngIf="authorCoveringList && authorCoveringList.length > 0">
            <div class="card-body">
              <h2 class="card-title mb-3">Creators Posting About #{{ tagsArticles.tag }}</h2>
              <div style="  cursor: pointer;" [routerLink]="['/author-details', authorList.user_id]" nz-button
                nz-popover nzPopoverTitle="{{ authorList.name }}" [nzPopoverContent]="contentTemplate"
                nzPopoverTrigger="hover" nzPopoverPlacement="top" class="d-flex align-items-center mb-3"
                *ngFor="let authorList of authorCoveringList">
                <img [src]="storageUrl + authorList.profile_pic" alt="{{ authorList.created_by_string }}"
                  class="rounded-circle contributor-img me-2" width="40" height="40">
                <div>
                  <div class="contributor-name">{{ authorList.name }}</div>
                
                </div>
                <ng-template #contentTemplate>
                  <div class="row align-items-center">

                    <div class="col-auto">
                      <img [src]="storageUrl + '/' + authorList.profile_pic" alt="User Avatar" class="rounded-circle"
                        style="width: 40px; height: 40px;">
                    </div>
                    <div class="col">

                      <div class="d-flex align-items-center">
                        <p class="mb-0 me-3">{{ authorList.created_by_name }}</p>
                        <div class="me-3">
                          <span class="d-block">Followers</span>
                          <span>{{ authorList.followers || 0 }}</span>
                        </div>
                        <div>
                          <span class="d-block">Following</span>
                          <span>{{ authorList.following }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>

              <a [routerLink]="['/allauthors', this.id]" nz-button nzType="link">More authors posting about #{{
                tagsArticles.tag }}</a>


            </div>

          </div>
          <div class="card mt-4" *ngIf="tagsArticles.related_tags && tagsArticles.related_tags.length > 0">
            <div class="card-body">
              <h2 class="card-title mb-3">Similar Tags to #{{ tagsArticles.tag }} </h2>
              <div class="card-body">
                <div *ngFor="let tag of tagsArticles.related_tags" class="d-inline-block me-2">
                  <nz-tag nzColor="blue" class="mx-2 rounded" [routerLink]="['/companytags', tag.related_tag_id]">
                    {{ tag.related_tag }}
                  </nz-tag>

                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4" *ngIf="tagsArticles.related_stocks && tagsArticles.related_stocks.length > 0">
            <div class="card-body">
              <h2 class="card-title mb-3">Related Stocks to #{{ tagsArticles.tag }}</h2>
              <div class="card-body">
                <div *ngFor="let stock of tagsArticles.related_stocks" class="d-inline-block me-2">
                  <nz-tag nzColor="green" class="mx-2 rounded" [routerLink]="['/stocks',stock.related_stock]">
                    {{ stock.related_stock }}
                  </nz-tag>
                
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4" *ngIf="tagsArticles.related_cryptos && tagsArticles.related_cryptos.length > 0">
            <div class="card-body">
              <h2 class="card-title mb-3">Related Crypto to #{{ tagsArticles.tag }}</h2>
              <div class="card-body">
                <div *ngFor="let crypto of tagsArticles.related_cryptos" class="d-inline-block me-2">
                  <nz-tag nzColor="pink" class="mx-2 rounded" [routerLink]="['/crypto', crypto.related_crypto]">
                    {{ crypto.related_crypto }}
                  </nz-tag>
                
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</app-main-fiminize-layout>