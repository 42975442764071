<button nz-button nzType="primary" (click)="openDrawer(false)">Add Event</button>

<ng-container *ngIf="events.length > 0; else noEvents">
    <ng-container *ngFor="let event of events">
        <div class="card border-light mb-4">
           
            <button nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight"
                style="border: none; padding: 0;">
                <span nz-icon nzType="more" nzTheme="outline" style="font-size: 20px; vertical-align: top;"></span>
            </button>
            <div class="card-body">
                {{ event.valid_from | date: 'd MMM yyyy' }} - {{ event.valid_till | date: 'd MMM yyyy' }}
                <p nz-paragraph [innerHTML]="event.event_title"></p>
                <p class="text-muted">{{ event.description }}</p>
            </div>
         

        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item>
                    <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                        nzPopconfirmTitle="Are you sure you want to delete this record?"
                        (nzOnConfirm)="deleteItem(event.event_id)" (nzOnCancel)="cancel()">
                        <span nz-icon nzType="delete"></span>
                    </button>
                </li>
                <li nz-menu-item>
                    <button nz-button nzType="default" nzShape="circle" (click)="openDrawer(true, event)">
                        <span nz-icon nzType="edit"></span>
                    </button>
                </li>
            </ul>
        </nz-dropdown-menu>

    </ng-container>
</ng-container>
<ng-template #noEvents>
    <p>No events available.</p>
</ng-template>

<nz-modal [nzFooter]="null" [nzVisible]="drawerVisible" [nzWidth]="500"
    [nzTitle]="isEditMode ? 'Edit Event' : 'Add Event'" (nzOnCancel)="closeDrawer()">
    <ng-container *nzModalContent>
        <form [formGroup]="eventForm" (ngSubmit)="handleEventFormSubmit()">
            <div class="row">
                <div class="col-12">
                    <nz-form-item>
                        <nz-form-label nzRequired>Event Title</nz-form-label>
                        <nz-form-control nzErrorTip="Enter Title">
                            <nz-input-group>
                                <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="event_title"
                                    placeholder="Event Title" style="width: 100%;">
                                </quill-editor>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <nz-form-item>
                        <nz-form-label nzFor="description">Description</nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Please write event description here!">
                            <nz-textarea-count [nzMaxCharacterCount]="1000">
                                <textarea nz-input formControlName="description" id="description"></textarea>
                            </nz-textarea-count>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <nz-form-item>
                        <nz-form-label>Valid From - Valid To</nz-form-label>
                        <nz-form-control>
                            <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                                formControlName="valid_date_range">
                            </nz-range-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-12 col-md-6">
                    <nz-form-item>
                        <nz-form-label>Event Status?</nz-form-label>
                        <nz-form-control>
                            <nz-radio-group formControlName="active_status_id">
                                <label nz-radio [nzValue]="1">Active</label>
                                <label nz-radio [nzValue]="0">Inactive</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-radio-group formControlName="is_public">
                                <label nz-radio [nzValue]="1">Public</label>
                                <label nz-radio [nzValue]="0">Private</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <nz-form-item>
                        <nz-form-control>
                            <button nz-button nzType="primary">{{ isEditMode ? 'Update Event' : 'Add Event' }}</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </ng-container>
</nz-modal>