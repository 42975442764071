import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as pageNames from './Constants/pageNames';
import { authGuardGuard } from './Guards/AuthGuard/auth-guard.guard';
import { AdminDashboardComponent } from './Modules/Admin/Dashboard/admin-dashboard/admin-dashboard.component';
import { LoginComponent } from './Modules/Auth/Login/login/login.component';
import { RegisterComponent } from './Modules/Auth/Register/register/register.component';
import { AuthorDashboardComponent } from './Modules/Author/Dashboard/author-dashboard/author-dashboard.component';
import { AddArticleComponent } from './Modules/Common/Content-Management/Articles/add-article/add-article.component';
import { ArticlesComponent } from './Modules/Common/Content-Management/Articles/articles/articles.component';
import { CategoriesComponent } from './Modules/Common/Content-Management/Categories/categories/categories.component';
import { DraftArticlesComponent } from './Modules/Common/Content-Management/Draft-Articles/draft-articles/draft-articles.component';
import { TagsComponent } from './Modules/Common/Content-Management/Tags/tags/tags.component';
import { CoursePreviewComponent } from './Modules/Common/Course-Management/Course-Preview/course-preview/course-preview.component';
import { CoursesComponent } from './Modules/Common/Course-Management/Courses/courses/courses.component';
import { SectionsComponent } from './Modules/Common/Course-Management/Sections/sections/sections.component';
import { MessagesComponent } from './Modules/Common/Message/messages/messages.component';
import { QuestionsComponent } from './Modules/Common/Quiz-Management/Questions/questions/questions.component';
import { QuizCategoriesComponent } from './Modules/Common/Quiz-Management/Quiz Categories/quiz-categories/quiz-categories.component';
import { QuizzesComponent } from './Modules/Common/Quiz-Management/Quizzes/quizzes/quizzes.component';
import { AuthorProfileComponent } from './Modules/Common/Reading/author-profile/author-profile.component';
import { CategoryArticlesComponent } from './Modules/Common/Reading/category-articles/category-articles.component';
import { ReadArticleComponent } from './Modules/Common/Reading/read-article/read-article.component';
import { HomePageComponent } from './Modules/HomePage/home-page/home-page.component';
import { ReaderHomePageComponent } from './Modules/Reader/Panel/ReaderHomePage/reader-home-page/reader-home-page.component';
import { SubscriberHomePageComponent } from './Modules/Subscriber/Panel/SubscriberHomePage/subscriber-home-page/subscriber-home-page.component';
import { CourseCategoriesComponent } from './Modules/Common/Course-Management/Course Categories/course-categories/course-categories.component';
import { LecturesComponent } from './Modules/Common/Course-Management/Lectures/lectures/lectures.component';
import { CourseHierarchyComponent } from './Modules/Common/Course-Management/Course-Preview/course-hierarchy/course-hierarchy.component';
import { AttendQuizComponent } from './Modules/Common/Quiz-Management/Quizzes/attend-quiz/attend-quiz.component';
import { AllModulesComponent } from './Modules/Admin/Permission/Module Setup/all-modules/all-modules.component';
import { AllRoleComponent } from './Modules/Admin/Permission/Role Setup/all-role/all-role.component';
import { NewPermissionComponent } from './Modules/Admin/Permission/Permission Setup/new-permission/new-permission.component';
import { FiminizeLayoutComponent } from './Layout/Fiminize Layout/fiminize-layout/fiminize-layout.component';
import { UserRoleSetUpComponent } from './Modules/Admin/Permission/User Role Setup/user-role-set-up/user-role-set-up.component';
import { RolePermissionSetUpComponent } from './Modules/Admin/Permission/Role Permissiom Setup/role-permission-set-up/role-permission-set-up.component';
import { AllCategoriesComponent } from './Modules/Common/Content-Management/Categories/all-categories/all-categories.component';
import { FetchArticleComponent } from './Modules/Common/Content-Management/Categories/fetch-article/fetch-article.component';
import { AuthorDetailsComponent } from './Modules/Common/Content-Management/author-details/author-details.component';
import { AllCoursesComponent } from './Modules/Common/Course-Management/all-courses/all-courses.component';
import { DiscussionComponent } from './Modules/Common/Content-Management/discussion/discussion.component';
import { AboutUsComponent } from './Modules/Common/Content-Management/about-us/about-us.component';
import { PopularTagsComponent } from './Modules/Common/popular-tags/popular-tags.component';
import { BookmarkArticlesComponent } from './Modules/Common/bookmark-articles/bookmark-articles.component';
import { RoomTopicsComponent } from './Modules/Common/Content-Management/discussion/room-topics/room-topics.component';
import { DicussiionForumComponent } from './Modules/Common/Content-Management/discussion/dicussiion-forum/dicussiion-forum.component';
import { MainLoginPageComponent } from './Modules/Auth/main-login-page/main-login-page.component';
import { IntelligenceComponent } from './Modules/Common/intelligence/intelligence.component';
import { ArticleFilterSetUpComponent } from './Modules/Common/Content-Management/Articles/article-filter-set-up/article-filter-set-up.component';
import { ArticleTagComponent } from './Modules/Common/Content-Management/Tags/article-tag/article-tag.component';
import { CommingSoonComponent } from './Reusable Component/comming-soon/comming-soon.component';
import { MarqueComponent } from './Modules/Common/Content-Management/marque/marque.component';
import { DostExplainComponent } from './Modules/Common/Content-Management/dost-explain/dost-explain.component';
import { SymbolsComponent } from './Modules/Common/Content-Management/Articles/symbols/symbols.component';
import { AuthorSignUpComponent } from './Layout/Author Onbording/author-sign-up/author-sign-up.component';
import { DostDiaryComponent } from './Modules/Pages/dost-diary/dost-diary.component';
import { LookUpsComponent } from './Modules/Common/Content-Management/look-Ups/look-ups/look-ups.component';
import { LeaderBoardPointComponent } from './Modules/Common/Content-Management/leader-board-point/leader-board-point.component';
import { EditQuizComponent } from './Modules/Common/Quiz-Management/Quizzes/quizzes/edit-quiz/edit-quiz.component';
import { AuthorManagementComponent } from './Layout/Author Onbording/author-management/author-management.component';
import { UserProfileComponent } from './Modules/Common/Reading/user-profile/user-profile.component';
import { DostPortfolioComponent } from './Modules/Pages/dost-portfolio/dost-portfolio.component';
import { CommunityComponent } from './Modules/Pages/community/community.component';
import { SignupFlowComponent } from './Modules/Auth/signup-flow/signup-flow.component';
import { StocksPageComponent } from './Modules/Pages/stocks-page/stocks-page.component';
import { CryptoCoinPageComponent } from './Modules/Pages/crypto-coin-page/crypto-coin-page.component';
import { CryptopageComponent } from './Modules/Common/Content-Management/Markets/cryptopage/cryptopage.component';
import { StockspageComponent } from './Modules/Common/Content-Management/Markets/stockspage/stockspage.component';
import { DostAlertComponent } from './Modules/Common/Content-Management/Markets/dost-alert/dost-alert.component';
import { CompanyTagsComponent } from './Modules/Pages/company-tags/company-tags.component';
import { ExchangesComponent } from './Modules/Pages/exchanges/exchanges.component';
import { SectorsComponent } from './Modules/Pages/sectors/sectors.component';
import { IndustryComponent } from './Modules/Pages/industry/industry.component';
import { FAQsComponent } from './Modules/Common/Content-Management/Markets/faqs/faqs.component';
import { TickerRequestsComponent } from './Modules/Common/Content-Management/Markets/ticker-requests/ticker-requests.component';
import { QuizAnswerDetailsComponent } from './Modules/Common/Content-Management/Markets/quiz-answer-details/quiz-answer-details.component';
import { AllAuthorsComponent } from './Modules/Pages/all-authors/all-authors.component';





const routes: Routes = [
  // Home Page -----------------------------------------------------
  // { path: '', component: HomePageComponent, canActivate: [authGuardGuard] },

  {
    path: '',
    component: MainLoginPageComponent,
    canActivate: [authGuardGuard],
  },
  {
    path:  'home-page',
    component: FiminizeLayoutComponent,
    canActivate: [authGuardGuard],
  },

  // Common Modules -----------------------------------------------------
  {
    path: 'add_article',
    component: AddArticleComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'symbols',
    component: SymbolsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'stock',
    component: StockspageComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'crypto',
    component: CryptopageComponent,
    canActivate: [authGuardGuard],
  },

  {
    path: 'dostAlerts',
    component: DostAlertComponent,
    canActivate: [authGuardGuard],
  },

  { path: 'dost_explain/:slug', component: DostExplainComponent, canActivate: [authGuardGuard] },

  {
    path: '',
    component: DostExplainComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'articles-filter-setup',
    component: ArticleFilterSetUpComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'article/:slug/:id',
    component: ReadArticleComponent,
    canActivate: [authGuardGuard],
  },


  { path: 'articles/:categoryId', component: FetchArticleComponent },
  {
    path: 'all-Categories',
    component: AllCategoriesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'marque',
    component: MarqueComponent,
    canActivate: [authGuardGuard],
  },
  { path: 'tags', component: TagsComponent, canActivate: [authGuardGuard] },
  { path: 'tag/:tag', component: ArticleTagComponent, canActivate: [authGuardGuard] },

  {
    path: 'draft_articles',
    component: DraftArticlesComponent,
    canActivate: [authGuardGuard],
  },

  {
    path: 'category_articles/:category/:id',
    component: CategoryArticlesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'companytags/:id',
    component: CompanyTagsComponent,
    canActivate: [authGuardGuard], 
  },
  {
    path: 'allauthors/:id',
    component: AllAuthorsComponent,
    canActivate: [authGuardGuard], 
  },
  {
    path: 'crypto/:symbol',
    component: CryptoCoinPageComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'stocks/:symbol',
    component: StocksPageComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'FAQs',
    component: FAQsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'quizz-details',
    component: QuizAnswerDetailsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'ticker-requests',
    component: TickerRequestsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'author/:author/:id',
    component: AuthorProfileComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'exchange/:exchange',
    component: ExchangesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'sector/:sector',
    component: SectorsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'industry/:industry',
    component: IndustryComponent,
    canActivate: [authGuardGuard],
  },

  {
    path: 'userprofile/:user_id',
    component: UserProfileComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'aboutUs',
    component: AboutUsComponent,
    canActivate: [authGuardGuard],
  },

  
  {
    path: 'popular-tags/:id',
    component: PopularTagsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'bookmark-articles',
    component: BookmarkArticlesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'discusstion',
    component: DiscussionComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'room-ropics/:id',
    component: RoomTopicsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'discussion-forum/:id',
    component: DicussiionForumComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'comming-soon',
    component: CommingSoonComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'quizzes',
    component: QuizzesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'edit-quiz/:id',
    component: EditQuizComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'attend-quiz',
    component: AttendQuizComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'quiz_questions',
    component: QuestionsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'quiz_categories',
    component: QuizCategoriesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'all-courses',
    component: AllCoursesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'courses',
    component: CoursesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'sections',
    component: SectionsComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'lectures',
    component: LecturesComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'course/preview/:slug',
    component: CoursePreviewComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'course/preview/:slug/:course_id',
    component: CoursePreviewComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'course-hierarchy/:slug',
    component: CourseHierarchyComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'course-hierarchy/:slug/:course_id',
    component: CourseHierarchyComponent,
    canActivate: [authGuardGuard],
  },
  {
    path: 'course_categories',
    component: CourseCategoriesComponent,
    canActivate: [authGuardGuard],
  },
  { path: 'author-details/:user_id', component: AuthorDetailsComponent },
  // Auth -----------------------------------------------------
  // { path: 'login', component: LoginComponent, canActivate: [authGuardGuard] },
  { path: 'login', component: SignupFlowComponent, canActivate: [authGuardGuard] },
  { path: 'main-login', component: MainLoginPageComponent, canActivate: [authGuardGuard] },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [authGuardGuard],
  },

  // Admin Related -----------------------------------------------------
  {
    path: 'admin_dashboard',
    component: AdminDashboardComponent,
    canActivate: [authGuardGuard],
  },

  // Author Related -----------------------------------------------------
  {
    path: 'author_dashboard',
    component: AuthorDashboardComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },
  //Pages
  {
    path: 'dostDiary',
    component: DostDiaryComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },
  {
    path: 'dostPortfolio',
    component: DostPortfolioComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },
  {
    path: 'community',
    component: CommunityComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },

  {
    path: 'author-Management',
    component: AuthorManagementComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },

  {
    path: 'author_signUp',
    component: AuthorSignUpComponent,
    canActivate: [authGuardGuard],
    title: pageNames.AUTHOR_PANEL,
  },


  // Subscriber Related -----------------------------------------------------
  {
    path: 'subscriber_homepage',
    component: SubscriberHomePageComponent,
    canActivate: [authGuardGuard],
    title: pageNames.SUBSCRIBER_PANEL,
  },

  // Reader Related -----------------------------------------------------
  {
    path: 'reader_homepage',
    component: ReaderHomePageComponent,
    canActivate: [authGuardGuard],
    title: pageNames.READER_PANEL,
  },

  // Permission Module
  {
    path: 'module',
    component: AllModulesComponent,
    canActivate: [authGuardGuard],
    title: pageNames.All_MODULE,
  },
  {
    path: 'role',
    component: AllRoleComponent,
    canActivate: [authGuardGuard],
    title: pageNames.All_ROLE,
  },
  {
    path: 'permission',
    component: NewPermissionComponent,
    canActivate: [authGuardGuard],
    title: pageNames.PERMISSION,
  },
  {
    path: 'user-role',
    component: UserRoleSetUpComponent,
    canActivate: [authGuardGuard],
    title: pageNames.USER_ROLE,
  },
  {
    path: 'role-permission',
    component: RolePermissionSetUpComponent,
    canActivate: [authGuardGuard],
    title: pageNames.USER_ROLE,
  },

  //categories
  {
    path: 'categories',
    component: AllModulesComponent,
    canActivate: [authGuardGuard],
    title: pageNames.All_MODULE,
  },
  //lookUps
  {
    path: 'look-ups',
    component: LookUpsComponent,
    canActivate: [authGuardGuard],
    title: pageNames.All_MODULE,
  },
  //leaderBoardPoint
  {
    path: 'leaderBoardPoint',
    component: LeaderBoardPointComponent,
    canActivate: [authGuardGuard],
    
  },
  //Intelligence Page
  {
    path: 'intelligence',
    component: IntelligenceComponent,
    canActivate: [authGuardGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
