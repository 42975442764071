import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-quiz-qustions',
  templateUrl: './all-quiz-qustions.component.html',
  styleUrls: ['./all-quiz-qustions.component.scss']
})
export class AllQuizQustionsComponent implements OnInit  {
  symbolID: any | null = null;
  relatedQuestions: any[] = [];
  isVisible:boolean=false;
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  quizSymbols:any[]=[];
  showLoadMoreButton: boolean = true;
  selectedOption:any;
  selectedQuestion: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, private message: NzMessageService,) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.symbolID = params.get('symbol_id') ?? '';
      console.log(this.symbolID);
    });
   
    
    if (this.symbolID) {
      this.fetchRelatedQuestions(this.symbolID);
    }
  }
  loadMoreQuestions(): void {
    this.showLoadMoreButton = false;
    this.fetchRelatedQuestions(this.symbolID);
  }

  fetchRelatedQuestions(symbolID: string): void {
    const apiUrl = `${environment.API_URL}fetch_symbol_more_questions`;
    const requestBody = { 
      user_id: Number(this.userId), 
      symbol_id: Number(symbolID) 
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response && Array.isArray(response)) {
          this.quizSymbols = [...this.quizSymbols, ...response]; // Append new questions
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
  handleOk(): void {
    this.isVisible = false;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
 
  openQuestionModal(question: any): void {
    this.isVisible=true;
    this.selectedOption=question.symbol_quiz_answer;
    this.selectedQuestion = question;
  }
  onOptionSelect(optionId: number): void {
    const apiUrl = `${environment.API_URL}save_symbol_answer`;
    const requestBody = {
      symbol_id: this.symbolID,
      user_id: this.userId,
      quiz_question_id: this.selectedQuestion?.id,
      quiz_option_id: optionId,
      quiz_id: null
    };
  
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response) {
          // Update the selected question with the correct/incorrect status
          this.selectedQuestion.is_answer_correct = response.is_answer_correct ? 'correct' : 'incorrect';
  
          // Update the options array to mark the correct option
          this.selectedQuestion.options.forEach((option: { id: number; is_correct_option: number; }) => {
            if (option.id === optionId) {
              option.is_correct_option = response.is_answer_correct ? 1 : 0;
            }
          });
          this.message.success('Answer saved successfully');
        }
        this.fetchRelatedQuestions(this.symbolID);
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to save the answer.');
      },
    });
  }
  
}
