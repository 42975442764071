import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit{
  completedTasks: any[] = [];
  showCompletedTasks = false;
  showAddTask: boolean = false;
  tasks: any[] = [];
  size: NzButtonSize = 'large';
  pipe: any;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe
  ) {}
  taskForm = new FormGroup({
    user_id: new FormControl(''),
    task_id: new FormControl(''),
    title: new FormControl('', Validators.required),
    details: new FormControl('', Validators.required),
    task_date: new FormControl(''),
    is_completed: new FormControl(),
  });
  ngOnInit(): void {
    this.fetchAllData();
    this.fetchAllTasks();
  }
  fetchAllData(): void {
    const tasks$ = this.apiService.getTasks();
  
    tasks$.subscribe({
      next: (tasks: any) => {
        this.tasks = tasks;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred while loading tasks');
      },
    });
  }
  
  fetchAllTasks() {
    this.http.get(`${environment.API_URL}show_completed_tasks`).subscribe({
      next: (response: any) => {
        this.completedTasks = response;
      },
      error: (error) => {
        console.error('Error fetching comments:', error);
      }
    });
  }
  toggleTaskView(): void {
    this.showCompletedTasks = !this.showCompletedTasks;
    if (this.showCompletedTasks && this.completedTasks.length>0) {
      this.fetchAllTasks();
    }
  }
  toggleAddTask(): void {
    this.showAddTask = !this.showAddTask;
  }
  onCheckboxChange(task: any): void {
    const apiUrl = `${environment.API_URL}save_task`; // Make sure to use the correct endpoint
    const requestData = {
      task_id: task.task_id,
      is_completed: 1,
    };
    console.log('compltedted', requestData);

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Task Completed');
        } else {
          this.message.error('Failed to update task status');
        }
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update task status');
      },
      complete: () => {
        this.fetchAllTasks();
      },
    });
  }
  deleteItem(id: number): void {
    const apiUrl = `${environment.API_URL}delete_task`;
    const requestData = { task_id: [id] };
  
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (!response.success) {
          this.message.error('Failed to delete event');
          return;
        }
        this.message.success('Task deleted successfully');
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('An error occurred, please try again later');
      },
      complete: () => {
        this.fetchAllData();
      },
    });
  }
  cancel(): void {
  }
  toggleTaskDetails(task: any): void {
    task.showDetails = !task.showDetails;
  }
  isDatePassed(date: Date): boolean {
    return new Date(date) < new Date();
  }
  addTask() {
    console.log('Adding task...');
    const apiUrl = `${environment.API_URL}save_task`;

    const taskDate = this.datePipe.transform(
      this.taskForm.value['task_date'],
      'yyyy-MM-dd'
    );

    if (!taskDate) {
      this.message.error('Invalid date format');
      return;
    }

    const requestData = {
      ...this.taskForm.value,
      is_completed: 0,
      user_id: localStorage.getItem('userId'),
      task_date: taskDate,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.message.success('Task added');
        this.fetchAllData();
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

}
