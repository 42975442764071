import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import Quill from 'quill';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dost-alert',
  templateUrl: './dost-alert.component.html',
  styleUrls: ['./dost-alert.component.scss'],
})
export class DostAlertComponent implements OnInit {
  isEditMode = false;
  filteredAlertList: any[] = [];
  activeTabIndex = 0;
  dostAlertForm: FormGroup = new FormGroup({});
  fallbackImage: string = 'assets/fallback.webp';
  userId: any = localStorage.getItem('userId');
  tableLoading: boolean = true;
  stockCryptoData: any[] = [];
  allStcoks: any[] = [];
  dostAlertList: any[] = [];
  selectedAlert: any;
  alertType: any[] = [];
  searchValue: string = '';
  allStocks: any[] = [];
  isAddDostAlertModalVisible = false;
  roleId = localStorage.getItem('role_id') ?? '';

  storageUrl = environment.STORAGE_URL;

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchAllDostAlerts(1);
    this.fetchDostAlertstype();
    this.fetchSimilarStocks();
    this.fetchSimilarCryptos();

    this.dostAlertForm = this.fb.group({
      alert_type_id: ['', Validators.required],
      symbol_id: ['', Validators.required],
      text: ['', Validators.required],
      is_diarypost: [''],
      active_status_id: [''],
      description: ['', Validators.required],
    });
  }

  fetchAllDostAlerts(categoryId: number): Promise<void> {

    const requestData = {
        category_id: categoryId,
    };
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_all_dost_alerts`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl,requestData).subscribe({
        next: (response: any) => {
          this.dostAlertList = response;
          this.filteredAlertList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  openDostAlertModal(alert?: any): void {
    if (alert) {
      // Edit Mode
      this.isEditMode = true;
      this.selectedAlert = alert;
      this.dostAlertForm.patchValue({
        alert_type_id: alert.alert_type_id,
        symbol_id: alert.symbol_id,
        text: alert.text,
        description: alert.description,
        is_diarypost: alert.is_diarypost,
        hide_status_id:alert.hide_status_id,
        active_status_id: alert.active_status_id,
      });
    } else {
      // Add Mode
      this.isEditMode = false;
      this.dostAlertForm.reset();
    }
  
    this.isAddDostAlertModalVisible = true;
  }


  filterDostAlerts(searchValue: string): void {
    if (!searchValue) {
      this.filteredAlertList = this.dostAlertList;
    } else {
      const searchLower = searchValue.toLowerCase();
      this.filteredAlertList = this.dostAlertList.filter((alert) =>
        alert.text.toLowerCase().includes(searchLower)
      );
    }
  }
  fetchDostAlertstype(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_dost_alert_type`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.alertType = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  fetchSimilarStocks(): Promise<void> {
    const apiUrl = `${environment.API_URL}stocks_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allStcoks = response;
        },
        error: (error) => {
          console.error('Error fetching stock data: ', error);
          reject();
        },
        complete: () => resolve(),
      });
    });
  }
  
  fetchSimilarCryptos(): Promise<void> {
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.stockCryptoData = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  handleSave(): void {
    if (this.dostAlertForm.valid) {
      const formData = {
        ...this.dostAlertForm.value,
        user_id: this.userId, // Add user_id for both add and edit
        ...(this.isEditMode && { dost_alert_id: this.selectedAlert.dost_alert_id, updated_by: this.userId }) // Add edit-specific fields if in edit mode
      };
      const apiUrl = `${environment.API_URL}${this.isEditMode ? 'edit_dost_alert' : 'add_dost_alert'}`;
      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          console.log(`${this.isEditMode ? 'Dost Alert Update' : 'Dost Alert added'} successfully`, response);
          this.isAddDostAlertModalVisible = false;
          this.message.success(this.isEditMode ? 'DostAlert™ updated successfully!' : 'DostAlert™ added successfully!');
          
          // Refresh the list after save
          this.fetchAllDostAlerts(1); 
        },
        error: (error) => {
          console.error('Error saving dost alert', error);
        }
      });
    } else {
      this.dostAlertForm.markAllAsTouched(); // Mark all fields as touched for validation
    }
  }
  
  handleCancel(): void {
    this.isAddDostAlertModalVisible = false;
  }
  updatehidestatuas(dostAlertId: number, hideStatus: boolean): void {
    const apiUrl = `${environment.API_URL}edit_dost_alert`;
  
    // Convert hideStatus to integer (0 or 1)
    const hideStatusId = hideStatus ? 1 : 0;
  
    const requestData = {
      updated_by: localStorage.getItem('userId'),
      dost_alert_id: dostAlertId,
      hide_status_id: hideStatusId,
    };
  
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Tag approval status updated');
          this.fetchAllDostAlerts(1);
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: () => {
        this.message.error('Error occurred, please try later');
      },
    });
  }
  
  deleteRecord(ID: number) {
    const apiUrl = `${environment.API_URL}delete_dost_alert`;
    const requestData = {
      dost_alert_id: ID,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.fetchAllDostAlerts(1).then(() => {
            this.message.success('DostAlert deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  textSortFn = (a: any, b: any) => (a.text || '').localeCompare(b.text || '');
  symbolSortFn = (a: any, b: any) =>
    (a.symbol || '').localeCompare(b.symbol || '');
  alertTypeSortFn = (a: any, b: any) =>
    (a.dost_alert_type || '').localeCompare(b.dost_alert_type || '');
  createdBySortFn = (a: any, b: any) =>
    (a.created_by_name || '').localeCompare(b.created_by_name || '');
  createdAtSortFn = (a: any, b: any) => {
    const dateA = Date.parse(a.created_at) || 0;
    const dateB = Date.parse(b.created_at) || 0;
    return dateA - dateB;
  };
  updatedBySortFn = (a: any, b: any) =>
    (a.updated_by_name || '').localeCompare(b.updated_by_name || '');
  updatedAtSortFn = (a: any, b: any) => {
    const dateA = a.updated_at ? Date.parse(a.updated_at) : 0;
    const dateB = b.updated_at ? Date.parse(b.updated_at) : 0;
    return dateA - dateB;
  };
}
