import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import Quill from 'quill';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dost-alert',
  templateUrl: './dost-alert.component.html',
  styleUrls: ['./dost-alert.component.scss'],
})
export class DostAlertComponent implements OnInit {
  isEditMode = false;
  filteredAlertList: any[] = [];
  dostAlertForm: FormGroup = new FormGroup({});
  fallbackImage: string = 'assets/fallback.webp';
  userId: any = localStorage.getItem('userId');
  tableLoading: boolean = true;
  dostAlertList: any[] = [];
  selectedAlert: any;
  alertType: any[] = [];
  searchValue: string = '';
  allStocks: any[] = [];
  isAddDostAlertModalVisible = false;
  storageUrl = environment.STORAGE_URL;

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchAllDostAlerts(1);
    this.fetchDostAlertstype();
    this.fetchSymbolS();

    this.dostAlertForm = this.fb.group({
      alert_type_id: ['', Validators.required],
      symbol_id: ['', Validators.required],
      text: ['', Validators.required],
      is_diarypost: [''],
      active_status_id: [''],
      description: ['', Validators.required],
    });
  }

  fetchAllDostAlerts(categoryId: number): Promise<void> {

    const requestData = {
        category_id: categoryId,
    };
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_all_dost_alerts`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl,requestData).subscribe({
        next: (response: any) => {
          this.dostAlertList = response;
          this.filteredAlertList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  openEditModal(alert: any): void {
    console.log(alert);
    
    this.isEditMode = true;
    this.selectedAlert = alert;
    this.dostAlertForm.patchValue({
      alert_type_id: alert.alert_type_id,
      symbol_id: alert.symbol_id,
      text: alert.text,
      description: alert.description,
      is_diarypost: alert.is_diarypost,
      active_status_id: alert.active_status_id,
    });
    this.isAddDostAlertModalVisible = true;
  }

  openAddDostAlertModal(): void {
    this.isAddDostAlertModalVisible = true;
    this.dostAlertForm.reset;
  }

  fetchDostAlertstype(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_dost_alert_type`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.alertType = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  filterDostAlerts(searchValue: string): void {
    if (!searchValue) {
      this.filteredAlertList = this.dostAlertList;
    } else {
      const searchLower = searchValue.toLowerCase();
      this.filteredAlertList = this.dostAlertList.filter((alert) =>
        alert.text.toLowerCase().includes(searchLower)
      );
    }
  }

  fetchSymbolS(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  handleAdd(): void {
    const formData = {
      ...this.dostAlertForm.value,
      user_id: this.userId,
    };
    const apiUrl = `${environment.API_URL}add_dost_alert`;
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Dost Alert added successfully', response);
        this.isAddDostAlertModalVisible = false;
        this.fetchAllDostAlerts(1);
      },
      error: (error) => {
        console.error('Error adding dost alert', error);
      },
    });
  }

  handleEdit(): void {
    if (this.dostAlertForm.valid) {
      const formData = {
        ...this.dostAlertForm.value,
        dost_alert_id: this.selectedAlert.dost_alert_id,
        updated_by: this.userId,
      };
      const apiUrl = `${environment.API_URL}edit_dost_alert`;
      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          console.log('Dost Alert Update successfully', response);
          this.isAddDostAlertModalVisible = false;
          this.fetchAllDostAlerts(1);
        },
        error: (error) => {
          console.error('Error adding dost alert', error);
        },
      });
    } else {
      this.dostAlertForm.markAllAsTouched();
    }
  }

  handleCancel(): void {
    this.isAddDostAlertModalVisible = false;
  }

  deleteRecord(ID: number) {
    const apiUrl = `${environment.API_URL}delete_dost_alert`;
    const requestData = {
      dost_alert_id: ID,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.fetchAllDostAlerts(1).then(() => {
            this.message.success('DostAlert deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  textSortFn = (a: any, b: any) => (a.text || '').localeCompare(b.text || '');
  symbolSortFn = (a: any, b: any) =>
    (a.symbol || '').localeCompare(b.symbol || '');
  alertTypeSortFn = (a: any, b: any) =>
    (a.dost_alert_type || '').localeCompare(b.dost_alert_type || '');
  createdBySortFn = (a: any, b: any) =>
    (a.created_by_name || '').localeCompare(b.created_by_name || '');
  createdAtSortFn = (a: any, b: any) => {
    const dateA = Date.parse(a.created_at) || 0;
    const dateB = Date.parse(b.created_at) || 0;
    return dateA - dateB;
  };
  updatedBySortFn = (a: any, b: any) =>
    (a.updated_by_name || '').localeCompare(b.updated_by_name || '');
  updatedAtSortFn = (a: any, b: any) => {
    const dateA = a.updated_at ? Date.parse(a.updated_at) : 0;
    const dateB = b.updated_at ? Date.parse(b.updated_at) : 0;
    return dateA - dateB;
  };
}
