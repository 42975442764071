import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Question, Quiz } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-edit-quiz',
  templateUrl: './edit-quiz.component.html',
  styleUrls: ['./edit-quiz.component.scss'],
})
export class EditQuizComponent implements OnInit {
  roleId: any;
  quizzes: Quiz[] = [];
  filteredQuizzes: Quiz[] = [];
  questions: Question[] = [];
  allcategories: any = [];
  filteredQuestions: any[] = [];
  allQuestions: any[] = [];
  allSymbols: any[] = [];
  quizQuestions: any = [];
  isModalVisible: boolean = false;
  currentEditQuizId?: number;
  useAdminLayout: boolean = false;
  treeSelectExpandKeys: string[] = [];
  categories: any = [];
  subCategories: any = [];
  searchQuery = '';
  allSelected = false;
  quizLevel: any = [];
  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {}
  QuizTable: number = 0;
  quizTableChecked = false;
  quizTableIndeterminate = false;

  // Question Table Variables
  QuestionTable: number = 1;
  questionTableChecked = false;
  questionTableIndeterminate = false;
  addQuestionToQuizDrawer = false;
  // Quiz Questions Table Variables
  QuizQuestionTable: number = 2;
  quizQuestionsTableChecked = false;
  quizQuestionsTableIndeterminate = false;

  // Set of Checked Ids of Quiz, Question, QuizQuestions Tables
  setOfCheckedQuizTableIds = new Set<number>();
  setOfCheckedQuestionsTableIds = new Set<number>();
  setOfCheckedQuizQuestionsTableIds = new Set<number>();
  editQuizForm: FormGroup = new FormGroup({
    quiz_id: new FormControl(''),
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    quiz_level_id: new FormControl(''),
    category_id: new FormControl(''),
    is_active: new FormControl(null),
    time: new FormControl(null),
    max_attempt: new FormControl(null),
    symbol_id: new FormControl(),
    bonus_points: new FormControl(),
    no_of_days: new FormControl(null),
    valid_date_range: new FormControl([]),
    valid_from: new FormControl(null),
    valid_to: new FormControl(null),
    category: new FormControl(null),
    sub_category: new FormControl(null),
    updated_by: new FormControl(sessionStorage.getItem('userId')),
  });
  quizId: any;
  ngOnInit(): void {
    this.allDropDown()
      .then(() => {
        this.route.paramMap.subscribe((params) => {
          console.log(params);
          this.quizId = params.get('id');
          console.log(this.quizId);
          this.fetchQuizData(this.quizId);
        });
      })
      .catch((error) => {
        console.error('Error initializing dropdowns:', error);
      });

  }

  allDropDown(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.fetchInitialData();
        this.fetchDropDowns();
        this.fetchAllSymbols();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  fetchDropDowns(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToCategoryTree(response.data);
        console.log('treedata', treeData);
        this.allcategories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }
  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const getCategories$ = this.apiService.getQuizCategories();
      const getSubCategories$ = this.apiService.getQuizSubCategories();
      const quizzes$ = this.apiService.getAllQuizzes();
      const quizzesLevel$ = this.apiService.getQuizLevel();
      forkJoin([
        getCategories$,
        getSubCategories$,
        quizzes$,
        quizzesLevel$,
      ]).subscribe({
        next: ([
          categoriesResponse,
          subCategoriesResponse,
          quizzesResponse,
          quizLevel,
        ]: any) => {
          this.categories = categoriesResponse.data;
          this.subCategories = subCategoriesResponse.data;
          this.quizzes = quizzesResponse.data;
          console.log(this.quizzes);
          this.quizLevel = quizLevel;
          this.filteredQuizzes = this.quizzes;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }
  fetchQuizData(quizId: number): void {
    const apiUrl = `${environment.API_URL}fetch_single_quiz`;
    const requestData = { quiz_id: quizId };
    this.http.post(apiUrl, requestData).subscribe({
      next: (res: any) => {
        console.log('Quiz Details: ', res);

        const categoryIds =
          typeof res.category_ids === 'string'
            ? JSON.parse(res.category_ids)
            : res.category_ids;
        const symbolIds =
          typeof res.symbol_ids === 'string'
            ? JSON.parse(res.symbol_ids)
            : res.symbol_ids;
        // Assuming res.time is a string or number representing the time

        this.editQuizForm.patchValue({
          quiz_id: res.id,
          title: res.title,
          bonus_points: res.bonus_points,
          description: res.description,
          quiz_level_id: res.quiz_level_id,
          no_of_days: res.no_of_days,
          max_attempt: res.max_attempt,
          is_active: res.is_active,
          category: res.category_string,
          symbol_id: symbolIds ?? [],
          category_id: categoryIds ?? [],
          valid_date_range: [res.valid_from, res.valid_to],
          time: res.time,
        });

        console.log(this.editQuizForm.value);
        console.log('Form values patched successfully.');
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.fetchQuizQuestions();
      },
    });
  }

  /////////////
  cancel(): void {}

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly any[] = [];
  listOfData: readonly any[] = [];
  setOfCheckedId = new Set<number>();
  selectedItemsArray: any[] = [];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.updateSelectedItems();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;
  }

  updateSelectedItems(): void {
    // Convert the Set to an array
    this.selectedItemsArray = Array.from(this.setOfCheckedId);
    console.log('Selected Items:', this.selectedItemsArray);
  }

  //////////

  refreshQuestions() {
    // Your refresh logic
  }

  handleDelete(selectedIds: number[]) {
    console.log('Delete IDs:', selectedIds);
    // Your delete logic
  }

  handleAdd() {
    console.log('Add new question');
    // Your add logic
  }

  RemoveQuestionFromQuiz(): void {
    const apiUrl = `${environment.API_URL}delete_quiz_questions`;
    this.formSubmitLoading = true;
    const requestData = {
      quiz_id: this.quizId,
      question_id: this.selectedItemsArray,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.message.success('Questions(s) Removed');
          // this.fetchQuizQuestions(this.currentEditQuizId!).then(() => {
          //   this.setOfCheckedQuizQuestionsTableIds.clear();
          //   this.refreshCheckedStatus();
          //   this.message.success('Questions(s) Removed');
          // });
          this.fetchQuizQuestions();
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
        this.formSubmitLoading = false;
      },
      complete: () => {
        this.formSubmitLoading = false;
      },
    });
  }

  searchText: string = '';
  searchQuestions(): void {
    if (this.searchText.trim() === '') {
      this.filteredQuestions = this.quizQuestions;
    } else {
      this.filteredQuestions = this.quizQuestions.filter(
        (question: { question_text: string }) =>
          question.question_text
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
      );
    }
  }

  sortByIndex = (a: any, b: any) => a.index - b.index;

  sortByQuestionText = (a: any, b: any) =>
    a.question_text.localeCompare(b.question_text);

  sortByQuestionType = (a: any, b: any) =>
    a.question_type_string.localeCompare(b.question_type_string);

  sortByStatus = (a: any, b: any) => a.is_active - b.is_active;

  sortByCategory = (a: any, b: any) =>
    (a.categories || '').localeCompare(b.categories || '');

  sortByEvaluationType = (a: any, b: any) =>
    a.evaluation_type.localeCompare(b.evaluation_type);

  fetchQuizQuestions(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_all_quiz_question`;
    var requestData = { quiz_id: this.quizId };

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.quizQuestions = response;
          this.filteredQuestions = this.quizQuestions;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  showModal(): void {
    this.isModalVisible = true;
    this.fetchQuestions();
  }
  handleOk(): void {
    this.isModalVisible = false;
  }
  handleCancel(): void {
    this.isModalVisible = false;
  }

  filterQuestions(): void {
    if (this.searchQuery.trim()) {
      this.filteredQuestions = this.allQuestions.filter((question) =>
        question.question_text
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.filteredQuestions = this.allQuestions;
    }
  }

  fetchQuestions(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}specific_quiz_questions`;
    const requestData = {
      quiz_id: this.quizId, // Ensure this variable is defined in your component
    };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.allQuestions = response;
          this.filteredQuestions = this.allQuestions;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  selectedQuestions: { [key: number]: boolean } = {};

  addSelectedQuestions(): void {
    const selectedQuestionIds = Object.keys(this.selectedQuestions)
      .filter((id) => this.selectedQuestions[+id])
      .map((id) => Number(id)); // Convert keys to numbers
    console.log(selectedQuestionIds);

    if (selectedQuestionIds.length === 0) {
      this.message.warning('No questions selected');
      return;
    }

    const requestData = {
      quiz_id: this.quizId, // Ensure this variable is defined in your component
      question_id: selectedQuestionIds,
    };

    this.addQuestionsToQuiz(requestData)
      .then(() => {
        this.handleCancel(); // Close the modal or drawer on success
      })
      .catch(() => {
        this.message.error('Failed to add questions');
      });
  }
  searchTerm: string = '';
  onSearch(): void {
    this.quizQuestions = this.quizQuestions.filter(
      (question: { question_text: string }) =>
        question.question_text
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
    );
  }
  addQuestionsToQuiz(data?: any): Promise<void> {
    this.formSubmitLoading = true;
    const apiUrl = `${environment.API_URL}add_bulk_quiz_question`;
    const requestData = data;

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.message.success('Question(s) Added');
          } else {
            this.message.error('An error occurred, please try later.');
          }
        },
        error: (error) => {
          console.error('Data Add Error: ', error);
          this.addQuestionToQuizDrawer = false;
          return reject(error);
        },
        complete: () => {
          this.fetchQuizQuestions();
          this.formSubmitLoading = false;
          return resolve();
        },
      });
    });
  }
  submitEditForm() {
    if (isFormValid(this.editQuizForm)) {
      this.formSubmitLoading = true;

      const apiUrl = `${environment.API_URL}edit_quiz`;

      let start_date = this.editQuizForm.controls['valid_date_range'].value[0];
      let end_date = this.editQuizForm.controls['valid_date_range'].value[1];

      const requestData = {
        ...this.editQuizForm.value,
        valid_from: this.datePipe.transform(start_date, 'yyyy-MM-dd HH:mm:ss'),
        valid_to: this.datePipe.transform(end_date, 'yyyy-MM-dd HH:mm:ss'),
        updated_by: localStorage.getItem('userId'),
      };
      // console.log(requestData);
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          // console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Quiz updated');
              this.editQuizForm.reset();
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
          this.formSubmitLoading = false;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.formSubmitLoading = false;
        },
      });
    }
  }

}
function transferArrayItem(data: string[], data1: string[], previousIndex: number, currentIndex: number) {
  throw new Error('Function not implemented.');
}

