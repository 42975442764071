<app-main-fiminize-layout>

  <div class="contianer profile-header py-4">
    <div class="row align-items-center">
      <div class="col-md-3 text-center text-md-start">
        <nz-avatar [nzSize]="100" [nzSrc]="storageUrl + '/' + userDetails.profile_pic" [nzShape]="'circle'">

        </nz-avatar>
      </div>
      <div class="col-md-6">
        <h2 class="mb-0">{{ userDetails?.name }}</h2>
        <p class="text-muted mb-2">{{ userDetails?.username }}</p>
        <div class="d-flex justify-content-start align-items-center mt-3">
          <span>{{ userDetails?.followers }} Followers</span>
          <span class="mx-2">·</span>
          <span>{{ userDetails?.following }} Following</span>
        </div>
        <div class="d-flex align-content-stretch flex-wrap ">
          <button *ngIf="dostUserID !== userID" nz-button nzType="primary" class="me-2" (click)="toggleFollow()">
            {{ userDetails?.already_following ? 'Following' : 'Follow' }}
          </button>
          <button nz-button nzType="default">

            Follow </button>
          <button nz-button nzType="default">
            <span nzTheme="fill" nz-icon nzType="message"></span>
            Message </button>
          <button nz-button nzType="default">
            <span nzTheme="fill" nz-icon nzType="bell"></span>
            Notify Me
          </button>
          <button nz-button nzType="default">
            <span nz-icon nzType="more"></span>
          </button>
        </div>
      </div>
      <div class="col-md-3 mt-3 mt-md-0">
        <div class="card">
          <div class="card-body">
            <p>{{ userDetails?.bio | truncate:100}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-8">
        <nz-tabset>
          <nz-tab [nzTitle]="titleTemplate">
            <ng-template #titleTemplate>
              <span>DostDiary<sup>™</sup></span>
            </ng-template>
            <ng-template nz-tab>
              <div *ngFor="let post of posts" class="card bg-light mb-3">
                <div class="card-header"
                  [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
                  <h5 class="card-title" [innerHTML]="post.caption"></h5>
                  <h6 class="card-subtitle text-muted">{{ post.created_at | date: 'MMMM yyyy' }}</h6>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <div class="flex-grow-1 d-flex align-items-center">
                      <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                        <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                          [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                      </button>
                      <span class="me-2"><b>{{ post.like_count }}</b></span>
                      <button nz-button nzType="text" nzShape="circle">
                        <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>

                      </button>
                      <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                        <i class="fa-solid fa-share-nodes"></i>
                      </button>
                      <button nz-button nzType="text" nzShape="circle">
                        <i class="fa-solid fa-bookmark"></i>
                      </button>
                      <button nz-button nzType="text" nzShape="circle">
                        <i class="fa-solid fa-exclamation"></i>
                      </button>
                      <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                        <i class="fa-solid fa-lock"></i>
                      </button>
                    </div>
                  </div>

                  <!-- Comment Section -->
                  <div *ngIf="commentVisible[post.diary_post_id]">
                    <nz-comment nzAuthor="{{ post.created_by_name }}" nzDatetime="{{ post.created_at | date:'short' }}">
                      <nz-avatar nz-comment-avatar nzIcon="user"
                        nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                      <nz-comment-content>
                        <form [formGroup]="postForm">
                          <nz-input-group [nzSuffix]="suffixIconSearch">
                            <input type="text" nz-input formControlName="comment" placeholder="Enter post comment" />
                          </nz-input-group>
                          <ng-template #suffixIconSearch>
                            <button type="submit" nz-button nzType="dashed" nzShape="circle"><span nz-icon
                                nzType="send"></span></button>
                          </ng-template>
                        </form>
                      </nz-comment-content>
                    </nz-comment>
                  </div>
                </div>
              </div>

            </ng-template>
          </nz-tab>
          <nz-tab [nzTitle]="dostPortfolio">
            <ng-template #dostPortfolio>
              <span>DostPortfolio<sup>™</sup></span>
            </ng-template>
            <ng-template nz-tab>
              DostPortfolio Notes content
            </ng-template>
          </nz-tab>

          <nz-tab nzTitle="Achievements">
            <ng-template nz-tab>
              Achievements content
            </ng-template>
          </nz-tab>
        </nz-tabset>
      </div>
      <div class="col-md-4 mt-4">
 
        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Interested In</h2>
          </div>
          <div class="card-body">
            <div *ngFor="let intrest of userDetails.interested_in" class="d-inline-block me-2">
              <span class="expertise-tag">
                {{ intrest.category_name }}
              </span>
            </div>
          </div>
        </div>
        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Likes
            </h2>
          </div>
          <div class="card-body">

            <div *ngFor="let intrest of userDetails.interested_in" class="d-inline-block me-2">
              <div class="expertise-tag">
                {{intrest.category_name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <nz-modal [(nzVisible)]="isModalVisible" nzTitle="Share this Post" (nzOnCancel)="handleCancel()">
    <div nz-modal-body>
      <ng-container *nzModalContent>
        <button nz-button nzType="text" (click)="share('facebook')">
          <i nz-icon nzType="facebook"></i> Facebook
        </button>
        <button nz-button nzType="text" (click)="share('twitter')">
          <i nz-icon nzType="twitter"></i> Twitter
        </button>
        <button nz-button nzType="text" (click)="share('linkedin')">
          <i nz-icon nzType="linkedin"></i> LinkedIn
        </button>
        <button nz-button nzType="text" (click)="share('Instagram')">
          <i nz-icon nzType="instagram"></i> Instagram
        </button>
      </ng-container>

    </div>
    <div nz-modal-footer>
      <button nz-button nzType="default" (click)="handleCancel()">Close</button>
    </div>
  </nz-modal>

</app-main-fiminize-layout>