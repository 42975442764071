import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-main-login-page',
  templateUrl: './main-login-page.component.html',
  styleUrls: ['./main-login-page.component.scss']
})
export class MainLoginPageComponent {
  currentYear: number = new Date().getFullYear();
  sidebarCollapseLogo: SafeUrl | undefined;
  sidebarExpandLogo: SafeUrl | undefined;
   passwordVisible: boolean = false;

  password?: string;
  constructor(private router: Router,private message: NzMessageService) {}
  submitPassword(): void {
    const correctPassword = 'Idostjan24!';
    if (this.password === correctPassword) {
      this.router.navigate(['/home-page']);
      this.message.success('Login Successfull');
    } else {
      this.message.error('Incorrect Password');
    }
  }
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

}
