import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import Quill from 'quill';
import { catchError, forkJoin, map, of } from 'rxjs';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
interface CategoryResponse {
  data: any[]; // Adjust this according to the actual structure of the 'data' you expect
}

@Component({
  selector: 'app-author-details',
  templateUrl: './author-details.component.html',
  styleUrls: ['./author-details.component.scss'],
})
export class AuthorDetailsComponent implements OnInit {
  storageUrl = environment.STORAGE_URL;
  profileData: any;
  authorSlug: any;
  isModalVisible = false;
  stockList: any = [];
  experienceList: any = [];
  activeTabIndex = 0;
  alertType: any[] = [];
  profilePic: File | null = null;
  imagePreview: string | null = null;
  latestArticles: any[] = [];
  quizLibrary: any[] = [];
  watchlistTickers: any[] = [];
  categoryId: any;
  stocksCovering: any[] = [];
  cryptoCovering: any[] = [];
  expertiseList: any = [];
  ideaHubroomslist: any[] = [];
  authorDostAlerts: any[] = [];
  cryptoList: any = [];
  categories: any = [];
  allStocks: any[] = [];
  approvedTags: any = [];
  courses: any[] = [];
  postForm: FormGroup;
  quill: Quill | undefined;
  dostAlertVisible = false;
  dostAlert: any;
  isAddDostAlertModalVisible = false;
  categoriesForm: FormGroup;
  commentVisible: { [key: number]: boolean } = {};
  isDrawerVisible = false;
  userID = localStorage.getItem('userId');
  roleID = parseInt(localStorage.getItem('role_id') || '0', 10);

  dostUserID: string | null = null;
  userDetails: any = {};
  posts: any[] = [];
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  dostExplains: any[] = [];

  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {
    this.postForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
    this.categoriesForm = new FormGroup({
      category_ids: new FormControl([], [Validators.required]), // Form control for category selection
    });
  }
 
  editProfileForm: FormGroup = new FormGroup({
    username: new FormControl(this.userDetails.username),
    name: new FormControl(this.userDetails.name),
    stocks_covering: new FormControl(this.userDetails.stocks_covering),
    category_id: new FormControl(this.userDetails.category_id),
    expertise_id: new FormControl(this.userDetails.expertise_id),
    experience_id: new FormControl(this.userDetails.experience_id),
    crypto_covering: new FormControl(this.userDetails.crypto_covering),
    experience: new FormControl(this.userDetails.experience),
    location: new FormControl(this.userDetails.location),
    position: new FormControl(this.userDetails.position),
    relevant_tags: new FormControl(this.userDetails.relevant_tags),
    company_name: new FormControl(this.userDetails.company_name),
    quote: new FormControl(this.userDetails.quote),
    joined_date: new FormControl(this.userDetails.joined_date),
    profile_pic: new FormControl(this.userDetails.profile_pic),
    bio: new FormControl(this.userDetails.bio),
    expertise_updated_at: new FormControl(new Date()),
  });

  ngOnInit(): void {
    this.dostUserID = this.activatedRoute.snapshot.paramMap.get('user_id');
    this.fetchDostExplains();
   


    this.fetchUserProfile().then(() => {
      this.fetchAllContnetList(this.updatedRoleID);
    });
 
    this.fetchLatestArticles(1);
    this.fetchAuthorDostportfolio(0);
    this.fetchQuizLibrary();
    this.fetchAuthorDostalerts(1);
    this.editProfileForm
      .get('expertise_id')
      ?.valueChanges.subscribe((selectedValues: any[]) => {
        if (selectedValues && selectedValues.length > 6) {
          this.editProfileForm
            .get('expertise_id')
            ?.setValue(selectedValues.slice(0, 6), { emitEvent: false });
        }
      });
  }
  updatedRoleID:any;
  fetchUserProfile(): Promise<void> {
    if (!this.dostUserID) {
      this.message.error('User ID is missing');
      return Promise.reject(new Error('User ID is missing'));
    }
  
    const requestData = {
      user_id: this.dostUserID,
      auth_user_id: this.userID,
    };
  
    const apiUrl = `${environment.API_URL}fetch_user_profile`;
  
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          try {
            this.userDetails = response;
            this.posts = response.posts ?? []; // Default to an empty array if posts is null/undefined
            this.updatedRoleID = this.userDetails?.role_id?.[0] ?? null; // Handle role_id safely
            resolve();
          } catch (error) {
            console.error('Error processing response:', error);
            this.message.error('Failed to process user details');
            reject(error);
          }
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          this.message.error('Failed to fetch user details');
          reject(error);
        },
      });
    });
  }
  





  removeUploadedLogo() {
    const apiUrl = `${environment.API_URL}delete_user_image`;

    const requestData = {
      user_id: this.userID,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('User Profile Deleted');
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  fetchDostExplains(): void {
    if (this.dostUserID) {
      const requestData = {
        user_id: this.dostUserID,
      };
      this.http.get(`${environment.API_URL}fetch_courses`).subscribe({
        next: (response: any) => {
          this.dostExplains = response;
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          this.message.error('Failed to fetch user details');
        },
      });
    } else {
      this.message.error('User ID is missing');
    }
  }

  notificationContnetList:any[]=[];
  fetchAllContnetList(ID:any ): Promise<void> {
 
  console.log(ID);
  
    const requestData = {
      role_id: [ID]
    };
    const apiUrl = `${environment.API_URL}notify_content_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.notificationContnetList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          
          return reject();
        },
        complete: () => {
          
          return resolve();
        },
      });
    });
  }
  onNotifyContentClick(): void {
    // Collect all selected notify_content_ids
    const selectedContentIds = this.notificationContnetList
      .filter(content => content.selected)  // Filter to get only selected items
      .map(content => content.notify_content_id); // Extract the ids
  
    if (selectedContentIds.length > 0) {
      console.log('Notifying for Content IDs:', selectedContentIds);
    
      // Prepare API request data
      const apiUrl = `${environment.API_URL}notify_me`;
      const requestData = {
        user_id: this.userID,
        notified_to: this.dostUserID,
        notify_content_id: selectedContentIds  // Pass the selected IDs in an array
      };
    
      // Send POST request
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.message.success('Notification Sent');
          this.fetchUserProfile(); // Update user details after notification
        },
        error: (error) => {
          console.error('Error:', error);
          this.message.error('Failed to send notification');
        },
      });
    } else {
      this.message.warning('Please select at least one content to notify');
    }
  }
  likeButton(post: any): void {
    const apiUrl = `${environment.API_URL}like_post`;
    const requestData = {
      post_id: post.diary_post_id,
      like_status: post.like_count ? 0 : 1,
      user_id: this.userID,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Like updated');
        this.fetchUserProfile();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update like status');
      },
    });
  }
  followButton(): void {
    const apiUrl = `${environment.API_URL}follow_user`;
    const requestData = {
      follower_id:this.userID,
      followed_id:this.dostUserID

    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Follow');
        this.fetchUserProfile();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update like status');
      },
    });
  }

  toggleCommentSection(postId: number): void {
    this.commentVisible[postId] = !this.commentVisible[postId];
  }
  openShareModal(): void {
    this.isModalVisible = true;
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];

      // Create a preview of the uploaded image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target?.result as string; // Set preview to the uploaded image
      };
      reader.readAsDataURL(file);

      // Patch the profile_pic form control with the file object
      this.editProfileForm.patchValue({
        profile_pic: file, // Store the file for uploading later
      });
    }
  }
  openEditProfileDrawer(): void {
    this.isDrawerVisible = true;
    if ((this.isDrawerVisible = true)) {
      this.fetchAllDropdowns();
    }

    const requestData = { user_id: this.dostUserID };

    this.http
      .post(`${environment.API_URL}fetch_single_profile`, requestData)
      .subscribe({
        next: (response: any) => {
          this.userDetails = response;

          // Set imagePreview with the profile picture path or fallback
          this.imagePreview = response.profile_pic
            ? `${this.storageUrl}${response.profile_pic}`
            : 'assets/fallback.webp';

          // Patch form values
          this.editProfileForm.patchValue({
            username: response.username,
            name: response.name,
            quote: response.quote,
            bio: response.bio,
            location: response.location,
            position: response.position,
            category_id: response.category_id,
            experience_id: response.experience_id,
            expertise_id: response.expertise_id,
            relevant_tags: response.relevant_tags,
            company_name: response.company_name,
            stocks_covering: response.stocks_covering,
            crypto_covering: response.crypto_covering,
            profile_pic: response.profile_pic, // Store the existing profile picture URL for later use
          });
          this.initialExpertiseIds = [...response.expertise_id];
        },
        error: (error) => {
          console.error('Error fetching profile:', error);
          this.message.error('Failed to fetch profile data');
        },
      });
  }
  initialExpertiseIds: string[] = [];
  hasExpertiseChanged(currentExpertiseIds: string[]): boolean {
    // Check if initialExpertiseIds is null or undefined
    if (
      !this.initialExpertiseIds ||
      this.initialExpertiseIds.length !== currentExpertiseIds.length
    ) {
      return true;
    }
    return !this.initialExpertiseIds.every(
      (id, index) => id === currentExpertiseIds[index]
    );
  }
  submitProfileForm(): void {
    const requestData = new FormData();
    const userID = this.dostUserID ? this.dostUserID.toString() : '';
    requestData.append('user_id', userID);
    requestData.append('username', this.editProfileForm.value.username);
    requestData.append('name', this.editProfileForm.value.name);
    requestData.append('position', this.editProfileForm.value.position);
    requestData.append('company_name', this.editProfileForm.value.company_name);
    requestData.append('location', this.editProfileForm.value.location);
    requestData.append('quote', this.editProfileForm.value.quote);
    requestData.append('bio', this.editProfileForm.value.bio);

    // Handle category and tags
    const categoryIds = this.editProfileForm.value.category_id;
    const expertiseIds = this.editProfileForm.value.expertise_id;
    const tagId = this.editProfileForm.value.relevant_tags;
    const symbolIds = this.editProfileForm.value.stocks_covering;
    const cryptoIds = this.editProfileForm.value.crypto_covering;
    const experienceId = this.editProfileForm.value.experience_id;

    categoryIds.forEach((id: string, index: number) => {
      requestData.append(`category_id[${index}]`, id);
    });

    // Only append expertise IDs if they have changed
    if (this.hasExpertiseChanged(expertiseIds)) {
      expertiseIds.forEach((id: string, index: number) => {
        requestData.append(`expertise_id[${index}]`, id);
      });
    }

    tagId.forEach((id: string, index: number) => {
      requestData.append(`relevant_tags[${index}]`, id);
    });
    symbolIds.forEach((id: string, index: number) => {
      requestData.append(`stocks_covering[${index}]`, id);
    });
    cryptoIds.forEach((id: string, index: number) => {
      requestData.append(`crypto_covering[${index}]`, id);
    });

    // Check if experienceId is an array and append accordingly
    if (Array.isArray(experienceId)) {
      experienceId.forEach((id: string, index: number) => {
        requestData.append(`experience_id[${index}]`, id);
      });
    } else if (experienceId) {
      // Handle single value if experienceId is not an array
      requestData.append('experience_id', experienceId.toString());
    }

    if (this.editProfileForm.value.profile_pic) {
      requestData.append('profile_pic', this.editProfileForm.value.profile_pic);
    }

    // Make HTTP request to update the profile
    this.http
      .post(`${environment.API_URL}edit_user_profile`, requestData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.message.success('Profile updated successfully.');
            this.fetchUserProfile(); // Refreshes user details
            this.isDrawerVisible = false; // Closes the drawer
          } else {
            this.message.error(response.message);
          }
        },
        error: (error) => {
          console.error('Error updating profile:', error);
        },
      });
  }

  fetchAllDropdowns(): Promise<void> {
    const apiUrlBase = `${environment.API_URL}`;
    const requests = {
      stocks: this.http.get(`${apiUrlBase}stocks_dropdown`),
      experience: this.http.get(`${apiUrlBase}experience_dropdown`),
      expertise: this.http.get(`${apiUrlBase}expertise_dropdown`),
      cryptos: this.http.get(`${apiUrlBase}cryptos_dropdown`),
      categories: this.apiService.getCategories(),
      tags: this.http.get(`${apiUrlBase}tag_dropdown`),
    };

    return new Promise((resolve, reject) => {
      forkJoin(requests)
        .pipe(
          map((response) => {
            const categoryResponse = response.categories as CategoryResponse; // Type assertion
            const categoryTree = categoryResponse
              ? convertToCategoryTree(categoryResponse.data)
              : [];
            return { ...response, categories: categoryTree };
          }),
          catchError((error) => {
            console.error('Error fetching dropdowns:', error);
            reject();
            return of(null);
          })
        )
        .subscribe({
          next: (result) => {
            if (result) {
              // Assign the API responses to their respective lists
              this.stockList = result.stocks;
              this.experienceList = result.experience;
              this.expertiseList = result.expertise;
              this.cryptoList = result.cryptos;
              this.categories = result.categories;
              this.approvedTags = result.tags;
            }
          },
          complete: () => {
            resolve();
          },
        });
    });
  }

  openAddDostAlertModal(): void {
    this.isAddDostAlertModalVisible = true;
  }

  fetchLatestArticles(index: number) {
    // Map index to articleType
    const articleType = index === 0 ? 1 : 2; // 1 for "Latest", 2 for "Top"
    console.log(articleType);

    const apiUrl = `${environment.API_URL}fetch_latest_articles_of_author`;
    const requestBody = {
      user_id: this.dostUserID,
      article_type_id: articleType,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.latestArticles = res; // Assign API response to latestArticles array
      },
      error: (err) => {
        console.error('Error fetching latest articles', err);
      },
    });
  }

  fetchQuizLibrary() {
    let apiUrl = `${environment.API_URL}fetch_quiz_library_of_author`;
    let requestBody: any = {
      user_id: this.dostUserID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.quizLibrary = res; // Assign API response to latestArticles array
      },
      error: (err) => {
        console.error('Error fetching latest articles', err);
      },
    });
  }
  fetchAuthorDostportfolio(tabIndex: number) {
    this.activeTabIndex = tabIndex; 
    const categoryId = tabIndex === 0 ? 1 : 2;
    let apiUrl = `${environment.API_URL}author_dostportfolio`;
    let requestBody: any = {
      category_id: categoryId,
      logged_user_id: this.userID,
      user_id: this.dostUserID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.watchlistTickers = res; // Assign API response to latestArticles array
      },
      error: (err) => {
        console.error('Error fetching latest articles', err);
      },
    });
  }
  deleteWatchlisTtickerId(id: number) {
    const apiUrl = `${environment.API_URL}delete_watch_list_ticker`;
    const requestData = {
      watch_list_ticker_id: id,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Dostportfolio deleted');
        this.fetchAuthorDostportfolio(this.activeTabIndex + 1);
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  fetchAuthorDostalerts(alert_type_id: number) {

    const apiUrl = `${environment.API_URL}fetch_dost_alert_of_author`;
    const requestBody = { user_id: this.dostUserID, alert_type_id }; // Pass the alert_type_id

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.authorDostAlerts = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch alerts.');
      },
    });
  }
  handleCancel(): void {
    this.isAddDostAlertModalVisible = false;
  }
  addTobookmark(dostAlertID: number) {
    console.log(dostAlertID);
    const apiUrl = `${environment.API_URL}add_bookmark`;
    const requestBody = {
      user_id: this.userID,
      dost_alert_id: dostAlertID,
    };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.message.success(' bookmark successfully');
        if (response.success) {
          this.dostAlert.is_bookmark = !this.dostAlert.is_bookmark;
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }

  openDostAlertModal(alert: any) {
    console.log(alert);

    this.dostAlertVisible = true;
    this.dostAlert = alert;
  }



  ngAfterViewInit(): void {
    const cardWidth = this.scrollContainer.nativeElement.querySelector('.card')?.offsetWidth + 16 || 350; // card width with margin-right
    this.scrollContainer.nativeElement.style.setProperty('--card-width', `${cardWidth}px`);
  }

  scroll(direction: 'left' | 'right'): void {
    const cardWidth = parseInt(getComputedStyle(this.scrollContainer.nativeElement).getPropertyValue('--card-width'));
    const visibleCardsCount = Math.floor(this.scrollContainer.nativeElement.offsetWidth / cardWidth); // Directly calculate visible cards count
    this.scrollContainer.nativeElement.scrollBy({
      left: (direction === 'left' ? -1 : 1) * visibleCardsCount * cardWidth,
      behavior: 'smooth',
    });
  }

}
