<ng-container>
  <app-admin-layout>
    <ng-container *ngTemplateOutlet="dostAlertTableTemplate"></ng-container>
  </app-admin-layout>
</ng-container>
<ng-template #dostAlertTableTemplate>

  <div class="d-flex mb-2">
    <nz-breadcrumb>
      <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
      <nz-breadcrumb-item><span>DostAlert<sup>™</sup></span></nz-breadcrumb-item>
    </nz-breadcrumb>

  </div>
  <nz-card>
    <nz-tabset [(nzSelectedIndex)]="activeTabIndex" (nzSelectedIndexChange)="fetchAllDostAlerts($event === 0 ? 1 : 2)">
      <nz-tab nzTitle="Stocks" ></nz-tab>
      <nz-tab nzTitle="Cryptos" ></nz-tab>
    </nz-tabset>
    <nz-row [nzGutter]="16">
      <nz-col [nzSpan]="18">
        <nz-input-group>
          <input nz-input placeholder="Search DostAlert" [(ngModel)]="searchValue"
            (input)="filterDostAlerts(searchValue)" />
        </nz-input-group>
      </nz-col>
      <nz-col [nzSpan]="6">
        <button nz-button nzType="primary" (click)="openDostAlertModal()">Add DostAlert<sup>™</sup></button>
      </nz-col>
    </nz-row>
    <nz-table #basicTable [nzData]="filteredAlertList" [nzLoading]="tableLoading" nzBordered [nzPageSize]="10"
      nzShowPagination>

      <thead>
        <tr>
          <th nzShowSort [nzSortFn]="textSortFn">Text</th>
          <th nzShowSort [nzSortFn]="symbolSortFn">Ticker</th>
          <th nzWidth="5%" >Status</th>
          <th nzShowSort [nzSortFn]="alertTypeSortFn">Alert Type</th>
          <th nzShowSort [nzSortFn]="createdBySortFn">Created By</th>
          <th nzShowSort [nzSortFn]="createdAtSortFn">Created At</th>
          <th nzShowSort [nzSortFn]="updatedBySortFn">Updated By</th>
          <th nzShowSort [nzSortFn]="updatedAtSortFn">Updated At</th>
          <th nzWidth="10%">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let alert of basicTable.data" (click)="openDostAlertModal(alert)">
          <td>{{ alert.text }}</td>
          <td>{{ alert.symbol }}</td>
          <td>{{ alert.active_status_id === 1 ? 'Active' : 'Inactive' }}</td>
          <td>{{ alert.dost_alert_type }}</td>
          <td>{{ alert.created_by_name }}</td>

          <td>{{ alert.created_at | date: 'yyyy-MM-dd' }}</td>
          <td>{{ alert.updated_by_name ? alert.updated_by_name : '-' }}</td>
          <td>{{ alert.updated_at ? (alert.updated_at | date: 'yyyy-MM-dd') : '-' }}</td>
          <td nzAlign="center" (click)="$event.stopPropagation()">
            <button *ngIf="['1'].includes(roleId)" nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
              nzPopconfirmTitle="Are you sure you want to delete this record?"
              (nzOnConfirm)="deleteRecord(alert.dost_alert_id)">
              <span nz-icon nzType="delete"></span>
            </button>
          </td>

        </tr>
      </tbody>
    </nz-table>


  </nz-card>
  <nz-modal [(nzVisible)]="isAddDostAlertModalVisible" [nzTitle]="isEditMode ? 'Edit DostAlert™' : 'Add DostAlert™'"
    (nzOnCancel)="handleCancel()" (nzOnOk)="handleSave()"  nzOkText="Notify">

    <ng-container *nzModalContent>
      <form [formGroup]="dostAlertForm">
        <div class="row">
          <div class="col-md-12">
            <div nz-form-item>
              <div nz-form-label>
                <label for="text">Text</label>
              </div>
              <div nz-form-control>
                <textarea nz-input formControlName="text" placeholder="Enter alert text" nzAutosize></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div nz-form-label>
              <label for="text">Description</label>
            </div>
            <nz-form-item>
              <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="description"
                placeholder="Enter alert description" style="width: 100%;"></quill-editor>

            </nz-form-item>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <nz-form-item>
              <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select DostAlert Type"
                formControlName="alert_type_id">
                <nz-option *ngFor="let alertType of alertType" [nzValue]="alertType.type_id"
                  [nzLabel]="alertType.dost_alert_type"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
          <!-- <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Select Ticker</nz-form-label>
              <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Ticker"
                formControlName="symbol_id">
                <nz-option *ngFor="let symbols of allStocks" [nzValue]="symbols.symbol_id"
                  [nzLabel]="symbols.symbol"></nz-option>
              </nz-select>
            </nz-form-item>
          </div> -->
          <div class="col-md-6">
        
            <nz-form-item *ngIf="activeTabIndex === 0">
              <nz-select class="w-100" nzAllowClear  nzShowSearch nzPlaceHolder="Select Stocks" formControlName="symbol_id">
                <nz-option *ngFor="let stocks of allStcoks" [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"
                  >
                </nz-option>
              </nz-select>
            </nz-form-item>
            <nz-form-item *ngIf="activeTabIndex === 1">
              <nz-select nzAllowClear class="w-100" nzShowSearch nzPlaceHolder="Select Crypto" formControlName="symbol_id">
                <nz-option *ngFor="let cryptos of stockCryptoData" [nzValue]="cryptos.symbol_id"
                  [nzLabel]="cryptos.symbol"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <nz-form-item >
              <nz-form-label>Add to DostDiary</nz-form-label>
              <nz-radio-group formControlName="is_diarypost">
                  <label nz-radio [nzValue]="1">Yes</label>
                  <label nz-radio [nzValue]="0">No</label>
              </nz-radio-group>
          </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item >
              <nz-form-label>DostAlert<sup>™</sup> Status</nz-form-label>
              <nz-radio-group formControlName="active_status_id">
                  <label nz-radio [nzValue]="1">Active</label>
                  <label nz-radio [nzValue]="2">Inactive</label>
              </nz-radio-group>
          </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>

</ng-template>