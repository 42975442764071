import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-card-design',
  templateUrl: './article-card-design.component.html',
  styleUrls: ['./article-card-design.component.scss'],
})
export class ArticleCardDesignComponent {
  loadingData: boolean = false;
  storageUrl = environment.STORAGE_URL;
  mainArticle: any = {};
  @Input() articleData: any[] = [];
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() showSlideButtons: boolean = false;
  @Output() slideEvent = new EventEmitter<number>();
  @Output() refresh = new EventEmitter<void>();
  fallbackImage: string = 'assets/fallback.webp';

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}


  toggleBookmark(id: number, bookmarkStatus: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }
  
    const obj = { user_id: userId, artical_id: id };
  
    if (bookmarkStatus === 0) {
      this.apiService.addBookmarkArticles(obj).subscribe({
        next: () => {
          this.refreshData();
          this.message.success('Article bookmarked');
        },
      });
    } else if (bookmarkStatus === 1) {
      this.apiService.deleteBookmarkArticles(obj).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.refreshData();
            this.message.success('Article Unbookmarked');
          }
        },
      });
    }
  }
  

  refreshData(): void {
    this.refresh.emit();
  }
  ngAfterViewInit(): void {
    const cardWidth = this.scrollContainer.nativeElement.querySelector('.card')?.offsetWidth + 16 || 350; // card width with margin-right
    this.scrollContainer.nativeElement.style.setProperty('--card-width', `${cardWidth}px`);
  }

  scroll(direction: 'left' | 'right'): void {
    const cardWidth = parseInt(getComputedStyle(this.scrollContainer.nativeElement).getPropertyValue('--card-width'));
    const visibleCardsCount = Math.floor(this.scrollContainer.nativeElement.offsetWidth / cardWidth); // Directly calculate visible cards count
    this.scrollContainer.nativeElement.scrollBy({
      left: (direction === 'left' ? -1 : 1) * visibleCardsCount * cardWidth,
      behavior: 'smooth',
    });
  }
  cancel(): void {}
}
