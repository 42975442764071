<div class="container-fluid d-flex align-items-center justify-content-center">
 <nz-card style="width: 500px;">
        <div class="text-center mb-4">
            <img [src]="headerLogo" alt="Logo">
        </div>
        <nz-divider nzText="Create Your Account"></nz-divider>
       

        <div *ngIf="step === 1">
            <form [formGroup]="userForm">
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input formControlName="name" placeholder="Enter your name" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input formControlName="email" placeholder="Enter your email" />
                    </nz-form-control>
                </nz-form-item>
                <button nz-button nzBlock   [disabled]="!userForm.valid" nzShape="round" nzType="primary" (click)="nextStep()">Next</button>
            </form>
        </div>
        <div *ngIf="step === 2">
            <p>Solve: {{ randomNum1 }} + {{ randomNum2 }}</p>
            <form [formGroup]="authForm">
                <nz-input-group>
                    <input nz-input formControlName="authAnswer" placeholder="Enter the result" />
                </nz-input-group>
                <button nzBlock [disabled]="!authForm.valid" (click)="sendOtpToUser()" nz-button nzType="primary"  [nzLoading]="isLoading">
                    Verify 
                </button>
            </form>
        </div>
        <div *ngIf="step === 3">
            <form [formGroup]="otpForm">
                <nz-form-item>
                    <nz-form-label [nzSm]="6" [nzXs]="24">OTP</nz-form-label>
                    <nz-form-control [nzSm]="14" [nzXs]="24">
                        <input nz-input formControlName="otp" placeholder="Enter OTP" />
                    </nz-form-control>
                </nz-form-item>

                <button nzBlock [disabled]="!otpForm.valid" nz-button nzType="primary" (click)="validateOtp()">Next</button>
            </form>
        </div>
        <div *ngIf="step === 4">
            <form [formGroup]="passwordForm">
                <nz-form-item>
                    <nz-form-label [nzSm]="6" [nzXs]="24">Password</nz-form-label>
                    <nz-form-control [nzSm]="14" [nzXs]="24">
                        <input nz-input type="password" formControlName="password" placeholder="Create your password" />
                    </nz-form-control>
                </nz-form-item>
                <button [disabled]="!passwordForm.valid" nz-button nzType="primary" (click)="nextStep()">Next</button>
            </form>
        </div>
        <div *ngIf="step === 5">
            <form [formGroup]="usernameForm">
                <nz-form-item>
                    <nz-form-label [nzSm]="6" [nzXs]="24">Username</nz-form-label>
                    <nz-form-control [nzSm]="14" [nzXs]="24">
                        <input nz-input formControlName="username" placeholder="Enter your username" />
                    </nz-form-control>
                </nz-form-item>
                <button [disabled]="!usernameForm.valid" nz-button nzType="primary" (click)="nextStep()">Next</button>
            </form>
        </div>
        <div *ngIf="step === 6">
            <form [formGroup]="profileForm">
                <label for="profilePicture">Upload Profile Picture:</label>

                <input *ngIf="!imageUrl" type="file" id="profilePicture" (change)="onProfilePictureChange($event)"
                    accept="image/*" />
                <div *ngIf="imageUrl">
                    <img [src]="imageUrl" alt="Profile Picture Preview"
                        style="max-width: 100px; max-height: 100px; border-radius: 50%;" />
                    <i>{{ imageName }}</i>
                    <button nzShape="circle" nz-button (click)="removeImage()" nzType="dashed" nzDanger><span nz-icon
                            nzType="delete"></span></button>
                </div>
            </form>
            <button nz-button nzType="text" nzBlock   (click)="nextStep()">Skip</button>
            <button  nz-button nzType="primary" nzBlock (click)="nextStep()">Next</button>
        </div>

        <div *ngIf="step === 7">
            <div>
                <h2>Select at Least Two Categories</h2>
                <form [formGroup]="categoryForm" >
                    <div class="row" role="group" aria-label="Basic checkbox toggle button group">
                        <div class="col-md-4" *ngFor="let category of categories">
                            <div class="category-box"role="group">
                                <input type="checkbox" class="btn-check" [value]="category.id"
                                    [checked]="(categoryForm.controls['selectedCategories'].value ?? []).includes(category.id)"
                                    (change)="onCategoryChange($event, category.id)" autocomplete="off"
                                    [id]="'btncheck' + category.id">
                                <label class="btn btn-outline-primary w-100" [for]="'btncheck' + category.id">
                                    {{ category.category_name }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <button nz-button nzType="primary"  [disabled]="(categoryForm.controls['selectedCategories'].value ?? []).length < 2"  nzBlock (click)="nextStep()">Next</button>
        
                </form>
              
        
            </div>
        </div>
<div *ngIf="step === 8">
    <form [formGroup]="subCategoryForm" >
        <div role="group" aria-label="Subcategory selection">
            <div class="col-md-2" *ngFor="let subCategory of subCategories">
                <ng-container *ngIf="subCategory.child && subCategory.child.length > 0">
                    <ng-container *ngFor="let item of subCategory.child">
                        <label class="mb-3 btn-group d-flex" role="group">
                            <input type="checkbox" class="btn-check" [value]="item.id"
                                [checked]="(subCategoryForm.controls['selectedSubCategories'].value ?? []).includes(item.id)"
                                (change)="onSubCategoryChange($event, item.id)" autocomplete="off"
                                [id]="'btncheck' + item.id">
                            <span class="btn btn-outline-primary">
                                {{ item.category_name }}
                            </span>
                        </label>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <button nz-button nzType="primary"  nzBlock (click)="nextStep()">Next</button>
        
    </form>
</div>
        <div *ngIf="step === 9">
            <h2>Select and Follow an Author</h2>
            <form [formGroup]="authorForm">
                <nz-form-item>
                    <nz-form-label>Select Author(s)</nz-form-label>
                    <nz-form-control>
                        <div *ngFor="let author of authors">
                   
                            <label nz-checkbox (change)="onAuthorChange($event, author.user_id)">
                                {{ author.name }}
                            </label>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>

        </div>

        <button *ngIf="step === 9" nz-button nzBlock nzType="primary" (click)="completeSignup()">Complete Signup</button>
    </nz-card>
</div>