<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>DostDiary</nz-breadcrumb-item>
  </nz-breadcrumb>

  <div class="row">
    <div class="col-md-3 sticky-sidebar ">
      <button nz-button nzType="primary" (click)="openDrawer('addEvent')">Add Event</button>

      <ng-container *ngIf="events.length > 0; else noEvents">
        <ng-container *ngFor="let event of events">
          <nz-page-header class="site-page-header bordered-header">
            <nz-page-header-subtitle>
              {{ event.valid_from | date: 'd MMM yyyy' }} - {{ event.valid_till | date: 'd MMM yyyy' }}
            </nz-page-header-subtitle>

            <nz-page-header-extra>
              <nz-space>
                <button nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight"
                  style="border: none; padding: 0;">
                  <span nz-icon nzType="more" nzTheme="outline" style="font-size: 20px; vertical-align: top;"></span>
                </button>
              </nz-space>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this record?"
                      (nzOnConfirm)="deleteItem(event.event_id, 'event')" (nzOnCancel)="cancel()">
                      <span nz-icon nzType="delete"></span>
                    </button>
                  </li>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzShape="circle" (click)="fetchEventtData(event)">
                      <span nz-icon nzType="edit"></span>
                    </button>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-page-header-extra>

            <nz-page-header-content>
              <div>
                <div class="content">
                  <p nz-paragraph [innerHTML]="event.event_title"></p>
                  <p class="text-muted">{{ event.description }}</p>
                </div>
              </div>
            </nz-page-header-content>
          </nz-page-header>
        </ng-container>
      </ng-container>

      <ng-template #noEvents>
        <div class="alert alert-info text-center" role="alert">
          <h4 class="alert-heading">No Events Available</h4>
          <p class="mb-0">It looks like there are no posts yet. Check back later or
            <span (click)="openDrawer('addEvent')" style="color: #007bff; cursor: pointer; text-decoration: underline;">
              create a new Event!
            </span>
          </p>
        </div>
      </ng-template>
    </div>
    <div class="col-md-6 scrollable-content ">
      <div class="header d-flex justify-content-between mb-3">
        <button nzBlock nz-button nzType="default" nzShape="round" class="custom-warning me-2">
          My Dost Diary+
        </button>
        <button nzBlock nz-button nzShape="round" nzType="default">
          Timeline
        </button>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <button nzBlock nz-button nzShape="round" nzType="default" class="custom-danger me-2">
          Add Post
        </button>
        <button nzBlock nz-button nzType="primary" nzShape="round" class="me-2">
          Joined Dost Dairy
        </button>
        <button nzBlock nz-button nzType="default">
          Liked & Saved
        </button>
      </div>


      <div class="bordered-header">
        <form [formGroup]="postForm" (ngSubmit)="addPost()">
          <nz-form-item>
            <nz-form-control nzErrorTip="Enter Caption">
              <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="caption"
                placeholder="Enter post caption..." style="width: 100%;"></quill-editor>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <div class="row">
              <div class="col-md-4">
                <nz-form-label>Is featured post</nz-form-label>
                <nz-radio-group formControlName="is_public">
                  <label nz-radio [nzValue]="0">No</label>
                  <label nz-radio [nzValue]="1">Yes</label>
                </nz-radio-group>
              </div>
              
              <div class="col-md-4">
                <nz-form-label>Visibility</nz-form-label>
                <nz-radio-group formControlName="is_featured_post">
                  <label nz-radio [nzValue]="0">Public</label>
                  <label nz-radio [nzValue]="1">Private</label>
                </nz-radio-group>
              </div>
            
              <div class="col-md-4">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Select Ticker">
                    <nz-form-label>Select Ticker</nz-form-label>
                    <nz-select nzShowSearch formControlName="symbol_id" 
                               [nzMaxTagCount]="3" 
                               nzPlaceHolder="Please select">
                      <nz-option *ngFor="let item of allSymbols" 
                                 [nzValue]="item.symbol_id" 
                                 [nzLabel]="item.symbol"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
          
            </div>
            
          </nz-form-item>
          <div class="row">
            <div class="col-md-12">
              <button type="submit" 
              [disabled]="formSubmitLoading || !postForm.get('caption')?.value"
              [nzLoading]="formSubmitLoading" 
              nz-button 
              nzType="dashed"  type="submit" nz-button nzType="primary" nzBlock>Add Post </button>
           
            </div>
          </div>
        </form>
      </div>
      <ng-container *ngIf="posts.length > 0; else noPosts">
        <ng-container *ngFor="let post of posts">
          <nz-page-header class="site-page-header bordered-header"
            [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
            <nz-avatar nz-page-header-avatar nzIcon="user"></nz-avatar>
            <nz-page-header-subtitle [routerLink]="['/userprofile', post.user_id]">{{ post.created_by_name }}, {{
              post.created_at | date: 'MMMM yyyy'
              }}</nz-page-header-subtitle>

            <nz-page-header-extra>
              <nz-space>
                <button *nzSpaceItem nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu"
                  nzPlacement="bottomRight" style="border: none; padding: 0">
                  <span nz-icon nzType="more" nzTheme="outline" style="font-size: 20px; vertical-align: top;"></span>
                </button>
              </nz-space>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this record?"
                      (nzOnConfirm)="deleteItem(post.diary_post_id, 'post')" (nzOnCancel)="cancel()">
                      <span nz-icon nzType="delete"></span>
                    </button>
                  </li>
                  <li nz-menu-item>
                    <button nz-button nzType="default" nzShape="circle" (click)="fetchPostData(post)">
                      <span nz-icon nzType="edit"></span>
                    </button>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-page-header-extra>

            <nz-page-header-content>
              <div>
                <div class="content">
                  <!-- Edit mode -->
                  <div *ngIf="isEditMode[post.diary_post_id]; else viewMode">
                    <form [formGroup]="editPostForm">
                      <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="caption"
                        placeholder="Enter post caption..." style="width: 100%;"></quill-editor>

                      <nz-divider></nz-divider>
                      <nz-form-item>
                        <nz-form-label>How are you feeling about this post?</nz-form-label>
                        <nz-radio-group formControlName="polling_indicator_id">
                          <label nz-radio [nzValue]="1">Bullish</label>
                          <label nz-radio [nzValue]="2">Bearish</label>
                          <label nz-radio [nzValue]="3">Neutral</label>
                        </nz-radio-group>
                      </nz-form-item>
                      <nz-divider></nz-divider>
                      <div class="d-flex justify-content-end">
                        <button (click)="submitPost(post.diary_post_id)" nz-button nzType="default">
                          <span nz-icon nzType="save"></span>
                          Update Post
                        </button>
                        <button class="me-2" (click)="cancelEdit(post.diary_post_id)" nz-button nzType="default">
                          <span nz-icon nzType="close-circle"></span>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  <!-- View mode -->
                  <ng-template #viewMode>
                    <p nz-paragraph [innerHTML]="post.caption"></p>
                    <nz-divider></nz-divider>

                    <nz-form-item>
                      <nz-form-label>How are you feeling about this post?</nz-form-label>
                      <nz-radio-group [(ngModel)]="post.polling_indicator_id"
                        (ngModelChange)="onPollingIndicatorChange($event, post)">
                        <label nz-radio [nzValue]="1">Bullish</label>
                        <label nz-radio [nzValue]="2">Bearish</label>
                        <label nz-radio [nzValue]="3">Neutral</label>
                      </nz-radio-group>
                    </nz-form-item>
                    <nz-divider></nz-divider>

                    <div class="d-flex align-items-center justify-content-between mt-1">
                      <div class="flex-grow-1 d-flex align-items-center">
                        <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                          <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                            [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                        </button>
                        <span class="me-2"><b>{{ post.like_count }}</b></span>
                        <button nz-button nzType="text" nzShape="circle">
                          <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>
                        </button>
                        <span class="me-2"><b>{{ post.comment_count }}</b></span>
                        <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                        <button nz-button nzType="text" nzShape="circle">
                          <i class="fa-solid fa-bookmark"></i>
                        </button>
                        <button nz-button nzType="text" nzShape="circle">
                          <i class="fa-solid fa-exclamation"></i>
                        </button>
                        <!-- Pushes the lock icon to the end -->
                        <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                          <i class="fa-solid fa-lock"></i>
                        </button>
                      </div>
                      <div class="ms-2">
                        <span *ngFor="let symbol of post.symbols" class="me-1">
                          <nz-tag [nzColor]="'magenta'">  {{ symbol.symbol }}</nz-tag>
                           
                        </span>
                    </div>
                    </div>

                    <div *ngIf="commentVisible[post.diary_post_id]">
                      <!-- Main Comment Form -->
                      <nz-comment
                        nzAuthor="{{ post.created_by_name }}"
                        nzDatetime="{{ post.created_at | date:'short' }}"
                        nzContentStyle="padding: 12px;"
                        nzCommentContentStyle="background: #f9f9f9; border-radius: 4px; padding: 12px;"
                      >
                        <nz-avatar
                          nz-comment-avatar
                          nzIcon="user"
                          nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                          nzSize="large"
                        ></nz-avatar>
                        <nz-comment-content>
                          <form [formGroup]="postForm" (ngSubmit)="submitCommentOrReply(post.diary_post_id, null)">
                            <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="comment"
                            placeholder="Enter post comment" style="width: 100%;"></quill-editor>
                            <button nz-button nzType="dashed" type="submit">
                              <span nz-icon nzType="send"></span>
                            </button>
                          </form>
                        </nz-comment-content>
                      </nz-comment>
                    
                      <ng-template #commentTemplate let-comment="comment">
                        <nz-comment
                          [nzAuthor]="comment.created_by_string"
                          nzDatetime="{{ comment.created_at | date:'short' }}"
                          nzContentStyle="padding: 12px;"
                          nzCommentContentStyle="background: #f9f9f9; border-radius: 4px; padding: 12px;"
                        >
                          <nz-avatar
                            nz-comment-avatar
                            nzIcon="user"
                            nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            nzSize="large"
                          ></nz-avatar>
                          <nz-comment-content>
                            <p>{{ comment.comment }}</p>
                       
                              <button nz-button nzType="link" (click)="prepareReply(comment.comment_id)">
                                <span nz-icon nzType="edit"></span> Reply
                              </button>
                              <button nz-button nzType="link" (click)="deleteComment(comment.comment_id)">
                                <span nz-icon nzType="delete"></span> 
                              </button>
                            <div *ngIf="replyingToCommentId === comment.comment_id">
                              <form [formGroup]="postForm" (ngSubmit)="submitCommentOrReply(post.diary_post_id, comment.comment_id)">
                                <nz-input-group [nzSuffix]="suffixIconReply">
                                  <input type="text" nz-input formControlName="comment" placeholder="Enter your reply" />
                                </nz-input-group>
                                <ng-template #suffixIconReply>
                                  <button type="submit" nz-button nzType="primary" nzShape="circle">
                                    <span nz-icon nzType="send"></span>
                                  </button>
                                </ng-template>
                              </form>
                            </div>
                            <div *ngIf="comment.children && comment.children.length > 0">
                              <ng-container *ngFor="let child of comment.children | filterComments: post.diary_post_id">
                                <ng-container *ngTemplateOutlet="commentTemplate; context: { comment: child }"></ng-container>
                              </ng-container>
                            </div>
                            <div *ngIf="!comment.children || (comment.children && !comment.childrenFullyLoaded)">
                              <button nz-button nzType="link" (click)="loadMoreChildComments(post.diary_post_id, comment.comment_id)">
                                <span nz-icon nzType="eye"></span> View More
                              </button>
                            </div>
                          </nz-comment-content>
                        </nz-comment>
                      </ng-template>
                    
                      <ng-container *ngFor="let comment of comments | filterComments: post.diary_post_id">
                        <ng-container *ngTemplateOutlet="commentTemplate; context: { comment: comment }"></ng-container>
                      </ng-container>
                    </div>
                    
                    
                  </ng-template>
                </div>
              </div>
            </nz-page-header-content>
          </nz-page-header>
        </ng-container>
      </ng-container>



      <ng-template #noPosts>
        <div class="alert alert-info text-center" role="alert">
          <h4 class="alert-heading">No Posts Available</h4>
          <p class="mb-0">It looks like there are no posts yet. Check back later or
            <span (click)="openDrawer('addPost')" style="color: #007bff; cursor: pointer; text-decoration: underline;">
              create a new post!
            </span>
          </p>
        </div>
      </ng-template>

    </div>
    <div class="col-md-3 sticky-sidebar">
      <div>
        <div class="row">
          <nz-page-header class="bordered-header">

            <nz-page-header-content>
              <button nz-button nzType="text" (click)="toggleTaskView()">
                {{ showCompletedTasks ? 'Back to Active Tasks' : 'Completed Tasks' }}
              </button>
              <button (click)="toggleAddTask()" nzType="dashed" nzBlock nz-button [nzSize]="size" nzShape="round">
                <i nz-icon nzType="plus"></i> Add a task
              </button>
              <div class="row mt-2" *ngIf="showAddTask && !showCompletedTasks">
                <form [formGroup]="taskForm">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <div class="form-group">
                        <input nz-input formControlName="title" placeholder="Task Name" />

                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <div class="form-group">
                        <nz-input-group [nzSuffix]="suffixIconSearch">
                          <input nz-input formControlName="details" placeholder="Task Description" />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                          <span nz-icon nzType="align-left"></span>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <div class="form-group">
                        <nz-date-picker nzBlock formControlName="task_date" nzPlaceHolder="Select task date"
                          [nzFormat]="'yyyy-MM-dd'" [ngStyle]="{ width: '100%' }"></nz-date-picker>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <button nz-button nzType="primary" [disabled]="!taskForm.valid" nzBlock (click)="addTask()">
                          Add Task
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="task-container mt-2" *ngIf="!showCompletedTasks">
                <ul>
                  <ng-container *ngIf="tasks.length > 0; else noTasks">
                    <li *ngFor="let task of tasks" class="bg-light mt-2">
                      <div class="row">
                        <div class="col-1">
                          <label nz-radio (ngModelChange)="onCheckboxChange(task)" [ngModel]="task.is_completed"
                            [nzValue]="true">
                          </label>
                        </div>
                        <div class="col-11" (click)="toggleTaskDetails(task)">
                          <div class="task-title">
                            <p><b>{{ task.title }}</b></p>
                          </div>
                          <div *ngIf="task.task_date" [ngClass]="{ 'border-danger': isDatePassed(task.task_date) }">
                            <nz-tag nzColor="#55acee">
                              <span nz-icon nzType="calendar"></span>
                              <span> {{ task.task_date | date: 'dd MMM yyyy' }}</span>
                            </nz-tag>
                          </div>
                          <div class="task-details">
                            {{ task.details }}
                          </div>
                        </div>
                        <nz-page-header-extra>
                          <nz-space>
                            <button *nzSpaceItem nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu"
                              nzPlacement="bottomRight" style="border: none; padding: 0">
                              <span nz-icon nzType="more" nzTheme="outline"
                                style="font-size: 20px; vertical-align: top;"></span>
                            </button>
                          </nz-space>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item>
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                  nzPopconfirmTitle="Are you sure you want to delete this record?"
                                  (nzOnConfirm)="deleteItem(task.task_id, 'task')" (nzOnCancel)="cancel()">
                                  <span nz-icon nzType="delete"></span>
                                </button>
                              </li>
                            </ul>
                          </nz-dropdown-menu>
                        </nz-page-header-extra>
                      </div>
                    </li>
                  </ng-container>
                </ul>
                <ng-template #noTasks>
                  <li><span>No tasks available</span></li>
                </ng-template>
              </div>



              <div class="task-container mt-2" *ngIf="showCompletedTasks">
                <ul>
                  <ng-container *ngIf="completedTasks.length > 0; else noCompletedTasks">
                    <li *ngFor="let task of completedTasks" class="bg-light mt-2">
                      <div class="row">
                        <div class="col-md-10">
                          <div class="task-title">
                            <p><b>{{ task.title }}</b></p>
                          </div>
                          <div *ngIf="task.task_date">
                            <span nz-typography nzType="secondary">
                              Created On: {{ task.task_date | date: 'dd MMM yyyy' }}
                              Completed On: {{ task.completed_at | date: 'dd MMM yyyy' }}
                            </span>
                          </div>
                          <div class="task-details">
                            <p>
                              {{ task.details }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                            (nzOnConfirm)="deleteItem(task.task_id, 'task')" (nzOnCancel)="cancel()">
                            <span nz-icon nzType="delete"></span>
                          </button>

                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
                <ng-template #noCompletedTasks>
                  <li><span>No completed tasks available</span></li>
                </ng-template>
              </div>
            </nz-page-header-content>
          </nz-page-header>
        </div>
      </div>
    </div>
  </div>

  <nz-drawer [nzVisible]="drawerVisibleAddEvent" [nzPlacement]="'right'" [nzWidth]="500" [nzTitle]="'Add Event'"
    (nzOnClose)="closeAllDrawers()">
    <ng-container *nzDrawerContent>
      <form [formGroup]="eventForm" (ngSubmit)="addEvents()">
        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-control nzErrorTip="Enter Title">
                <nz-input-group>
                  <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="event_title"
                    placeholder="Event Title" style="width: 100%;"></quill-editor>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-control nzHasFeedback nzErrorTip="Please write event description here!">
                <nz-textarea-count [nzMaxCharacterCount]="1000">
                  <textarea nz-input placeholder="Event Description" formControlName="description"
                    id="description"></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-label>Valid From - Valid To</nz-form-label>
              <nz-form-control>
                <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                  formControlName="valid_date_range"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-label>Event Status?</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="active_status_id">
                  <label nz-radio [nzValue]="1">Active</label>
                  <label nz-radio [nzValue]="0">Inactive</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-radio-group formControlName="is_public">
                  <label nz-radio [nzValue]="1">Public</label>
                  <label nz-radio [nzValue]="0">Private</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-control>
                <button nz-button nzType="primary">Submit</button>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>


    </ng-container>

  </nz-drawer>
  <nz-drawer [nzVisible]="drawerVisibleEditEvent" [nzPlacement]="'right'" [nzWidth]="500" [nzTitle]="'Edit Event'"
    (nzOnClose)="closeAllDrawers()">
    <ng-container *nzDrawerContent>
      <form [formGroup]="editEventForm" (ngSubmit)="updateEvent()">
        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzRequired>Event Title</nz-form-label>
              <nz-form-control nzErrorTip="Enter Title">
                <nz-input-group>
                  <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="event_title"
                    placeholder="Enter post caption..." style="width: 100%;"></quill-editor>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzFor="description">Description</nz-form-label>
              <nz-form-control nzHasFeedback nzErrorTip="Please write event description here!">
                <nz-textarea-count [nzMaxCharacterCount]="1000">
                  <textarea nz-input formControlName="description" id="description"></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-label>Valid From - Valid To</nz-form-label>
              <nz-form-control>
                <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                  formControlName="valid_date_range"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-label>Event Status?</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="active_status_id">
                  <label nz-radio [nzValue]="1">Active</label>
                  <label nz-radio [nzValue]="0">Inactive</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <nz-form-item>
              <nz-form-control>
                <nz-radio-group formControlName="is_public">
                  <label nz-radio [nzValue]="1">Public</label>
                  <label nz-radio [nzValue]="0">Private</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <nz-form-item>
              <nz-form-control>
                <button nz-button nzType="primary">Submit</button>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>


    </ng-container>

  </nz-drawer>
  <nz-modal [(nzVisible)]="isModalVisible" nzTitle="Share this Post" (nzOnCancel)="handleCancel()">
    <div nz-modal-body>
      <ng-container *nzModalContent>
        <button nz-button nzType="text" (click)="share('facebook')">
          <i nz-icon nzType="facebook"></i> Facebook
        </button>
        <button nz-button nzType="text" (click)="share('twitter')">
          <i nz-icon nzType="twitter"></i> Twitter
        </button>
        <button nz-button nzType="text" (click)="share('linkedin')">
          <i nz-icon nzType="linkedin"></i> LinkedIn
        </button>
        <button nz-button nzType="text" (click)="share('Instagram')">
          <i nz-icon nzType="instagram"></i> Instagram
        </button>
      </ng-container>

    </div>
    <div nz-modal-footer>
      <button nz-button nzType="default" (click)="handleCancel()">Close</button>
    </div>
  </nz-modal>
</app-main-fiminize-layout>