import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common'; // Import DatePipe
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'; // Import the NzDropDownModule
import Quill from 'quill';

@Component({
  selector: 'app-dost-diary',
  templateUrl: './dost-diary.component.html',
  styleUrls: ['./dost-diary.component.scss'],
})
export class DostDiaryComponent implements OnInit {
  visible = false;
  tasks: any[] = [];
  posts: any;
  events: any[] = [];
  comments: any[] = [];
  commentVisibility: any;
  showAddTask: boolean = false;
  completedTasks: any[] = [];
  showCompletedTasks = false;
  newTaskTitle: string = '';
  size: NzButtonSize = 'large';
  formSubmitLoading: boolean = false;
  previewImageUrl: string | null = null;
  uploadedFileName: string = '';
  eventPhotoUrl: string | null = null;
  replyingToCommentId: number | null = null;
  selectedEventName: string = '';
  pipe = new DatePipe('en-US');
  isDialogVisible = false;
  commentLoading: { [key: number]: boolean } = {};
  drawerVisibleAddEvent = false;
  drawerVisibleAddPost = false;
  drawerVisibleEditPost = false;
  drawerVisibleEditEvent = false;
  storageUrl = environment.STORAGE_URL;
  fallbackImage: string = 'assets/fallback.webp';
  @Input() diaryPostId: number = 0;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe
  ) {}
  postForm: FormGroup = new FormGroup({
    caption: new FormControl('', [Validators.required]),
    is_featured_post: new FormControl(),
    is_public: new FormControl(),
    symbol_id: new FormControl(''),
    comment: new FormControl(),
    diary_post_id: new FormControl(),
  });
  ngOnInit(): void {
    this.fetchAllPosts();
    this.fetchAllSymbols();
  }
  commentVisible: { [key: number]: boolean } = {};
  toggleCommentSection(postId: number): void {
    this.commentVisible[postId] = !this.commentVisible[postId];
    if (this.commentVisible[postId]) {
      this.fetchComments(postId);
    }
  }

  isEditMode: { [key: number]: boolean } = {};
  editPostForm: FormGroup = new FormGroup({
    diary_post_id: new FormControl(''),
    polling_indicator_id: new FormControl(),
    symbol_id: new FormControl(''),
    caption: new FormControl('', [Validators.required]),
  });

  editEventForm: FormGroup = new FormGroup({
    event_id: new FormControl(null),
    event_title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    valid_date_range: new FormControl([]),
    valid_from: new FormControl(null),
    valid_till: new FormControl(null),
    is_public: new FormControl(null),
    active_status_id: new FormControl('', [Validators.required]),
  });

  eventForm: FormGroup = new FormGroup({
    event_id: new FormControl(null),
    event_title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    valid_date_range: new FormControl([]),
    valid_from: new FormControl(null),
    valid_till: new FormControl(null),
    active_status_id: new FormControl('', [Validators.required]),
    thumbnail: new FormControl(''),
    is_public: new FormControl(null),
  });

  taskForm = new FormGroup({
    user_id: new FormControl(''),
    task_id: new FormControl(''),
    title: new FormControl('', Validators.required),
    details: new FormControl('', Validators.required),
    task_date: new FormControl(''),
    is_completed: new FormControl(),
  });
diarypostId = sessionStorage.getItem('postId');
  fetchAllPosts(): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID is missing');
      return;
    }

    this.http
      .post(`${environment.API_URL}fetch_post`, { user_id: userId , diary_post_id: this.diarypostId})
      .subscribe({
        next: (response: any) => {

          if(response.success){
            sessionStorage.removeItem('postId');
          }
          this.posts = response.diary_post.map((post: any) => {
            // Extract polling indicators for each post
            post.polling_indicators = post.polling_indicators || [];
            return post;
          });
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred while loading posts');
        },
      });
  }

  fetchComments(diaryPostId: number) {
    this.http
      .post(`${environment.API_URL}fetch_diarypost_comment`, {
        diary_post_id: diaryPostId,
        comment_id: null,
      })
      .subscribe({
        next: (response: any) => {
          this.comments = response;
        },
        error: (error) => {
          console.error('Error fetching comments:', error);
        },
      });
  }
  loadMoreChildComments(diaryPostId: number, commentId: number) {
    const requestPayload = {
      diary_post_id: diaryPostId,
      comment_id: commentId,
    };
    this.http
      .post(`${environment.API_URL}fetch_diarypost_comment`, requestPayload)
      .subscribe({
        next: (response: any) => {
          this.comments = response;
        },
        error: (error) => {
          console.error('Error fetching comments:', error);
        },
      });
  }
  deleteComment(commentId: number) {
    this.http
      .post(`${environment.API_URL}delete_comment`, { comment_id: commentId })
      .subscribe({
        next: () => {
          this.message.success('Comment deleted successfully');
          this.comments = this.comments.filter(
            (comment) => comment.comment_id !== commentId
          );
        },
        error: (error) => {
          console.error('Error deleting comment:', error);
          this.message.error('Error occurred while deleting the comment');
        },
      });
  }
  submitCommentOrReply(
    diaryPostId: number | null,
    replyToCommentId: number | null
  ) {
    const commentData = {
      diary_post_id: diaryPostId,
      parent_comment_id: replyToCommentId,
      commented_by: localStorage.getItem('userId'),
      comment: this.postForm.value.comment,
    };

    this.http
      .post(`${environment.API_URL}add_diarypost_comment`, commentData)
      .subscribe({
        next: () => {
          this.message.success(
            replyToCommentId ? 'Reply Added' : 'Post Comment Added'
          );
          this.replyingToCommentId = null;
          this.fetchComments(diaryPostId!); // Use non-null assertion if diaryPostId is guaranteed to be not null
          this.postForm.reset();
        },
        error: (error) => {
          console.error('Error:', error);
          this.message.error('Error occurred, please try later');
        },
      });
  }

  prepareReply(commentId: number) {
    this.replyingToCommentId = commentId;
    this.postForm.patchValue({
      diary_post_id: null,
    });
  }

  cancel(): void {
    this.isDialogVisible = false;
  }

  toggleAddTask(): void {
    this.showAddTask = !this.showAddTask;
  }
  toggleTaskView(): void {
    this.showCompletedTasks = !this.showCompletedTasks;
    if (this.showCompletedTasks) {
    }
  }

  deletePost(id: number): void {
    const apiUrl = `${environment.API_URL}delete_post`;
    const requestData = {
      user_id: localStorage.getItem('userId'),
      diary_post_id: id,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (!response.success) {
          this.message.error('Failed to delete post');
          return;
        }
        this.message.success('Post deleted successfully');
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Error occurred, please try later');
      },
      complete: () => {
        this.fetchAllPosts();
      },
    });
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  //Post ADD Edit
  cancelEdit(postId: number) {
    this.isEditMode[postId] = false; // Disable edit mode for this post
    this.editPostForm.reset(); // Reset the form
  }
  fetchPostData(post: any) {
    this.isEditMode[post.diary_post_id] = true; // Enable edit mode for this post
    if (post) {
      this.editPostForm.patchValue({
        diary_post_id: post.diary_post_id,
        caption: post.caption,
        polling_indicator_id: post.polling_indicator_id,
      });
    }
  }
  fetchEventtData(event: any) {
    if (event) {
      this.editEventForm.patchValue({
        event_id: event.event_id,
        event_title: event.event_title,
        description: event.description,
        valid_date_range: [
          new Date(event.valid_from),
          new Date(event.valid_till),
        ],
        valid_from: event.valid_from,
        is_public: event.is_public,
        valid_till: event.valid_till,
        active_status_id: event.active_status_id,
      });

      this.drawerVisibleEditEvent = true; // Opens the drawer for editing
    }
  }
  submitPost(postId: number) {
    if (this.editPostForm.valid) {
      const apiUrl = `${environment.API_URL}edit_post`;
      const postObj = {
        updated_by: localStorage.getItem('userId'),
        caption: this.editPostForm.value.caption,
        diary_post_id: this.editPostForm.value.diary_post_id,
        is_public: this.editPostForm.value.is_public,
        symbol_id: this.editPostForm.value.symbol_id,
      };

      this.http.post(apiUrl, postObj).subscribe({
        next: () => {
          this.message.success('Post updated successfully');
          this.isEditMode[postId] = false; // Disable edit mode after saving
          this.fetchAllPosts(); // Fetch updated data
        },
        error: (error) => {
          this.message.error('Failed to update post');
          console.error(error);
        },
      });
    }
  }

  addPost(): void {
    this.formSubmitLoading = true;

    const apiUrl = `${environment.API_URL}add_diary_post`;
    const postObj = {
      user_id: localStorage.getItem('userId'),
      caption: this.postForm.value.caption,
      is_featured_post: this.postForm.value.is_featured_post,
      is_public: this.postForm.value.is_public,
      symbol_id: this.postForm.value.symbol_id,
    };

    this.http.post(apiUrl, postObj).subscribe({
      next: (response: any) => {
        this.posts.push(response);
        this.message.success('Post added successfully');
      },
      error: (error) => {
        this.message.error('Error occurred, please try later');
        console.error('Error:', error);
      },
      complete: () => {
        this.formSubmitLoading = false;
        this.fetchAllPosts();
        this.postForm.reset(); // Reset the add post form
      },
    });
  }

  change(value: boolean): void {
    console.log(value);
  }

  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        // ['blockquote'],
        // [{ 'header': 1 }, { 'header': 2 }],
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],
        // [{ 'indent': '-1' }, { 'indent': '+1' }],
        // [{ 'direction': 'rtl' }],
        ['clean'],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],

      clipboard: {
        matchVisual: false,
      },
    },
  };
  isModalVisible = false;
  openShareModal(): void {
    this.isModalVisible = true;
  }
  share(platform: string): void {
    this.message.success(`Sharing on ${platform}`);
  }
  handleCancel(): void {
    this.isModalVisible = false;
  }

  likeButton(post: any): void {
    const apiUrl = `${environment.API_URL}like_post`;
    const requestData = {
      post_id: post.diary_post_id,
      like_status: post.already_liked ? 0 : 1, // Toggle like status based on already_liked
      user_id: localStorage.getItem('userId'),
    };
    
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Like status updated successfully');
        // Refresh the posts or update UI as needed
        this.fetchAllPosts();
      },
      error: (error) => {
        console.error('Error updating like status:', error);
      },
      complete: () => {
        console.log('Like status update process completed');
      },
    });
  }
  
  onPollingIndicatorChange(selectedValue: number, post: any) {
    const apiUrl = `${environment.API_URL}add_post_poll_answer`;
    const requestPayload = {
      user_id: localStorage.getItem('userId'),
      post_id: post.diary_post_id,
      polling_indicator_id: selectedValue,
    };

    this.http.post(apiUrl, requestPayload).subscribe({
      next: (response: any) => {
        this.message.success('Polling  updated');
        // Update the post's like count or handle the response
        this.fetchAllPosts();
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {
        this.fetchAllPosts();
      },
    });
  }
  allSymbols: any[] = [];
  fetchAllSymbols(): Promise<void> {
    // console.log(id);
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  addToDostPortfolio(symbolId: any) {
    const newTicker = {
      user_id: localStorage.getItem('userId'),
      watch_list_id: null,
      symbol_id: symbolId,
      sentiment_id: null,
      date: new Date().toISOString().split('T')[0],
      risk_id: null,
      horizon_id: null,
      reminder: 0,
      trade_id: null,
      is_public: 0,
    };

    this.http
      .post(`${environment.API_URL}add_watch_list`, newTicker)
      .subscribe({
        next: (response) => {
          console.log('API Response:', response);
          this.message.success('Symbol added to watch list.');
          this.fetchAllPosts();
        },
        error: (error) => {
          console.error('Error handling ticker:', error);
          this.message.error('Failed to update the ticker. Please try again.');
        },
      });
  }
  addTobookmark(diarypostId: number): void {
    const postIndex = this.posts.findIndex(
      (post: { diary_post_id: number }) => post.diary_post_id === diarypostId
    );

    if (postIndex === -1) {
      console.error(`Post with ID ${diarypostId} not found.`);
      return;
    }

    const isCurrentlyBookmarked = this.posts[postIndex].is_bookmark;
    const apiUrl = isCurrentlyBookmarked
      ? `${environment.API_URL}delete_bookmark`
      : `${environment.API_URL}add_bookmark`;

    const requestBody = {
      user_id: localStorage.getItem('userId'),
      diary_post_id: diarypostId,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Toggle the is_bookmark status locally
          this.posts[postIndex].is_bookmark = !isCurrentlyBookmarked;
          this.message.success(
            `Bookmark ${
              isCurrentlyBookmarked ? 'removed' : 'added'
            } successfully.`
          );
          this.fetchAllPosts();
        } else {
          this.message.error('Failed to update bookmark status.');
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to update bookmark status.');
      },
    });
  }
}
