<button nz-button nzType="text" (click)="toggleTaskView()">
    {{ showCompletedTasks ? 'Back to Active Tasks' : 'Completed Tasks' }}
</button>
<button (click)="toggleAddTask()" nzType="dashed" nzBlock nz-button [nzSize]="size" nzShape="round">
    <i nz-icon nzType="plus"></i> Add a task
</button>
<div class="row mt-2" *ngIf="showAddTask && !showCompletedTasks">
    <form [formGroup]="taskForm">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="form-group">
                    <input nz-input formControlName="title" placeholder="Task Name" />
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="form-group">
                    <nz-input-group [nzSuffix]="suffixIconSearch">
                        <input nz-input formControlName="details" placeholder="Task Description" />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <span nz-icon nzType="align-left"></span>
                    </ng-template>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="form-group">
                    <nz-date-picker nzBlock formControlName="task_date" nzPlaceHolder="Select task date"
                        [nzFormat]="'yyyy-MM-dd'" [ngStyle]="{ width: '100%' }"></nz-date-picker>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <button nz-button nzType="primary" [disabled]="!taskForm.valid" nzBlock (click)="addTask()">
                        Add Task
                    </button>
                </div>
            </div>
        </div>
    </form>

</div>

<div class="card border-light" *ngFor="let task of tasks">
    <div class="card-body">
        <div class="task-container mt-2" *ngIf="!showCompletedTasks">
            <ul>
                <ng-container *ngIf="tasks && tasks.length > 0; else noTasks">
                    <li >
                        <div class="row">
                            <div class="col-1">
                                <label nz-radio (ngModelChange)="onCheckboxChange(task)" [ngModel]="task.is_completed"
                                    [nzValue]="true">
                                </label>
                            </div>
                            <div class="col-11" (click)="toggleTaskDetails(task)">
                                <div class="task-title">
                                    <p><b>{{ task.title }}</b></p>
                                </div>
                                <div *ngIf="task.task_date" [ngClass]="{ 'border-danger': isDatePassed(task.task_date) }">
                                    <nz-tag nzColor="#55acee">
                                        <span nz-icon nzType="calendar"></span>
                                        <span>{{ task.task_date | date: 'dd MMM yyyy' }}</span>
                                    </nz-tag>
                                </div>
                                <div class="task-details">
                                    {{ task.details }}
                                </div>
                            </div>
                            <nz-page-header-extra>
                                <nz-space>
                                    <button *nzSpaceItem nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu"
                                        nzPlacement="bottomRight" style="border: none; padding: 0">
                                        <span nz-icon nzType="more" nzTheme="outline"
                                            style="font-size: 20px; vertical-align: top;"></span>
                                    </button>
                                </nz-space>
                                <nz-dropdown-menu #menu="nzDropdownMenu">
                                    <ul nz-menu>
                                        <li nz-menu-item>
                                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                                (nzOnConfirm)="deleteItem(task.task_id)" (nzOnCancel)="cancel()">
                                                <span nz-icon nzType="delete"></span>
                                            </button>
                                        </li>
                                    </ul>
                                </nz-dropdown-menu>
                            </nz-page-header-extra>
                        </div>
                    </li>
                </ng-container>
                <ng-template #noTasks>
                    <li>
                        <span>No tasks available</span>
                    </li>
                </ng-template>
            </ul>
        </div>
        
        <div class="task-container mt-2" *ngIf="showCompletedTasks">
            <ul>
                <ng-container *ngIf="completedTasks.length > 0; else noCompletedTasks">
                    <li *ngFor="let task of completedTasks">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="task-title">
                                    <p><b>{{ task.title }}</b></p>
                                </div>
                                <div *ngIf="task.task_date">
                                    <span nz-typography nzType="secondary">
                                        Created On: {{ task.task_date | date: 'dd MMM yyyy' }}
                                        Completed On: {{ task.completed_at | date: 'dd MMM yyyy' }}
                                    </span>
                                </div>
                                <div class="task-details">
                                    <p>
                                        {{ task.details }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="deleteItem(task.task_id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>

                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
            <ng-template #noCompletedTasks>
                <li><span>No completed tasks available</span></li>
            </ng-template>
        </div>
    </div>

</div>