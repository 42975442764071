import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Clipboard } from '@angular/cdk/clipboard';
import Quill from 'quill';
import { environment } from 'src/environments/environment';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-crypto-coin-page',
  templateUrl: './crypto-coin-page.component.html',
  styleUrls: ['./crypto-coin-page.component.scss'],
})
export class CryptoCoinPageComponent implements OnInit {
  symbolName: string = '';
  categoryOrSymbolName: string = '';
  CryptoData: any = {};
  sepecialTermsData: any[] = [];
  request: string = '';
  dostAlert: any;
  alertType: any[] = [];
  isLoading: boolean = false;
  quill: Quill | undefined;
  requestTypes: any[] = [];
  primaryChain: any;
  otherChains: any[] = [];

  FAQquestions: any[] = [];
  copied = false; // Track if copied
  currentIndex = 0;
  articlesPerPage = 2;

  latestArticles: any[] = [];
  isVisible = false;
  specialTermsData: any[] = [];
  selectedOption: any;
  selectedQuestion: any;
  allStocks: any[] = [];
  isAddDostAlertModalVisible = false;
  dostDiaryList: any[] = [];
  Dostalerts: any[] = [];
  TickerVoting: any;
  isEllipsisVisible: boolean = false;
  authorCovering: any[] = [];
  isFollowed: boolean = false;
  defaultLimit: number = 5; // Default number of questions to show
  displayLimit: number = this.defaultLimit;
  showAllQuestions: boolean = false; // Toggle flag
  showProgress: boolean = false;
  symbolDescription: string = '';
  storageUrl = environment.STORAGE_URL;
  authors: string[] = [];
  similarTags: string[] = [];
  relatedStocks: string[] = [];
  userId = localStorage.getItem('userId');
  roleId = localStorage.getItem('role_id') ?? '';
  similarCryptos: any[] = [];
  dostAlertVisible = false;
  addRequestVisible = false;
  dostExplains: any[] = [];
  symbolID: any;
  stockData: any;
  cardWidth: number = 0;
  visibleCardsCount: number = 0;
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private activateROute: ActivatedRoute,
    private modal: NzModalService,
    private router:Router,
    private clipboard: Clipboard

  ) {}
  ngOnInit(): void {
    this.activateROute.paramMap.subscribe((params) => {
      this.symbolName = params.get('symbol') ?? ''; // Ensure matching parameter name
      // Check if symbolName is being retrieved correctly

      this.fetchArticles(this.symbolName)?.then(() => {
        if (this.CryptoData.symbol_id) {
          this.symbolID = this.CryptoData.symbol_id;
          console.log('symbol_id', this.CryptoData.symbol_id);
          this.fetchLatestArticles(1);
          this.fetchSpecialTerms(2);
          this.fetchDostAlerts(1);
          this.fetchAuthors();
          this.fetchSimilarStocks();
          this.FetchFaq();
          this.fetchTickerVoting();
          this.fetchQuizSymbol();
          this.fetchRelaventTags();
          this.fetchRequestType();
          this.fetchRelaventTags();
          this.fetchDostDiaryPost(0);
          this.fetchDostexplains();
        }
      });
    });
  }
  tabs = [
    { id: 2, name: 'DostFact' },
    { id: 6, name: 'DostSecret' },
    { id: 4, name: 'DostTips' },
    { id: 3, name: 'DostInsights' },
    { id: 7, name: 'DostSolution' },
  ];
  dostAlertForm: FormGroup = new FormGroup({
    alert_type_id: new FormControl('', Validators.required),
    symbol_id: new FormControl(this.CryptoData.symbol_id),
    text: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    is_diarypost: new FormControl('', Validators.required),
    active_status_id: new FormControl('', Validators.required),
  });

  fetchSimilarStocks() {
    const apiUrl = `${environment.API_URL}fetch_similar_symbols`;
    const requestBody = { symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.similarCryptos = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  fetchDostexplains() {
    const apiUrl = `${environment.API_URL}fetch_dost_explains`;
    const requestBody = { symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.dostExplains = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  copiedIndex: number | string | null = null;
  copyToClipboard(address: string | null, index: number | string): void {
    if (address) {
      this.clipboard.copy(address);
      this.message.success('Address copied to clipboard.');
      this.copiedIndex = index;
      setTimeout(() => {
        this.copiedIndex = null;
      }, 3000);
    }
  }

  openQuestionModal(question: any): void {
    console.log(question);
    this.isVisible = true;
    this.selectedOption = question.symbol_quiz_answer;
    this.selectedQuestion = question;
  }
  reportDostalert(dostAlertID: number) {
    console.log(dostAlertID);

    const apiUrl = `${environment.API_URL}report_dost_alert`;
    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          if (this.dostAlert.is_bookmark) {
            this.message.success('Report');
            this.fetchDostAlerts(1);
          } else {
            this.message.success('Report');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to Report.');
      },
    });
  }
  handleOk(): void {
    this.isVisible = false;
  }
  openAddDostAlertModal(): void {
    this.isAddDostAlertModalVisible = true;
    this.dostAlertForm.reset;
    this.fetchSymbolS();
    this.fetchDostAlertstype();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isAddDostAlertModalVisible = false;
    this.dostAlertVisible = false;
    this.addRequestVisible = false;
  }
  votingDisabled: boolean = false;

  isSubmitted = false;
  onOptionSelect(optionId: number) {
    const apiUrl = `${environment.API_URL}save_symbol_answer`;
    const requestBody = {
      symbol_id: this.symbolID,
      user_id: this.userId,
      quiz_question_id: this.selectedQuestion?.id,
      quiz_option_id: optionId,
      quiz_id: this.CryptoData.quiz_id,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.message.success('Answer saved successfully');
        this.fetchQuizSymbol();
        this.isSubmitted = true;
        this.handleCancel();
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  toggleQuestions(): void {
    if (this.showAllQuestions) {
      // If currently showing all, collapse back to the default limit
      this.displayLimit = this.defaultLimit;
    } else {
      // If currently showing limited, expand to show all
      this.displayLimit = this.quizSymbols.length;
    }
    this.showAllQuestions = !this.showAllQuestions; // Toggle the flag
  }
  fetchSymbolS(): Promise<void> {
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }

  fetchAuthors() {
    const apiUrl = `${environment.API_URL}fetch_author_covering_crypto`;
    const requestBody = { symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.authorCovering = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  fetchDostAlerts(alert_type_id: number) {
    const apiUrl = `${environment.API_URL}fetch_dost_alert`;
    const requestBody = {
      user_id: this.userId,
      symbol_id: this.symbolID,
      alert_type_id,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.Dostalerts = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch alerts.');
      },
    });
  }
  addTobookmark(dostAlertID: number) {
    console.log(dostAlertID);
    const isCurrentlyBookmarked = this.dostAlert.is_bookmark;
    const apiUrl = isCurrentlyBookmarked? `${environment.API_URL}delete_dost_alert_bookmark` : `${environment.API_URL}add_bookmark`;
    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID
    };
  
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Toggle bookmark status
          this.dostAlert.is_bookmark = !isCurrentlyBookmarked;
  
          // Show appropriate message based on bookmark status
          if (this.dostAlert.is_bookmark) {
            this.message.success('Bookmark added successfully.');
          } else {
            this.message.success('Bookmark removed successfully.');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to update bookmark status.');
      }
    });
  }
  fetchDostAlertstype(): Promise<void> {
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_dost_alert_type`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.alertType = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  selectedRequestTypeIds: number[] = [];
  onCheckboxChange(event: Event, requestTypeId: number) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedRequestTypeIds.push(requestTypeId);
    } else {
      this.selectedRequestTypeIds = this.selectedRequestTypeIds.filter(
        (id) => id !== requestTypeId
      );
    }
  }
  addRequest() {
    const apiUrl = `${environment.API_URL}add_ticker_request`;
    const requestBody = {
      symbol_id: this.symbolID,
      user_id: this.userId,
      request_type_id: this.selectedRequestTypeIds,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.message.success(response.message);
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to send the request.');
      },
      complete: () => {
        this.addRequestVisible = false;
        this.request = '';
      },
    });
  }
  fetchRequestType(): Promise<void> {
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_request_type
  `;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.requestTypes = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  addToDostPortfolio() {

    const action = this.CryptoData.already_added ? 'remove' : 'add'; // Action based on current state

    const newTicker = {
      user_id: this.userId,
      watch_list_id: null,
      symbol_id: this.CryptoData.symbol_id,
      sentiment_id: null,
      date: new Date().toISOString().split('T')[0],
      risk_id: null,
      horizon_id: null,
      trade_id: null,
      is_public:0,
      action: action, // Include the action (add/remove) in the payload
    };

    this.http
      .post(`${environment.API_URL}add_watch_list`, newTicker)
      .subscribe({
        next: (response) => {
          console.log('API Response:', response);
          if (action === 'add') {
            this.CryptoData.already_added = true; // Update state to indicate the stock is added
            this.message.success('Added to DostPortfolio!');
          } else {
            this.CryptoData.already_added = false; // Update state to indicate the stock is removed
            this.message.success('Removed from DostPortfolio!');
          }
          this.fetchArticles(this.symbolName); // Refresh the articles
        },
        error: (error) => {
          console.error('Error handling ticker:', error);
          this.message.error('Failed to update the ticker. Please try again.');
        },
      });
  }

  fetchTickerVoting() {
    const apiUrl = `${environment.API_URL}fetch_ticker_voting`;
    const requestBody = { user_id: this.userId, symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        // Set TickerVoting with already_voted and mapped voting details
        this.TickerVoting = {
          already_voted: response.already_voted,
          voting: [
            {
              indicator_id: 1,
              percentage:
                response.voting.find((vote: any) => vote.indicator_id === 1)
                  ?.percentage || 0,
              strokeColor: '#4caf50', // Green for Bull
            },
            {
              indicator_id: 2,
              percentage:
                response.voting.find((vote: any) => vote.indicator_id === 2)
                  ?.percentage || 0,
              strokeColor: 'red', // Red for Bear
            },
          ],
        };
      },
      error: () => {
        this.message.error('Failed to fetch voting data.');
      },
    });
  }

  submitTickerVoting(indicator_id: number) {
    const apiUrl = `${environment.API_URL}add_ticker_voting`;
    const requestBody = {
      user_id: this.userId,
      symbol_id: this.symbolID,
      indicator_id: indicator_id,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success(response.message);

          // Update TickerVoting based on the submitted vote
          this.TickerVoting.voting = this.TickerVoting.voting.map(
            (vote: { indicator_id: number }) => ({
              ...vote,
              percentage: vote.indicator_id === indicator_id ? 100 : 0,
            })
          );

          // Fetch updated voting data after submitting
          this.fetchTickerVoting();
        } else {
          this.message.error(response.message);
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error(err.message);
      },
    });
  }

  fetchArticles(ID: string): Promise<void> | null {
    const apiUrl = `${environment.API_URL}fetch_articles_by_symbol`;
    const requestBody: any = {
      auth_user_id: this.userId,
      symbol: ID,
    };
    this.isLoading = true;
    return new Promise((resolve) => {
      this.http.post(apiUrl, requestBody).subscribe({
        next: (res: any) => {
          this.CryptoData = res;
          const chains = this.CryptoData.chains;
          this.primaryChain = chains.find(
            (chain: { is_primary: number }) => chain.is_primary === 1
          );
          this.otherChains = chains.filter(
            (chain: { is_primary: number }) => chain.is_primary === 0
          );
          console.log('oterChains   ', this.otherChains);

          resolve();
        },
        error: (err: any) => {
          console.log(err);
          resolve();
        },
        complete: () => {
          this.isLoading = false; // Set loading state to false on completion
        },
      });
    });
  }


  fetchSpecialTerms(sectionTypeID: any): void {
    console.log('Selected Tab ID:', sectionTypeID); // Logs the selected tab ID

    const apiUrl = `${environment.API_URL}fetch_special_terms`;
    const requestBody = {
      symbol_id: this.symbolID,
      section_type_id: sectionTypeID, // Now using the directly passed ID
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.specialTermsData = res ?? []; // Populate specialTermsData with response or empty array
      },
      error: (err) => {
        console.error('Error fetching special terms:', err);
      },
    });
  }
  onTabChange(index: number): void {
    this.fetchLatestArticles(index);
  }

  fetchLatestArticles(index: number) {
    const articleType = index === 0 ? 'latest' : 'Top';
    let apiUrl = `${environment.API_URL}fetch_latest_articles`;
    let requestBody: any = {
      symbol_id: this.symbolID,
      article_type_id: index === 0 ? 1 : 2,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (res: any) => {
        this.latestArticles = res;
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  getCurrentArticles() {
    return this.latestArticles.slice(
      this.currentIndex,
      this.currentIndex + this.articlesPerPage
    );
  }
  prevArticle() {
    if (this.currentIndex > 0) {
      this.currentIndex -= this.articlesPerPage;
    }
  }
  nextArticle() {
    if (this.currentIndex + this.articlesPerPage < this.latestArticles.length) {
      this.currentIndex += this.articlesPerPage;
    }
  }
  FetchFaq() {
    const apiUrl = `${environment.API_URL}fetch_FAQ`;
    const requestBody = { symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.FAQquestions = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  handleAdd(): void {
    if (this.dostAlertForm.valid) {
      // Add the user_id to the form data
      const formData = {
        ...this.dostAlertForm.value,
        user_id: this.userId,
      };
      const apiUrl = `${environment.API_URL}add_dost_alert`;
      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          console.log('Dost Alert added successfully', response);
          this.isAddDostAlertModalVisible = false;
          this.fetchDostAlerts(1);
        },
        error: (error) => {
          console.error('Error adding dost alert', error);
        },
      complete: () => {
        this.dostAlertForm.reset(); 
      },

      });
    } else {
      this.dostAlertForm.markAllAsTouched();
    }
  }
  quizSymbols: any[] = [];
  fetchQuizSymbol() {
    const apiUrl = `${environment.API_URL}fetch_quiz_of_symbol`;
    const requestBody = { symbol_id: this.symbolID, user_id: this.userId };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.quizSymbols = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  relevantTags: any[] = [];
  fetchRelaventTags() {
    const apiUrl = `${environment.API_URL}fetch_relevant_tags`;
    const requestBody = { symbol_id: this.symbolID };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.relevantTags = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  displayedPosts: any[] = [];
  selectedTab: 'latest' | 'trending' = 'latest';
  getPosts(type: string) {
    return this.dostDiaryList.filter((post) => {
        if (type === 'trending') {
            return post.is_featured_post !== 1; // General posts
        } else if (type === 'featured') {
            return post.is_featured_post === 1; // Featured posts
        }
        return false; // Default case
    });
}

fetchDostDiaryPost(isFeaturedPost: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_post_of_symbol`;
    return new Promise((resolve, reject) => {
        this.http.post(apiUrl, {
            user_id: this.userId,
            symbol_id: this.symbolID,
            is_featured_post: isFeaturedPost,
        }).subscribe({
            next: (response: any) => {
                this.dostDiaryList = response.diary_post || [];
            },
            error: (error) => {
                console.error('Error: ', error);
                return reject();
            },
            complete: () => {
                return resolve();
            },
        });
    });
}

  toggleEllipsisVisibility(): void {
    this.isEllipsisVisible = !this.isEllipsisVisible;
  }

  openDostAlertModal(alert: any) {
    console.log(alert);

    this.dostAlertVisible = true;
    this.dostAlert = alert;
  }

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],

      clipboard: {
        matchVisual: false,
      },
    },
  };

  viewPost(postId: any): void {
    
    sessionStorage.setItem('postId', postId.toString()); 
    console.log(postId);
    
    this.router.navigateByUrl('/dostDiary');
  }
  

}
