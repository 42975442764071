import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Question } from 'src/app/Interfaces/Quiz';
import { FormDataService } from 'src/app/Services/FormData/form-data.service';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { formatBytes } from 'src/app/Functions/formatBytes';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { NzImageService } from 'ng-zorro-antd/image';

interface Symbol {
  symbol_id: number; // Assuming this is the ID for the symbol
  symbol: string;    // The name or label for the symbol
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent {
  questions: Question[] = [];
  filteredQuestion: Question[] = [];
  searchQuery: string = '';
  useAdminLayout: boolean = false; // Default to non-admin layout
  roleId: any;
  questionTypes: any = [];
  categories: any = [];
  subCategories: any = [];
  quizzes: any = [];

  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;
  editDrawerLoading: boolean = false;

  ADD_FORM: number = 1;
  EDIT_FORM: number = 0;
  imagePreview: string | null = null;
  // Evaluation Types Constants
  AUTO: string = 'Auto';
  MANUAL: string = 'Manual';

  // Below 3 Variables are used for Question types having only 1 correct option
  onlyOneCorrectOptionRequired: boolean = true;
  allUncheckedCheckBoxDisabled: boolean = false;
  checkedCheckBoxId?: number;

  unCommonQuesIds = [4, 7, 8]; // Long Answers, Audio/Video Recording, Evaluation Type will be 'MANUAL'

  evaluationTypeManualAndDisabled: boolean = false; // Used to fix(disabled) and set the evaluation_type field as Manual
  optionsOrAnswersNeeded: boolean = false; // Used to Show or Hide Options/Answers Details Section

  // Constants used to Show/Hide appropriate Option type(TEXT/IMAGE/SHORT Answer) for particular question type
  TEXT_OPTIONS: number = 0;
  IMAGE_OPTIONS: number = 1;
  SHORT_TEXT_ANSWER: number = 2;
  allcategories: any = [];
  // Below Variable will take value from above 3 Variables
  selectedOptionOrAnswerType?: number;

  addDrawer: boolean = false;
  editDrawer: boolean = false;
  fallbackImage: string = 'assets/fallback.webp'; // Update to your actual path
  QuestionImagePath: string = '';
  featuredImagePresent: boolean = true;
  storageUrl = environment.STORAGE_URL;
  selectedFile: File | null = null;
  showImage: boolean = false;
  imageSrc: string = '';
  treeSelectExpandKeys: string[] = [];
  ////

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  // File Upload

  sortByQuestion = (a: any, b: any) => a.question.localeCompare(b.question);
  fetchDropDowns(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToCategoryTree(response.data);
        console.log('treedata', treeData);
        this.allcategories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }

  questionTypesPopoverInfo: any[] = [
    {
      id: 1,
      heading: 'Choose Single Option (Text Based)',
      description:
        'For this question type, quiz takers must select a single correct answer from a list of options.',
    },
    {
      id: 2,
      heading: 'Choose Multiple Option (Text Based)',
      description:
        'With this question type, quiz takers can choose multiple correct answers from a list of options.',
    },
    {
      id: 3,
      heading: 'Short Answer',
      description:
        'Use this question type when you want quiz takers to provide concise Small Text/Name/Number responses. Perfect for brief and to-the-point answers.',
    },
    {
      id: 4,
      heading: 'Long Answer (Manual Evaluation)',
      description:
        'Use this for detailed responses in quizzes. Manual Evaluation Required, No Auto Evaluation.',
    },
    {
      id: 5,
      heading: 'Choose Single Option (Image Based)',
      description:
        'Image-Based options, quiz takers must select a single correct answer from a list of options.',
    },
    {
      id: 6,
      heading: 'Choose Multiple Option (Image Based)',
      description:
        'Image-Based options, quiz takers can select multiple correct answer from a list of options.',
    },
    {
      id: 7,
      heading: 'Record Audio (Manual Evaluation)',
      description:
        'For this question type, quiz takers need to provide answers in audio format. Manual Evaluation Required, No Auto Evaluation.',
    },
    {
      id: 8,
      heading: 'Record Video (Manual Evaluation)',
      description:
        'With this question type, quiz takers must provide answers in video format. Manual Evaluation Required, No Auto Evaluation.',
    },
  ];

  addQuestionForm: FormGroup = new FormGroup({
    // Define your form control with validation
    question_text: new FormControl('', [
      Validators.required,
      Validators.maxLength(2000),
    ]),
    question_type: new FormControl('', [Validators.required]),
    points: new FormControl(2, [Validators.required]),
    is_active: new FormControl(1),
    question_image: new FormControl(),
    category_id: new FormControl([]),
    symbol_category_ids: new FormControl([]),
    category: new FormControl(''),
    sub_category: new FormControl(''),
    symbol_id: new FormControl([]),
    evaluation_type: new FormControl(this.AUTO),
    options: new FormArray([]),
    short_text_answer: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(100),
    ]),
    created_by: new FormControl(sessionStorage.getItem('userId')),
  });

  editQuestionForm: FormGroup = new FormGroup({
    // Define your form control with validation
    id: new FormControl(null),
    question_text: new FormControl(null, [
      Validators.required,
      Validators.maxLength(2000),
    ]),
    question_type: new FormControl(null, [Validators.required]),
    points: new FormControl(null, [Validators.required]),
    is_active: new FormControl(null),
    question_image: new FormControl(),
    category_id: new FormControl(),
    symbol_id: new FormControl([]),
    symbol_category_ids: new FormControl([]),

    evaluation_type: new FormControl(null),
    options: new FormArray([]),
    short_text_answer: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(100),
    ]),
    updated_by: new FormControl(sessionStorage.getItem('userId')),
  });

  get addFormOptionsArray() {
    return this.addQuestionForm.get('options') as FormArray;
  }

  get editFormOptionsArray() {
    return this.editQuestionForm.get('options') as FormArray;
  }

  get addFormQuestionTypeEmpty() {
    let value = this.addQuestionForm.get('question_type')?.value;
    return value === '' ? true : value ? false : true;
  }

  get editFormQuestionTypeEmpty() {
    let value = this.editQuestionForm.get('question_type')?.value;
    return value === '' ? true : value ? false : true;
  }

  optionId(index: number) {
    return this.editFormOptionsArray.at(index).get('id')?.value;
  }

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService,
    private apiService: FetchApiService,
    private formDataService: FormDataService,
    private nzImageService: NzImageService
  ) {}

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
    this.fetchDropDowns();
    this.fetchAllSymbols(1);

    this.fetchApiService.getAllQuizzes().subscribe({
      next: (res: any) => {
        this.quizzes = res.data.filter((x: any) => x.is_active === 1);
      },
    });
  }

  // Fetch Functions =======================================================================

  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const getCategories$ = this.fetchApiService.getQuizCategories();
      const getSubCategories$ = this.fetchApiService.getQuizSubCategories();
      const getQuestionTypes$ = this.fetchApiService.getQuestionTypes();
      const getAllQuestions$ = this.fetchApiService.getAllQuestions();

      forkJoin([
        getCategories$,
        getSubCategories$,
        getQuestionTypes$,
        getAllQuestions$,
      ]).subscribe({
        next: ([
          categoriesResponse,
          subCategoriesResponse,
          getQuestionTypesResponse,
          getAllQuestionsResponse,
        ]: any) => {
          this.categories = categoriesResponse.data;
          this.subCategories = subCategoriesResponse.data;
          this.questionTypes = getQuestionTypesResponse.data;
          this.questions = getAllQuestionsResponse.data;
          this.filteredQuestion = this.questions;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }
  cryptos: Symbol[] = [];
  stocks: Symbol[] = [];
  fetchQuestions(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      this.fetchApiService.getAllQuestions().subscribe({
        next: (res: any) => {
          // console.log('Questions: ', res);
          this.questions = res.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }

  selectedCategories: number[] = [];

  allSymbols: Symbol[] = [];
  fetchAllSymbols(categoryID: number, event?: boolean): Promise<void> {
    this.tableLoading = true;

    // Update selectedCategories based on checkbox status
    if (event) {
      if (!this.selectedCategories.includes(categoryID)) {
        this.selectedCategories.push(categoryID);
      }
    } else {
      this.selectedCategories = this.selectedCategories.filter(id => id !== categoryID);
    }

    const requestData = { category_id: this.selectedCategories }; // Pass the selected categories
    const apiUrl = `${environment.API_URL}symbol_dropdown_for_question`;
    
    return new Promise((resolve, reject) => {
      this.http.post<Symbol[]>(apiUrl, requestData).subscribe({
        next: (response: Symbol[]) => {
          this.allSymbols = response; // Populate the dropdown with the new data
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchQuestionDetails(queId: string | number): Promise<void> {
    return new Promise((resolve, reject) => {
        this.fetchApiService.getQuestionDetails(queId).subscribe({
            next: (res: any) => {
                console.log('Question Details API Data: ', res);

                const questionData = res.data;

                // Assuming question_image is a relative path. Prepend storageUrl.
                this.imagePreview = questionData.question_image ? questionData.question_image : null;

                // Check if imagePreview should include the storage URL
                if (this.imagePreview) {
                    this.imagePreview = this.storageUrl + this.imagePreview; // Construct full URL
                } else {
                    this.imagePreview = null; // Clear the preview if no image is present
                }
                const symbolIds = questionData.symbol_ids || [];
                // Patch form with question data
                this.editQuestionForm.patchValue({
                    category_id: questionData.category_id,
                    id: questionData.id,
                    question_text: questionData.question_text,
                    question_type: questionData.question_type,
                    points: questionData.points,
                    is_active: questionData.is_active,
                    symbol_id: questionData.symbol_id,
                    short_text_answer: questionData.short_text_answer,
                    evaluation_type: questionData.evaluation_type,
                    symbol_category_ids:questionData.symbol_category_ids || [],
                    question_image: questionData.question_image, // Already patched
                });

                // ... (rest of your logic)
            },
            error: (err) => {
                console.log(err);
                this.editDrawerLoading = false;
                return reject(err);
            },
            complete: () => {
                this.editDrawerLoading = false;
                return resolve();
            },
        });
    });
}

  // =======================================================================
  EvaluationTypeChanged(type: any, formType: number) {
    var SelectedForm: FormGroup =
      formType === this.ADD_FORM ? this.addQuestionForm : this.editQuestionForm;

    if (
      SelectedForm.controls['question_type'].value === 3 &&
      type === this.MANUAL
    ) {
      SelectedForm.controls['short_text_answer'].disable();
    } else if (
      SelectedForm.controls['question_type'].value === 3 &&
      type === this.AUTO
    ) {
      SelectedForm.controls['short_text_answer'].enable();
    }
  }

  // =======================================================================

  onQuestionTypeChange(selectedId: number, formType: number) {
    var SelectedForm: FormGroup =
      formType === this.ADD_FORM ? this.addQuestionForm : this.editQuestionForm;
    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;

    // selectedId = null Or undefined
    if (!selectedId) {
      SelectedFormOptionsArray.clear();
      this.optionsOrAnswersNeeded = false;
      return;
    }

    // .... Here we Don't need Options Or Answers for Question ......................
    if (this.unCommonQuesIds.includes(selectedId)) {
      this.evaluationTypeManualAndDisabled = true;
      this.optionsOrAnswersNeeded = false;

      SelectedForm.patchValue({ evaluation_type: this.MANUAL });
      SelectedForm.controls['evaluation_type'].disable();
      SelectedForm.controls['short_text_answer'].disable();
    }
    // .... Here we Need Options Or Answers for Question  ......................
    else {
      this.evaluationTypeManualAndDisabled = false;
      this.optionsOrAnswersNeeded = true;

      this.allUncheckedCheckBoxDisabled = false;
      this.checkedCheckBoxId = undefined;

      if (formType === this.ADD_FORM) {
        SelectedForm.patchValue({ evaluation_type: this.AUTO });
      }
      SelectedForm.controls['evaluation_type'].enable();
      SelectedForm.controls['short_text_answer'].disable();

      // Making Options Array Empty, before adding any new option type FormControl
      SelectedFormOptionsArray.clear();

      if ([1, 2].includes(selectedId)) {
        this.selectedOptionOrAnswerType = this.TEXT_OPTIONS;
        this.addTextOption(this.ADD_FORM);
        this.onlyOneCorrectOptionRequired = selectedId === 2 ? false : true;
      } else if ([5, 6].includes(selectedId)) {
        this.selectedOptionOrAnswerType = this.IMAGE_OPTIONS;
        this.addImageOption(this.ADD_FORM);
        this.onlyOneCorrectOptionRequired = selectedId === 6 ? false : true;
      } else {
        // selectId = 3
        this.selectedOptionOrAnswerType = this.SHORT_TEXT_ANSWER;
        SelectedForm.controls['short_text_answer'].enable();

        if (SelectedForm.controls['evaluation_type'].value === this.MANUAL) {
          SelectedForm.controls['short_text_answer'].disable();
        }
      }
    }
  }

  onCheckboxChange(state: boolean, index: number) {
    if (this.onlyOneCorrectOptionRequired) {
      // Here Only one Check Boxes can be checked,
      // Means question can have only One correct Answer
      if (state) {
        this.allUncheckedCheckBoxDisabled = true;
        this.checkedCheckBoxId = index;
      } else {
        this.allUncheckedCheckBoxDisabled = false;
        this.checkedCheckBoxId = undefined;
      }
    } else {
      // Here Multiple Check Boxes can be checked,
      // Means question can have multiple correct Answers
      this.allUncheckedCheckBoxDisabled = false;
      this.checkedCheckBoxId = undefined;
    }
  }

  // =======================================================================
  addTextOption(formType: number) {
    const optionGroup = new FormGroup({
      text: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      is_correct_option: new FormControl(false),
    });

    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;
    SelectedFormOptionsArray.push(optionGroup);
  }

  addImageOption(formType: number): void {
    const optionGroup = new FormGroup({
      text: new FormControl('', [Validators.maxLength(500)]),
      image: new FormControl(null), // Add validators as needed
      is_correct_option: new FormControl(false),
      imageSrc: new FormControl(''),
    });

    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;
    SelectedFormOptionsArray.push(optionGroup);
  }

  // FIle Upload
  onFileSelected(event: any, index: number, formType: number): void {
    const file = event.target.files[0];

    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;

    if (file) {
      var control = SelectedFormOptionsArray.at(index).get(
        'image'
      ) as FormControl;
      control.setValue(file);
      this.viewImage(index, formType);
    }
  }

  viewImage(index: number, formType: number): void {
    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;
    var control = SelectedFormOptionsArray.at(index).get(
      'image'
    ) as FormControl;
    const file = control.value as File;

    if (file) {
      // Assuming you want to display the image as a base64 data URL
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Update the imageSrc property for display
        SelectedFormOptionsArray.at(index)
          .get('imageSrc')
          ?.setValue(reader.result as string);
      };
    }
  }

  // =======================================================================
  openEditDrawer(questionId: number): void {
    this.editDrawer = true;
    this.editDrawerLoading = true;
    
    this.fetchQuestionDetails(questionId);
  }

  closeEditDrawer(): void {
    this.optionsOrAnswersNeeded = false;
    this.editFormOptionsArray.clear();
    this.editDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.optionsOrAnswersNeeded = false;
    this.addFormOptionsArray.clear();
    this.addDrawer = false;
    this.addQuestionForm.reset();
    this.addQuestionForm.patchValue({
      question_text: '',
      question_type: '',
      points: 2,
      is_active: 1,
      category: '',
      sub_category: '',
      evaluation_type: this.AUTO,
      short_text_answer: '',
      created_by: sessionStorage.getItem('userId'),
    });
  }

  // =======================================================================
  cancel(): void {}

  confirm(id: number): void {
    this.deleteQuestion(id);
  }

  // =======================================================================
  submitAddForm() {
    if (this.addQuestionForm.valid) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}add_quiz_question`;

      const {
        options,
        category_id,
        symbol_id,
        question_image,
        ...allOtherFields
      } = this.addQuestionForm.value;

      if (
        allOtherFields.evaluation_type === this.AUTO &&
        [1, 2, 5, 6].includes(allOtherFields.question_type)
      ) {
        const allOptionsWrong = options.every(
          (option: any) => option.is_correct_option === false
        );
        if (allOptionsWrong) {
          const errorMsg = [1, 5].includes(allOtherFields.question_type)
            ? 'Please select One Option as Correct Option.'
            : 'Please select At-Least One Option as Correct Option.';
          this.message.error(errorMsg);
          this.formSubmitLoading = false;
          return;
        }
      }

      const formData = new FormData();
      this.formDataService.appendObjectValuesToFormData(
        formData,
        allOtherFields
      );

      // Append `category_id` and `symbol_id` arrays
      category_id.forEach((id: string, index: number) => {
        formData.append(`category_id[${index}]`, id);
      });
      symbol_id.forEach((id: string, index: number) => {
        formData.append(`symbol_id[${index}]`, id);
      });

      // Append options
      options.forEach((option: any, index: number) => {
        const quizOptionData = {
          text: option.text,
          is_correct_option:
            allOtherFields.evaluation_type === this.MANUAL
              ? false
              : option.is_correct_option,
        };
        formData.append(
          `optionDetails[${index}]`,
          JSON.stringify(quizOptionData)
        );
        if ([5, 6].includes(allOtherFields.question_type)) {
          formData.append(`file[${index}]`, option.image);
        }
      });

      // Append question image if available
      if (question_image) {
        formData.append('question_image', question_image);
      }

      // Submit form data
      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Question added');
              this.closeAddDrawer();
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
          this.formSubmitLoading = false;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
          this.formSubmitLoading = false;
        },
      });
    }
  }
  submitEditForm() {
    if (isFormValid(this.editQuestionForm)) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}edit_quiz_question`;

      // We want to remove options key from the this.addQuestionForm.value
      const { options, question_image, ...allOtherFields } =
        this.editQuestionForm.value;

      const formData = new FormData();

      //  Adding Other Fields of the Question to formData
      this.formDataService.appendObjectValuesToFormData(
        formData,
        allOtherFields
      );

      formData.append('question_id', allOtherFields.id);
      if (question_image) {
        formData.append('question_image', question_image); // Append the image
      }
      // Below IF/Else block is used to append Options for Image & Text Based Options
      options.forEach((option: any, index: number) => {
        // console.log(option);
        const quizOptionData = {
          id: option?.id ?? null,
          text: option.text,
          is_correct_option:
            allOtherFields.evaluation_type === this.MANUAL
              ? false
              : option.is_correct_option,
        };

        formData.append(
          `optionDetails[${index}]`,
          JSON.stringify(quizOptionData)
        );

        // Image options will have 'image' field as well
        if ([5, 6].includes(allOtherFields.question_type)) {
          formData.append(`file[${index}]`, option.image);
        }
      });

      formData.forEach((value, key) => console.log(key, value));

      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchQuestions();
            this.fetchQuestionDetails(allOtherFields.id).then(() => {
              this.message.success('Question updated');
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
          this.formSubmitLoading = false;
          this.fetchInitialData()
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
          this.formSubmitLoading = false;
        },
      });
    }
  }
  onQuestionImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
        // Generate preview
        const reader = new FileReader();
        reader.onload = () => {
            this.imagePreview = reader.result as string; // Cast to string
        };
        reader.readAsDataURL(file);

        // Update both form controls with the selected file
        this.addQuestionForm.patchValue({
            question_image: file,
        });
        this.editQuestionForm.patchValue({
            question_image: file,
        });

        // Ensure the controls are valid
        this.addQuestionForm.get('question_image')?.updateValueAndValidity();
        this.editQuestionForm.get('question_image')?.updateValueAndValidity();
    } else {
        // Clear image preview if no file is selected
        this.imagePreview = null; 
    }
    
    // Update the image preview from the edit form if needed
    const currentImage = this.editQuestionForm.get('question_image')?.value;
    if (currentImage) {
        this.imagePreview = currentImage; // Update preview with current image if available
    }
}

  
  removeImage(): void {
    this.imagePreview = null;
    this.addQuestionForm.patchValue({ question_image: null }); // Clear form control
    this.editQuestionForm.patchValue({ question_image: null }); // Clear form control

  }

  searchQuestions(): void {
    const trimmedQuery = this.searchQuery.trim().toLowerCase();

    if (trimmedQuery === '') {
      this.filteredQuestion = this.questions; // Reset to all quizzes if query is empty
    } else {
      this.filteredQuestion = this.questions.filter((Question) =>
        Question.question_text.toLowerCase().includes(trimmedQuery)
      );
    }
  }

  deleteQuestion(id: number) {
    const apiUrl = `${environment.API_URL}delete_quiz_question`;

    const requestData = {
      question_id: id,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('Question deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }


  deleteQuestionImage(questionID: number) {
    const apiUrl = `${environment.API_URL}delete_question_image`;

    const requestData = {
      id: questionID,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success('Question  image deleted');
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }


  // Removing Both Kind of Options (Image/Text)
  deleteOption(index: number, formType: number, option_id?: number) {
    var SelectedFormOptionsArray: FormArray =
      formType === this.ADD_FORM
        ? this.addFormOptionsArray
        : this.editFormOptionsArray;
    var SelectedForm: FormGroup =
      formType === this.ADD_FORM ? this.addQuestionForm : this.editQuestionForm;

    if (formType === this.ADD_FORM || !option_id) {
      SelectedFormOptionsArray.removeAt(index);
      this.message.success('Option deleted');
    } else {
      const apiUrl = `${environment.API_URL}delete_quiz_option`;
      const requestData = {
        id: option_id,
        question_type: SelectedForm.controls['question_type'].value,
      };

      // console.log(requestData);
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchQuestionDetails(SelectedForm.controls['id'].value).then(
              () => {
                this.message.success('Option deleted');
              }
            );
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        },
      });
    }
  }

  // =======================================================================================

  // Add Questions to Quiz Part

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  selectedQuizId?: number[];
  isDialogVisible = false;
  dialogLoading = false;

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.questions.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    // const listOfEnabledData = this.questions.filter(({ disabled }) => !disabled);
    this.checked = this.questions.every(({ id }) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      this.questions.some(({ id }) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }

  openAddQizQuestionDialog() {
    console.log(this.setOfCheckedId);
    this.selectedQuizId = undefined;
    this.isDialogVisible = true;
  }

  addQuestionsToQuiz(): void {
    this.dialogLoading = true;
    const apiUrl = `${environment.API_URL}add_bulk_quiz_question`;

    var requestData = {
      quiz_id: this.selectedQuizId,
      question_id: [...this.setOfCheckedId],
    };
    console.log(requestData);

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Add Response: ', response);
        if (response.success) {
          this.message.success('Question(s) Added');
        } else {
          this.message.error('An error occurred, please try later.');
        }
      },
      error: (error) => {
        console.error('Data Add Error: ', error);
        this.isDialogVisible = false;
        this.dialogLoading = false;
      },
      complete: () => {
        this.isDialogVisible = false;
        this.dialogLoading = false;
      },
    });
  }

  handleCancel(): void {
    this.isDialogVisible = false;
  }
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ['image'],
      ['clean'], // remove formatting button
    ],
  };
  questionTextSort = (a: any, b: any) =>
    a.question_text.localeCompare(b.question_text);
  imageSort = (a: any, b: any) =>
    (a.question_image || '').localeCompare(b.question_image || '');
  typeSort = (a: any, b: any) =>
    a.question_type_name.localeCompare(b.question_type_name);
  statusSort = (a: any, b: any) => a.is_active - b.is_active;
  categorySort = (a: any, b: any) =>
    (a.quiz_category_string || '').localeCompare(b.quiz_category_string || '');
  evaluationTypeSort = (a: any, b: any) =>
    a.evaluation_type.localeCompare(b.evaluation_type);
  createdAtSort = (a: any, b: any) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  updatedAtSort = (a: any, b: any) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  createdBySort = (a: any, b: any) =>
    (a.created_by_string || '').localeCompare(b.created_by_string || '');
  updatedBySort = (a: any, b: any) =>
    (a.updated_by_string || '').localeCompare(b.updated_by_string || '');
}
