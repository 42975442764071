<ng-container>
  <app-admin-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>


<ng-template #content>
  <div class="d-flex mb-2">
    <nz-breadcrumb>
      <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
      <nz-breadcrumb-item>FAQ</nz-breadcrumb-item>
    </nz-breadcrumb>

  </div>
  <div class="row mb-2">
    <div class="col-md-6">
      <nz-input-group>
        <input nz-input placeholder="Search tickers" [(ngModel)]="searchQuery" (input)="filterFAQs()"
          style="margin-bottom: 10px;" />
      </nz-input-group>
    </div>

  </div>

  <nz-card>
    <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" (nzSelectedIndexChange)="fetchAllFAQs($event === 0 ? 1 : 2)">
      <nz-tab nzTitle="Stocks"></nz-tab>
      <nz-tab nzTitle="Cryptos"></nz-tab>
    </nz-tabset>
    <button nz-button nzType="primary" (click)="openFAQModal()">
      <span nz-icon nzType="plus"></span> Add {{ selectedTabIndex === 0 ? 'Stock' : 'Crypto' }}
    </button>
    <nz-table #basicTable [nzData]="filteredFaqList" [nzLoading]="tableLoading" [nzShowPagination]="false"
      [nzScroll]="{ x: '100%' }">
      <thead>
        <tr>
          <th nzWidth="5%">#</th>
          <th>Ticker</th>
          <th nzAlign="center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let faq of filteredFaqList; let i = index" (click)="openAllFAQlist(faq.symbol_id)">
          <td>{{ i + 1 }}</td>
          <td>{{ faq.symbol }}</td>

          <td nzAlign="center" (click)="$event.stopPropagation()">
            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
              nzPopconfirmTitle="Are you sure you want to delete this Question"
              (nzOnConfirm)="dynamicDeleteFunction(faq , 'delete_FAQ' , 'symbol_id' )" (nzOnCancel)="cancel()">
              <span nz-icon nzType="delete"></span>
            </button>

          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>
  <nz-modal [(nzVisible)]="isFAQModalVisible" nzTitle="Select Ticker" (nzOnCancel)="handleCancel()"
    (nzOnOk)="addSymbol()" [nzOkLoading]="modalOkLoading">
    <ng-container *nzModalContent>
      <form [formGroup]="FAQForm">
        <div>
          <nz-form-item *ngIf="selectedTabIndex === 0">
            <nz-form-label>Select Stocks</nz-form-label>
            <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzPlaceHolder="Select Stocks"
              formControlName="symbol_id">
              <nz-option [nzDisabled]="isSymbolIdDisabled(stocks.symbol_id)" *ngFor="let stocks of similarStocks"
                [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"></nz-option>
            </nz-select>
          </nz-form-item>


          <nz-form-item *ngIf="selectedTabIndex === 1">
            <nz-form-label>Select Crypto</nz-form-label>
            <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzPlaceHolder="Select Crypto"
              formControlName="symbol_id">
              <nz-option [nzDisabled]="isSymbolIdDisabled(cryptos.symbol_id)" *ngFor="let cryptos of similarCryptos"
                [nzValue]="cryptos.symbol_id" [nzLabel]="cryptos.symbol"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
      </form>
    </ng-container>

  </nz-modal>

  <nz-modal [nzFooter]="null" [(nzVisible)]="faqListOpen" [nzTitle]="modalTitleTemplate" (nzOnCancel)="handleCancel()"
    [nzWidth]="1000">
    <ng-template #modalTitleTemplate>
      <div class="modal-title">
        <span>FAQ Details</span>
        <button nz-button nzType="primary" class="add-faq-btn" (click)="isFAQFormVisible = true">
          <span nz-icon nzType="plus"></span>Add FAQ
        </button>
      </div>
    </ng-template>
    <ng-container *nzModalContent>
      <form *ngIf="isFAQFormVisible" [formGroup]="FAQForm">
        <div class="row">
          <div class="col-md-6">
            <textarea nz-input formControlName="question" placeholder="Enter question" nzAutosize></textarea>
          </div>
          <div class="col-md-6">
            <textarea nz-input formControlName="answer" placeholder="Enter answer"
              [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <nz-form-item *ngIf="selectedTabIndex === 0">
              <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzPlaceHolder="Select Stocks"
                formControlName="symbol_id">
                <nz-option *ngFor="let stocks of similarStocks" [nzValue]="stocks.symbol_id"
                  [nzLabel]="stocks.symbol"></nz-option>
              </nz-select>
            </nz-form-item>

            <nz-form-item *ngIf="selectedTabIndex === 1">
              <nz-form-label>Select Crypto</nz-form-label>
              <nz-select [nzAllowClear]="true" class="w-100" nzShowSearch nzPlaceHolder="Select Crypto"
                formControlName="symbol_id">
                <nz-option *ngFor="let cryptos of similarCryptos" [nzValue]="cryptos.symbol_id"
                  [nzLabel]="cryptos.symbol"></nz-option>
              </nz-select>
            </nz-form-item>
            <div>

            </div>
          </div>
          <div class="col-md-6">
            <button nz-button nzType="primary" (click)="handleOk()"> Add Question</button>
          </div>
        </div>
      </form>
      <nz-table #basicTable [nzData]="FAQlist" [nzLoading]="tableLoading" [nzShowPagination]="false">
        <thead>
          <tr>
            <th>Sr.no</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let faq of FAQlist; let i = index" (dblclick)="enableEdit(faq)">
            <td>{{ i + 1 }}</td>
            <td>
              <ng-container *ngIf="faq.isEditing">
                <form [formGroup]="FAQForm">
                  <textarea formControlName="question" nz-input [rows]="3" [cols]="20"></textarea>
                </form>
              </ng-container>
              <ng-container *ngIf="!faq.isEditing">
                {{ faq.question }}
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="faq.isEditing">
                <form [formGroup]="FAQForm">
                  <textarea formControlName="answer" nz-input [rows]="3" [cols]="20"></textarea>
                </form>
              </ng-container>
              <ng-container *ngIf="!faq.isEditing">
                {{ faq.answer || 'Not answered' }}
              </ng-container>
            </td>
            <td>{{ faq.created_by_name }}</td>
            <td>{{ faq.created_at | date: 'short' }}</td>
            <td nzAlign="center" (click)="$event.stopPropagation()">
              <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this Question"
                (nzOnConfirm)="dynamicDeleteFunction(faq , 'delete_faq_question' , 'FAQ_id' )" (nzOnCancel)="cancel()">
                <span nz-icon nzType="delete"></span>
              </button>
              <button class="ms-2" nz-button nzType="primary" *ngIf="isEditMode" (click)="handleOk()" nzShape="circle">
                <span nz-icon nzType="save"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>


    </ng-container>
  </nz-modal>

</ng-template>