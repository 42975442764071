<ng-container *ngIf="useAdminLayout; else authorLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template>

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->

    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Quiz Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Questions</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto me-2" nz-button [disabled]="setOfCheckedId.size === 0"
            (click)="openAddQizQuestionDialog()"><span nz-icon nzType="plus"></span>Add To Quiz</button>
        <button class="" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon nzType="plus"></span>Add
            Question</button>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Questions" [(ngModel)]="searchQuery"
                    (ngModelChange)="searchQuestions()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>
    <nz-card>
        <div class="table-responsive">
            <nz-table #QuestionTable [nzData]="filteredQuestion" nzTableLayout="fixed" [nzLoading]="tableLoading"
                #rowSelectionTable nzShowSizeChanger>
                <thead>
                    <tr>
                        <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" nzLabel="Select all"
                            (nzCheckedChange)="onAllChecked($event)" width="5%"></th>
                        <th [nzSortFn]="questionTextSort">Question</th>
                        <th nzWidth="100px" [nzSortFn]="imageSort">Image</th>
                        <th [nzSortFn]="typeSort">Type</th>
                        <th [nzSortFn]="statusSort">Status</th>
                        <th [nzSortFn]="categorySort">Category</th>
                        <th [nzSortFn]="evaluationTypeSort">Evaluation Type</th>
                        <th [nzSortFn]="createdAtSort">Created At</th>
                        <th [nzSortFn]="updatedAtSort">Updated At</th>
                        <th [nzSortFn]="createdBySort">Created By</th>
                        <th [nzSortFn]="updatedBySort">Updated By</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                      </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let question of QuestionTable.data">
                        <!-- Main Table -->
                        <tr (click)="openEditDrawer(question.id)">
                            <td [nzChecked]="setOfCheckedId.has(question.id)" (click)="$event.stopPropagation()"
                                (nzCheckedChange)="onItemChecked(question.id, $event)"
                                [nzDisabled]="question.is_active === 0"></td>
                            <td nzEllipsis>{{ question.question_text }}</td>
                            <td>
                                <img class="latest_article_image" alt="example"
                                    [src]="question.question_image ? (storageUrl + question.question_image) : fallbackImage"
                                    style="width: 100px; height: auto;" />
                            </td>

                            <td>{{ question.question_type_name }}</td>
                            <td>{{ question.is_active === 1 ? 'Active': 'Inactive' }}</td>
                            <td>{{ question.categories|| '--'}}</td>
                            <td>{{ question.evaluation_type }}</td>
                            <td>{{ question.created_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ question.updated_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ question.created_by_string || '--'}}</td>
                            <td>{{ question.updated_by_string || '--'}}</td>

                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(question.id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>


        <nz-modal [nzVisible]="addDrawer" nzWidth="95%" nzTitle="Add Question" (nzOnCancel)="closeAddDrawer()">
            <ng-container *nzModalContent>

                <form nz-form nzLayout="vertical" [formGroup]="addQuestionForm" (ngSubmit)="submitAddForm()">
                    <div class="row mb-3">
                        <div class="col">
                            <app-small-section-heading title="Question Details"></app-small-section-heading>
                        </div>
                    </div>

                    <div class="row">
                        <!-- First Column: Enter Question -->
                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Enter Question</nz-form-label>
                                <nz-form-control>
                                    <input nz-input formControlName="question_text" placeholder="Write your question here" type="text" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-md-3">
                            <nz-form-control nzErrorTip="Please select category!">
                                <nz-form-label>Select category</nz-form-label>
                                <nz-tree-select 
                                    [nzDefaultExpandAll]="true" 
                                    nzShowSearch 
                                    [nzAllowClear]="true"
                                    [nzMultiple]="true" 
                                    [nzExpandedKeys]="treeSelectExpandKeys"
                                    [nzNodes]="allcategories" 
                                    nzShowSearch 
                                    nzPlaceHolder="Select Category"
                                    formControlName="category_id">
                                </nz-tree-select>
                            </nz-form-control>
                        </div>
                        <div class="col-md-2">
                            <div nz-row>
                                <label nz-checkbox 
                                       [nzChecked]="addQuestionForm.get('symbol_category_ids')?.value.includes(1)" 
                                       (nzCheckedChange)="fetchAllSymbols(1, $event)">Stocks</label>
                                <label nz-checkbox 
                                       [nzChecked]="addQuestionForm.get('symbol_category_ids')?.value.includes(2)" 
                                       (nzCheckedChange)="fetchAllSymbols(2, $event)">Crypto</label>
                              </div>
                        </div>
                        <!-- Fourth Column: Select Ticker -->
                        <div class="col-md-2" *ngIf="selectedCategories.length > 0">
                            <nz-form-item>
                                <nz-form-label>Select Ticker</nz-form-label>
                                <nz-select formControlName="symbol_id" nzMode="multiple" nzPlaceHolder="Please select">
                                    <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                    </div>
                    
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label nzRequired>Question's Option Type <span nz-popover
                                    [nzPopoverOverlayStyle]="{width:'50rem'}"
                                    [nzPopoverContent]="QueTypePopoverTemplate" class="ms-2" nz-icon
                                    nzType="question-circle" nzTheme="outline"></span>
                            </nz-form-label>
                            <nz-select nzAllowClear nz-popover nzPopoverPlacement="topLeft"
                                [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverTitle="Alert Information"
                                [nzPopoverContent]="contentTemplate"
                                [nzPopoverTrigger]="(addQuestionForm.controls['question_type'].value) ? 'hover' : null"
                                nzShowSearch nzPlaceHolder="Select Option Type"
                                formControlName="question_type"
                                (ngModelChange)="onQuestionTypeChange($event, ADD_FORM)">
                                <nz-option *ngFor="let questionType of questionTypes"
                                    [nzLabel]="questionType.question_type"
                                    [nzValue]="questionType.id"></nz-option>
                            </nz-select>

                            <ng-template #contentTemplate>
                                Changing the Question's Option Type will result in the <strong>Deletion of
                                    all Existing Options/Answer Details.</strong>
                                <br /> Be sure before you proceed.
                            </ng-template>

                            <ng-template #QueTypePopoverTemplate>
                                <nz-descriptions nzTitle="Option Type Info" [nzColumn]="1">
                                    <nz-descriptions-item *ngFor="let types of questionTypesPopoverInfo"
                                        [nzTitle]="types.heading">
                                        {{ types.description }}
                                    </nz-descriptions-item>
                                </nz-descriptions>
                            </ng-template>
                        </nz-form-item>
                    </div>
                    <div class="col-md-2">
                        <nz-form-item>
                            <nz-form-label nzRequired>Points <span nz-popover nzPopoverContent="The points allocated when
                            this question has been answered correctly by the quiz taker." class="ms-2"
                                    nz-icon nzType="question-circle"
                                    nzTheme="outline"></span></nz-form-label>
                            <nz-input-number required formControlName="points" [nzMin]="1" [nzMax]="1000"
                                [nzStep]="1"></nz-input-number>
                        </nz-form-item>
                    </div>
                    <div class="col-md-3">
                        <nz-form-item>
                            <nz-form-label>Evaluation Type?
                                <span nz-popover [nzPopoverContent]="EvaluationTypePopoverTemplate"
                                    [nzPopoverOverlayStyle]="{width:'30rem'}" class="ms-2" nz-icon
                                    nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                            <nz-radio-group formControlName="evaluation_type"
                                (ngModelChange)="EvaluationTypeChanged($event, ADD_FORM)">
                                <label nz-radio [nzValue]="AUTO">Auto</label>
                                <label nz-radio [nzValue]="MANUAL">Manual</label>
                            </nz-radio-group>
                            <ng-template #EvaluationTypePopoverTemplate>
                                <p><strong>Auto Evaluation</strong>:
                                    Choose this option if you prefer the system to automatically grade this
                                    question according to your chosen correct option.</p>
                                <p><strong>Manual Evaluation</strong>:
                                    Select this option if you want to personally
                                    review and grade this question for accurate assessment and feedback.</p>

                                <nz-divider *ngIf="evaluationTypeManualAndDisabled"></nz-divider>
                                <p *ngIf="evaluationTypeManualAndDisabled">For Question Types: <strong>Long
                                        answers,
                                        Audio recording,
                                        and Video
                                        recording</strong>, the Evaluation Type will always be Manual.</p>
                            </ng-template>
                        </nz-form-item>
                    </div>
                    <div class="col-md-3">
                        <nz-form-item>
                            <nz-form-label>Question status?</nz-form-label>
                            <nz-radio-group formControlName="is_active">
                                <label nz-radio [nzValue]="1">Active</label>
                                <label nz-radio [nzValue]="0">Inactive</label>
                            </nz-radio-group>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                          <nz-form-item>
                            <label for="fileInput" class="file-upload-container">
                              <input type="file" (change)="onQuestionImageChange($event)"
                                     accept="image/png, image/jpeg, image/jpg" id="fileInput" 
                                     style="display: none;" />
                              <div>
                                <span>Upload Image</span>
                              </div>
                            </label>
                          </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <div *ngIf="imagePreview">
                            <img  nz-image width="30px"
                                 height="30px" [nzSrc]="imagePreview" 
                                 alt="Uploaded Image" />
                                 <button (click)="removeImage()" nz-button nzDanger nzType="dashed"
                                 nzShape="circle"><span nz-icon nzType="delete"></span></button>
                          </div>
                    </div>
                </div>
                    <!-- Options Details -->
                    <div class="row mb-3">
                        <div class="col">
                            <app-small-section-heading title="Options/Answers Details"></app-small-section-heading>
                        </div>

                        <div class="col-auto">
                            <button nz-button nzType="primary" [disabled]="!addQuestionForm.valid"
                                [nzLoading]="formSubmitLoading" type="submit">Submit
                                Question</button>
                        </div>
                    </div>

                    <nz-result *ngIf="!addFormQuestionTypeEmpty && !optionsOrAnswersNeeded" [nzIcon]="iconTemplate"
                        nzTitle="No Action Needed!"
                        nzSubTitle="The selected question type doesn't require setting options or answers.">
                        <ng-template #iconTemplate></ng-template>
                    </nz-result>

                    <nz-result *ngIf="addFormQuestionTypeEmpty && !optionsOrAnswersNeeded" [nzIcon]=" iconTemplate"
                        [nzIcon]="iconTemplate" nzTitle="No Action Available!"
                        nzSubTitle="Please select a Question's Option type.">
                        <ng-template #iconTemplate></ng-template>
                    </nz-result>

                    <!-- Below Code: Text Based Choose Options -->
                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === TEXT_OPTIONS"
                        formArrayName="options" class="row g-4">
                        <div class="col-12 col-md-3"
                            *ngFor="let option of addFormOptionsArray.controls; let i = index; let last = last">
                            <nz-card class="light-shadow p-0" [nzBodyStyle]="{'padding':'.5rem'}" [formGroupName]="i">

                                <div class="hstack">
                                    <h6 class="me-auto mb-0">Option {{i+1}}</h6>
                                    <div class="hstack gap-3">
                                        <button nz-button nzDanger nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Delete Option" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this option?"
                                            (nzOnConfirm)="deleteOption(i, ADD_FORM)" (nzOnCancel)="cancel()"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>

                                        <button *ngIf="last" nz-button nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Add Option" (click)="addTextOption(ADD_FORM)"
                                            nzShape="circle"><span nz-icon nzType="plus"></span></button>
                                    </div>
                                </div>
                                <nz-divider class="my-2"></nz-divider>
                                <nz-form-item class="mb-0">
                                    <nz-form-control>
                                        <nz-textarea-count [nzMaxCharacterCount]="500">
                                            <textarea required formControlName="text" nz-input rows="2"
                                                placeholder="Enter Option Text"></textarea>
                                        </nz-textarea-count>
                                    </nz-form-control>
                                </nz-form-item>

                                <label nz-checkbox *ngIf="addQuestionForm.controls['evaluation_type'].value === AUTO"
                                    [nzDisabled]="onlyOneCorrectOptionRequired && allUncheckedCheckBoxDisabled && (i !== checkedCheckBoxId)"
                                    (ngModelChange)="onCheckboxChange($event, i)" formControlName="is_correct_option">Is
                                    Correct Option?</label>
                            </nz-card>
                        </div>
                    </div>

                    <!-- Below Code: Image Based Choose Options -->
                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === IMAGE_OPTIONS"
                        formArrayName="options" class="row g-4">
                        <div class="col-12 col-md-3"
                            *ngFor="let option of addFormOptionsArray.controls; let i = index; let last = last">
                            <nz-card class="light-shadow p-0" [nzBodyStyle]="{'padding':'.5rem'}" [formGroupName]="i">
                                <!-- ... existing code for text-based options ... -->
                                <div class="hstack">
                                    <h6 class="me-auto mb-0">Option {{i+1}}</h6>
                                    <div class="hstack gap-3">
                                        <button nz-button nzDanger nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Delete Option" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this option?"
                                            (nzOnConfirm)="deleteOption(i, ADD_FORM)" (nzOnCancel)="cancel()"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>

                                        <button *ngIf="last" nz-button nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Add Option" (click)="addImageOption(ADD_FORM)"
                                            nzShape="circle"><span nz-icon nzType="plus"></span></button>
                                    </div>
                                </div>
                                <nz-divider class="my-2"></nz-divider>

                                <nz-form-item>
                                    <label for="fileInput{{i}}" class="file-upload-container"
                                        *ngIf="!addFormOptionsArray.at(i).get('image')?.value">
                                        <input type="file" (change)="onFileSelected($event, i, ADD_FORM)"
                                            accept="png, jpeg, jpg" id="fileInput{{i}}" #fileInput
                                            style="display: none;" />
                                        <div>
                                            <span>Upload Image</span>
                                        </div>
                                    </label>
                                    <img *ngIf="addFormOptionsArray.at(i).get('imageSrc')?.value" nz-image width="50px"
                                        height="50px" [nzSrc]="addFormOptionsArray.at(i).get('imageSrc')?.value"
                                        alt="Uploaded Image" />
                                </nz-form-item>

                                <nz-form-item class="mb-0">
                                    <nz-form-control>
                                        <nz-textarea-count [nzMaxCharacterCount]="500">
                                            <textarea required formControlName="text" nz-input rows="2"
                                                placeholder="Enter Image Text"></textarea>
                                        </nz-textarea-count>
                                    </nz-form-control>
                                </nz-form-item>

                                <label nz-checkbox *ngIf="addQuestionForm.controls['evaluation_type'].value === AUTO"
                                    [nzDisabled]="onlyOneCorrectOptionRequired && allUncheckedCheckBoxDisabled && (i !== checkedCheckBoxId)"
                                    (ngModelChange)="onCheckboxChange($event, i)" formControlName="is_correct_option">Is
                                    Correct Option?</label>
                            </nz-card>
                        </div>
                    </div>

                    <!-- Below Code: Short Text Answer -->
                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === SHORT_TEXT_ANSWER"
                        class="row g-4">
                        <div class="col-12 col-md-3">
                            <nz-form-item class="mb-0">
                                <nz-form-label>Enter Short Answer</nz-form-label>
                                <nz-form-control>
                                    <nz-textarea-count [nzMaxCharacterCount]="100">
                                        <textarea required formControlName="short_text_answer" nz-input rows="2"
                                            placeholder="Enter Answer"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </ng-container>
        </nz-modal>

        <nz-modal [nzVisible]="editDrawer" nzWidth="95%" nzTitle=" Edit Question" (nzOnCancel)="closeEditDrawer()">
            <ng-container *nzModalContent>
                <nz-spin *ngIf="editDrawerLoading" class="position-absolute start-50 top-50" nzSimple></nz-spin>

                <form *ngIf="!editDrawerLoading" nz-form nzLayout="vertical" [formGroup]="editQuestionForm"
                    (ngSubmit)="submitEditForm()">

                    <div class="row mb-3">
                        <div class="col">
                            <app-small-section-heading title="Question Details"></app-small-section-heading>
                        </div>
                    </div>
                    <div class="row">
                        <!-- First Column: Enter Question -->
                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Enter Question</nz-form-label>
                                <nz-form-control>
                                    <input nz-input formControlName="question_text" placeholder="Write your question here" type="text" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-md-3">
                            <nz-form-control nzErrorTip="Please select category!">
                                <nz-form-label>Select category</nz-form-label>
                                <nz-tree-select 
                                    [nzDefaultExpandAll]="true" 
                                    nzShowSearch 
                                    [nzAllowClear]="true"
                                    [nzMultiple]="true" 
                                    [nzExpandedKeys]="treeSelectExpandKeys"
                                    [nzNodes]="allcategories" 
                                    nzShowSearch 
                                    nzPlaceHolder="Select Category"
                                    formControlName="category_id">
                                </nz-tree-select>
                            </nz-form-control>
                        </div>
                        <div class="col-md-2">
                            <div nz-row>
                             <label nz-checkbox 
                                       [nzChecked]="editQuestionForm.get('symbol_category_ids')?.value.includes(1)" 
                                       (nzCheckedChange)="fetchAllSymbols(1, $event)">Stocks</label>
                                <label nz-checkbox 
                                       [nzChecked]="editQuestionForm.get('symbol_category_ids')?.value.includes(2)" 
                                       (nzCheckedChange)="fetchAllSymbols(2, $event)">Crypto</label> 
                              </div>
                        </div>
                        <!-- Fourth Column: Select Ticker -->
                        <div class="col-md-2" >
                            <nz-form-item>
                                <nz-form-label>Select Ticker</nz-form-label>
                                <nz-select formControlName="symbol_id" nzMode="multiple" nzPlaceHolder="Please select">
                                    <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label nzRequired>Question's Option Type <span nz-popover
                                    [nzPopoverOverlayStyle]="{width:'50rem'}"
                                    [nzPopoverContent]="QueTypePopoverTemplate" class="ms-2" nz-icon
                                    nzType="question-circle" nzTheme="outline"></span>
                            </nz-form-label>
                            <nz-select nzAllowClear nz-popover nzPopoverPlacement="topLeft"
                                [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverTitle="Alert Information"
                                [nzPopoverContent]="contentTemplate"
                                [nzPopoverTrigger]="(addQuestionForm.controls['question_type'].value) ? 'hover' : null"
                                nzShowSearch nzPlaceHolder="Select Option Type"
                                formControlName="question_type"
                                (ngModelChange)="onQuestionTypeChange($event, ADD_FORM)">
                                <nz-option *ngFor="let questionType of questionTypes"
                                    [nzLabel]="questionType.question_type"
                                    [nzValue]="questionType.id"></nz-option>
                            </nz-select>

                            <ng-template #contentTemplate>
                                Changing the Question's Option Type will result in the <strong>Deletion of
                                    all Existing Options/Answer Details.</strong>
                                <br /> Be sure before you proceed.
                            </ng-template>

                            <ng-template #QueTypePopoverTemplate>
                                <nz-descriptions nzTitle="Option Type Info" [nzColumn]="1">
                                    <nz-descriptions-item *ngFor="let types of questionTypesPopoverInfo"
                                        [nzTitle]="types.heading">
                                        {{ types.description }}
                                    </nz-descriptions-item>
                                </nz-descriptions>
                            </ng-template>
                        </nz-form-item>
                    </div>
                    <div class="col-md-2">
                        <nz-form-item>
                            <nz-form-label nzRequired>Points <span nz-popover nzPopoverContent="The points allocated when
                            this question has been answered correctly by the quiz taker." class="ms-2"
                                    nz-icon nzType="question-circle"
                                    nzTheme="outline"></span></nz-form-label>
                            <nz-input-number required formControlName="points" [nzMin]="1" [nzMax]="1000"
                                [nzStep]="1"></nz-input-number>
                        </nz-form-item>
                    </div>
                    <div class="col-md-3">
                        <nz-form-item>
                            <nz-form-label>Evaluation Type?
                                <span nz-popover [nzPopoverContent]="EvaluationTypePopoverTemplate"
                                    [nzPopoverOverlayStyle]="{width:'30rem'}" class="ms-2" nz-icon
                                    nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                            <nz-radio-group formControlName="evaluation_type"
                                (ngModelChange)="EvaluationTypeChanged($event, ADD_FORM)">
                                <label nz-radio [nzValue]="AUTO">Auto</label>
                                <label nz-radio [nzValue]="MANUAL">Manual</label>
                            </nz-radio-group>
                            <ng-template #EvaluationTypePopoverTemplate>
                                <p><strong>Auto Evaluation</strong>:
                                    Choose this option if you prefer the system to automatically grade this
                                    question according to your chosen correct option.</p>
                                <p><strong>Manual Evaluation</strong>:
                                    Select this option if you want to personally
                                    review and grade this question for accurate assessment and feedback.</p>

                                <nz-divider *ngIf="evaluationTypeManualAndDisabled"></nz-divider>
                                <p *ngIf="evaluationTypeManualAndDisabled">For Question Types: <strong>Long
                                        answers,
                                        Audio recording,
                                        and Video
                                        recording</strong>, the Evaluation Type will always be Manual.</p>
                            </ng-template>
                        </nz-form-item>
                    </div>
                    <div class="col-md-3">
                        <nz-form-item>
                            <nz-form-label>Question status?</nz-form-label>
                            <nz-radio-group formControlName="is_active">
                                <label nz-radio [nzValue]="1">Active</label>
                                <label nz-radio [nzValue]="0">Inactive</label>
                            </nz-radio-group>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <label for="fileInput" class="file-upload-container">
                                <input type="file" (change)="onQuestionImageChange($event)"
                                       accept="image/png, image/jpeg, image/jpg" id="fileInput" 
                                       style="display: none;" />
                                <div>
                                    <span>Upload Image</span>
                                </div>
                            </label>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <div *ngIf="imagePreview">
                            <img nz-image width="30px" height="30px"
                                 [nzSrc]="imagePreview" 
                                 alt="Uploaded Image" />
                            <button (click)="deleteQuestionImage(editQuestionForm.get('id')?.value)" nz-button nzDanger nzType="dashed"
                                    nzShape="circle">
                                <span nz-icon nzType="delete"></span>
                            </button>
                        </div>
                    </div>
                </div>
                   

                    <div class="row mb-3">
                        <div class="col">
                            <app-small-section-heading title="Options/Answers Details"></app-small-section-heading>
                        </div>
                        <div class="col-auto">
                            <button nz-button nzType="primary" [nzLoading]="formSubmitLoading"
                                [disabled]="!editQuestionForm.valid" type="submit">Update
                                Question</button>
                        </div>
                    </div>


                    <nz-result *ngIf="!editFormQuestionTypeEmpty && !optionsOrAnswersNeeded" [nzIcon]="iconTemplate"
                        nzTitle="No Action Needed!"
                        nzSubTitle="The selected option type doesn't require setting options or answers.">
                        <ng-template #iconTemplate></ng-template>
                    </nz-result>

                    <nz-result *ngIf="editFormQuestionTypeEmpty && !optionsOrAnswersNeeded" [nzIcon]="iconTemplate"
                        nzTitle="No Action Available!" nzSubTitle="Please select a Question's Option type.">
                        <ng-template #iconTemplate></ng-template>
                    </nz-result>

                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === TEXT_OPTIONS"
                        formArrayName="options" class="row g-4">
                        <div class="col-12 col-md-3"
                            *ngFor="let option of editFormOptionsArray.controls; let i = index; let last = last">
                            <nz-card class="light-shadow p-0" [nzBodyStyle]="{'padding':'.5rem'}" [formGroupName]="i">

                                <div class="hstack">
                                    <h6 class="me-auto mb-0">Option {{i+1}}</h6>
                                    <div class="hstack gap-3">
                                        <button nz-button nzDanger nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Delete Option" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this option?"
                                            (nzOnConfirm)="deleteOption(i, EDIT_FORM, option.value.id)"
                                            (nzOnCancel)="cancel()" nzShape="circle"><span nz-icon
                                                nzType="delete"></span></button>

                                        <button *ngIf="last" nz-button nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Add Option" (click)="addTextOption(EDIT_FORM)"
                                            nzShape="circle"><span nz-icon nzType="plus"></span></button>
                                    </div>
                                </div>
                                <nz-divider class="my-2"></nz-divider>
                                <nz-form-item class="mb-0">
                                    <nz-form-control>
                                        <nz-textarea-count [nzMaxCharacterCount]="500">
                                            <textarea required formControlName="text" nz-input rows="2"
                                                placeholder="Enter Option Text"></textarea>
                                        </nz-textarea-count>
                                    </nz-form-control>
                                </nz-form-item>

                                <label nz-checkbox *ngIf="editQuestionForm.controls['evaluation_type'].value === AUTO"
                                    [nzDisabled]="onlyOneCorrectOptionRequired && allUncheckedCheckBoxDisabled && (option.value.id !== checkedCheckBoxId)"
                                    (ngModelChange)="onCheckboxChange($event, option.value.id)"
                                    formControlName="is_correct_option">Is
                                    Correct Option?</label>
                            </nz-card>
                        </div>
                    </div>

                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === IMAGE_OPTIONS"
                        formArrayName="options" class="row g-4">
                        <div class="col-12 col-md-3"
                            *ngFor="let option of editFormOptionsArray.controls; let i = index; let last = last">
                            <nz-card class="light-shadow p-0" [nzBodyStyle]="{'padding':'.5rem'}" [formGroupName]="i">
                                <div class="hstack">
                                    <h6 class="me-auto mb-0">Option {{i+1}}</h6>
                                    <div class="hstack gap-3">
                                        <button nz-button nzDanger nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Delete Option" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this option?"
                                            (nzOnConfirm)="deleteOption(i, EDIT_FORM, option.value.id)"
                                            (nzOnCancel)="cancel()" nzShape="circle"><span nz-icon
                                                nzType="delete"></span></button>

                                        <!-- <button nz-button nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Edit Option" nzShape="circle"><span nz-icon
                                                nzType="edit"></span></button> -->

                                        <button *ngIf="last" nz-button nzType="primary" type="button" nz-tooltip
                                            nzTooltipTitle="Add Option" (click)="addImageOption(EDIT_FORM)"
                                            nzShape="circle"><span nz-icon nzType="plus"></span></button>
                                    </div>
                                </div>
                                <nz-divider class="my-2"></nz-divider>

                                <nz-form-item>

                                    <img *ngIf="editFormOptionsArray.at(i).get('image_url')?.value" nz-image
                                        width="50px" height="50px"
                                        nzSrc="{{storageUrl}}{{editFormOptionsArray.at(i).get('image_url')?.value}}"
                                        alt="Uploaded Image" />


                                    <img *ngIf="editFormOptionsArray.at(i).get('imageSrc')?.value" nz-image width="50px"
                                        height="50px" [nzSrc]="editFormOptionsArray.at(i).get('imageSrc')?.value"
                                        alt="Uploaded Image" />


                                    <label
                                        *ngIf="!editFormOptionsArray.at(i).get('image_url')?.value && !editFormOptionsArray.at(i).get('image')?.value"
                                        for="fileInput{{i}}" class="file-upload-container">
                                        <input type="file" (change)="onFileSelected($event, i, EDIT_FORM)"
                                            accept="png, jpeg, jpg" id="fileInput{{i}}" #fileInput
                                            style="display: none;" />
                                        <div>
                                            <span>Upload Image</span>
                                        </div>
                                    </label>

                                </nz-form-item>

                                <nz-form-item>

                                    <textarea [nzAutosize]="{ minRows: 1, maxRows: 3 }" formControlName="text" nz-input
                                        placeholder="Enter Image Text"></textarea>

                                </nz-form-item>

                                <label nz-checkbox *ngIf="editQuestionForm.controls['evaluation_type'].value === AUTO"
                                    [nzDisabled]="onlyOneCorrectOptionRequired && allUncheckedCheckBoxDisabled && (i !== checkedCheckBoxId)"
                                    (ngModelChange)="onCheckboxChange($event, i)" formControlName="is_correct_option">Is
                                    Correct Option?</label>
                            </nz-card>
                        </div>
                    </div>

                    <div *ngIf="optionsOrAnswersNeeded && selectedOptionOrAnswerType === SHORT_TEXT_ANSWER"
                        class="row g-4">
                        <div class="col-12 col-md-3">
                            <nz-form-item class="mb-0">
                                <nz-form-label>Enter Short Answer</nz-form-label>
                                <nz-form-control>
                                    <nz-textarea-count [nzMaxCharacterCount]="100">
                                        <textarea required formControlName="short_text_answer" nz-input rows="2"
                                            placeholder="Enter Answer"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>


                </form>

            </ng-container>
        </nz-modal>













        <nz-modal [(nzVisible)]="isDialogVisible" [nzOkDisabled]="!selectedQuizId" nzTitle="Add Questions To Quiz"
            (nzOnCancel)="handleCancel()" (nzOnOk)="addQuestionsToQuiz()" nzOkText="Add" [nzOkLoading]="dialogLoading"
            [nzContent]="modalContent">

            <ng-template #modalContent>

                <nz-form-label nzRequired>Select Quiz</nz-form-label>
                <nz-form-control nzSpan="15" nzErrorTip="Please select a quiz!">
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Quiz(es)" [(ngModel)]="selectedQuizId"
                        [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple">
                        <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title" [nzValue]="quiz.id"></nz-option>
                    </nz-select>
                    <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more
                        selected</ng-template>
                </nz-form-control>

            </ng-template>
        </nz-modal>

    </nz-card>
</ng-template>