import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-preview',
  templateUrl: './course-preview.component.html',
  styleUrls: ['./course-preview.component.scss'],
})
export class CoursePreviewComponent {
  sidebarExpandLogo: SafeUrl;
  isSidebarCollapsed = false;
  quizQuestions: any[] = [];
  slug: string | null = null;
  courseId: string | null = null;
  currentLectureNumber: number = 1;
  currentQuestionIndex: number = 0;
  isVisible = false;
  loading: boolean = false;
  storageUrl: string = environment.STORAGE_URL;
  courseData: any;

  noDataToDisplay: boolean = true;
  displayLectureData: any;
  displayLectureDataType: number | null = null;
  videoVissible: boolean = true;

  constructor(
    private fetchApiService: FetchApiService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // Create a safe URL for the image file
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/InvestDostFull.png'
    );
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      console.log(paramMap);
      this.slug = paramMap.get('slug');
      this.courseId = paramMap.get('course_id');
      this.fetchInitialData();
      console.log(this.slug, this.courseId);
    });
  }

  fetchInitialData(): Promise<any> {
    this.loading = true; // Show spinner
    const apiUrl = `${environment.API_URL}course_data`;
  
    const requestData = {
      use_slug: this.courseId === null ? true : false,
      course_slug: this.slug,
      course_id: this.courseId,
    };
  
    return this.http.post(apiUrl, requestData).toPromise()
      .then((response: any) => {
        console.log('Course Data: ', response);
        // Simulate a delay of 3 seconds before rendering the data
        setTimeout(() => {
          this.courseData = response[0];
          this.updateNoDataToDisplay();
          this.loading = false; // Hide spinner after delay
        }, 3000);
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.loading = false; // Hide spinner if there's an error
      });
  }
  
  fetchQuizQuestions(quizId: number): void {
    debugger;
    this.videoVissible = false;

    const requestData = { quiz_id: quizId };
    console.log('quiz id is ', requestData);

    this.fetchApiService.getAllQuizQuestions(requestData).subscribe(
      (response: any) => {
        console.log('Quiz Questions:', response);
        this.quizQuestions = response;
        this.currentQuestionIndex = 0;
      },
      (error) => {
        console.error('Error fetching quiz questions:', error);
      }
    );
    console.log('Quizz ID:', quizId);
    this.isVisible = true;
  }
  saveData(event: any) {
    console.log('hi');
  }
  nextQuestion(): void {
    if (this.currentQuestionIndex < this.quizQuestions.length - 1) {
      this.currentQuestionIndex++;
    }
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  previousQuestion(): void {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }
  // Function to check and update noDataToDisplay
  updateNoDataToDisplay(courseData: any = this.courseData) {
    console.log(courseData);
    // Check if courseSections is empty or the first object's lectures property is empty
    if (
      courseData.courseSections.length === 0 ||
      courseData.courseSections[0].lectures.length === 0
    ) {
      this.noDataToDisplay = true;
    } else {
      this.noDataToDisplay = false;
      var firstLectureData = courseData.courseSections[0].lectures[0];
      this.displayLectureDataType = firstLectureData.content_type_id;
      if (this.displayLectureDataType === 1) {
        // Text Data
        this.displayLectureData = firstLectureData.content;
      } else {
        // Video Data
        this.displayLectureData = firstLectureData.content_url;
      }
    }
  }

  changeLecture(lectureData: any) {
    console.log(lectureData);
    this.noDataToDisplay = false;
    this.displayLectureDataType = lectureData.content_type_id;
    if (this.displayLectureDataType === 1) {
      // Text Data
      this.displayLectureData = lectureData.content;
    } else {
      // Video Data
      this.displayLectureData = lectureData.content_url;
    }
  }

  // Function to sanitize a URL
  sanitizeURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  closeWindow() {
    window.close();
  }
}
