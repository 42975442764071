<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Tags</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon
                nzType="plus"></span>Add Tag</button>
    </div>

    <nz-card>
        <div class="mb-3">
            <nz-input-group [nzSuffix]="searchIcon">
                <input nz-input placeholder="Search Tag Name" [(ngModel)]="searchQuery"
                    (ngModelChange)="filterTags()" />
                <ng-template #searchIcon>
                    <i nz-icon nzType="search"></i>
                </ng-template>
            </nz-input-group>
        </div>
        <div class="table-responsive">
            <nz-table #tagsTable [nzData]="filteredTags" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
              <thead>
                <tr>
                  <th nzWidth="5%">Sr.No</th>
                  <th [nzSortFn]="sortByName">Name</th>
                  <th [nzSortFn]="sortByName">Category</th>
                  <th>Related Crypto</th>
                  <th nzWidth="5%" [nzSortFn]="sortByApproved">Approved</th>
                  <th [nzSortFn]="sortByCreatedAt">Created At</th>
                  <th [nzSortFn]="sortByCreatedAt">Created By</th>
                  <th [nzSortFn]="sortByUpdatedAt">Updated By</th>
                  <th [nzSortFn]="sortByUpdatedAt">Updated At</th>
                  <th nzWidth="100px" nzAlign="center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tag of tagsTable.data; let i = index" (click)="tag ? openModal(true, tag) : null">
                  <td>{{ i + 1 }}</td>
                  <td>{{ tag.tag_name }}</td>
                  <td>{{ tag.tag_category }}</td>
                  <td>{{ tag.related_cryptos }}</td>
                  <td (click)="$event.stopPropagation()">
                    <div>
                      <input
                        [(ngModel)]="tag.is_approved"
                        (ngModelChange)="updateApprovalStatus(tag.tag_id, $event)"
                        class="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault{{ i }}"
                      />
                    </div>
                  </td>
                  <td>{{ tag.created_at | date: 'yyyy-MM-dd' }}</td>
                  <td>{{ tag.created_by_name }}</td>
                  <td>{{ tag.updated_by_name }}</td>
                  <td>{{ tag.updated_at | date: 'yyyy-MM-dd' }}</td>
                  <td (click)="$event.stopPropagation()">
                    <button
                      nz-button
                      nzType="default"
                      nzDanger
                      nzShape="circle"
                      nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this record?"
                      (nzOnConfirm)="confirm(tag.tag_id)"
                      (nzOnCancel)="cancel()"
                    >
                      <span nz-icon nzType="delete"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          
          <nz-modal [nzFooter]="null" [nzVisible]="isModalVisible" [nzTitle]="modalTitle" (nzOnCancel)="closeModal()">
            <ng-container *nzModalContent>
              <form nz-form nzLayout="vertical" [formGroup]="tagForm" (ngSubmit)="submitForm()">
                <nz-form-item>
                  <nz-form-label nzRequired>Tag Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please enter Tag">
                    <nz-input-group>
                      <input type="text" nz-input formControlName="tag_name" required placeholder="Tag Name" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
          
                <nz-form-item>
                  <nz-form-label>Description</nz-form-label>
                  <nz-form-control>
                    <textarea formControlName="description" nz-input placeholder="Description" nzAutosize></textarea>
                  </nz-form-control>
                </nz-form-item>
          
                <nz-form-item>
                  <nz-form-label>Relevant Tags</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzMode="multiple"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Tag"
                      formControlName="related_tag_id"
                      class="w-100"
                    >
                      <nz-option *ngFor="let tag of approvedTags" [nzValue]="tag.tag_id" [nzLabel]="tag.tag_name"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
          
                <nz-form-item>
                  <nz-form-label nzRequired>Market Category</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzMode="multiple"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Category"
                      formControlName="tag_category_id"
                      class="w-100"
                    >
                      <nz-option *ngFor="let category of categoryList" [nzValue]="category.category_id" [nzLabel]="category.ticker_category"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
          
                <nz-form-item *ngIf="tagForm.controls['tag_category_id']?.value?.includes(1)">
                  <nz-form-label>Select Stocks</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzMode="multiple"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Stocks"
                      formControlName="related_stock_id"
                      class="w-100"
                    >
                      <nz-option *ngFor="let stock of similarStocks" [nzValue]="stock.symbol_id" [nzLabel]="stock.symbol"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
          
                <nz-form-item *ngIf="tagForm.controls['tag_category_id']?.value?.includes(2)">
                  <nz-form-label>Select Crypto</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzMode="multiple"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select Crypto"
                      formControlName="related_crypto_id"
                      class="w-100"
                    >
                      <nz-option *ngFor="let crypto of similarCryptos" [nzValue]="crypto.symbol_id" [nzLabel]="crypto.symbol"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
          
                <button nz-button nzType="primary" type="submit">{{ isEditMode ? 'Update' : 'Add' }} Tag</button>
              </form>
            </ng-container>
          </nz-modal>
          

    </nz-card>
</ng-template>