import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { QuillModule } from 'ngx-quill';
import { AdminLayoutComponent } from './Layout/Admin/admin-layout/admin-layout.component';
import { AuthorLayoutComponent } from './Layout/Author/author-layout/author-layout.component';
import { HomePageLayoutComponent } from './Layout/HomePage/home-page-layout/home-page-layout.component';
import { ReaderLayoutComponent } from './Layout/Reader/reader-layout/reader-layout.component';
import { SubscriberLayoutComponent } from './Layout/Subscriber/subscriber-layout/subscriber-layout.component';
import { AdminDashboardComponent } from './Modules/Admin/Dashboard/admin-dashboard/admin-dashboard.component';
import { SubscribersComponent } from './Modules/Admin/Subscription-Management/subscribers/subscribers.component';
import { SubscriptionPlansComponent } from './Modules/Admin/Subscription-Management/subscription-plans/subscription-plans.component';
import { AuthorsComponent } from './Modules/Admin/User-Management/authors/authors.component';
import { UsersComponent } from './Modules/Admin/User-Management/users/users.component';
import { LoginComponent } from './Modules/Auth/Login/login/login.component';
import { RegisterComponent } from './Modules/Auth/Register/register/register.component';
import { AuthorDashboardComponent } from './Modules/Author/Dashboard/author-dashboard/author-dashboard.component';
import { AddArticleComponent } from './Modules/Common/Content-Management/Articles/add-article/add-article.component';
import { ArticlesComponent } from './Modules/Common/Content-Management/Articles/articles/articles.component';
import { CategoriesComponent } from './Modules/Common/Content-Management/Categories/categories/categories.component';
import { DraftArticlesComponent } from './Modules/Common/Content-Management/Draft-Articles/draft-articles/draft-articles.component';
import { TagsComponent } from './Modules/Common/Content-Management/Tags/tags/tags.component';
import { MessagesComponent } from './Modules/Common/Message/messages/messages.component';
import { HomePageComponent } from './Modules/HomePage/home-page/home-page.component';
import { ReaderHomePageComponent } from './Modules/Reader/Panel/ReaderHomePage/reader-home-page/reader-home-page.component';
import { SubscriberHomePageComponent } from './Modules/Subscriber/Panel/SubscriberHomePage/subscriber-home-page/subscriber-home-page.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroModule } from './ng-zorro.module';
import { ReadArticleComponent } from './Modules/Common/Reading/read-article/read-article.component';
import { SectionHeadingComponent } from './Components/Common/UI Elements/section-heading/section-heading.component';
import { ArticleDisplayStyleOneComponent } from './Components/Common/UI Elements/article-display-style-one/article-display-style-one.component';
import { ArticleDisplayStyleThreeComponent } from './Components/Common/UI Elements/article-display-style-three/article-display-style-three.component';
import { ArticleDisplayStyleTwoComponent } from './Components/Common/UI Elements/article-display-style-two/article-display-style-two.component';
import { CategoryArticlesComponent } from './Modules/Common/Reading/category-articles/category-articles.component';
import { AuthorProfileComponent } from './Modules/Common/Reading/author-profile/author-profile.component';
import { HyphenatePipe } from './Pipes/Hyphen Pipe/hyphenate.pipe';
import { DeHyphenatePipe } from './Pipes/Hyphen Pipe/dehyphenate.pipe';
import { QuizzesComponent } from './Modules/Common/Quiz-Management/Quizzes/quizzes/quizzes.component';
import { QuestionsComponent } from './Modules/Common/Quiz-Management/Questions/questions/questions.component';
import { QuizCategoriesComponent } from './Modules/Common/Quiz-Management/Quiz Categories/quiz-categories/quiz-categories.component';
import { SmallSectionHeadingComponent } from './Components/Common/UI Elements/small-section-heading/small-section-heading.component';
import { CoursesComponent } from './Modules/Common/Course-Management/Courses/courses/courses.component';
import { SectionsComponent } from './Modules/Common/Course-Management/Sections/sections/sections.component';
import { CoursePreviewComponent } from './Modules/Common/Course-Management/Course-Preview/course-preview/course-preview.component';
import { CourseCategoriesComponent } from './Modules/Common/Course-Management/Course Categories/course-categories/course-categories.component';
import { LecturesComponent } from './Modules/Common/Course-Management/Lectures/lectures/lectures.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CourseHierarchyComponent } from './Modules/Common/Course-Management/Course-Preview/course-hierarchy/course-hierarchy.component';
import { AttendQuizComponent } from './Modules/Common/Quiz-Management/Quizzes/attend-quiz/attend-quiz.component';
import { AllModulesComponent } from './Modules/Admin/Permission/Module Setup/all-modules/all-modules.component';
import { AllRoleComponent } from './Modules/Admin/Permission/Role Setup/all-role/all-role.component';
import { NewPermissionComponent } from './Modules/Admin/Permission/Permission Setup/new-permission/new-permission.component';
import { FiminizeLayoutComponent } from './Layout/Fiminize Layout/fiminize-layout/fiminize-layout.component';
import { MainFiminizeLayoutComponent } from './Layout/main-fiminize-layout/main-fiminize-layout.component';
import { TruncatePipe } from './Pipes/truncate.pipe';
import { UserRoleSetUpComponent } from './Modules/Admin/Permission/User Role Setup/user-role-set-up/user-role-set-up.component';
import { RolePermissionSetUpComponent } from './Modules/Admin/Permission/Role Permissiom Setup/role-permission-set-up/role-permission-set-up.component';
import { AllCategoriesComponent } from './Modules/Common/Content-Management/Categories/all-categories/all-categories.component';
import { FetchArticleComponent } from './Modules/Common/Content-Management/Categories/fetch-article/fetch-article.component';
import { AuthorDetailsComponent } from './Modules/Common/Content-Management/author-details/author-details.component';
import { AllCoursesComponent } from './Modules/Common/Course-Management/all-courses/all-courses.component';
import { DiscussionComponent } from './Modules/Common/Content-Management/discussion/discussion.component';

import { AboutUsComponent } from './Modules/Common/Content-Management/about-us/about-us.component';
import { ArticleCardDesignComponent } from './Reusable Component/article-card-design/article-card-design.component';
import { PopularTagsComponent } from './Modules/Common/popular-tags/popular-tags.component';
import { BookmarkArticlesComponent } from './Modules/Common/bookmark-articles/bookmark-articles.component';
import { DiscussionForumComponent } from './Modules/Common/discussion-forum/discussion-forum.component';
import { RoomTopicsComponent } from './Modules/Common/Content-Management/discussion/room-topics/room-topics.component';
import { DicussiionForumComponent } from './Modules/Common/Content-Management/discussion/dicussiion-forum/dicussiion-forum.component';
import { MainLoginPageComponent } from './Modules/Auth/main-login-page/main-login-page.component';
import { IntelligenceComponent } from './Modules/Common/intelligence/intelligence.component';
import { DndModule } from 'ngx-drag-drop';
import { ArticleFilterSetUpComponent } from './Modules/Common/Content-Management/Articles/article-filter-set-up/article-filter-set-up.component';
import { ArticleTagComponent } from './Modules/Common/Content-Management/Tags/article-tag/article-tag.component';
import { CommingSoonComponent } from './Reusable Component/comming-soon/comming-soon.component';
import { MarqueComponent } from './Modules/Common/Content-Management/marque/marque.component';
import { DostExplainComponent } from './Modules/Common/Content-Management/dost-explain/dost-explain.component';
import { SymbolsComponent } from './Modules/Common/Content-Management/Articles/symbols/symbols.component';
import { AuthorSignUpComponent } from './Layout/Author Onbording/author-sign-up/author-sign-up.component';
import { DostDiaryComponent } from './Modules/Pages/dost-diary/dost-diary.component';
import { LookUpsComponent } from './Modules/Common/Content-Management/look-Ups/look-ups/look-ups.component';
import { LeaderBoardPointComponent } from './Modules/Common/Content-Management/leader-board-point/leader-board-point.component';
import { EditQuizComponent } from './Modules/Common/Quiz-Management/Quizzes/quizzes/edit-quiz/edit-quiz.component';
import { QuestionTableComponent } from './Modules/Common/Quiz-Management/Quizzes/quizzes/question-table/question-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthorManagementComponent } from './Layout/Author Onbording/author-management/author-management.component';
import { UserProfileComponent } from './Modules/Common/Reading/user-profile/user-profile.component';
import { FilterCommentsPipe } from './Pipes/filter-comments.pipe';
import { DostPortfolioComponent } from './Modules/Pages/dost-portfolio/dost-portfolio.component';
import { CommunityComponent } from './Modules/Pages/community/community.component';
import { SignupFlowComponent } from './Modules/Auth/signup-flow/signup-flow.component';
import { StocksPageComponent } from './Modules/Pages/stocks-page/stocks-page.component';
import { CryptoCoinPageComponent } from './Modules/Pages/crypto-coin-page/crypto-coin-page.component';
import { StockspageComponent } from './Modules/Common/Content-Management/Markets/stockspage/stockspage.component';
import { CryptopageComponent } from './Modules/Common/Content-Management/Markets/cryptopage/cryptopage.component';
import { DostAlertComponent } from './Modules/Common/Content-Management/Markets/dost-alert/dost-alert.component';
import { CompanyTagsComponent } from './Modules/Pages/company-tags/company-tags.component';
import { SectorsComponent } from './Modules/Pages/sectors/sectors.component';
import { ExchangesComponent } from './Modules/Pages/exchanges/exchanges.component';
import { IndustryComponent } from './Modules/Pages/industry/industry.component';
import { FAQsComponent } from './Modules/Common/Content-Management/Markets/faqs/faqs.component';
import { TickerRequestsComponent } from './Modules/Common/Content-Management/Markets/ticker-requests/ticker-requests.component';
import { ContactAddressPipe } from './Pipes/contact-address.pipe';
import { QuizAnswerDetailsComponent } from './Modules/Common/Content-Management/Markets/quiz-answer-details/quiz-answer-details.component';
import { AllAuthorsComponent } from './Modules/Pages/all-authors/all-authors.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    HomePageComponent,
    AdminDashboardComponent,
    AuthorDashboardComponent,
    ReaderHomePageComponent,
    SubscriberHomePageComponent,
    HomePageLayoutComponent,
    AuthorLayoutComponent,
    ReaderLayoutComponent,
    SubscriberLayoutComponent,
    LoginComponent,
    RegisterComponent,
    AddArticleComponent,
    ArticlesComponent,
    DraftArticlesComponent,
    CategoriesComponent,
    TagsComponent,
    MessagesComponent,
    UsersComponent,
    AuthorsComponent,
    SubscribersComponent,
    SubscriptionPlansComponent,
    ReadArticleComponent,
    SectionHeadingComponent,
    ArticleDisplayStyleOneComponent,
    ArticleDisplayStyleTwoComponent,
    ArticleDisplayStyleThreeComponent,
    CategoryArticlesComponent,
    AuthorProfileComponent,
    HyphenatePipe,
    DeHyphenatePipe,
    QuizzesComponent,
    QuestionsComponent,
    QuizCategoriesComponent,
    SmallSectionHeadingComponent,
    CoursesComponent,
    SectionsComponent,
    CoursePreviewComponent,
    CourseCategoriesComponent,
    LecturesComponent,
    CourseHierarchyComponent,
    AttendQuizComponent,
    AllModulesComponent,
    AllRoleComponent,
    NewPermissionComponent,
    FiminizeLayoutComponent,
    MainFiminizeLayoutComponent,
    TruncatePipe,
    UserRoleSetUpComponent,
    RolePermissionSetUpComponent,
    AllCategoriesComponent,
    FetchArticleComponent,
    AuthorDetailsComponent,
    AllCoursesComponent,
    DiscussionComponent,
    AboutUsComponent,
    ArticleCardDesignComponent,
    PopularTagsComponent,
    BookmarkArticlesComponent,
    DiscussionForumComponent,
    RoomTopicsComponent,
    DicussiionForumComponent,
    MainLoginPageComponent,
    IntelligenceComponent,
    ArticleFilterSetUpComponent,
    ArticleTagComponent,
    CommingSoonComponent,
    MarqueComponent,
    DostExplainComponent,
    SymbolsComponent,
    AuthorSignUpComponent,
    DostDiaryComponent,
    LookUpsComponent,
    LeaderBoardPointComponent,
    EditQuizComponent,
    QuestionTableComponent,
    AuthorManagementComponent,
    UserProfileComponent,
    FilterCommentsPipe,
    DostPortfolioComponent,
    CommunityComponent,
    SignupFlowComponent,
    StocksPageComponent,
    CryptoCoinPageComponent,
    StockspageComponent,
    CryptopageComponent,
    DostAlertComponent,
    CompanyTagsComponent,
    SectorsComponent,
    ExchangesComponent,
    IndustryComponent,
    FAQsComponent,
    TickerRequestsComponent,
    ContactAddressPipe,
    QuizAnswerDetailsComponent,
    AllAuthorsComponent,
    

    
  ],
  imports: [
    DndModule,
    NgZorroModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ClipboardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DragDropModule,
    QuillModule.forRoot({
      sanitize: true,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']                         // link and image, video
        ]
      }
    })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
