<ng-container>
  <app-admin-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>
<ng-template #content>
  <div class="d-flex">
    <nz-breadcrumb>
      <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
      <nz-breadcrumb-item>Marquee</nz-breadcrumb-item>
    </nz-breadcrumb>
    <button class="ms-auto mb-2" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon
        nzType="plus"></span>Add Marquee</button>
  </div>
  <!-- src/app/markup-line-table/markup-line-table.component.html -->

  <div class="row">
    <div class="col-md-6 mb-3">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Search Marquee" [(ngModel)]="searchQuery"
          (ngModelChange)="filterMarkupLines()">
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>

    </div>
  </div>

  <nz-card>
    <div class="table-responsive">
      <nz-table #basicTable [nzData]="markupLines" nzBordered>
        <thead>
          <tr>
            <th nzWidth="5%">Sr No</th>
            <th nzWidth="30%" [nzSortFn]="sortByMarqueeLine">Marquee Line</th>
            <th nzWidth="40%" [nzSortFn]="sortByArticle">Article</th>
            <th nzWidth="10%" [nzSortFn]="sortByStatus">Status</th>
            <th nzWidth="5%" [nzSortFn]="sortBySequence">Sequence</th>
            <th nzWidth="15%" [nzSortFn]="sortByDate">Created At</th>
            <th nzWidth="15%" [nzSortFn]="sortByDate">Created By</th>
            <th nzWidth="15%" [nzSortFn]="sortByDate">Updated By</th>

            <th nzWidth="15%" [nzSortFn]="sortByDate">Updated At</th>
            <th nzWidth="5%" nzAlign="center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of basicTable.data;  let i = index "
            (click)="fetchSingleMarkupLine(item.markup_line_id)">
            <td>{{ i + 1 }}</td>

            <td nzEllipsis>{{ item.markup_line }}</td>
            <td nzEllipsis>{{ item.artical_name}}</td>
            <td nzEllipsis>{{ item.status === 'Active' ? 'Yes' : 'No' }}</td>
            <td nzEllipsis>{{ item.sequence }}</td>
            <td nzEllipsis>{{ item.created_at ? (item.created_at | date: 'dd MMM y, h:mm a') : '--' }}</td>
            <td nzEllipsis>{{ item.created_by_string }}</td>
            <td nzEllipsis>{{ item.updated_at ? (item.updated_at | date: 'dd MMM y, h:mm a') : '--' }}</td>
            <td nzEllipsis>{{ item.updated_by_string }}</td>
            <td nzAlign="center" (click)="$event.stopPropagation()">
              <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this record?"
                (nzOnConfirm)="confirm(item.markup_line_id)" (nzOnCancel)="cancel()">
                <span nz-icon nzType="delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>

  <nz-drawer [nzVisible]="addDrawer" nzTitle="Add Marquee" (nzOnClose)="closeAddDrawer()">
    <ng-container *nzDrawerContent>

      <form nz-form nzLayout="vertical" [formGroup]="markupForm" (ngSubmit)="submitAddForm()">
        <nz-form-item>
          <nz-form-label nzRequired>Enter Marque Line</nz-form-label>
          <nz-form-control nzErrorTip="Marquee Line">
            <nz-input-group>
              <input type="text" nz-input formControlName="markup_line" required placeholder="Marquee Line" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired>Sequence</nz-form-label>
          <nz-form-control nzErrorTip="Please enter a valid sequence (digits only)">
            <nz-input-group>
              <input type="text" nz-input formControlName="sequence" required placeholder="Sequence" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>Select an Article</nz-form-label>
          <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select an Article"
            formControlName="artical_id">
            <nz-option *ngFor="let article of articles" [nzValue]="article.id" [nzLabel]="article.title"></nz-option>
          </nz-select>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired>Is Active?</nz-form-label>
          <nz-radio-group formControlName="active_status_id">
            <label nz-radio [nzValue]="1">Yes</label>
            <label nz-radio [nzValue]="2">No</label>
          </nz-radio-group>
        </nz-form-item>
        <button nz-button nzType="primary" type="submit">Submit</button>
      </form>


    </ng-container>
  </nz-drawer>
  <nz-drawer [nzVisible]="editDrawer" nzTitle="Edit Marquee" (nzOnClose)="closeEditDrawer()">
    <ng-container *nzDrawerContent>
      <form nz-form nzLayout="vertical" [formGroup]="markupForm" (ngSubmit)="submitEditForm()">
        <nz-form-item>
          <nz-form-label nzRequired>Enter Marquee Line</nz-form-label>
          <nz-form-control nzErrorTip="Please input the Marquee line">
            <input nz-input formControlName="markup_line" placeholder="Marquee Line" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired>Sequence</nz-form-label>
          <nz-form-control nzErrorTip="Please input the sequence">
            <input nz-input formControlName="sequence" placeholder="Sequence" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>Select an Article</nz-form-label>
          <nz-select formControlName="artical_id" nzPlaceHolder="Select an Article" class="w-100">
            <nz-option *ngFor="let article of articles" [nzValue]="article.id" [nzLabel]="article.title"></nz-option>
          </nz-select>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired>Status</nz-form-label>
          <nz-radio-group formControlName="active_status_id">
            <label nz-radio [nzValue]="1">Yes</label>
            <label nz-radio [nzValue]="2">No</label>
          </nz-radio-group>
        </nz-form-item>
        <button nz-button nzType="primary" type="submit">Submit</button>
      </form>
    </ng-container>
  </nz-drawer>
</ng-template>







