import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Quill from 'quill';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dost-portfolio',
  templateUrl: './dost-portfolio.component.html',
  styleUrls: ['./dost-portfolio.component.scss'],
})
export class DostPortfolioComponent implements OnInit {
  userId = localStorage.getItem('userId');
  sentiments: any[] = [];
  risks: any[] = [];
  horizons: any[] = [];
  trades: any[] = [];
  tickers: any[] = [];
  isAddDostAlertModalVisible = false;
  activeTabIndex = 0;
  watchLists: any[] = [];
  categoryList: any[] = [];
  watchlistTickers: any[] = [];
  alertType:any[]=[];
  watchlist: any[] = [];
  dostportfolioArtilces: any[] = [];
  dostPortfolioAlertList: any[] = [];
  drawerVisible = false;
  EditDrawer = false;
  isEditMode: boolean = false;
  tickerForm: FormGroup;

  categoryId: any;
  alertTypeId: any;

  storageUrl = environment.STORAGE_URL;
  similarCryptos: any[] = []; // To track if the modal is in edit mode
  similarStocks: any[] = [];
  stockCryptoData: any[] = [];
  roleId = localStorage.getItem('role_id') ?? '';
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private drawerService: NzDrawerService,
    private fb: FormBuilder
  ) {
    this.tickerForm = this.fb.group({
      symbol_id: ['', Validators.required],
      sentiment_id: [''],
      risk_id: [''],
      horizon_id: [''],
      trade_id: [''],
      is_public: [0],
      reminder: [0],
      review_date: [],
      watch_list_ticker_id: [null], // Required for edit mode
    });
  }
  dostAlertForm: FormGroup = new FormGroup({
    alert_type_id: new FormControl('', Validators.required),
    symbol_id: new FormControl(),
    text: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    is_diarypost:  new FormControl('', Validators.required),
    active_status_id:  new FormControl('', Validators.required),
  });
  postForm: FormGroup = new FormGroup({
    caption: new FormControl('', [Validators.required]),
    is_featured_post: new FormControl(),
    is_public: new FormControl(),
    symbol_id: new FormControl(''),
    comment: new FormControl(),
    diary_post_id:new FormControl(),

  });

  ngOnInit(): void {
    this.fetchWatchLists();
    this.callThreeFunction(1, 1);
    if (this.activeTabIndex === 0) {
      this.fetchSimilarStocks();
    } else if (this.activeTabIndex === 1) {
      this.fetchSimilarCryptos();
    }
    this.fetchDostAlertstype();
  }

  fetchWatchLists() {
    forkJoin([
      this.http.get(`${environment.API_URL}sentiment_dropdown`),
      this.http.get(`${environment.API_URL}risk_dropdown`),
      this.http.get(`${environment.API_URL}horizon_dropdown`),
      this.http.get(`${environment.API_URL}trade_dropdown`),
      this.http.get(`${environment.API_URL}ticker_dropdown`),
      this.http.post(`${environment.API_URL}fetch_watch_lists`, {
        user_id: this.userId,
      }),
    ]).subscribe(
      ([sentiments, risks, horizons, trades, tickers, watchLists]) => {
        this.sentiments = sentiments as any[];
        this.risks = risks as any[];
        this.horizons = horizons as any[];
        this.trades = trades as any[];
        this.tickers = tickers as any[];
        this.watchLists = watchLists as any[];
        this.fetchWatchlistTickers(1);
      },
      (error) => this.message.error('Failed to fetch data.')
    );
  }
  fetchDostAlertstype(): Promise<void> {

    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_dost_alert_type`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.alertType = response;
        },
        error: (error) => {
          console.error('Error: ', error);
         
          return reject();
        },
        complete: () => {
         
          return resolve();
        },
      });
    });
  }


  callThreeFunction(categoryID: any, alertID: any) {
    this.fetchWatchlistTickers(categoryID);
    this.fetchPortfoliosDostAlert(categoryID, alertID);
    this.fetchportfoliosarticles(categoryID);
  }
  onTabChange(tabIndex: number): void {
    this.categoryId = tabIndex === 0 ? 1 : 2;
    this.callThreeFunction(this.categoryId, 1);
    this.activeTabIndex = tabIndex;
  }

  onAlertTypeChange(tabIndex: number): void {
    this.alertTypeId = tabIndex === 0 ? 1 : 2;
    const categoryId = this.activeTabIndex === 0 ? 1 : 2;
  
    this.fetchPortfoliosDostAlert(categoryId, this.alertTypeId);
  }
  
  fetchPortfoliosDostAlert(categoryId: number, alertTypeId: number): Promise<void> {
    const requestData = {
      category_id: categoryId,
      alert_type_id: alertTypeId,
      user_id: this.userId,
    };

    const apiUrl = `${environment.API_URL}portfolios_dost_alert`;

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.dostPortfolioAlertList = response; // Adjust based on response structure
          resolve();
        },
        error: (error) => {
          console.error('Error fetching portfolio dost alert:', error);
          reject(error);
        },
      });
    });
  }

  fetchWatchlistTickers(categoryId: number): Promise<void> {
    const requestData = {
      category_id: categoryId,
      user_id: this.userId,
    };

    const apiUrl = `${environment.API_URL}fetch_watchlist_tickers`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.watchlistTickers = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }

  fetchportfoliosarticles(categoryId: number): Promise<void> {
    const requestData = {
      category_id: categoryId,
      user_id: this.userId,
    };

    const apiUrl = `${environment.API_URL}fetch_portfolio_articles`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.dostportfolioArtilces = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }

  cancel(): void {}
  deleteWatchlisTtickerId(id: number) {
    const apiUrl = `${environment.API_URL}delete_watch_list_ticker`;

    const requestData = {
      watch_list_ticker_id: id,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.fetchWatchlistTickers(this.activeTabIndex + 1);
        this.fetchportfoliosarticles(this.activeTabIndex + 1);
        this.fetchPortfoliosDostAlert(1, 1);
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  fetchSimilarStocks(): Promise<void> {
    const apiUrl = `${environment.API_URL}stocks_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // Assuming `this.watchlistTickers` is the list of tickers already in the watchlist
          this.watchlist = this.watchlistTickers.map((item: any) => item.symbol_id); // Extract symbol_id from watchlistTickers
          this.stockCryptoData = response; // Store the fetched stock/crypto data
        },
        error: (error) => {
          console.error('Error fetching stock data: ', error);
          reject();
        },
        complete: () => resolve(),
      });
    });
  }
  openDrawer(ticker: any = null): void {
    this.drawerVisible = true;
    this.isEditMode = !!ticker;  // If ticker is provided, set edit mode to true
  
    if (this.activeTabIndex === 0) {
      this.fetchSimilarStocks();
    } else if (this.activeTabIndex === 1) {
      this.fetchSimilarCryptos();
    }
  
    if (ticker) {
      // Populate the form if editing a ticker
      this.tickerForm.patchValue({
        watch_list_ticker_id: ticker.watch_list_ticker_id,
        symbol_id: ticker.symbol_id,
        sentiment_id: ticker.sentiment_id,
        risk_id: ticker.risk_id,
        horizon_id: ticker.horizon_id,
        trade_id: ticker.trade_id,
        is_public: ticker.is_public,
        reminder: ticker.reminder,
        review_date: ticker.review_date,
      });
    }
  }

  fetchSimilarCryptos(): Promise<void> {
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.watchlist = this.watchlistTickers.map(
            (item: any) => item.symbol_id
          );
          this.stockCryptoData = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  saveTicker(isEditMode: boolean): void {
    if (this.tickerForm.valid) {
      const endpoint = isEditMode
        ? `${environment.API_URL}edit_watch_list`
        : `${environment.API_URL}add_watch_list`;

        const reviewDate = this.tickerForm.get('review_date')?.value 
        ? new Date(this.tickerForm.get('review_date')?.value).toISOString().split('T')[0]
        : null;

      const payload = {
        ...this.tickerForm.value,
        user_id: this.userId,
        review_date: reviewDate,
        watch_list_id: isEditMode ? undefined : 1,
        is_public: this.tickerForm.get('is_public')?.value ?? 0,
        updated_by: isEditMode ? this.userId : undefined,
      };

      this.http.post(endpoint, payload).subscribe({
        next: () => {
          this.message.success(
            `Ticker ${isEditMode ? 'updated' : 'added'} successfully!`
          );
          this.closeDrawer();
          this.fetchWatchlistTickers(this.activeTabIndex + 1);
          this.fetchPortfoliosDostAlert(1, 1);
        },
        error: () =>
          this.message.error(
            `Failed to ${
              isEditMode ? 'update' : 'add'
            } the ticker. Please try again.`
          ),
      });
    }
  }
  addPost(): void {

    const apiUrl = `${environment.API_URL}add_diary_post`;
    const postObj = {
      user_id: localStorage.getItem('userId'),
      caption: this.postForm.value.caption,
      is_featured_post: this.postForm.value.is_featured_post,
      is_public: this.postForm.value.is_public,
      symbol_id: this.postForm.value.symbol_id,
    };

    this.http.post(apiUrl, postObj).subscribe({
      next: (response: any) => {
        this.message.success('Post added successfully');
      },
      error: (error) => {
        this.message.error('Error occurred, please try later');
        console.error('Error:', error);
      },
      complete: () => {
     
      
        this.postForm.reset(); // Reset the add post form
      },
    });
  }

  closeDrawer(): void {
    this.drawerVisible = false;
    this.tickerForm.reset();
    this.dostAlertVisible = false;
   this. isAddDostAlertModalVisible=false
  }
  addTobookmark(dostAlertID: number) {
    const isCurrentlyBookmarked = this.dostAlert.is_bookmark;
    const apiUrl = isCurrentlyBookmarked
      ? `${environment.API_URL}delete_dost_alert_bookmark`
      : `${environment.API_URL}add_bookmark`;

    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Toggle bookmark status
          this.dostAlert.is_bookmark = !isCurrentlyBookmarked;

          // Show appropriate message based on bookmark status
          if (this.dostAlert.is_bookmark) {
            this.message.success('Bookmark added successfully.');
          } else {
            this.message.success('Bookmark removed successfully.');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to update bookmark status.');
      },
    });
  }
  reportDostalert(dostAlertID: number) {
    const apiUrl = `${environment.API_URL}report_dost_alert`;
    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          if (this.dostAlert.is_bookmark) {
            this.message.success('Report');
          } else {
            this.message.success('Report');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to Report.');
      },
    });
  }
  dostAlert: any;
  dostAlertVisible = false;
  openDostAlertModal(alert: any) {
    this.dostAlertVisible = true;
    this.dostAlert = alert;
  }
  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
        [{ align: [] }],
        ['link', 'image', 'video'],
      ],

      clipboard: {
        matchVisual: false,
      },
    },
  };
  openAddDostAlertModal(): void {
    this.isAddDostAlertModalVisible = true;
  }
  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.watchlistTickers, event.previousIndex, event.currentIndex);
    // Optional: Make an API call to save the new order
    this.saveOrder();
  }

  saveOrder(): void {
    // Example: Post the updated order to the server
    const reorderedIds = this.watchlistTickers.map(ticker => ticker.id)
  }
  handleAdd(): void {
    if (this.dostAlertForm.valid) {
      // Add the user_id to the form data
      const formData = {
        ...this.dostAlertForm.value,
        user_id: this.userId 
      };
      const apiUrl = `${environment.API_URL}add_dost_alert`;    
      this.http.post(apiUrl, formData).subscribe({
        next: (response: any) => {
          console.log('Dost Alert added successfully', response);
          this.isAddDostAlertModalVisible = false;
          this.fetchPortfoliosDostAlert(1, 1);
        },
        error: (error) => {
          console.error('Error adding dost alert', error);
        }
      });
    } else {
      this.dostAlertForm.markAllAsTouched();
    }
  }
}

