import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stockspage',
  templateUrl: './stockspage.component.html',
  styleUrls: ['./stockspage.component.scss'],
})
export class StockspageComponent implements OnInit {
  allSymbols: any[] = [];


  dostExplanins: any[] = [];
  similarStocks: any[] = [];
  quizz: any[] = [];
  tags: any[] = [];
  sectors: any[] = [];
  industries: any[] = [];
  exchanges: any[] = [];
  
  fileList: NzUploadFile[] = []; // For nz-upload


  addDrawer: boolean = false;
  editDrawer: boolean = false;
  tableLoading: boolean = true;
  fallbackImage: string = 'assets/fallback.webp';
  userId:any =localStorage.getItem('userId');

  storageUrl = environment.STORAGE_URL;


  uploadedLogo: string | ArrayBuffer | null = null;
  uploadedLogoName: string = '';
 
  symbolLogoFile: File | null = null;
  symbolLogoPreview: string | ArrayBuffer | null = null;


  searchQuery: string = '';
  addSymbolForm: FormGroup = new FormGroup({
    symbol: new FormControl('', Validators.required), 
    name: new FormControl('', Validators.required), 
    active_status_id: new FormControl(''), 
    description: new FormControl('', Validators.required), 
    industry_id: new FormControl('', Validators.required), 
    exchange_id: new FormControl('', Validators.required), 
    sector_id: new FormControl(null, Validators.required), 
    similar_symbols: new FormControl(null),
    quiz_id: new FormControl(null),
    relevant_tags: new FormControl(null),
    dost_explains: new FormControl(null),
    symbol_logo: new FormControl('', Validators.required),
    category_id: new FormControl(1),
  });

  editSymbolForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    symbol: new FormControl(''),
    name: new FormControl(''),
    active_status_id: new FormControl(''),
    description: new FormControl(''),
    industry_id: new FormControl(''),
    exchange_id: new FormControl(''),
    sector_id: new FormControl(null),
    similar_symbols: new FormControl(null),
    quiz_id: new FormControl(null),
    relevant_tags: new FormControl(null),
    dost_explains: new FormControl(null),
    symbol_logo: new FormControl(''),
    category_id: new FormControl(1),
    symbol_id: new FormControl(''),
  });

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}

  ngOnInit(): void {
    this.fetchAllSymbols();
    // this.allDropdownFunctions();
     this.fetchInitialData();
 
    this.fetchDostExplanins();
    this.fetchSimilarStocks();
    this.fetchQuiz();
    this.fetchTags();
    this.fetchselector()
  }
  submitAddForm() {
    const apiUrl = `${environment.API_URL}add_symbol`;
    const formData = new FormData();
    const { similar_symbols, sector_id, dost_explains, relevant_tags, exchange_id, category_id, industry_id, symbol, name, description, active_status_id } = this.addSymbolForm.value;
  
    // Safely handle similar_symbols array
    if (similar_symbols && Array.isArray(similar_symbols)) {
      similar_symbols.forEach((value: any, index: number) => {
        formData.append(`similar_symbols[${index}]`, value);
      });
    }
  
    // Safely handle sector_id array
    if (sector_id && Array.isArray(sector_id)) {
      sector_id.forEach((value: any, index: number) => {
        formData.append(`sector_id[${index}]`, value);
      });
    }
  
    // Safely handle dost_explains array
    if (dost_explains && Array.isArray(dost_explains)) {
      dost_explains.forEach((value: any, index: number) => {
        formData.append(`dost_explains[${index}]`, value);
      });
    }
  
    // Safely handle relevant_tags array
    if (relevant_tags && Array.isArray(relevant_tags)) {
      relevant_tags.forEach((value: any, index: number) => {
        formData.append(`relevant_tags[${index}]`, value);
      });
    }
  
    // Append other form fields
    formData.append('exchange_id', exchange_id);
    formData.append('category_id', '1'); // Hardcoded category_id to 1
    formData.append('industry_id', industry_id);
    formData.append('user_id', this.userId);
    formData.append('symbol', symbol);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('active_status_id', active_status_id);
  
    // Append symbol_logo if uploaded
    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }
  
    // Submit form data
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols()
            .then(() => {
              this.message.success('Ticker added');
              this.addSymbolForm.reset();
              this.handleCancel();
            })
            .catch((error) => {
              this.message.error('Error occurred, please try later');
              console.log('Fetch Error:', error);
            });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  
  

  submitEditForm() {
    const apiUrl = `${environment.API_URL}edit_symbol`;
    const formData = new FormData();
    const { similar_symbols,symbol_id,quiz_id, sector_id, dost_explains, relevant_tags, exchange_id, category_id, industry_id, symbol, name, description, active_status_id } = this.editSymbolForm.value;
  
    // Safely handle similar_symbols array
    if (similar_symbols && Array.isArray(similar_symbols)) {
      similar_symbols.forEach((value: any, index: number) => {
        formData.append(`similar_symbols[${index}]`, value);
      });
    }
  
    // Safely handle sector_id array
    if (sector_id && Array.isArray(sector_id)) {
      sector_id.forEach((value: any, index: number) => {
        formData.append(`sector_id[${index}]`, value);
      });
    }
  
    // Safely handle dost_explains array
    if (dost_explains && Array.isArray(dost_explains)) {
      dost_explains.forEach((value: any, index: number) => {
        formData.append(`dost_explains[${index}]`, value);
      });
    }
  
    // Safely handle relevant_tags array
    if (relevant_tags && Array.isArray(relevant_tags)) {
      relevant_tags.forEach((value: any, index: number) => {
        formData.append(`relevant_tags[${index}]`, value);
      });
    }
  
    // Append other form fields
    formData.append('exchange_id', exchange_id);
    formData.append('category_id', category_id);
    formData.append('industry_id', industry_id);
    formData.append('user_id', this.userId);
    formData.append('quiz_id', quiz_id?? '');
    formData.append('symbol', symbol);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('active_status_id', active_status_id);
    formData.append('symbol_id', symbol_id);
    // Append symbol_logo if uploaded
    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }
  


    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.isEditVisible = false;
          this.fetchAllSymbols();
          this.handleCancel();
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }

  onFileChange(event: Event, isEdit: boolean = false): void {
    const file = (event.target as HTMLInputElement).files?.[0]; // Use optional chaining here
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result; // Use optional chaining here
        if (result) {
          this.symbolLogoPreview = result as string;
          this.symbolLogoFile = file; // Set the file to the class variable
          this.editSymbolForm.get('symbol_logo')?.setValue(file); // Set the file in the form control
        }
      };
      reader.readAsDataURL(file);
    }
  }
  removeUploadedLogo() {
    this.symbolLogoPreview = '';
  }

  currentEditQuizId?: number;
  openEditDrawer(symbolId: number) {
    this.isEditVisible = true;
    const selectedSymbol = this.allSymbols.find(
      (symbol) => symbol.symbol_id === symbolId
    );

    if (selectedSymbol) {
      this.editSymbolForm.patchValue({
        symbol: selectedSymbol.symbol,
        name: selectedSymbol.name,
        dost_explains: selectedSymbol.dost_explains,
        dost_alerts: selectedSymbol.dost_alerts,
        quiz_id: selectedSymbol.quiz_id,
        description: selectedSymbol.description,
        sector_id: selectedSymbol.sector_id,
        exchange_id: selectedSymbol.exchange_id,
        industry_id: selectedSymbol.industry_id,
        relevant_tags: selectedSymbol.relevant_tags,
        category_id: selectedSymbol.category_id,
        similar_symbols: selectedSymbol.similar_symbols,
        active_status_id: selectedSymbol.active_status_id,
        symbol_id: selectedSymbol.symbol_id,
        symbol_logo: selectedSymbol.symbol_logo, // Patch symbol_logo
      });

      this.symbolLogoPreview = selectedSymbol.symbol_logo
        ? `${this.storageUrl}${selectedSymbol.symbol_logo}`
        : null;

        console.log("Edit Symbol" ,  this.editSymbolForm);
        
      this.editDrawer = true; // Open the drawer
    }
  }

  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const industriesDropdown$ = this.fetchApiService.getIndustryDropdown();
      const ExchangeDropdown$ = this.fetchApiService.getExchangeDropdown();
      const CategoryDropdown$ = this.fetchApiService.getCategoryDropdown();
      forkJoin([
        industriesDropdown$,
        ExchangeDropdown$,
      
        CategoryDropdown$,
      ]).subscribe({
        next: ([
          indusTriesResponse,
          exchangereponse,
          sectorResponse,
          categories,
        ]: any) => {
          console.log('industriesDropdown: ', indusTriesResponse);
          console.log('ExchangeDropdown: ', exchangereponse);
          console.log('SectorDropdown ', sectorResponse);
          this.industries = indusTriesResponse;
          this.exchanges = exchangereponse;
          
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }
  fetchselector(): Promise<void> {
    this.tableLoading = true;
    const requestData = {
          sector_type_id: 1,
        };
    const apiUrl = `${environment.API_URL}sector_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl,requestData).subscribe({
        next: (response: any) => {
          this.sectors  = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.allSymbols = this.allSymbols.filter((symbol) =>
        symbol.symbol.toLowerCase().includes(query)
      );
    } else {
      // Reset the symbols array to show all symbols when search query is empty
      this.fetchAllSymbols(); // This will reload the symbols from the API
    }
  }

  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_all_stocks`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  allDropdownFunctions(){
    this.fetchDostExplanins();
    this.fetchQuiz();
    this.fetchSimilarStocks();
    this.fetchTags();
    this.fetchInitialData();
  }

  fetchDostExplanins(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}courses_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.dostExplanins = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchSimilarStocks(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}stocks_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.similarStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchQuiz(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_quizzes`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.quizz = response.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchTags(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}tags_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.tags = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_symbol`;

    const requestData = {
      symbol_id: [id],
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {
            this.message.success('Stock deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  cancel(): void {}

  isVisible = false;
  isEditVisible = false;
  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.isEditVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isEditVisible = false;
  }
  sortByTicker: NzTableSortFn<any> = (a, b) => a.symbol.localeCompare(b.symbol);
  sortByName: NzTableSortFn<any> = (a, b) => a.name.localeCompare(b.name);
  sortByStatus: NzTableSortFn<any> = (a, b) =>
    a.active_status_id - b.active_status_id;
  sortByLogo: NzTableSortFn<any> = (a, b) =>
    a.symbol_logo.localeCompare(b.symbol_logo );
  sortBySector: NzTableSortFn<any> = (a, b) => a.sector.localeCompare(b.sector);
  sortByExchange: NzTableSortFn<any> = (a, b) =>
    a.exchange.localeCompare(b.exchange);
  sortByIndustry: NzTableSortFn<any> = (a, b) =>
    a.industry.localeCompare(b.industry);
  sortByCategory: NzTableSortFn<any> = (a, b) =>
    a.category_name.localeCompare(b.category_name);
  sortByCreatedOn: NzTableSortFn<any> = (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  sortByUpdatedOn: NzTableSortFn<any> = (a, b) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  sortByUpdatedBy: NzTableSortFn<any> = (a, b) =>
    a.updated_by_name.localeCompare(b.updated_by_name);
  sortByUpdatedAt: NzTableSortFn<any> = (a, b) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
}
