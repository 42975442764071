<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
    <nz-breadcrumb-item>Article</nz-breadcrumb-item>
  </nz-breadcrumb>

  <div class="container">
   <div class="row">
    <div class="col-md-12">
      <div class="quote" *ngIf="userDetails.quote">
        <h2>"{{userDetails.quote}}"</h2>
      </div>
      <div class="profile-card">
        <div class="header-bar">
          <div class="livewire-logo">
            <img src="assets/app-icon.png" alt="" style="height: 5vh;">
          </div>
        </div>
        <div class="profile-content">
          <div class="row">
            <div class="col-auto">
              <img
                [src]="userDetails.profile_pic ? (storageUrl + userDetails.profile_pic) : 'assets/dummy-images/dummy_user_profile.jpg'"
                alt="{{ userDetails.name }}" class="profile-image mb-3" />
            </div>
            <div class="col">
              <div class="d-flex justify-content-between align-items-start mb-2">
                <div>
                  <h2 class="h3 mb-1">{{userDetails.name}}</h2>
                  <div class="title mb-2">{{userDetails.username}}</div>
                </div>
                <div>
                  <button nz-dropdown [nzDropdownMenu]="menu2"  (click)="followButton()" class="btn btn-follow text-white me-2" *ngIf="dostUserID !== userID">
                   
                   Notify Me
                  </button>
                  <nz-dropdown-menu #menu2="nzDropdownMenu">
                    <ul nz-menu>
                      <li nzMode="multiple" *ngFor="let contentlist of notificationContnetList" nz-menu-item>
                        <label nz-checkbox [(ngModel)]="contentlist.selected">
                          {{ contentlist.content }}
                        </label>
                      </li>
    
                    </ul>
    
                  </nz-dropdown-menu>
                  
                  <button (click)="followButton()" class="btn btn-follow text-white me-2" *ngIf="dostUserID !== userID">
                   
                    {{ userDetails.already_following ? 'Unfollow' : 'Follow' }}
                  </button>
                  
                  
                  <button class="btn btn-contact text-white">
                    <i class="bi bi-envelope"></i> CONTACT
                  </button>
                </div>
              </div>
  
              <p class="mb-3">
                {{userDetails.bio}}
                <a href="#" class="more-link">More</a>
              </p>
  
              <div class="stats">
                <span>{{userDetails.followers}} </span> FOLLOWERS
                <span class="mx-2">•</span>
                <span>{{userDetails.artical_count}}</span> ARTICLES
                <span class="mx-2">•</span>
                <span>6.8M</span> VIEWS
                <span class="mx-2">•</span>
                <span>100</span> DOSTEXPLAINS™
                <span class="mx-2">•</span>
                Contributor Since May 2024
              </div>
              <div class="row"  *ngIf="userDetails.user_id == userID">
               
                <div class="col-md-3">
                  <button routerLink="/articles" nz-button nzType="dashed" nzBlock>My Articles </button>

                </div>
                <div class="col-md-3" >
                  <button routerLink="/dostDiary" nz-button nzType="dashed" nzBlock>Add Post</button>

                </div>
                <div class="col-md-3">
                  <button (click)="openEditProfileDrawer()" nz-button nzType="dashed" nzBlock>
                    <span nz-icon nzType="user"></span>
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  

   </div>
    <div class="row mt-3">
      <div class="col-md-8">
        <div>
          <nz-tabset nzType="card" nzSize="large" (nzSelectedIndexChange)="fetchLatestArticles($event)">
            <nz-tab nzTitle="Latest"></nz-tab>
            <nz-tab nzTitle="Top"></nz-tab>
          </nz-tabset>
        </div>
        
        <app-article-card-design [articleData]="latestArticles" [showSlideButtons]="true"
       (refresh)="fetchLatestArticles(1)">
    </app-article-card-design>


        <div class="card border-light">
          <div class="card-header">
            <h2 class="card-title">Dost Diary Posts</h2>
          </div>
          <div class="card-body" style="height: 100vh; overflow-y: auto; border: 1px solid #e8e8e8; padding: 10px;">
            <div class="card mt-1" *ngFor="let post of posts">
              <div class="card-body">
                <p [innerHTML]="post.caption"></p>
              </div>
              <div class="card-footer">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                      <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                        [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                    </button>
                    <span class="me-2"><b>{{ post.like_count }}</b></span>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>
                    </button>
                    <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                      <i class="fa-solid fa-share-nodes"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-bookmark"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-exclamation"></i>
                    </button>

                    <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                      <i class="fa-solid fa-lock"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body" *ngIf="commentVisible[post.diary_post_id]">

                <div>
                  <nz-comment nzAuthor="{{ post.created_by_name }}" nzDatetime="{{ post.created_at | date: 'short' }}">
                    <nz-avatar nz-comment-avatar nzIcon="user"
                      nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                    <nz-comment-content>
                      <form [formGroup]="postForm">
                        <nz-input-group [nzSuffix]="suffixIconSearch">
                          <input type="text" nz-input formControlName="comment" placeholder="Enter post comment" />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                          <button type="submit" nz-button nzType="dashed" nzShape="circle">
                            <span nz-icon nzType="send"></span>
                          </button>
                        </ng-template>
                      </form>
                    </nz-comment-content>
                  </nz-comment>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card  border-light">
          <div class="card-header">
            <h2 class="card-title"> <span>DostPortfolio <sup>™</sup></span></h2>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <nz-tabset [(nzSelectedIndex)]="activeTabIndex" (nzSelectedIndexChange)="fetchAuthorDostportfolio($event)">
                <nz-tab nzTitle="Stocks"></nz-tab>
                <nz-tab nzTitle="Cryptos"></nz-tab>
              </nz-tabset>
              <nz-table #basicTable [nzData]="watchlistTickers">
                <thead>
                  <tr>
                    <th nzWidth="50%">{{ activeTabIndex === 0 ? 'Stock' : 'Crypto' }}</th>
                    <th nzWidth="120px">Updated</th>
                    <th nzWidth="120px">Sentiment</th>
                    <th nzWidth="120px">Risk</th>
                    <th nzWidth="120px">Horizon</th>
                    <th nzWidth="120px">Trade</th>
                    <th nzWidth="120px">Status</th>
                    <th *ngIf="dostUserID === userID" nzWidth="120px">Review Date</th>
                    <th  *ngIf="dostUserID === userID" nzWidth="120px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ticker of watchlistTickers">
                    <td>
                      <span class="bg-light fw-bolder p-2 px-3"
                        [routerLink]="[ticker.ticker_category === 'Crypto' ? '/crypto' : '/stocks', ticker.symbol]">{{ ticker.symbol
                        }}</span>
                    </td>
                    <td>{{ ticker.date }}</td>
                    <td>{{ ticker.sentiment_name || '-' }}</td>
                    <td>{{ ticker.risk_name || '-' }}</td>
                    <td>{{ ticker.horizon_name || '-'}}</td>
                    <td>{{ ticker.trade_name || '-' }}</td>
                    <td>{{ ticker.is_public === 1 ? 'Public' : 'Private' }}</td>
                    <td *ngIf="dostUserID === userID">{{ ticker.review_date | date: 'yyyy-MM-dd' }}</td>
                    <td *ngIf="dostUserID === userID" nzAlign="center" (click)="$event.stopPropagation()">
                      <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                        nzPopconfirmTitle="Are you sure you want to delete this record?"
                        (nzOnConfirm)="deleteWatchlisTtickerId(ticker.watch_list_ticker_id)">
                        <span nz-icon nzType="delete"></span>
                      </button>
                    
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-4">
        <div class="card border-light mb-4">
          <div class="card-header ">
            <h2 class="card-title d-flex justify-content-between align-items-center">
              <span>DostAlert<sup>™</sup></span>

            </h2>
          </div>

          <div class="card-content">
            <div *ngIf="authorDostAlerts && authorDostAlerts.length > 0">
              <nz-tabset nzType="card" (nzSelectedIndexChange)="fetchAuthorDostalerts($event + 1)">
                <nz-tab nzTitle="Fundamental "></nz-tab>
                <nz-tab nzTitle="Technical "></nz-tab>
              </nz-tabset>
            </div>
            <div *ngIf="authorDostAlerts.length > 0">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let alert of authorDostAlerts | slice:0:5">
                  <div class="row">
                    <div class="row align-items-center">
                      <p (click)="openDostAlertModal(alert)" class="mb-0">{{ alert.text }}</p>

                      <div class="d-flex align-items-center">
                        <nz-avatar nzSize="small"
                          [nzSrc]="alert.profile_pic ? (storageUrl + alert.profile_pic) : 'assets/profile-images/profile-image.png'"
                          nzIcon="user"></nz-avatar>
                        <span class="ms-2" [routerLink]="['/author-details', alert.created_by]">{{ alert.created_by_name
                          }}</span>
                      </div>
                    </div>
                  </div>

                </li>
              </ul>
              <nz-modal [nzWidth]="500" [nzFooter]="null" [(nzVisible)]="dostAlertVisible" nzTitle="DostAlert™"
                (nzOnCancel)="handleCancel()">
                <ng-container *nzModalContent>
                  <nz-card [nzActions]="[actionSetting, actionEllipsis]">
                    <nz-card-meta nzTitle="{{ dostAlert?.text }}">
                    </nz-card-meta>
                    <p [innerHTML]="dostAlert?.description"></p>
                  </nz-card>

                  <ng-template #actionSetting>

                    {{ dostAlert?.created_by_name }}
                  </ng-template>

                  <ng-template #actionEllipsis>

                    <i (click)="addTobookmark(dostAlert.dost_alert_id)" [class.fa-solid]="dostAlert.is_bookmark"
                      [class.fa-regular]="!dostAlert.is_bookmark" class="fa-bookmark">
                    </i>
                  </ng-template>
                </ng-container>
              </nz-modal>

            </div>



          </div>
        </div>

        <div class="card  border-light mb-4">
          <div class="card-header">
            <h2 class="card-title"> <span>DostExplains<sup>™</sup></span></h2>
          </div>
          <div class="card-body">
            <ul style="cursor: pointer;" class="list-group list-group-flush">
              <li [routerLink]="['/course',  'preview', explain.course_slug, explain.course_id]" class="list-group-item"
                *ngFor="let explain of dostExplains"> {{ explain.title }}</li>

            </ul>
          </div>
        </div>
        <!-- Quiz Library -->
        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Quiz Library</h2>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let quizLibrary of quizLibrary | slice:0:5">{{quizLibrary.title}}</li>
            </ul>
          </div>
        </div>
        <!-- Stocks Covering -->

        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Stocks Covering
            </h2>
          </div>
          <div class="card-body">
            <div class="expertise-tag" [routerLink]="['/stocks', stockcovering.stock]"
              *ngFor="let stockcovering of userDetails.stocks">{{stockcovering.stock}}
            </div>
          </div>
        </div>
        <!-- Crypto Covering -->
        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Crypto Covering
            </h2>
          </div>
          <div class="card-body">
            <div class="expertise-tag" [routerLink]="['/stocks', cryptoCoveing.crypto]"
              *ngFor="let cryptoCoveing of userDetails.crypto">{{cryptoCoveing.crypto}}
            </div>
          </div>
        </div>
        <div class="card border-light mb-4">
          <div class="card-header">
            <h2 class="card-title">Tags
            </h2>
          </div>
          <div class="card-body">
            <div [routerLink]="['/companytags', tags.tag_id]" class="expertise-tag"
              *ngFor="let tags of userDetails.relevant_tags">{{tags.tag_name}}
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ">
        <div class="card">
       <div class="card-body">
        <h2 class="section-title">ABOUT {{userDetails.name}}</h2>
        <div class="mb-4">
          <h2 class="info-label">BIO</h2>
          <p>{{userDetails.bio}}</p>
        </div>

        <div class="mb-4">
          <h2 class="info-label">EXPERTISE</h2>
          <div class="expertise-tag" *ngFor="let experties of userDetails.expertise">{{experties.expertise}}
          </div>

        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="mb-4">
              <h2 class="info-label">JOINED</h2>
              <p class="info-value">{{userDetails.joined_date | date: 'yyyy-MM-dd'}}</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-4">
              <h2 class="info-label">EXPERIENCE</h2>
              <p class="info-value">{{userDetails.experience}}</p>
            </div>


          </div>
          <div class="col-md-3">
            <div class="mb-4">
              <h2 class="info-label">LOCATION</h2>
              <p class="info-value">{{userDetails.location}}</p>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <a href="https://www.linkedin.com" target="_blank" class="text-dark me-3" aria-label="LinkedIn">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" class="text-dark me-3" aria-label="Twitter">
              <i class="fa-brands fa-square-x-twitter"></i>
            </a>
            <a href="https://www.youtube.com" target="_blank" class="text-dark me-3" aria-label="YouTube">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com" target="_blank" class="text-dark" aria-label="Instagram">
              <i class="fa-brands fa-instagram"></i>
            </a>
          </div>
        </div>
       </div>
        </div>
      </div>
    </div>
  </div>
  <nz-modal [nzFooter]="null" [nzWidth]="1000" [nzVisible]="isDrawerVisible" nzTitle="Edit Profile" nzPlacement="right"
    (nzOnCancel)="isDrawerVisible = false" [nzClosable]="true">
    <ng-container *nzModalContent>
      <form [formGroup]="editProfileForm" (ngSubmit)="submitProfileForm()">
        <div class="row">
          <div class="col-md-3 file-upload-container d-flex justify-content-between align-items-center">
            <nz-form-item class="d-flex align-items-center flex-grow-1">
              <label for="fileInput" class="d-flex align-items-center" style="cursor: pointer;">
                <input type="file" (change)="onFileChange($event)" accept="image/png, image/jpeg" id="fileInput"
                  style="display: none;" />
                <div class="me-2">
                  <span><i>Change Profile Picture</i></span>
                </div>
              </label>
            </nz-form-item>
          </div>
          <div class="col-md-3">
            <img height="80%" width="80%" nz-image [src]="imagePreview" alt="Profile Image Preview"
              class="img-preview nz-image-circle" />
            <button (click)="removeUploadedLogo()" nz-button nzDanger nzType="dashed" nzShape="circle">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>


          <div class="col-md-6">
            <nz-form-label nzFor="quote">Bio</nz-form-label>
            <nz-form-control>
              <textarea rows="4" nz-input formControlName="bio"></textarea>
            </nz-form-control>

          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <nz-form-label nzFor="name">Name</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="name" id="name" />
            </nz-form-control>
          </div>
          <div class="col-md-4">
            <nz-form-label nzFor="username">Username</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="username" id="username" />
            </nz-form-control>
          </div>
          <div class="col-md-4">
            <nz-form-label nzFor="quote">Quote</nz-form-label>
            <nz-form-control>
              <textarea rows="2" nz-input formControlName="quote"></textarea>
            </nz-form-control>

          </div>


        </div>
        <div class="row">

          <div class="col-md-4">
            <nz-form-item>
              <nz-form-label>
                Select Expertise
                <span class="ms-1" nz-icon nzType="question-circle" nzTheme="outline" nz-tooltip
                  nzTooltipTitle="Once updated, you will not be able to edit again for 3 months.">
                </span>
              </nz-form-label>

              <nz-select [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch
                nzPlaceHolder="Select Expertise" formControlName="expertise_id">

                <nz-option *ngFor="let expert of expertiseList" [nzValue]="expert.expertise_id"
                  [nzLabel]="expert.expertise">
                </nz-option>

              </nz-select>
            </nz-form-item>
          </div>

          <div class="col-md-4">

            <nz-form-label nzFor="location">Location</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="location" id="location" />
            </nz-form-control>

          </div>
          <div class="col-md-4">
            <nz-form-item>
              <nz-form-label>Select experience</nz-form-label>
              <nz-select class="w-100" nzShowSearch nzPlaceHolder="Select Experience" formControlName="experience_id">
                <nz-option *ngFor="let workExpeirence of experienceList" [nzValue]="workExpeirence.experience_id"
                  [nzLabel]="workExpeirence.experience"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <nz-form-item>

              <nz-form-label>Select Stocks To Cover</nz-form-label>
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear nzPlaceHolder="Select stocks"
                formControlName="stocks_covering" class="w-100">
                <nz-option *ngFor="let stocks of stockList" [nzValue]="stocks.symbol_id"
                  [nzLabel]="stocks.symbol"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Select a Category</nz-form-label>
              <nz-form-control nzErrorTip="Select category!">
                <nz-tree-select style="width: 100%;" nzPlaceHolder="Select Category" formControlName="category_id"
                  [nzNodes]="categories" [nzDefaultExpandAll]="true" [nzAllowClear]="true" [nzMultiple]="true">
                </nz-tree-select>
              </nz-form-control>
            </nz-form-item>

          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Select Crypto To Cover</nz-form-label>
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear nzPlaceHolder="Select Crypto"
                formControlName="crypto_covering" class="w-100">
                <nz-option *ngFor="let cryptos of cryptoList" [nzValue]="cryptos.symbol_id"
                  [nzLabel]="cryptos.symbol"></nz-option> </nz-select>
            </nz-form-item>
          </div>

          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label>Select Tags To Cover</nz-form-label>
              <nz-select nzMode="multiple" nzShowSearch nzAllowClear nzPlaceHolder="Select Tag"
                formControlName="relevant_tags" class="w-100">
                <nz-option *ngFor="let tags of approvedTags" [nzValue]="tags.tag_id"
                  [nzLabel]="tags.tag_name"></nz-option> </nz-select>
            </nz-form-item>
          </div>
        </div>
        <div class="drawer-footer" align="end">
          <button nz-button nzType="primary" type="submit">Update</button>
          <button nz-button nzType="default" (click)="isDrawerVisible = false">Cancel</button>
        </div>
      </form>

    </ng-container>

  </nz-modal>
</app-main-fiminize-layout>