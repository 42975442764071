<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>


<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Articles</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" nz-button nzType="default" (click)="fetchInitialData(0)"><span nz-icon></span>Draft
            Articles</button>
        <button class="ms-3 mb-2" nz-button nzType="default" (click)="fetchInitialData(1)"><span
                nz-icon></span>Published
            Articles</button>
        <button class="ms-3 mb-2" nz-button nzType="default" (click)="fetchInitialData()"><span nz-icon></span>All
            Articles</button>
        <button class="ms-3 mb-2" (click)="addArticleID()" nz-button nzType="primary"><span nz-icon
                nzType="plus"></span>Add
            Articles</button>
    </div>

    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Article" [(ngModel)]="searchQuery"
                    (ngModelChange)="filterArticles()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>


    <nz-card>

        <div class="table-responsive">
            <nz-table #fixedTable [nzData]="filteredArticles" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                       
                        <th  nzWidth="100px" nzAlign="center"></th>
                        <th   nzWidth="50px">Sr.no</th>
                        <th  nzWidth="20%" [nzSortFn]="sortByTitle">Title</th>
                        <th nzAlign="center" [nzSortFn]="sortByIsFreeArticle">InvestDost+</th>
                        <th nzAlign="center" [nzSortFn]="sortByCategory">Categories</th>
                        <th nzAlign="center" [nzSortFn]="sortByStatus">Status</th>
                        <th nzAlign="center" [nzSortFn]="sortByTag">Tag(s)</th>
                        <th nzAlign="center" [nzSortFn]="sortBySymbol">Ticker</th>
                        <th nzAlign="center" [nzSortFn]="sortByCreatedBy">Created By</th>
                        <th nzAlign="center" [nzSortFn]="sortByUpdatedBy">Updated By</th>
                        <th nzAlign="center" [nzSortFn]="sortByPublishDate">Created On</th>
                        <th nzAlign="center" [nzSortFn]="sortByUpdatedAt">Updated On</th>
                        <th  nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let article of fixedTable.data; let i = index" (click)="openEditDrawer(article.id)">
                        
                        <td nzAlign="center" (click)="$event.stopPropagation()">
                            <a [routerLink]="['/article', article.slug, article.id]" target="_blank" nz-button
                                nzType="default" nzShape="circle" nz-tooltip="View Article" nzTooltipPlacement="right">
                                <i class="fa-solid fa-up-right-from-square"></i>
                            </a>
                        </td>
                        <td>{{ i + 1 }}</td>
                        <td nzEllipsis>{{ article.title }}</td>
                        <td nzEllipsis>{{ article.is_free_artical === 0 ? 'No' : ' Yes' }}</td>
                        <td>{{ article.category_string ? article.category_string : '--' }}</td>
                        <td nzEllipsis>{{ article.status }}</td>
                        <td nzEllipsis>{{ article.tag_names }}</td>
                        <td nzEllipsis>{{ article.symbol_string }}</td>
                        <td nzEllipsis>{{ article.created_by_string }}</td>
                        <td nzEllipsis>{{ article.updated_by_string }}</td>
                        <td>{{ article.publish_date | date: 'yyyy-MM-dd' }}</td>
                        <td>{{ article.updated_at | date: 'yyyy-MM-dd' }}</td>
                        <td nzAlign="center" (click)="$event.stopPropagation()">
                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                (nzOnConfirm)="confirm(article.id)" (nzOnCancel)="cancel()">
                                <span nz-icon nzType="delete"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer [nzVisible]="editDrawer" nzWidth="90%" nzTitle="Edit Article" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>
                <nz-spin *ngIf="editFormLoading" class="position-absolute start-50 top-50" nzSimple></nz-spin>
                <form *ngIf="!editFormLoading" [formGroup]="editArticleForm" (ngSubmit)="onSubmit()" nz-form
                    nzLayout="vertical">

                    <div class="row">
                        <div class="col-md-7">
                            <nz-card>
                                <nz-row class="mb-2">
                                    <nz-form-control nzErrorTip="Please enter title">
                                        <nz-input-group>
                                            <input type="text" nz-input formControlName="title" required
                                                placeholder="Title" />
                                        </nz-input-group>
                                    </nz-form-control>

                                </nz-row>

                                <div *ngFor="let editor of Content; let i = index" class="editor-container">

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="d-flex">
                                                <nz-select nzShowSearch [nzAllowClear]="true" *ngIf="i !== 0" nzPlaceHolder="Select Section Type"
                                                    style="width: 100%;" [(ngModel)]="editor.section_type_id" [ngModelOptions]="{ standalone: true }">
                                                    <nz-option *ngFor="let type of sectionTypes" [nzValue]="type.section_type_id"
                                                        [nzLabel]="type.section_type">
                                                    </nz-option>
                                                </nz-select>
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-4" *ngIf="editor.section_type_id !== 1 && editor.section_type_id !== null && i !== 0">
                                            <nz-form-item>
                                                <nz-select [nzAllowClear]="true" nzMode="multiple" style="width: 100%;" nzPlaceHolder="Select Stocks"
                                                    [(ngModel)]="editor.symbol_id" [ngModelOptions]="{ standalone: true }">
                                                    <nz-option *ngFor="let item of similarStocks" [nzValue]="item.symbol_id"
                                                        [nzLabel]="item.symbol"></nz-option>
                                                </nz-select>
                                            </nz-form-item>
                                        </div>
                                    
                                        <div class="col-md-4" *ngIf="editor.section_type_id !== 1 && editor.section_type_id !== null && i !== 0">
                                            <nz-form-item>
                                                <nz-select [nzAllowClear]="true" nzMode="multiple" style="width: 100%;" nzPlaceHolder="Select Crypto"
                                                    [(ngModel)]="editor.symbol_id" [ngModelOptions]="{ standalone: true }">
                                                    <nz-option *ngFor="let item of similarCryptos" [nzValue]="item.symbol_id"
                                                        [nzLabel]="item.symbol"></nz-option>
                                                </nz-select>
                                            </nz-form-item>
                                        </div>
                                    
                                        <div class="col-md-1 d-flex justify-content-end">
                                            <button  nzType="dashed" nz-button nzDanger *ngIf="i!==0" nzShape="circle" nz-popconfirm
                                                (nzOnConfirm)="delelteArticle(editor.artical_section_id)"
                                                nzPopconfirmTitle="Are you sure you want to delete this section?" (nzOnCancel)="cancel()">
                                                <span nz-icon nzType="delete"></span>
                                            </button>
                                        </div>
                                    </div>
                                    
                                    <nz-form-item class="mt-2">
                                        <nz-form-control nzErrorTip="Please write something here!">
                                            <quill-editor class="resizable-quill-editor" [modules]="editorOptions"
                                                (input)="updateCharacterCount()" [sanitize]="false"
                                                name="section_content" [(ngModel)]="editor.section_content"
                                                [ngModelOptions]="{ standalone: true }"
                                                placeholder="Enter your content here..."
                                                style="width: 100%;"></quill-editor>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <div class="text-end">
                                    <button nz-button nzType="primary" type="button" (click)="addRichTextEditor()">Add
                                        Section</button>
                                </div>
                            </nz-card>


                        </div>
                        <div class="col-md-5">
                            <nz-card>
                                <div class="d-flex bd-highlight">
                                    <div class="p-2 w-100 bd-highlight">
                                        <nz-form-label class="flex-shrink-0">Status</nz-form-label>
                                        <nz-radio-group formControlName="is_published"
                                            class="flex-grow-1 d-flex align-items-center">
                                            <label nz-radio [nzValue]="0" class="mr-2">Draft</label>
                                            <label nz-radio [nzValue]="1" class="mr-2">Published</label>
                                        </nz-radio-group>
                                    </div>
                                    <div class="p-2 flex-shrink-1 bd-highlight">
                                        <button nz-button nzType="primary" type="submit">Update Article</button>
                                    </div>
                                </div>

                                <nz-divider></nz-divider>

                                <nz-form-item *ngIf="roleID !== '3'">
                                    <nz-form-label>Is this for InvestDost+?</nz-form-label>
                                    <nz-radio-group formControlName="is_free_artical">
                                        <label nz-radio [nzValue]="FreeArticle">Yes</label>
                                        <label nz-radio [nzValue]="PaidArticle">No</label>
                                    </nz-radio-group>
                                </nz-form-item>
                                <div class="row">
                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-form-label>Select Post Type</nz-form-label>
                                                <nz-select nzShowSearch [nzAllowClear]="true" formControlName="post_type"
                                                    nzPlaceHolder="Select a Post Type">
                                                    <nz-option *ngFor="let item of postTypes"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-form-label>Select a Sentiment</nz-form-label>
                                                <nz-select nzShowSearch [nzAllowClear]="true" formControlName="sentiments"
                                                    nzPlaceHolder="Select a Sentiment">
                                                    <nz-option *ngFor="let item of sentiments"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Select category!">
                                                <nz-form-label>Select a category</nz-form-label>
                                                <nz-tree-select 
                                                    style="width: 100%;" 
                                                    nzPlaceHolder="Select Category" 
                                                    formControlName="category_id"
                                                    [nzMaxTagCount]="3" 
                                                    [nzNodes]="categories" 
                                                    [nzDefaultExpandAll]="true" 
                                                    [nzAllowClear]="true"
                                                    [nzMultiple]="true" 
                                                    (ngModelChange)="onChange($event)">
                                                </nz-tree-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Select Ticker">
                                                <nz-form-label>Select Ticker</nz-form-label>
                                                <nz-select nzShowSearch formControlName="symbol_id" [nzMaxTagCount]="3"
                                                    nzMode="multiple" nzPlaceHolder="Please select">
                                                    <nz-option *ngFor="let item of allSymbols"
                                                        [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div> -->
                                    
                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-label>Select Stock</nz-form-label>
                                            <nz-select [nzAllowClear]="true" nzMode="multiple" style="width: 100%;" nzPlaceHolder="Select Stocks"
                                              formControlName="symbol_id">
                                                <nz-option *ngFor="let item of similarStocks" [nzValue]="item.symbol_id"
                                                    [nzLabel]="item.symbol"></nz-option>
                                            </nz-select>
                                        </nz-form-item>
                                    </div>
                                
                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-label>Select Crypto</nz-form-label>
                                            <nz-select [nzAllowClear]="true" nzMode="multiple" style="width: 100%;" nzPlaceHolder="Select Crypto"
                                              formControlName="symbol_id">
                                                <nz-option *ngFor="let item of similarCryptos" [nzValue]="item.symbol_id"
                                                    [nzLabel]="item.symbol"></nz-option>
                                            </nz-select>
                                        </nz-form-item>
                                    </div>
                                
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <nz-form-item>
                                            <nz-form-label>Quiz</nz-form-label>
                                            <nz-form-control>
                                                <nz-select nzShowSearch nzAllowClear formControlName="quiz_id" nzPlaceHolder="Select a Quiz">
                                                    <nz-option *ngFor="let quiz of quizzes" [nzValue]="quiz.id"
                                                        [nzLabel]="quiz.title"></nz-option>
                                                </nz-select>

                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Slug</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <input type="text" nz-input formControlName="slug"
                                                placeholder="enter-title-slug" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label>Tags <span nz-popover
                                            nzPopoverContent="You can add multiple tags by typing a tag and pressing the 'Enter' key."
                                            class="ms-2" nz-icon nzType="question-circle"
                                            nzTheme="outline"></span></nz-form-label>
                                    <nz-select formControlName="tag_id" nzMode="tags"   nzPlaceHolder="Please select">
                                        <nz-option *ngFor="let option of allTags"   [nzLabel]="option.tag_name"
                                            [nzValue]="option.tag_id"></nz-option>
                                    </nz-select>
                                </nz-form-item>

                                <nz-form-item *ngIf="featuredImagePresent">
                                    <nz-form-label>Featured Image</nz-form-label>
                                    <div class="border p-2 d-flex justify-content-between align-items-center">
                                        <img style="cursor: pointer;" nz-image width="50px" height="50px"
                                            [nzSrc]="featuredImagePath" alt="Uploaded Image"
                                            [nzFallback]="fallbackImage" />
                                        <button (click)="removeSelectedImage()" nz-button nzDanger nzType="dashed"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>
                                    </div>
                                </nz-form-item>
                                <nz-form-item *ngIf="!featuredImagePresent">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Featured Image</span>
                                    </div>

                                    <label *ngIf="!showImage" for="fileInput" class="file-upload-container">
                                        <input type="file" (change)="onFileSelected($event)"
                                            id="fileInput" #fileInput style="display: none;" />
                                        <div *ngIf="!selectedFile">
                                            <span>Upload Featured Image</span>
                                        </div>

                                    </label>
                                    <span><i><strong>File Size:</strong> Keep it under 1MB.</i></span>
                                    <div *ngIf="showImage"
                                        class="border p-2 d-flex justify-content-between align-items-center">
                                        <img style="cursor: pointer;" nz-image width="250px" height="250px"
                                            [nzSrc]="imageSrc" alt="Uploaded Image" />
                                        <button (click)="removeSelectedImage()" nz-button nzDanger nzType="dashed"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>
                                    </div>
                                    <span><i>{{selectedFile?.['name']}}</i></span>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label>Meta Description</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>

                                            <textarea rows="5" nz-input formControlName="meta_description"
                                                placeholder="Enter Meta description"></textarea>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>



                            </nz-card>

                        </div>
                    </div>
                </form>

            </ng-container>
        </nz-drawer>
    </nz-card>
</ng-template>