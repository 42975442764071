<nz-layout style="min-height: 100vh;">
  <nz-sider [nzWidth]="290" nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">

    <div class="mx-auto d-flex align-items-center bg-white px-4 " style="height: 64px; background-color: f8f9ff;"
      routerLink="/home-page">

      <img class="sidebar-collapse-logo" *ngIf="isCollapsed" [src]="sidebarCollapseLogo" alt="Invest Dost Logo">
      <img class="sidebar-expand-logo" *ngIf="!isCollapsed" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
    </div>
    <div class="sidebar-List">
      <ul class="menu-list" nz-menu nzMode="inline">

        <li routerLink="/home-page" nzSelected [routerLinkActive]="['active']" nz-menu-item
          [nzTooltipTitle]="isCollapsed ? 'Home': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\Home-PNG.png" alt="">
          <span>Home</span>

        </li>

        <li routerLink="/intelligence" [routerLinkActive]="['active']" nz-menu-item
          [nzTooltipTitle]="isCollapsed ? 'Intelligence': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\Intelligence.png" alt="">
          <span>Intelligence </span>
        </li>

        <li routerLink="/dostDiary" [routerLinkActive]="['active']" nz-menu-item
          [nzTooltipTitle]="isCollapsed ? 'DostDiary': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets/icons/ideahub_PNG.png" alt="">
          <span>DostDiary<sup>™</sup></span>
        </li>

        <li nz-menu-item routerLink="/dostPortfolio" [routerLinkActive]="['active']"
          [nzTooltipTitle]="isCollapsed ? 'DostPortfolio': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\compass.png" alt="">
          <span>DostPortfolio<sup>™</sup></span>
        </li>

        <li nz-menu-item routerLink="/community" [routerLinkActive]="['active']"
          [nzTooltipTitle]="isCollapsed ? 'Community': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\diary.png" alt="">
          <span>Community </span>
        </li>

        <!-- routerLink="/admin_dashboard" -->
        <li nz-menu-item routerLink="/all-courses" [routerLinkActive]="['active']"
          [nzTooltipTitle]="isCollapsed ? 'Education': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\Bookmark-PNG.png" alt="">
          <span>Education </span>
        </li>
        <li nz-menu-item routerLink="/all-courses" [routerLinkActive]="['active']"
          [nzTooltipTitle]="isCollapsed ? 'DostClubs': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\user.png" alt="">
          <span>DostClubs </span>
        </li>
        <li nz-menu-item routerLink="/all-courses" [routerLinkActive]="['active']"
          [nzTooltipTitle]="isCollapsed ? 'Profile': null" nzTooltipPlacement="right" nz-tooltip>
          <img class="icons" src="assets\icons\user.png" alt="">
          <span>Profile </span>
        </li>
      </ul>
      <div class="popular-tags" *ngIf="!isCollapsed">
        <h1>Popular</h1>
        <div class="row">
          <div>
           
            <a href="" [routerLink]="['/companytags', tag]" href="" class="mt-5" *ngFor="let tag of popularTagList">
              <nz-tag [nzColor]="'yellow'"  class="mx-2 mt-1 py-1 rounded" >{{tag }}</nz-tag>
      
            </a>
          </div>

        </div>
      </div>
    </div>


  </nz-sider>

  <nz-layout>
    <nz-header>
      <div class="d-flex align-items-center">
        <span class="trigger p-2" (click)="isCollapsed = !isCollapsed">
          <i class="fa-solid" [ngClass]="{'fa-chevron-right': isCollapsed, 'fa-chevron-left': !isCollapsed}"></i>
        </span>
        <div class="ms-auto">
          <button *ngIf="roleId" nz-button nzTheme="fill" nzType="primary" nzShape="circle"><span nz-icon
              nzType="bell"></span></button>
          <button class="ms-2"  *ngIf="['1', '2','4', '5'].includes(roleId)" nz-button nzType="default"
            [routerLink]="['/admin_dashboard']">Dashboard</button>
   
          <button (click)="openAddDrawer()"
             *ngIf="!roleId" nz-button nzShape="round">
            <span> <i class="fa-solid fa-user"></i> Log In</span>
          </button>
          <b class="ms-2">{{ displayName }}</b>

          <button class="me-5" nz-button nzShape="round"
             *ngIf="!roleId" nz-button nzType="default"
            (click)="showModal()"><span>Sign Up</span></button>
        </div>
        <nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" (nzOnCancel)="handleCancel()">
          <ng-container *nzModalContent>
            <app-signup-flow></app-signup-flow>
          </ng-container>
        </nz-modal>

        <nz-drawer [nzVisible]="addDrawer" nzWidth="40%" nzTitle="Login" (nzOnClose)="closeAddDrawer()">
          <ng-container *nzDrawerContent>
            <form nz-form nzLayout="vertical" [formGroup]="loginForm" (ngSubmit)="submitForm()">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="username">Username</nz-form-label>
                <nz-form-control nzSpan="15">
                  <nz-input-group nzPrefixIcon="user">
                    <input type="text" nz-input formControlName="username" placeholder="Username" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzRequired nzFor="password">Password</nz-form-label>
                <nz-form-control nzSpan="15" nzErrorTip="Please input your Password!">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                      placeholder="Password" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #suffixTemplate>
                  <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    (click)="passwordVisible = !passwordVisible"></span>
                </ng-template>
              </nz-form-item>



              <div nz-row class="mb-4">
                <div nz-col [nzSpan]="7">
                  <label nz-checkbox formControlName="remember">
                    <span>Remember me</span>
                  </label>
                </div>
                <div nz-col [nzSpan]="8">
                  <a class="float-end">Forgot password?</a>
                </div>
              </div>
              <button nz-button nzType="primary" type="submit">Log in</button>
              <p class="mt-3"> Or
                <a class="ps-0" nz-button nzType="link" routerLink="/register">register now!</a>
              </p>

            </form>

          </ng-container>
        </nz-drawer>




        <nz-avatar *ngIf="roleId" nz-dropdown [nzDropdownMenu]="avatarDropDownMenu" [nzSrc]="storageUrl + '/' + profilePhoto"
          class="me-5 ms-3 d-flex justify-content-center align-items-center" [nzSize]="40" nzIcon="user"></nz-avatar>
        <nz-dropdown-menu #avatarDropDownMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item [routerLink]="['/account-settings']">
              <span nz-icon nzType="setting" nzTheme="outline" class="me-3"></span> Account Settings
            </li>
            <li nz-menu-item [routerLink]="['/bookmark-articles']" class="me-3">
              <i class="fa-solid fa-bookmark me-3"></i> My Bookmark
            </li>
            <li nz-menu-item (click)="myprofile()">
              <span nz-icon nzType="user" nzTheme="outline" class="me-3"></span> My Profile
            </li>
            <nz-divider class="m-0"></nz-divider>
            <li nz-menu-item (click)="logout()">
              <span nz-icon nzType="logout" nzTheme="outline" class="me-3"></span> Logout
            </li>
            <nz-divider class="m-0"></nz-divider>
          </ul>

        </nz-dropdown-menu>
      </div>

    </nz-header>
    <nz-content class="border-bottom" style="padding-right: 3rem;padding-left: 3rem;">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </nz-content>
    <nz-footer>
      <div class="d-flex">
        <span>&#169; {{ currentYear }} Version 1.0.3 </span>
        <span class="ms-auto"> InvestDost | Development | Designed By
          <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
            Technologies</a>
        </span>
        <button nz-button nzType="primary" class="ms-2" routerLink="/author_signUp">Author Sign Up</button>

      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>