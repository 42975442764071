<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>
<ng-template #content>
    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Ticker Requests</nz-breadcrumb-item>
        </nz-breadcrumb>
    </div>
    <nz-card>
        <div style="margin-bottom: 16px;">
            <div class="row">
                <div class="col-md-6">
                    <nz-input-group nzSearch>
                        <input nz-input placeholder="Search requests..." [(ngModel)]="searchValue" (ngModelChange)="search()" />
                      </nz-input-group>
                </div>
            </div>
           
          </div>
          <nz-table
          #basicTable
          [nzData]="filteredTickerRequestList"
          [nzLoading]="tableLoading"
          [nzBordered]="true"
         
        >
          <thead>
            <tr>
              <th nzWidth="5%">Symbol</th>
              <th nzWidth="5%">Category</th>
              <th nzWidth="25%">Request Type</th>
              <th nzWidth="5%">Status</th>
              <th nzWidth="10%">Request By</th>
              <th nzWidth="10%">Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let request of basicTable.data">
              <td>{{ request.symbol }}</td>
              <td>{{ request.category }}</td>
              <td>
                <ng-container *ngFor="let type of request.request_types; let last = last">
                  {{ type }}<span *ngIf="!last">, </span>
                </ng-container>
              </td>
              <td>{{ request.status === 0 ? 'Pending' : 'Completed' }}</td>
              <td>{{ request.request_by }}</td>
              <td>{{ request.created_at | date: 'yyyy-MM-dd' }}</td>
            </tr>
          </tbody>
        </nz-table>
    </nz-card>
</ng-template>
