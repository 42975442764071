import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  allFollowwerLists: any[] = [];
  allLikePosts:any[]=[]
  userId = localStorage.getItem('userId');
  allLikedArticles:any[]=[];
  storageUrl = environment.STORAGE_URL;
  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private fetchApiService: FetchApiService
  ) {
    
  }
 
  ngOnInit(): void {
 
   this.fetchAllFollowHistory();
   this.fetchAllMyLikedPosts();
   this.fetchAllLikedArtilces();
  }
  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }
  
  fetchAllFollowHistory() {
    const apiUrl = `${environment.API_URL}fetch_follow_history`;
    const requestBody = { user_id: this.userId };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.allFollowwerLists = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  fetchAllMyLikedPosts() {
    const apiUrl = `${environment.API_URL}fetch_like_post_history`;
    const requestBody = { user_id: this.userId };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.allLikePosts = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  fetchAllLikedArtilces() {
    const apiUrl = `${environment.API_URL}fetch_like_article_history`;
    const requestBody = { user_id: this.userId };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.allLikedArticles = response;

      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch alerts.');
      },
    });
  }
}
