import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cryptopage',
  templateUrl: './cryptopage.component.html',
  styleUrls: ['./cryptopage.component.scss'],
})
export class CryptopageComponent {
  allSymbols: any[] = [];

  dostExplanins: any[] = [];
  similarStocks: any[] = [];
  quizz: any[] = [];
  tags: any[] = [];
  chainList: any[] = [];
  chainsContactList:any[]=[]
  sectors: any[] = [];
  industries: any[] = [];
  exchanges: any[] = [];

  fileList: NzUploadFile[] = []; // For nz-upload

  addDrawer: boolean = false;
  editDrawer: boolean = false;
  tableLoading: boolean = true;
  fallbackImage: string = 'assets/fallback.webp';
  userId: any = localStorage.getItem('userId');

  storageUrl = environment.STORAGE_URL;

  uploadedLogo: string | ArrayBuffer | null = null;
  uploadedLogoName: string = '';

  symbolLogoFile: File | null = null;
  symbolLogoPreview: string | ArrayBuffer | null = null;

  searchQuery: string = '';
  addCryptoForm: FormGroup = new FormGroup({
    symbol: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    active_status_id: new FormControl(''),
    description: new FormControl('', Validators.required),
    contact_address: new FormControl('', Validators.required),
    website: new FormControl(''),
    sector_id: new FormControl(null, Validators.required),
    similar_symbols: new FormControl(null),
    quiz_id: new FormControl(null),
    chain_id: new FormControl(null),
    dost_explains: new FormControl(null),
    relevant_tags: new FormControl(null),
    symbol_logo: new FormControl('', Validators.required),
    category_id: new FormControl(2),
  });

  editCryptoForm: FormGroup = new FormGroup({
    symbol: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    active_status_id: new FormControl(''),
    description: new FormControl('', Validators.required),
    contact_address: new FormControl('', Validators.required),
    website: new FormControl(''),
    sector_id: new FormControl(null, Validators.required),
    similar_symbols: new FormControl(null),
    quiz_id: new FormControl(null),

    chain_id: new FormControl(null),
    dost_explains: new FormControl(null),
    relevant_tags: new FormControl(null),
    symbol_logo: new FormControl('', Validators.required),
    category_id: new FormControl(2),
    symbol_id: new FormControl(''),
  });

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}

  ngOnInit(): void {
    this.fetchAllSymbols();
    // this.allDropdownFunctions();
    this.fetchselector();
    this.fetchChains();
    this.fetchSimilarStocks();
    this.fetchTags();
   this. fetchDostExplanins();
   this.fetchQuiz();
  }

  symbolID: number | null = null;
  selectedTabIndex = 0;
  submitAddForm() {
    const apiUrl = `${environment.API_URL}add_symbol`;
    const formData = new FormData();
    const { similar_symbols,dost_explains,quiz_id,  contract_address,website,  sector_id,  chain_id,  relevant_tags,  symbol,  name,   description,   active_status_id,
    } = this.addCryptoForm.value;
    if (similar_symbols && Array.isArray(similar_symbols)) {
      similar_symbols.forEach((value: any, index: number) => {
        formData.append(`similar_symbols[${index}]`, value);
      });
    }
    if (chain_id && Array.isArray(chain_id)) {
      chain_id.forEach((value: any, index: number) => {
        formData.append(`chain_id[${index}]`, value);
      });
    }
  if (dost_explains && Array.isArray(dost_explains)) {
    dost_explains.forEach((value: any, index: number) => {
      formData.append(`dost_explains[${index}]`, value);
    });
  }
    if (sector_id && Array.isArray(sector_id)) {
      sector_id.forEach((value: any, index: number) => {
        formData.append(`sector_id[${index}]`, value);
      });
    }

    if (chain_id && Array.isArray(chain_id)) {
      chain_id.forEach((value: any, index: number) => {
        formData.append(`chain_id[${index}]`, value);
      });
    }
    if (relevant_tags && Array.isArray(relevant_tags)) {
      relevant_tags.forEach((value: any, index: number) => {
        formData.append(`relevant_tags[${index}]`, value);
      });
    }

    formData.append('category_id', '2');
    formData.append('user_id', this.userId);
    formData.append('symbol', symbol);
    formData.append('quiz_id', quiz_id?? '');
    formData.append('name', name);
    formData.append('website', website);
    formData.append('contract_address', contract_address);
    formData.append('description', description);
    formData.append('active_status_id', active_status_id);

    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.symbolID=response.symbol_id
          if(this.symbolID){
            this.selectedTabIndex = 1;
          }
        } else {
          this.message.error('Error occurred, please try later');
        }
       
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  addChainContractAddress() {
    const apiUrl = `${environment.API_URL}add_chain_contact_address`; 
  
    // Determine the request body based on the form type (edit or add)
    const requestBody = this.isEditVisible
      ? {
          chain_id: this.editCryptoForm.get('chain_id')?.value,
          contact_address: this.editCryptoForm.get('contact_address')?.value, 
          symbol_id: this.editCryptoForm.get('symbol_id')?.value,
          user_id: this.userId,
        }
      : {
          chain_id: this.addCryptoForm.get('chain_id')?.value,
          contact_address: this.addCryptoForm.get('contact_address')?.value,
          symbol_id: this.symbolID,
          user_id: this.userId,
        };
  
    // Make the HTTP POST request
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.fetchChainContactLogs();
  
        // Reset the appropriate form after successful submission
        if (this.isEditVisible) {
          this.editCryptoForm.reset();
        } else {
          this.addCryptoForm.reset();
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  
  
  fetchChainContactLogs(): Promise<void> {
    this.tableLoading = true;
    const requestBody = {
      symbol_id:this.symbolID ?? this.editCryptoForm.controls['symbol_id']?.value,
    };
    const apiUrl = `${environment.API_URL}fetch_chain_contact_log`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl,requestBody).subscribe({
        next: (response: any) => {   
          this.chainsContactList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  submitEditForm() {
    const apiUrl = `${environment.API_URL}edit_symbol`;
    const formData = new FormData();
    const {  similar_symbols,symbol_id,quiz_id, dost_explains, contract_address,  website,  sector_id, chain_id, relevant_tags, symbol,
      name,
      description,
      active_status_id,
    } = this.editCryptoForm.value;

    // Safely handle similar_symbols array
    if (similar_symbols && Array.isArray(similar_symbols)) {
      similar_symbols.forEach((value: any, index: number) => {
        formData.append(`similar_symbols[${index}]`, value);
      });
    }
    if (dost_explains && Array.isArray(dost_explains)) {
      dost_explains.forEach((value: any, index: number) => {
        formData.append(`dost_explains[${index}]`, value);
      });
    }
   
    if (sector_id && Array.isArray(sector_id)) {
      sector_id.forEach((value: any, index: number) => {
        formData.append(`sector_id[${index}]`, value);
      });
    }

    // Safely handle dost_explains array
    if (chain_id && Array.isArray(chain_id)) {
      chain_id.forEach((value: any, index: number) => {
        formData.append(`chain_id[${index}]`, value);
      });
    }

    // Safely handle relevant_tags array
    if (relevant_tags && Array.isArray(relevant_tags)) {
      relevant_tags.forEach((value: any, index: number) => {
        formData.append(`relevant_tags[${index}]`, value);
      });
    }

    formData.append('category_id', '2'); // Hardcoded category_id to 1
    formData.append('user_id', this.userId);

    formData.append('symbol', symbol);
    formData.append('name', name);
    formData.append('website', website);
    formData.append('quiz_id', quiz_id?? '');
    formData.append('contract_address', contract_address);
    formData.append('description', description);
    formData.append('active_status_id', active_status_id);
    formData.append('symbol_id', symbol_id);

  
    if (this.symbolLogoFile) {
      formData.append('symbol_logo', this.symbolLogoFile);
    }

    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.symbolID=response.symbol_id?? this.editCryptoForm.controls['symbol_id'].value
          if(this.symbolID){

            this.selectedTabIndex = 1;
          }
        } else {
          this.message.error('Error occurred, please try later');
        }
       
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }
  setAsprimary(ID: number): void {
    const apiUrl = `${environment.API_URL}set_primary_chain_contact`;
    const requestData = { log_id: ID };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        if (response?.success) {
          this.message.success(response.message || 'Primary contact set successfully.');
        } else {
          this.message.error(response?.message || 'Error occurred, please try later.');
        }
      },
      error: (error) => {
        console.error('API Error: ', error);
        this.message.error('Error occurred, please try later.');
      }
    });
  }
  onFileChange(event: Event, isEdit: boolean = false): void {
    const file = (event.target as HTMLInputElement).files?.[0]; 
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result; 
        if (result) {
          this.symbolLogoPreview = result as string;
          this.symbolLogoFile = file;
          this.editCryptoForm.get('symbol_logo')?.setValue(file); 
        }
      };
      reader.readAsDataURL(file);
    }
  }
  removeUploadedLogo() {
    this.symbolLogoPreview = '';
  }
  fetchQuiz(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_quizzes`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.quizz = response.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  currentEditQuizId?: number;
  openEditDrawer(symbolId: number) {
    this.isEditVisible = true;
    const selectedSymbol = this.allSymbols.find(
      (symbol) => symbol.symbol_id === symbolId
    );
    
      
    if (selectedSymbol) {
      this.editCryptoForm.patchValue({
        symbol: selectedSymbol.symbol,
        name: selectedSymbol.name,
        description: selectedSymbol.description,
        sector_id: selectedSymbol.sector_id,
        website: selectedSymbol.website,
        contact_address: selectedSymbol.contact_address,
        chain_id: selectedSymbol.chain_id,
        relevant_tags: selectedSymbol.relevant_tags,
        dost_explains: selectedSymbol.dost_explains,
        quiz_id: selectedSymbol.quiz_id,
        similar_symbols: selectedSymbol.similar_symbols,
        active_status_id: selectedSymbol.active_status_id,
        symbol_id: selectedSymbol.symbol_id,
        symbol_logo: selectedSymbol.symbol_logo,
     
      });
      this.chainsContactList=selectedSymbol.chain
      
      this.symbolLogoPreview = selectedSymbol.symbol_logo
        ? `${this.storageUrl}${selectedSymbol.symbol_logo}`
        : null;

      console.log('Edit Symbol', this.editCryptoForm);
     this. fetchChainContactLogs()
      this.editDrawer = true;
    }
  }

 
  
  fetchselector(): Promise<void> {
    this.tableLoading = true;
    const requestData = {
          sector_type_id: 2,
        };
    const apiUrl = `${environment.API_URL}sector_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl,requestData).subscribe({
        next: (response: any) => {
          this.sectors  = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.allSymbols = this.allSymbols.filter((symbol) =>
        symbol.symbol.toLowerCase().includes(query)
      );
    } else {
      this.fetchAllSymbols();
    }
  }
  fetchDostExplanins(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}courses_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.dostExplanins = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_all_cryptos`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  allDropdownFunctions() {
    this.fetchSimilarStocks();
    this.fetchTags();
    this.fetchselector();
    this.fetchChains();
  }

  fetchSimilarStocks(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.similarStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  fetchTags(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}tags_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.tags = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }
  fetchChains(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}fetch_all_chains`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.chainList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_symbol`;

    const requestData = {
      symbol_id: [id],
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {
            this.message.success('Crypto deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  
  deleteContactAddress(id: number) {
    const apiUrl = `${environment.API_URL}delete_chain_contact_address`;
    const requestData = { log_id: id };
  
    console.log('Request Data:', requestData);
  
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response:', response);
        if (response.success) {
          this.fetchChainContactLogs().then(() => {
            this.message.success('Contact address deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Error occurred, please try later');
      }
    });
  }
  
  cancel(): void {}

  isVisible = false;
  isEditVisible = false;
  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
    this.isEditVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isEditVisible = false;
  }
  sortByTicker: NzTableSortFn<any> = (a, b) => a.symbol.localeCompare(b.symbol);
  sortByName: NzTableSortFn<any> = (a, b) => a.name.localeCompare(b.name);
  sortByStatus: NzTableSortFn<any> = (a, b) =>
    a.active_status_id - b.active_status_id;
  sortByLogo: NzTableSortFn<any> = (a, b) =>
    a.symbol_logo.localeCompare(b.symbol_logo);
  sortBySector: NzTableSortFn<any> = (a, b) => a.sector.localeCompare(b.sector);
  sortByExchange: NzTableSortFn<any> = (a, b) =>
    a.exchange.localeCompare(b.exchange);
  sortByIndustry: NzTableSortFn<any> = (a, b) =>
    a.industry.localeCompare(b.industry);
  sortByCategory: NzTableSortFn<any> = (a, b) =>
    a.category_name.localeCompare(b.category_name);
  sortByCreatedOn: NzTableSortFn<any> = (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  sortByUpdatedOn: NzTableSortFn<any> = (a, b) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  sortByUpdatedBy: NzTableSortFn<any> = (a, b) =>
    a.updated_by_name.localeCompare(b.updated_by_name);
  sortByUpdatedAt: NzTableSortFn<any> = (a, b) =>
    new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
}
