import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-tags',
  templateUrl: './company-tags.component.html',
  styleUrls: ['./company-tags.component.scss'],
})
export class CompanyTagsComponent {
  id: number = 0;
  commentForm: FormGroup;
  articles: any[] = []; // Keeping articles as an array of any type
  tagsArticles: any = { articles: [], tag: '' };
  authorCoveringList: any[] = []; // Using any type for tagsArticles
  comments: { [key: number]: any[] } = {};
  visibleComments: Set<number> = new Set(); // Change comments to be an object indexed by article ID
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');

  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private fetchApiService: FetchApiService
  ) {
    this.commentForm = this.fb.group({
      comment_text: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = +(params.get('id') || 0);
      if (this.id) {
        this.fetchArticlesbyTag();
        this.fetchauthorcoveringtags();
      }
    });
  }

  loadComments(articleId: number) {
    const requestPayload = { id: articleId };
    this.http
      .post(`${environment.API_URL}fetch_article_comment`, requestPayload)
      .subscribe({
        next: (response: any) => {
          this.comments[articleId] = response; // Store comments associated with the article id
        },
        error: (error) => {
          console.error('Error fetching comments:', error);
        },
      });
  }
  toggleCommentSection(articleId: number): void {
    if (this.visibleComments.has(articleId)) {
      this.visibleComments.delete(articleId);
    } else {
      this.visibleComments.add(articleId);
    }
  }

  isCommentSectionVisible(articleId: number): boolean {
    return this.visibleComments.has(articleId);
  }

  deleteComment(commentId: number, articleId: number) {
    const roleID = localStorage.getItem('role_id');
    if (roleID === '1' || roleID === '2') {
      this.fetchApiService.deleteArticleComment(commentId).subscribe(
        (response) => {
          if (response.success) {
            console.log('Comment deleted successfully!');
            this.loadComments(articleId); // Pass the articleId instead of commentId
          } else {
            console.error('Failed to delete comment:', response.message);
          }
        },
        (error) => {
          console.error('Failed to delete comment:', error);
        }
      );
    } else {
      console.error(
        'Permission denied: You do not have the required role to delete comments.'
      );
    }
  }
  cancel(): void {}
  fetchArticlesbyTag() {
    const apiUrl = `${environment.API_URL}fetch_articles_by_tag`;
    const requestBody = { tag_id: this.id, auth_user_id: this.userId };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.tagsArticles = response; // Assigning response directly
        this.articles = response.articles;

        // Load comments for each article after fetching them
        this.articles.forEach((article) => {
          this.loadComments(article.id); // Pass each article's ID to load comments
        });
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  fetchauthorcoveringtags() {
    const apiUrl = `${environment.API_URL}fetch_author_covering_tags`;
    const requestBody = { tag_id: this.id };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.authorCoveringList = response;
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }

  likeTags() {
    const apiUrl = `${environment.API_URL}like_tag`;
    const requestBody = { user_id: this.userId, tag_id: this.id };
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.message.success(response.message);
        this.fetchArticlesbyTag();
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to fetch authors.');
      },
    });
  }
  isCourseModalLoading: boolean = false;
  likeButton(article: any) {
    const newLikeStatus = article.like_status === 1 ? 0 : 1; // Toggle the like status
    const requestData = {
      article_id: article.id,
      like_status: newLikeStatus,
      user_id: sessionStorage.getItem('user_id'),
    };
  
    this.isCourseModalLoading = true;
  
    return new Promise((resolve, reject) => {
      this.fetchApiService.likeArticle(requestData).subscribe({
        next: (response: any) => {
          if (response && response.success) {
            // Update like_status and like_count on success
            article.like_status = newLikeStatus;
            article.like_count = newLikeStatus === 1 ? article.like_count + 1 : article.like_count - 1;
            resolve(true);
          } else {
            console.error('Failed to add like:', response && response.message);
            reject();
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          reject();
        },
        complete: () => {
          this.fetchArticlesbyTag();
          this.isCourseModalLoading = false;
        },
      });
    });
  }
  
}
