<ng-container >
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
  </ng-container>
  
  <!-- <ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
  </ng-template> -->

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->
    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Lectures</nz-breadcrumb-item>
        </nz-breadcrumb>
    </div>

    <nz-card>
        <div class="col-12 d-flex mb-3">
            <nz-form-label class="col-auto">Choose Course</nz-form-label>

            <nz-select class="col-3" nzShowSearch nzAllowClear nzPlaceHolder="Select Course"
                (ngModelChange)="fetchCourseSections($event)" [(ngModel)]="selectedCourseId">
                <nz-option *ngFor="let course of courses" [nzLabel]="course.title"
                    [nzValue]="course.course_id"></nz-option>
            </nz-select>

            <nz-form-label class="col-auto ms-3">Choose Section</nz-form-label>

            <nz-select class="col-3" nzShowSearch nzAllowClear nzPlaceHolder="Select Section"
                (ngModelChange)="fetchLecturesOfSection($event)" [(ngModel)]="selectedSectionId">
                <nz-option *ngFor="let section of sections" [nzLabel]="section.title"
                    [nzValue]="section.section_id"></nz-option>
            </nz-select>

            <button class="ms-auto col-auto" nz-button nzType="primary" (click)="openAddDrawer()"
                [disabled]="!selectedSectionId"><span nz-icon nzType="plus"></span>Add
                Lecture</button>
        </div>

        <div class="table-responsive">
            <nz-table #lectureTable [nzData]="lectures" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                        <th [nzChecked]="LectureTableChecked" [nzIndeterminate]="LectureTableIndeterminate"
                            nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)" width="5%"></th>
                        <th>Title</th>
                        <th>Quiz</th>
                        <th>Content Type</th>
                        
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let lecture of lectureTable.data">

                        <!-- Main Table -->
                        <tr (click)="openEditDrawer(lecture.lecture_id)">
                            <td [nzChecked]="setOfCheckedLectureTableIds.has(lecture.lecture_id)"
                                (click)="$event.stopPropagation()"
                                (nzCheckedChange)="onItemChecked(lecture.lecture_id, $event)"></td>
                            <td>{{ lecture.title }}</td>
                            <td>{{ lecture.quiz_name }}</td>
                            <td>{{ lecture.content_type_id ===1 ? 'Text': 'Video' }}</td>
                            <td>{{ lecture.created_at | date: 'dd MMM y, h:mm a' }}</td>
                            <td>{{ lecture.updated_at | date: 'dd MMM y, h:mm a' }}</td>

                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button class="me-3" nz-button nzType="default" nzShape="circle"
                                    nz-tooltip="Manage Resources" nzTooltipPlacement="left"
                                    (click)="openAddResourceDrawer(lecture.lecture_id)">
                                    <span nz-icon nzType="paper-clip"></span>
                                </button>
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(lecture.lecture_id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer nzWidth="80%" [nzVisible]="addDrawer" nzTitle="Add Lecture" (nzOnClose)="closeAddDrawer()"
            [nzFooter]="AddFormFooterTpl">
            <ng-container *nzDrawerContent>

                <form id="add-form" nz-form nzLayout="vertical" [formGroup]="addLectureForm"
                    (ngSubmit)="submitAddForm()">
                    <div class="row">

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Lecture title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this section <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Main Quiz"
                                    formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive understanding of this section. It can also be referred to as
                                    the <strong>Final Quiz</strong> for this section.</span>
                            </ng-template>
                        </div>
                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Under Section</nz-form-label>
                                <nz-form-control nzErrorTip="Select Section">
                                    <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Section"
                                        formControlName="section_id">
                                        <nz-option *ngFor="let section of sections" [nzLabel]="section.title"
                                            [nzValue]="section.section_id"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content Type</nz-form-label>
                                <nz-form-control nzErrorTip="Select Content Type">
                                    <nz-select class="w-100" nzShowSearch nzAllowClear
                                        nzPlaceHolder="Select Content Type" formControlName="content_type_id" required
                                        (ngModelChange)="onContentTypeChange($event, addForm)">
                                        <nz-option *ngFor="let contentType of lectureContentTypes"
                                            [nzLabel]="contentType.content_type"
                                            [nzValue]="contentType.content_type_id"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label>Lecture status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>

                        <div *ngIf="addLectureForm.controls['content_type_id'].value === 2" class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content Url</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Url">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="content_url" required
                                            placeholder="Enter Url" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngIf="addLectureForm.controls['content_type_id'].value === 1" class="col-12 col-md-12">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content</nz-form-label>
                                <nz-form-control nzErrorTip="Please write something here!">
                                    <quill-editor formControlName="content" [sanitize]="true"
                                        placeholder="Enter your content here..."
                                        style="width: 100%; height: 400px; margin-bottom: 3rem;"></quill-editor>
                                </nz-form-control>
                            </nz-form-item>
                        </div>


                    </div>
                </form>
            </ng-container>

            <ng-template #AddFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeAddDrawer()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="add-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>

        <nz-drawer nzWidth="80%" [nzVisible]="editDrawer" nzTitle="Edit Lecture" (nzOnClose)="closeEditDrawer()"
            [nzFooter]="EditFormFooterTpl">
            <ng-container *nzDrawerContent>

                <form id="edit-form" class="mb-4" nz-form nzLayout="vertical" [formGroup]="editLectureForm"
                    (ngSubmit)="submitEditForm()">

                    <div class="row">

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Lecture title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this section <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Main Quiz"
                                    formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive understanding of this section. It can also be referred to as
                                    the <strong>Final Quiz</strong> for this section.</span>
                            </ng-template>
                        </div>
                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Under Section</nz-form-label>
                                <nz-form-control nzErrorTip="Select Section">
                                    <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Section"
                                        formControlName="section_id">
                                        <nz-option *ngFor="let section of sections" [nzLabel]="section.title"
                                            [nzValue]="section.section_id"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content Type</nz-form-label>
                                <nz-form-control nzErrorTip="Select Content Type">
                                    <nz-select class="w-100" nzShowSearch nzAllowClear
                                        nzPlaceHolder="Select Content Type" formControlName="content_type_id" required
                                        (ngModelChange)="onContentTypeChange($event, editForm)">
                                        <nz-option *ngFor="let contentType of lectureContentTypes"
                                            [nzLabel]="contentType.content_type"
                                            [nzValue]="contentType.content_type_id"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-3">
                            <nz-form-item>
                                <nz-form-label>Lecture status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>


                        <div *ngIf="editLectureForm.controls['content_type_id'].value === 2" class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content Url</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Url">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="content_url" required
                                            placeholder="Enter Url" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div *ngIf="editLectureForm.controls['content_type_id'].value === 1" class="col-12 col-md-12">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content</nz-form-label>
                                <nz-form-control nzErrorTip="Please write something here!">
                                    <quill-editor formControlName="content" [sanitize]="true"
                                        placeholder="Enter your content here..."
                                        style="width: 100%; height: 400px; margin-bottom: 3rem;"></quill-editor>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                    </div>
                </form>

            </ng-container>
            <ng-template #EditFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeEditDrawer()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="edit-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>

        <nz-drawer nzWidth="50%" [nzVisible]="lectureResourceDrawer" nzTitle="Manage Lecture Resources"
            (nzOnClose)="closeAddResourceDrawer()">
            <ng-container *nzDrawerContent>

                <form nz-form nzLayout="vertical" [formGroup]="addLectureResourceForm">
                    <div class="row">

                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label nzRequired>Resource Name</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Name">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="name"
                                            placeholder="Resource name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>



                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label nzRequired>Resource Type</nz-form-label>
                                <nz-form-control nzErrorTip="Select Resource Type">
                                    <nz-select class="w-100" nzShowSearch nzAllowClear
                                        nzPlaceHolder="Select Resource Type" formControlName="content_type"
                                        (ngModelChange)="onResourceTypeChange($event)">
                                        <nz-option nzLabel="Downloadable" [nzValue]="1"></nz-option>
                                        <nz-option nzLabel="External Link" [nzValue]="2"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="addLectureResourceForm.controls['content_type'].value === 1">

                            <nz-upload [nzMultiple]="false" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                                <button nz-button>
                                    <span nz-icon nzType="upload"></span>
                                    Select File
                                </button>
                            </nz-upload>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="addLectureResourceForm.controls['content_type'].value === 2">
                            <nz-form-item>
                                <nz-form-label nzRequired>Content Url</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Url">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="content_url" required
                                            placeholder="Enter Url" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <button nz-button nzType="primary"
                            [disabled]="!addLectureResourceForm.valid || (addLectureResourceForm.value.content_type === 1 && fileList.length === 0)"
                            class="col-auto ms-auto my-auto" [nzLoading]="drawerLoading"
                            (click)="submitAddResourceForm()" type="submit">Add
                            Resource</button>

                    </div>
                </form>

                <nz-divider></nz-divider>
                <div class="table-responsive">
                    <nz-table #lectureResourceTable [nzData]="lectureResources" nzTableLayout="fixed"
                        [nzLoading]="drawerLoading" nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <!-- <th>Type</th> -->
                                <th>Resource</th>
                                <th>Created At</th>
                                <th nzWidth="100px" nzAlign="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let resource of lectureResourceTable.data">

                                <tr>
                                    <td>{{ resource.name }}</td>

                                    <td>
                                        <ng-container *ngIf="resource.content_type === 2; else otherContentType">
                                            <a [href]="resource.content_url" target="_blank">
                                                External Link
                                            </a>
                                        </ng-container>
                                        <ng-template #otherContentType>
                                            <a [href]="storageUrl + resource.content_url" download>
                                                Downloadable Content
                                            </a>
                                        </ng-template>
                                    </td>

                                    <td>{{ resource.created_at | date: 'dd MMM y' }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="deleteLectureResource(resource.lecture_id)"
                                            (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>

                            </ng-container>
                        </tbody>
                    </nz-table>
                </div>
            </ng-container>
        </nz-drawer>
    </nz-card>
</ng-template>