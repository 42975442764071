import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dost-portfolio',
  templateUrl: './dost-portfolio.component.html',
  styleUrls: ['./dost-portfolio.component.scss'],
})
export class DostPortfolioComponent implements OnInit {
  userId = localStorage.getItem('userId');
  sentiments: any[] = [];
  risks: any[] = [];
  horizons: any[] = [];
  trades: any[] = [];
  tickers: any[] = [];
  selectedTab: number = 0;
  watchLists: any[] = [];
  categoryList: any[] = [];
  watchlistTickers: any[] = [];
  dostportfolioArtilces: any[] = [];
  dostPortfolioAlertList: any[] = [];
  drawerVisible = false;
  EditDrawer = false;
  isEditMode: boolean = false;
  tickerForm: FormGroup;

  categoryId: any;
  alertTypeId: any;

  storageUrl = environment.STORAGE_URL;
  similarCryptos: any[] = []; // To track if the modal is in edit mode
  similarStocks: any[] = [];
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private drawerService: NzDrawerService,
    private fb: FormBuilder
  ) {
    this.tickerForm = this.fb.group({
      symbol_id: ['', Validators.required],
      sentiment_id: [''],
      risk_id: [''],
      horizon_id: [''],
      trade_id: [''],
      is_public: [],
      review_date: [],
      watch_list_ticker_id: [null], // Required for edit mode
    });
  }

  ngOnInit(): void {
    this.fetchWatchLists();
    this.fetchSimilarCryptos();
    this.fetchSimilarStocks();
    this.callThreeFunction(1, 1);
  }
  onTabChange(tabIndex: number): void {
    this.categoryId = tabIndex === 0 ? 1 : 2;
    this.callThreeFunction(this.categoryId, 1);
    this.selectedTab = tabIndex;
  }

  fetchWatchLists() {
    forkJoin([
      this.http.get(`${environment.API_URL}sentiment_dropdown`),
      this.http.get(`${environment.API_URL}risk_dropdown`),
      this.http.get(`${environment.API_URL}horizon_dropdown`),
      this.http.get(`${environment.API_URL}trade_dropdown`),
      this.http.get(`${environment.API_URL}ticker_dropdown`),
      this.http.post(`${environment.API_URL}fetch_watch_lists`, {
        user_id: this.userId,
      }),
    ]).subscribe(
      ([sentiments, risks, horizons, trades, tickers, watchLists]) => {
        this.sentiments = sentiments as any[];
        this.risks = risks as any[];
        this.horizons = horizons as any[];
        this.trades = trades as any[];
        this.tickers = tickers as any[];
        this.watchLists = watchLists as any[];
        this.fetchWatchlistTickers(1);
      },
      (error) => this.message.error('Failed to fetch data.')
    );
  }
  getTickerDataToEdit(ticker: any): void {
    this.isEditMode = true;
    this.drawerVisible = true;
    this.tickerForm.patchValue({
      watch_list_ticker_id: ticker.watch_list_ticker_id,
      symbol_id: ticker.symbol_id,
      sentiment_id: ticker.sentiment_id,
      risk_id: ticker.risk_id,
      horizon_id: ticker.horizon_id,
      trade_id: ticker.trade_id,
      is_public: ticker.is_public,
      review_date: ticker.review_date,
    });
  }

  callThreeFunction(categoryID: any, alertID: any) {
    this.fetchWatchlistTickers(categoryID);
    this.fetchportfoliosDostAlert(categoryID, alertID);
    this.fetchportfoliosarticles(categoryID);
  }

  fetchportfoliosDostAlert(
    categoryId: number,
    alertTypeId: number
  ): Promise<void> {
    const requestData = {
      category_id: categoryId,
      alert_type_id: alertTypeId,
      user_id: this.userId,
    };
    const apiUrl = `${environment.API_URL}portfolios_dost_alert`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.dostPortfolioAlertList = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          reject();
        },
        complete: () => {
          resolve();
        },
      });
    });
  }
  onAlertTypeChange(tabIndex: number): void {
    this.alertTypeId = tabIndex === 0 ? 1 : 2;
    this.fetchportfoliosDostAlert(this.categoryId, this.alertTypeId);
  }
  fetchWatchlistTickers(categoryId: number): Promise<void> {
    const requestData = {
      category_id: categoryId,
      user_id: this.userId,
    };

    const apiUrl = `${environment.API_URL}fetch_watchlist_tickers`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.watchlistTickers = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }

  fetchportfoliosarticles(categoryId: number): Promise<void> {
    const requestData = {
      category_id: categoryId,
      user_id: this.userId,
    };

    const apiUrl = `${environment.API_URL}fetch_portfolio_articles`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          this.dostportfolioArtilces = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }

  cancel(): void {}
  deleteWatchlisTtickerId(id: number) {
    const apiUrl = `${environment.API_URL}delete_watch_list_ticker`;

    const requestData = {
      watch_list_ticker_id: id,
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.fetchWatchlistTickers(this.selectedTab + 1);
        this.fetchportfoliosarticles(this.selectedTab + 1);
        this.fetchportfoliosDostAlert(1,1)
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  fetchSimilarStocks(): Promise<void> {
    const apiUrl = `${environment.API_URL}stocks_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.similarStocks = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  fetchSimilarCryptos(): Promise<void> {
    const apiUrl = `${environment.API_URL}cryptos_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.similarCryptos = response;
        },
        error: (error) => {
          console.error('Error: ', error);

          return reject();
        },
        complete: () => {
          return resolve();
        },
      });
    });
  }
  saveTicker(isEditMode: boolean): void {
    if (this.tickerForm.valid) {
      const endpoint = isEditMode
        ? `${environment.API_URL}edit_watch_list`
        : `${environment.API_URL}add_watch_list`;
  
      // Ensure the review_date is in YYYY-MM-DD format
      const reviewDate = this.tickerForm.get('review_date')?.value
        ? new Date(this.tickerForm.get('review_date')?.value).toISOString().split('T')[0]
        : new Date().toISOString().split('T')[0];
  
      const payload = {
        ...this.tickerForm.value,
        user_id: this.userId,
        review_date: reviewDate, // Assign formatted date
        watch_list_id: isEditMode ? undefined : 1,
        is_public:this.tickerForm.get('is_public')?.value ?? 0,
        updated_by: isEditMode ? this.userId : undefined,
      };
  
      this.http.post(endpoint, payload).subscribe({
        next: () => {
          this.message.success(
            `Ticker ${isEditMode ? 'updated' : 'added'} successfully!`
          );
          this.closeDrawer();
          this.fetchWatchlistTickers(this.selectedTab + 1);
          this.fetchportfoliosDostAlert(1,1)
        },
        error: () =>
          this.message.error(
            `Failed to ${isEditMode ? 'update' : 'add'} the ticker. Please try again.`
          ),
      });
    }
  }
  closeDrawer(): void {
    this.drawerVisible = false;
    this.tickerForm.reset();
    this.dostAlertVisible = false;
  }
  addTobookmark(dostAlertID: number) {
    const isCurrentlyBookmarked = this.dostAlert.is_bookmark;
    const apiUrl = isCurrentlyBookmarked
      ? `${environment.API_URL}delete_dost_alert_bookmark`
      : `${environment.API_URL}add_bookmark`;

    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          // Toggle bookmark status
          this.dostAlert.is_bookmark = !isCurrentlyBookmarked;

          // Show appropriate message based on bookmark status
          if (this.dostAlert.is_bookmark) {
            this.message.success('Bookmark added successfully.');
          } else {
            this.message.success('Bookmark removed successfully.');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to update bookmark status.');
      },
    });
  }
  reportDostalert(dostAlertID: number) {
    const apiUrl = `${environment.API_URL}report_dost_alert`;
    const requestBody = {
      user_id: this.userId,
      dost_alert_id: dostAlertID,
    };

    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          if (this.dostAlert.is_bookmark) {
            this.message.success('Report');
          } else {
            this.message.success('Report');
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Failed to Report.');
      },
    });
  }
  dostAlert: any;
  dostAlertVisible = false;
  openDostAlertModal(alert: any) {
    this.dostAlertVisible = true;
    this.dostAlert = alert;
  }
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  ngAfterViewInit(): void {
    // Now `scrollContainer` will be available
  }
  scrollLeft(): void {
    this.scrollContainer.nativeElement.scrollBy({
      left: -250,
      behavior: 'smooth',
    });
  }

  scrollRight(): void {
    this.scrollContainer.nativeElement.scrollBy({
      left: 250,
      behavior: 'smooth',
    });
  }
  openDrawer(): void {
    this.drawerVisible = true;
    this.isEditMode = false;
  }
}
