<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>

    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Stocks</nz-breadcrumb-item>
        </nz-breadcrumb>

        <button class="ms-auto" nz-button nzType="primary"  (click)="showModal()"><span nz-icon
                nzType="plus"></span>Add Stock</button>
    </div>

    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Ticker" [(ngModel)]="searchQuery"
                    (ngModelChange)="filterSymbols()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>

    <nz-card>
        <div class="table-responsive">


          <nz-table
            #quizTable
            [nzData]="allSymbols"
            nzTableLayout="fixed"
            [nzLoading]="tableLoading"
            nzShowSizeChanger
          >
            <thead>
              <tr>
                <th nzWidth="50px">Sr.no</th>
                <th [nzSortFn]="sortByTicker">Stock</th>
                <th [nzSortFn]="sortByName">Name</th>
                <th [nzSortFn]="sortByStatus">Status</th>
                <th [nzSortFn]="sortByLogo">Logo</th>
                <th [nzSortFn]="sortBySector">Sector</th>
                <th [nzSortFn]="sortBySector">Relevant Tags</th>
                <th [nzSortFn]="sortBySector">similar Symbols</th>
                <th [nzSortFn]="sortByExchange">Exchange</th>
                <th [nzSortFn]="sortByIndustry">Industry</th>
                <th [nzSortFn]="sortByCreatedOn">Created On</th>
                <th [nzSortFn]="sortByUpdatedOn">Updated On</th>
                <th [nzSortFn]="sortByUpdatedBy">Updated By</th>
                <th [nzSortFn]="sortByUpdatedAt">Updated At</th>
                <th nzWidth="100px" nzAlign="center">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let quiz of quizTable.data; let i = index">
                <tr (click)="openEditDrawer(quiz.symbol_id)">
                  <td>{{ i + 1 }}</td>
                  <td>{{ quiz.symbol }}</td>
                  <td>{{ quiz.name }}</td>
                  <td>{{ quiz.active_status_id === 1 ? 'Yes' : 'No' }}</td>
                  <td>
                    <img
                      class="latest_article_image"
                      [src]="quiz.symbol_logo ? (storageUrl + quiz.symbol_logo) : fallbackImage"
                      alt="{{ quiz.symbol }} logo"
                      style="width: 100px; height: auto;"
                    />
                  </td>
                  <td>{{ quiz.sector }}</td>
                  <td>{{ quiz.relevant_tags_name }}</td>
                  <td>{{ quiz.similar_symbols_name }}</td>
                  <td>{{ quiz.exchange }}</td>
                  <td>{{ quiz.industry }}</td>
                  <td>{{ quiz.created_by_name }}</td>
                  <td>{{ quiz.created_at | date: 'yyyy-MM-dd' }}</td>
                  <td>{{ quiz.updated_by_name }}</td>
                  <td>{{ quiz.updated_at | date: 'yyyy-MM-dd' }}</td>
                  <td nzAlign="center" (click)="$event.stopPropagation()">
                    <button
                      nz-button
                      nzType="default"
                      nzDanger
                      nzShape="circle"
                      nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this record?"
                      (nzOnConfirm)="confirm(quiz.symbol_id)"
                      (nzOnCancel)="cancel()"
                    >
                      <span nz-icon nzType="delete"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
      


      <nz-modal  [nzFooter]="null"  [nzWidth]="800"  [(nzVisible)]="isVisible" nzTitle="Add Stock" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <form id="add-form" nz-form nzLayout="vertical" [formGroup]="addSymbolForm" (ngSubmit)="submitAddForm()">
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label nzFor="symbol">Stock</nz-form-label>
                            <nz-form-control nzErrorTip="Please enter Stock">
                                <input required nz-input id="symbol" formControlName="symbol" placeholder="Enter Stock" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="col-md-6">
                        <nz-form-item>
                            <nz-form-label nzFor="name">Full name</nz-form-label>
                            <nz-form-control nzErrorTip="Please enter full name">
                                <input required nz-input id="name" formControlName="name" placeholder="Enter full name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="col-md-2">
                        <nz-form-item>
                            <nz-form-label>Stock status?</nz-form-label>
                            <nz-radio-group formControlName="active_status_id">
                                <label nz-radio [nzValue]="1">Active</label>
                                <label nz-radio [nzValue]="2">Inactive</label>
                            </nz-radio-group>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <textarea formControlName="description" nz-input placeholder="Description" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Industry</nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Industry" formControlName="industry_id">
                                <nz-option *ngFor="let industry of industries" [nzValue]="industry.industry_id" [nzLabel]="industry.industry"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Exchange</nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Exchange" formControlName="exchange_id">
                                <nz-option *ngFor="let exchange of exchanges" [nzValue]="exchange.exchange_id" [nzLabel]="exchange.exchange"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Sector</nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="sector_id">
                                <nz-option *ngFor="let sector of sectors" [nzValue]="sector.sector_id" [nzLabel]="sector.sector"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
             
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Similar stocks</nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="similar_symbols">
                                <nz-option *ngFor="let stocks of similarStocks" [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Quiz </nz-form-label>
                            <nz-select  [nzAllowClear]="true"  class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="quiz_id">
                                <nz-option *ngFor="let quiz of quizz" [nzValue]="quiz.id" [nzLabel]="quiz.title"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Tags </nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple"  class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="relevant_tags">
                                <nz-option *ngFor="let tag of tags;
                                " [nzValue]="tag.tag_id" [nzLabel]="tag.tag_name"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <nz-form-label><span>DostExplains<sup>™</sup></span></nz-form-label>
                        <nz-select formControlName="dost_explains"  [nzAllowClear]="true" nzMode="multiple"  style="width: 100%;"   nzPlaceHolder="Select DostExplains">
                    <nz-option  *ngFor="let item of dostExplanins"
                        [nzValue]="item.course_id"
                        [nzLabel]="item.title"></nz-option>
                </nz-select>

                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-12">
                        <nz-form-item>
                            <nz-form-label nzFor="symbol_logo">Stock Logo</nz-form-label>
                            <nz-form-control [nzErrorTip]="'Logo is required'">
                                <input type="file" id="symbol_logo" (change)="onFileChange($event)" />
                                <div *ngIf="symbolLogoPreview" class="img-preview-wrapper">
                                    <img [src]="symbolLogoPreview" alt="Symbol Logo Preview" class="img-preview" />
                                    <button nz-button nzType="default" (click)="removeUploadedLogo()">Remove</button>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div> -->
                    <div class="row">
                        <div class="col-md-4">
                            <nz-form-item>
                              <label for="fileInput" class="file-upload-container">
                                <input type="file" (change)="onFileChange($event)"
                                       accept="image/png, image/jpeg, image/jpg" id="fileInput" 
                                       style="display: none;" />
                                <div>
                                  <span>Upload Image</span>
                                </div>
                              </label>
                            </nz-form-item>
                      </div>
                      <div class="col-md-4">
                          <div *ngIf="symbolLogoPreview">
                            <img [src]="symbolLogoPreview" alt="Symbol Logo Preview" class="img-preview" />
                            <button nz-button nzType="default" (click)="removeUploadedLogo()">Remove</button>
                            </div>
                      </div>
                    </div>
                </div>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button nzType="primary">Add Stock</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </ng-container>
    </nz-modal>


    <nz-modal [nzFooter]="null"  [(nzVisible)]="isEditVisible"  [nzWidth]="800"  nzTitle="Edit Symbol" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>

            <form id="edit-form" nz-form nzLayout="vertical" [formGroup]="editSymbolForm" (ngSubmit)="submitEditForm()">
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label nzFor="symbol">Stock</nz-form-label>
                            <nz-form-control nzErrorTip="Please enter Symbol">
                                <input required nz-input id="symbol" formControlName="symbol" placeholder="Enter Stock" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="col-md-6">
                        <nz-form-item>
                            <nz-form-label nzFor="name">Full name</nz-form-label>
                            <nz-form-control nzErrorTip="Please enter full name">
                                <input required nz-input id="name" formControlName="name" placeholder="Enter full name" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div class="col-md-2">
                        <nz-form-item>
                            <nz-form-label>Stock status?</nz-form-label>
                            <nz-radio-group formControlName="active_status_id">
                                <label nz-radio [nzValue]="1">Active</label>
                                <label nz-radio [nzValue]="2">Inactive</label>
                            </nz-radio-group>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <textarea formControlName="description" nz-input placeholder="Description" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Industry</nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Industry" formControlName="industry_id">
                                <nz-option *ngFor="let industry of industries" [nzValue]="industry.industry_id" [nzLabel]="industry.industry"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Exchange</nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Exchange" formControlName="exchange_id">
                                <nz-option *ngFor="let exchange of exchanges" [nzValue]="exchange.exchange_id" [nzLabel]="exchange.exchange"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Sector</nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="sector_id">
                                <nz-option *ngFor="let sector of sectors" [nzValue]="sector.sector_id" [nzLabel]="sector.sector"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
             
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Similar stocks</nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple" class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="similar_symbols">
                                <nz-option *ngFor="let stocks of similarStocks" [nzValue]="stocks.symbol_id" [nzLabel]="stocks.symbol"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Quiz </nz-form-label>
                            <nz-select  [nzAllowClear]="true"  class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="quiz_id">
                                <nz-option *ngFor="let quiz of quizz" [nzValue]="quiz.id" [nzLabel]="quiz.title"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-4">
                        <nz-form-item>
                            <nz-form-label>Select Tags </nz-form-label>
                            <nz-select  [nzAllowClear]="true" nzMode="multiple"  class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Sector" formControlName="relevant_tags">
                                <nz-option *ngFor="let tag of tags;
                                " [nzValue]="tag.tag_id" [nzLabel]="tag.tag_name"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <nz-form-label><span>DostExplains<sup>™</sup></span></nz-form-label>
                        <nz-select formControlName="dost_explains"  [nzAllowClear]="true" nzMode="multiple"  style="width: 100%;"   nzPlaceHolder="Select DostExplains">
                    <nz-option [routerLink]="['/course',  'preview', item.course_slug, item.dost_explains]"  *ngFor="let item of dostExplanins"
                        [nzValue]="item.course_id"
                        [nzLabel]="item.title"></nz-option>
                </nz-select>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <nz-form-item>
                          <label for="fileInput" class="file-upload-container">
                            <input type="file" (change)="onFileChange($event)"
                                   accept="image/png, image/jpeg, image/jpg" id="fileInput" 
                                   style="display: none;" />
                            <div>
                              <span>Upload Image</span>
                            </div>
                          </label>
                        </nz-form-item>
                  </div>
                  <div class="col-md-4">
                      <div *ngIf="symbolLogoPreview">
                        <img [src]="symbolLogoPreview" alt="Symbol Logo Preview" class="img-preview" />
                        <button nz-button nzType="default" (click)="removeUploadedLogo()">Remove</button>
                        </div>
                  </div>
                </div>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button nzType="primary"> Update Stock</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </ng-container>
    </nz-modal >
</ng-template>