<ng-container *ngIf="useAdminLayout; else authorLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template>

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->


    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Quiz Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Quizzes</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto me-2" nz-button [disabled]="setOfCheckedQuizTableIds.size === 0"
            (click)="openAddQuestionsToQuizDialog()"><span nz-icon nzType="plus"></span>Add Questions</button>
        <button class="" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon nzType="plus"></span>Add
            Quiz</button>
            <button nz-button 
        [disabled]="selectedQuizIds.length === 0"  (click)="submitSelectedQuizzes()"
      >Quiz of the Day</button>
    </div>

    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Quizzes" [(ngModel)]="searchQuery"
                    (ngModelChange)="searchQuizzes()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>

    <nz-card>

        <div class="table-responsive">

            <nz-table #quizTable [nzData]="filteredQuizzes" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr >
                        <th [nzSortFn]="sortByTitle"></th>
                        <th  nzWidth="100px" nzAlign="center">Action</th>
                        <th [nzSortFn]="sortByTitle">Title</th>
                        <th [nzSortFn]="sortByDescription">Description</th>
                        <th [nzSortFn]="sortByStatus">Status</th>
                        <th [nzSortFn]="sortByQuizLevel">Quiz Level</th>
                        <th [nzSortFn]="sortByCategory">Category</th>
                        <th [nzSortFn]="sortByCategory">Ticker</th>
                        <th [nzSortFn]="sortByValidFrom">Quiz time</th>
                        <th [nzSortFn]="sortByValidFrom">Attempts</th>
                        <th [nzSortFn]="sortByValidFrom">Valid From</th>
                        <th [nzSortFn]="sortByValidTo">Valid To</th>
                        <th [nzSortFn]="sortByCreatedAt">Created At</th>
                        <th [nzSortFn]="sortByUpdatedAt">Updated At</th>
                        <th [nzSortFn]="sortByCreatedBy">Created By</th>
                        <th [nzSortFn]="sortByUpdatedBy">Updated By</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let quiz of quizTable.data; let i = index">
                       
                        <tr [routerLink]="['/edit-quiz', quiz.id]">
                            <td>
                                <label nz-checkbox 
                                (change)="onCheckboxChange(quiz.id, $event.target)"></label>
                            </td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <a [routerLink]="['/attend-quiz']" target="_blank" nz-button nzType="default"
                                    nzShape="circle" nz-tooltip="Launch Preview" class="me-2"
                                    nzTooltipPlacement="right">
                                    <span nz-icon nzType="play-circle"></span>
                                </a>
                            </td>
                            <td>{{ quiz.title }}</td>
                            <td nzEllipsis>{{ quiz.description ? quiz.description: '--' }}</td>
                            <td>{{ quiz.is_active === 1 ? 'Active': 'Inactive' }}</td>
                            <td>{{ quiz.quiz_level ? quiz.quiz_level: '--'}}</td>
                            <td>{{ quiz.category_string ? quiz.category_string: '--'}}</td>
                            <td>{{ quiz.symbol_name ? quiz.symbol_name: '--'}}</td>
                            <td>{{ quiz.time}}</td>
                            <td>{{ quiz.max_attempt}}</td>
                            <td>{{ quiz.valid_from | date: 'dd MMM yyyy' }}</td>
                            <td>{{ quiz.valid_to | date: 'dd MMM yyyy' }}</td>
                            <td>{{ quiz.created_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ quiz.updated_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ quiz.created_by_string ? quiz.created_by_string: '--'}}</td>

                            <td>{{ quiz.updated_by_string ? quiz.updated_by_string: '--' }}</td>

                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(quiz.id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </nz-table>
        </div>


        <nz-drawer nzSize="large" [nzVisible]="addDrawer" nzTitle="Add Quiz" (nzOnClose)="closeAddDrawer()">
            <ng-container *nzDrawerContent>
                <form nz-form nzLayout="vertical" [formGroup]="addQuizForm" (ngSubmit)="submitAddForm()">
                    <div class="row">
                        <!-- Title and Description in one row -->
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required placeholder="Quiz title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="1000">
                                        <textarea formControlName="description" nz-input rows="2"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
        
                    <div class="row">
                        <!-- Select category, Quiz Level, and Select Ticker in one row -->
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please select category!">
                                    <nz-form-label>Select category</nz-form-label>
                                    <nz-tree-select [nzDefaultExpandAll]="true" nzShowSearch [nzAllowClear]="true"
                                        [nzMultiple]="true" [nzExpandedKeys]="treeSelectExpandKeys" [nzNodes]="allcategories"
                                        nzShowSearch nzPlaceHolder="Select Category" formControlName="category_id">
                                    </nz-tree-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Quiz Level</nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Quiz Level"
                                    formControlName="quiz_level_id">
                                    <nz-option *ngFor="let quizLevel of quizLevel" [nzLabel]="quizLevel.quiz_level"
                                        [nzValue]="quizLevel.quiz_level_id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Select Ticker</nz-form-label>
                                <nz-select formControlName="symbol_id" nzMode="multiple" nzPlaceHolder="Please select">
                                    <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id"
                                        [nzLabel]="item.symbol">
                                    </nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                    </div>
        
                    <div class="row">
                        <!-- Quiz status? and Valid From - Valid To and Bonus Point in one row -->
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Quiz status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Valid From - Valid To</nz-form-label>
                                <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                                    formControlName="valid_date_range">
                                </nz-range-picker>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Quiz Time</nz-form-label>
                                <nz-time-picker nzFormat="HH:mm:ss" formControlName="time"></nz-time-picker>
                            </nz-form-item>
                        </div>
                     
                    </div>
        
                    <div class="row">
                        <!-- Quiz Time and Attempts in one row -->
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Bonus Point</nz-form-label>
                                <nz-input-number formControlName="bonus_points" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>

                                <nz-form-label>Attempts</nz-form-label>
                                <nz-input-number formControlName="max_attempt" [nzMax]="100" [nzStep]="1"></nz-input-number> 
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>No of days</nz-form-label>
                                <nz-input-number formControlName="no_of_days" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
                            </nz-form-item>
                        </div>
                    </div>
        
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" type="submit">Submit</button>
                </form>
            </ng-container>
        </nz-drawer>
          


        <nz-drawer nzWidth="80%" [nzVisible]="editDrawer" nzTitle="Edit Quiz" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>
        
                <form class="mb-4" nz-form nzLayout="vertical" [formGroup]="editQuizForm" (ngSubmit)="submitEditForm()">
        
                    <div class="row">
                        <div class="col-12 d-flex justify-content-between mb-3">
                            <h6 class="m-0">Quiz Details</h6>
                            <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" type="submit">Update</button>
                        </div>
        
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required placeholder="Quiz title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
        
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="1000">
                                        <textarea formControlName="description" nz-input rows="2"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
        
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Quiz status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
        
                            </nz-form-item>
                        </div>
        
                    </div>
                    <div class="row">
                        <nz-form-item>
                            <nz-form-label>Select Ticker</nz-form-label>
                            <nz-select formControlName="symbol_id" nzMode="multiple" nzPlaceHolder="Please select">
                                <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id"
                                    [nzLabel]="item.symbol"></nz-option>
                            </nz-select>
                        </nz-form-item>
                        <div class="col-md-4 col-12">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please select category!">
                                    <nz-form-label>Select category</nz-form-label>
                                    <nz-tree-select [nzDefaultExpandAll]="true" nzShowSearch [nzAllowClear]="true"
                                        [nzMultiple]="true" [nzExpandedKeys]="treeSelectExpandKeys" [nzNodes]="allcategories"
                                        nzShowSearch nzPlaceHolder="Select Category"
                                        formControlName="category_id"></nz-tree-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
        
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Valid From - Valid To</nz-form-label>
                                <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
                                    formControlName="valid_date_range"
                                    (nzOnCalendarChange)="onEditQuizValidDateRangeCalendarChange($event)"
                                    change></nz-range-picker>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-4">
                            <nz-form-item>
                                <nz-form-label>Quiz Level</nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Quiz Level"
                                    formControlName="quiz_level_id">
                                    <nz-option *ngFor="let category of quizLevel" [nzLabel]="category.quiz_level"
                                        [nzValue]="category.quiz_level_id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12 d-flex mb-3">
                        <h6 class="m-0">Added Questions</h6>
                        <button nz-button nzType="primary" class="ms-3 me-auto"
                            [disabled]="setOfCheckedQuizQuestionsTableIds.size === 0" [nzDanger]="true" nz-popconfirm
                            nzPopconfirmTitle="Are you sure you want to Remove Selected Question(s) from this Quiz?"
                            (nzOnConfirm)="RemoveQuestionFromQuiz()" (nzOnCancel)="cancel()"
                            [nzLoading]="formSubmitLoading">Remove From
                            Quiz</button>
                        <button [nzLoading]="formSubmitLoading" class="ms-3" nz-button nzType="primary"
                            (click)="toggleTableVisibility()">
                            {{ tableVisible ? 'Hide' : 'Show' }} Questions
                        </button>
                    </div>
        
        
                    <div class="col-12">
                        <div class="table-responsive">
                            <nz-table [nzBordered]="true" #quizQuestionsTable [nzData]="quizQuestions" nzTableLayout="fixed"
                                [nzLoading]="tableLoading" #rowSelectionTable nzShowSizeChanger>
                                <thead>
                                    <tr>
                                        <th [nzChecked]="quizQuestionsTableChecked"
                                            [nzIndeterminate]="quizQuestionsTableIndeterminate" nzLabel="Select all"
                                            (nzCheckedChange)="onAllChecked(QuizQuestionTable, $event)" width="5%">
                                        </th>
                                        <th>Question</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Category</th>
                                        <th>Evaluation Type</th>
        
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let question of quizQuestionsTable.data">
                                        <!-- Main Table -->
                                        <tr>
                                            <td [nzChecked]="setOfCheckedQuizQuestionsTableIds.has(question.id)"
                                                (click)="$event.stopPropagation()"
                                                (nzCheckedChange)="onItemChecked(QuizQuestionTable, question.id, $event)">
                                            </td>
                                            <td nzEllipsis>{{ question.question_text }}</td>
                                            <td>{{ question.question_type_string }}</td>
                                            <td>{{ question.is_active === 1 ? 'Active': 'Inactive' }}</td>
                                            <td>{{ question.category_string || '--'}}</td>
                                            <td>{{ question.evaluation_type }}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </nz-drawer>

        <!-- Add Questions to Quizzes Part -->

        <nz-drawer nzWidth="80%" [nzVisible]="addQuestionToQuizDrawer" [nzTitle]="titleTemplate"
            (nzOnClose)="closeAddQuestionToQuizDrawer()">

            <ng-template #titleTemplate>
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-auto">
                        <span>Add Question(s) To Selected Quizzes</span>
                    </div>
                    <div class="col-auto">
                        <button nz-button nzType="primary" [nzLoading]="formSubmitLoading"
                            (click)="addQuestionsToQuiz()">Submit</button>
                    </div>
                </div>
            </ng-template>

            <ng-container *nzDrawerContent>
                <div class="row">
                    <div class="col-12">

                        <div class="table-responsive">

                            <nz-table #questionTable [nzData]="questions" nzTableLayout="fixed"
                                [nzLoading]="tableLoading" #rowSelectionTable nzShowSizeChanger>
                                <thead>
                                    <tr>
                                        <th [nzChecked]="questionTableChecked"
                                            [nzIndeterminate]="questionTableIndeterminate" nzLabel="Select all"
                                            (nzCheckedChange)="onAllChecked(QuestionTable,$event)" width="5%"></th>
                                        <th>Question</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Quiz Level</th>
                                        <th>Category</th>
                                        <th>Evaluation Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let question of questionTable.data">
                                        <!-- Main Table -->
                                        <tr>
                                            <td [nzChecked]="setOfCheckedQuestionsTableIds.has(question.id)"
                                                (click)="$event.stopPropagation()"
                                                (nzCheckedChange)="onItemChecked(QuestionTable,question.id, $event)">
                                            </td>
                                            <td nzEllipsis>{{ question.question_text }}</td>
                                            <td>{{ question.question_type_name }}</td>
                                            <td>{{ question.is_active === 1 ? 'Active': 'Inactive' }}</td>
                                            <td>{{ question.quiz_level_id }}</td>
                                            <td>{{ question.evaluation_type }}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </nz-drawer>

    </nz-card>
</ng-template>