<div *ngIf="!loadingData" class="frame_article">

    <h2 class="mt-2">{{ title }}</h2>
    <p class="mt-2 mb-2">{{ description }}</p>
    <div class="latest_article pb-2">
        <div class="slidingButton" *ngIf="articleData.length > 5">
            <div class="leftSlideButton">
                <button #leftButton nzSize="large" style="border-radius: 30px;" nzType="primary" (click)="slideLeft()">
                    <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </button>
            </div>
            <div class="rightSlideButton">
                <button #rightButton nzSize="large" style="border-radius: 30px;" nzType="primary"
                    (click)="slideRight()">
                    <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
                </button>
            </div>
        </div>
        <div class="article" *ngFor="let item of articleData">
            <ng-container>
                <div class="card " [routerLink]="['/article', item.slug, item.id]" style="width:250px; height: 380px; border-radius: 20px;">
                    <img class="article-image" alt="example"
                    [src]="item.featured_img_path ? (storageUrl + item.featured_img_path) : fallbackImage" />
                
                    <div class="card-body">
                        <span *ngIf="item.category_string" style="cursor: pointer;"
                            [routerLink]="['/category_articles', mainArticle.category_string | hyphenatePipe, mainArticle.category_id]"
                            class="inline-flex p-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{item.category_string}}
                        </span>
                        <div class="title_text">
                            <p style="margin-bottom: 0px; font-family: Heading;"> {{item.title | truncate:50 |
                                titlecase}}
                            </p>
                        </div>
                        <div class="description_text">
                            <p style="margin-top: 0px;font-size: 14px;">{{item.meta_description | truncate:50}}</p>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex bd-highlight mb-3">
                                <div class="p-2 bd-highlight">
                                    <span style="cursor: pointer;" [routerLink]="['/author-details', item.author_slug]">
                                        <h5> {{item.created_by_string}}</h5>
                                    </span>
                                    <span *ngIf="item.post_type_name" style="cursor: pointer;"
                                        class="inline-flex p-1 mt-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{item.post_type_name}}
                                    </span>
                                </div>

                                <div class="ms-auto p-2 bd-highlight">
                                    <button *ngIf="item.bookmark_status === 0" (click)="$event.stopPropagation();">

                                        <i (click)="addBookmarks(item.id)" class="fa-regular fa-bookmark"></i>
                                    </button>

                                    <button *ngIf="item.bookmark_status === 1" (click)="$event.stopPropagation();">
                                        <i (click)="deleteBookmarkedArticle(item.id)" class="fa-solid fa-bookmark"></i>
                                    </button>
                                    <i *ngIf="item.is_free_artical === 1" class="fa-solid fa-lock"
                                        style="margin-left: 8px;"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                  
            </ng-container>
        </div>