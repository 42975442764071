import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import { DostAlertComponent } from '../../Common/Content-Management/Markets/dost-alert/dost-alert.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Quill from 'quill';
export interface Question {
  date: string; // or Date if you want to use JavaScript Date objects
  text: string;
}

@Component({
  selector: 'app-stocks-page',
  templateUrl: './stocks-page.component.html',
  styleUrls: ['./stocks-page.component.scss']
})
export class StocksPageComponent implements OnInit{
  stockName: string = '';
  stockData: any = {};
  isLoading: boolean = false; 
  showProgress: boolean = false;
  size: NzButtonSize = 'large';
  
quizSymbols:any[]=[];
  specialTermsData:any[]=[]
  userId = localStorage.getItem('userId');
  authorCovering:any[] = [];
  dostExplains:any[]=[];
  comments: any[] = []; 
  isAddDostAlertModalVisible = false;
  similarStocks:any[]=[];
  FAQquestions:any[]=[];
  currentIndex = 0; 
  requestTypes: any[] = [];
  requestTypeOptions: any[] = [];
  selectedRequestTypes: any[] = [];
  request: string = ''; 
  defaultLimit: number = 5; // Default number of questions to show
  displayLimit: number = this.defaultLimit;
  showAllQuestions: boolean = false; // Toggle flag

  addRequestVisible=false;
  alertType:any[]=[];
  dostDiaryList:any[]=[]
  articlesPerPage = 2;
  Dostalerts:any[]=[]
  allStocks:any[]=[]
  storageUrl = environment.STORAGE_URL;
  latestArticles:any[]=[]
  isVisible = false;
  dostAlertVisible=false;


  symbolID:any; 
  modalContent: string | TemplateRef<any> | Type<unknown> | undefined;
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private modal: NzModalService,

  ) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.stockName = params.get('symbol') ?? '';  // Ensure matching parameter name
     // Check if stockName is being retrieved correctly
    console.log("stockName",this.stockName);
      this.fetchArticles(this.stockName)?.then(()=>{
        if(this.stockData.symbol_id){
          this.symbolID= this.stockData.symbol_id
          console.log("symbol_id", this.stockData.symbol_id);
          this. fetchLatestArticles(1);
         this. fetchAuthors();
         
         this.fetchDostAlerts(1);
         this.fetchSpecialTerms(2);
         this.FetchFaq();
         this.fetchDostexplains();
         this.fetchSimilarStocks();
        this.fetchQuizSymbol();
         this.fetchTickerVoting();
          this.fetchRelaventTags();
          this.fetchDostDiaryPost();
          this.fetchRequestType();
        }
        
      });
  
     
    });
    
  }
  dostAlertForm: FormGroup = new FormGroup({
    alert_type_id: new FormControl('', Validators.required),
    symbol_id: new FormControl(this.stockData.symbol_id),
    text: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),

  });
  isArray(data: any): boolean {
    return Array.isArray(data);
  }
  addToDostPortfolio(){
    const newTicker = {
      user_id: this.userId, 
      watch_list_id: null, 
      symbol_id:  this.stockData.symbol_id,
      sentiment_id: null,
      date: new Date().toISOString().split('T')[0], 
      risk_id:null,
      horizon_id: null,
      is_public:0,
      trade_id: null,
    };
    this.http.post(`${environment.API_URL}add_watch_list`, newTicker).subscribe({
      next: (response) => {
        console.log('API Response:', response);
        this.message.success('Added to Dostportfolio !');
        this.fetchArticles(this.stockName);
      },
      error: (error) => {
        console.error('Error adding ticker:', error);
        this.message.error('Failed to add the ticker. Please try again.');
      }
    });
  }
  fetchArticles(ID:string): Promise<void> | null {
    const apiUrl = `${environment.API_URL}fetch_articles_by_symbol`;
    const requestBody: any = {
          auth_user_id: this.userId,
          symbol: ID
        };
        this.isLoading = true; 
    return new Promise((resolve) => {
      this.http.post(apiUrl, requestBody).subscribe({
        next: (res: any) => {
          this.stockData = res;
          resolve();
        },
        error: (err: any) => {
        
          console.log(err);
          resolve();
        },
        complete: () => {
     // Set loading state to false on completion
        },
      });
    });
  }
getCurrentArticles() {
  return this.latestArticles.slice(this.currentIndex, this.currentIndex + this.articlesPerPage);
}
prevArticle() {
  if (this.currentIndex > 0) {
    this.currentIndex -= this.articlesPerPage;
  }
}
nextArticle() {
  if (this.currentIndex + this.articlesPerPage < this.latestArticles.length) {
    this.currentIndex += this.articlesPerPage;
  }
}
TickerVoting: any = {};
fetchTickerVoting() {
  const apiUrl = `${environment.API_URL}fetch_ticker_voting`;
  const requestBody = { user_id: this.userId, symbol_id: this.symbolID };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      // Set TickerVoting with already_voted and mapped voting details
      this.TickerVoting = {
        already_voted: response.already_voted,
        voting: [
          {
            indicator_id: 1,
            percentage: response.voting.find((vote: any) => vote.indicator_id === 1)?.percentage || 0,
            strokeColor: '#4caf50' // Green for Bull
          },
          {
            indicator_id: 2,
            percentage: response.voting.find((vote: any) => vote.indicator_id === 2)?.percentage || 0,
            strokeColor: 'red' // Red for Bear
          }
        ]
      };
    },
    error: () => {
      this.message.error('Failed to fetch voting data.');
    }
  });
}

submitTickerVoting(indicator_id: number) {
  const apiUrl = `${environment.API_URL}add_ticker_voting`;
  const requestBody = {
    user_id: this.userId,
    symbol_id: this.symbolID,
    indicator_id: indicator_id
  };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      if (response.success) {
        this.message.success(response.message);

        // Update TickerVoting based on the submitted vote
        this.TickerVoting.voting = this.TickerVoting.voting.map((vote: { indicator_id: number; }) => ({
          ...vote,
          percentage: vote.indicator_id === indicator_id ? 100 : 0
        }));

        // Fetch updated voting data after submitting
        this.fetchTickerVoting();
      } else {
        this.message.error(response.message);
      }
    },
    error: (err) => {
      console.error(err);
      this.message.error(err.message);
    }
  });
}

toggleQuestions(): void {
  if (this.showAllQuestions) {
    // If currently showing all, collapse back to the default limit
    this.displayLimit = this.defaultLimit;
  } else {
    // If currently showing limited, expand to show all
    this.displayLimit = this.quizSymbols.length;
  }
  this.showAllQuestions = !this.showAllQuestions; // Toggle the flag
}
fetchAuthors() {
  const apiUrl = `${environment.API_URL}fetch_author_covering_stocks`;
  const requestBody = { symbol_id: this.symbolID}; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response:any) => {
      this.authorCovering = response;
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    }
  });
}
fetchDostexplains() {
  const apiUrl = `${environment.API_URL}fetch_dost_explains`;
  const requestBody = { symbol_id: this.symbolID}; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response:any) => {
      this.dostExplains = response;
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    }
  });
}

fetchSimilarStocks() {
  const apiUrl = `${environment.API_URL}fetch_similar_symbols`;
  const requestBody = { symbol_id: this.symbolID}; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response:any) => {
      this.similarStocks = response;
      console.log(response);
      
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    }
  });
}

FetchFaq() {
  const apiUrl = `${environment.API_URL}fetch_FAQ`;
  const requestBody = { symbol_id: this.symbolID}; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response:any) => {
      this.FAQquestions = response;
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    }
  });
}

hasFundamentalAlerts(): boolean {
  return this.Dostalerts.some(alert => alert.alert_type_id === 1 && alert.text !== null);
}

hasTechnicalAlerts(): boolean {
  return this.Dostalerts.some(alert => alert.alert_type_id === 2 && alert.text !== null);
}
fetchDostAlerts(alert_type_id?: number) {
  const apiUrl = `${environment.API_URL}fetch_dost_alert`;
  const requestBody = {user_id:this.userId, symbol_id: this.symbolID, alert_type_id }; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      this.Dostalerts = response;
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch alerts.');
    }
  });
}
addTobookmark(dostAlertID: number) {
  console.log(dostAlertID);

  const isCurrentlyBookmarked = this.dostAlert.is_bookmark;
  const apiUrl = isCurrentlyBookmarked
    ? `${environment.API_URL}delete_dost_alert_bookmark`
    : `${environment.API_URL}add_bookmark`;

  const requestBody = {
    user_id: this.userId,
    dost_alert_id: dostAlertID
  };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      if (response.success) {
        // Toggle bookmark status
        this.dostAlert.is_bookmark = !isCurrentlyBookmarked;

        // Show appropriate message based on bookmark status
        if (this.dostAlert.is_bookmark) {
          this.message.success('Bookmark added successfully.');
        } else {
          this.message.success('Bookmark removed successfully.');
        }
      }
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to update bookmark status.');
    }
  });
}


reportDostalert(dostAlertID: number) {
  console.log(dostAlertID);
  
  const apiUrl = `${environment.API_URL}report_dost_alert`;
  const requestBody = {
    user_id: this.userId,
    dost_alert_id: dostAlertID
  };
  
  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      if (response.success) {
        if (this.dostAlert.is_bookmark) {
          this.message.success("Report");
          this.fetchDostAlerts();
        } else {
          this.message.success('Report');
        }
      }
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to Report.');
    }
  });
}

tabs = [
  { id: 2, name: 'DostFact' },
  { id: 6, name: 'DostSecret' },
  { id: 4, name: 'DostTips' },
  { id: 3, name: 'DostInsights' },
  { id: 7, name: 'DostSolution' }
];
openAddDostAlertModal(): void {
  this.isAddDostAlertModalVisible = true;
  this.dostAlertForm.reset;
  this.fetchSymbolS();
 this.fetchDostAlertstype()
}
fetchSpecialTerms(sectionTypeID: any): void {
  console.log("Selected Tab ID:", sectionTypeID); // Logs the selected tab ID

  const apiUrl = `${environment.API_URL}fetch_special_terms`;
  const requestBody = {
    symbol_id: this.symbolID,  
    section_type_id: sectionTypeID // Now using the directly passed ID
  };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (res: any) => {
      this.specialTermsData = res ?? []; // Populate specialTermsData with response or empty array
    },
    error: (err) => {
      console.error('Error fetching special terms:', err);
    }
  });
}

fetchQuizSymbol() {
  const apiUrl = `${environment.API_URL}fetch_quiz_of_symbol`;
  const requestBody = { symbol_id: this.symbolID,user_id: this.userId, };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      this.quizSymbols = response; 
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    },
  });
}
selectedOption:any;
selectedQuestion: any;
openQuestionModal(question: any): void {
  this.isVisible=true;
  this.selectedOption=question.symbol_quiz_answer;
  this.selectedQuestion = question;
}
dostAlert:any;
openDostAlertModal(alert:any){
  console.log(alert);
  
  this.dostAlertVisible=true;
  this.dostAlert=alert;
}

onOptionSelect(optionId: number) {
  const apiUrl = `${environment.API_URL}save_symbol_answer`;
  const requestBody = {
     symbol_id: this.symbolID,
     user_id: this.userId,
     quiz_question_id: this.selectedQuestion?.id,
     quiz_option_id: optionId ,
     quiz_id: this.stockData.quiz_id
    }; 

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      this.message.error('Answer saved successfully');
      this.fetchQuizSymbol();
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    },
  });
}
handleOk(): void {
  this.isVisible = false;
}
onTabChange(index: number): void {
  this.fetchLatestArticles(index);
}

fetchLatestArticles(index: number) {
  const articleType = index === 0 ? 'latest' : 'Top';
  let apiUrl = `${environment.API_URL}fetch_latest_articles`;
  let requestBody: any = {
    symbol_id: this.symbolID,
    article_type_id: index === 0 ? 1 : 2
  };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (res: any) => {
      this.latestArticles = res;
    },
    error: (err) => {},
    complete: () => {},
  });
}
handleCancel(): void {
  this.isVisible = false;
  this.isAddDostAlertModalVisible = false;
  this.dostAlertVisible=false;
  this.addRequestVisible=false;
}
relevantTags:any[]=[];
fetchRelaventTags() {
  const apiUrl = `${environment.API_URL}fetch_relevant_tags`;
  const requestBody = { symbol_id: this.symbolID };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      this.relevantTags = response; 
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to fetch authors.');
    },
  });
}
fetchDostAlertstype(): Promise<void> {

  // console.log(id);
  const apiUrl = `${environment.API_URL}fetch_dost_alert_type`;
  return new Promise((resolve, reject) => {
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        // console.log('Quiz Questions: ', response);
        this.alertType = response;
      },
      error: (error) => {
        console.error('Error: ', error);
       
        return reject();
      },
      complete: () => {
       
        return resolve();
      },
    });
  });
}

fetchRequestType(): Promise<void> {

  // console.log(id);
  const apiUrl = `${environment.API_URL}fetch_request_type
`;
  return new Promise((resolve, reject) => {
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        // console.log('Quiz Questions: ', response);
        this.requestTypes = response;
      },
      error: (error) => {
        console.error('Error: ', error);
       
        return reject();
      },
      complete: () => {
       
        return resolve();
      },
    });
  });
}
fetchDostDiaryPost(): Promise<void> {
  const apiUrl = `${environment.API_URL}fetch_post`;
  return new Promise((resolve, reject) => {
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        // console.log('Quiz Questions: ', response);
        this.dostDiaryList = response;
      },
      error: (error) => {
        console.error('Error: ', error);
       
        return reject();
      },
      complete: () => {
       
        return resolve();
      },
    });
  });
}
getPosts(type: string) {
  return this.dostDiaryList.filter(post => {
    if (type === 'trending') {
      return post.is_featured_post !== 1; // Assuming you have a property to check for trending posts
    } else if (type === 'featured') {
      return post.is_featured_post === 1;
    }
    return false; // Default case
  });
}
selectedRequestTypeIds: number[] = [];
onCheckboxChange(event: Event, requestTypeId: number) {
  const checkbox = event.target as HTMLInputElement;
  if (checkbox.checked) {
    this.selectedRequestTypeIds.push(requestTypeId);
  } else {
    this.selectedRequestTypeIds = this.selectedRequestTypeIds.filter(id => id !== requestTypeId);
  }
}
addRequest() {
  const apiUrl = `${environment.API_URL}add_ticker_request`;
  const requestBody = {
    symbol_id: this.symbolID,
    user_id: this.userId,
    request_type_id: this.selectedRequestTypeIds,
  };

  this.http.post(apiUrl, requestBody).subscribe({
    next: (response: any) => {
      this.message.success(response.message);
    },
    error: (err) => {
      console.error(err);
      this.message.error('Failed to send the request.');
    },
    complete: () => {
      this.addRequestVisible=false
      this.request = '';
    },

  });
}
fetchSymbolS(): Promise<void> {

  // console.log(id);
  const apiUrl = `${environment.API_URL}fetch_all_stocks`;
  return new Promise((resolve, reject) => {
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        // console.log('Quiz Questions: ', response);
        this.allStocks = response;
      },
      error: (error) => {
        console.error('Error: ', error);
  
        return reject();
      },
      complete: () => {
    
        return resolve();
      },
    });
  });
}
handleAdd(): void {
  if (this.dostAlertForm.valid) {
    // Add the user_id to the form data
    const formData = {
      ...this.dostAlertForm.value,
      user_id: this.userId 
    };
    const apiUrl = `${environment.API_URL}add_dost_alert`;    
    this.http.post(apiUrl, formData).subscribe({
      next: (response: any) => {
        console.log('Dost Alert added successfully', response);
        this.isAddDostAlertModalVisible = false;
        this.fetchDostAlerts(1);
      },
      error: (error) => {
        console.error('Error adding dost alert', error);
      }
    });
  } else {
    this.dostAlertForm.markAllAsTouched();
  }
}
@ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
cardWidth: number = 0;
visibleCardsCount: number = 0;
ngAfterViewInit(): void {
  // Get the width of the first card (assuming all cards are the same width)
  const cardElement = this.scrollContainer.nativeElement.querySelector('.card');
  this.cardWidth = cardElement ? cardElement.offsetWidth + 16 : 350; // Including margin-right
  this.calculateVisibleCardsCount();
}

calculateVisibleCardsCount(): void {
  const containerWidth = this.scrollContainer.nativeElement.offsetWidth;
  this.visibleCardsCount = Math.floor(containerWidth / this.cardWidth);
}

scrollLeft(): void {
  const scrollAmount = this.visibleCardsCount * this.cardWidth;
  this.scrollContainer.nativeElement.scrollBy({
    left: -scrollAmount, // Scroll left by the calculated amount
    behavior: 'smooth'
  });
}

scrollRight(): void {
  const scrollAmount = this.visibleCardsCount * this.cardWidth;
  this.scrollContainer.nativeElement.scrollBy({
    left: scrollAmount, // Scroll right by the calculated amount
    behavior: 'smooth'
  });
}
quill: Quill | undefined;

editorOptions = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['clean'],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],

    clipboard: {
      matchVisual: false,
    },
  },
};


}
