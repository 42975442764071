<app-main-fiminize-layout>

    <div class="container mt-5">
        <div class="card border-light mb-3">
            <div class="card-header">
                <img [src]="CryptoData.symbol_logo ? (storageUrl + CryptoData.symbol_logo) : 'assets/profile-images/profile-image.png'"
                    class="card-img-top" alt="TKX Logo">
                <h2 class="symbol-name">{{ CryptoData.name }} ({{ symbolName }})</h2>
            </div>
            <button class="btn btn-sm btn-outline-primary top-right-button" nz-button nzType="default" nzSize="large"
            [ngStyle]="{'background-color': '#FFF9EB', 'border': 'none', 'color': 'black'}"
            (click)="addToDostPortfolio()">
        <ng-container *ngIf="CryptoData.already_added; else notAdded">
            TRACKING
        </ng-container>
        <ng-template #notAdded>
            <span>+ DostPortfolio<sup>™</sup></span>
        </ng-template>
    </button>
            <div class="card-body">
                <p class="card-text">{{ CryptoData.symbol_description }}</p>
            </div>
            <div class="card-footer">
                <div class="row info-row text-center align-items-center g-0">
                    <div class="col-md-1 info-item">
                        <p class="info-label">TRACKING</p>
                        <nz-statistic nzTitle="Status" nzValue="100"></nz-statistic>
                        <!-- <p class="info-value"> {{ CryptoData.followers }}100</p> -->
                    </div>
                    <div class="col-md-2 info-item">
                        <p class="info-label">Contact Address</p>
                        <button nz-button nzType="text">
                            {{ primaryChain?.contact_address | truncateAddress }}
                            <span *ngIf="copiedIndex === 'primary'; else copyIconPrimary" nz-icon nzType="check-circle"
                                nzTheme="outline" style="color: green;">
                            </span>
                            <ng-template #copyIconPrimary>
                                <span nz-icon nzType="copy"
                                    (click)="copyToClipboard(primaryChain?.contact_address, 'primary')">
                                </span>
                            </ng-template>
                            <span nz-popover [nzPopoverContent]="contactAddressTemplate" nzPopoverTrigger="click"
                                nz-icon nzType="down"></span>
                        </button>

                        <ng-template #contactAddressTemplate>
                            <div></div>
                            <div>
                                <ul class="list-group">
                                    <li class="list-group-item bg-light list-group-item-action">
                                        <div>
                                            <i class="fa-solid fa-map-pin"></i>
                                            <b>{{ primaryChain?.chain_name }}</b>
                                        </div>
                                        <div nz-row nzAlign="middle" nzJustify="space-between">
                                            <span>{{ primaryChain?.contact_address | truncateAddress }}</span>
                                            <span nz-icon nzType="copy" (click)="copyToClipboard(primaryChain?.contact_address, 'primary')" style="cursor: pointer;"></span>
                                        </div>
                                    </li>
                                    
                                    <li class="list-group-item" *ngFor="let chain of otherChains; let i = index">
                                        <div>
                                            <b>{{ chain.chain_name }}</b>
                                        </div>
                                        <div nz-row nzAlign="middle" nzJustify="space-between">
                                            <span>{{ chain.contact_address | truncateAddress }}</span>
                                            <span *ngIf="copiedIndex === i; else copyIcon" nz-icon nzType="check-circle" nzTheme="outline" style="color: green;"></span>
                                            <ng-template #copyIcon>
                                                <span nz-icon nzType="copy" nzTheme="outline" (click)="copyToClipboard(chain.contact_address, i)" style="cursor: pointer;"></span>
                                            </ng-template>
                                        </div>
                                    </li>
                                </ul>
                                
                                
                            </div>
                        </ng-template>
                    </div>


                    <div class="col-md-1 info-item">
                        <p class="info-label">Website</p>
                        <button nz-button nzType="text">
                            <a href="{{ CryptoData.website }}" target="_blank" rel="noopener noreferrer"> <span nz-icon
                                    nzType="global"></span></a>
                        </button>
                    </div>
                    <div class="col-md-2 info-item">
                        <p class="info-label">Chain</p>

                        <button nz-button nzType="text"> {{ primaryChain?.chain_name }}
                            <span nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click" nz-icon
                                nzType="down"></span></button>
                        <ng-template #contentTemplate>
                            <div>
                                <ul class="list-group">
                                    <li class="list-group-item bg-light list-group-item-action">
                                        <i class="fa-solid fa-map-pin"></i>  <b> {{ primaryChain?.chain_name }}</b>
                                    </li>
                                    <li class="list-group-item list-group-item-action" *ngFor="let chain of otherChains; let i = index">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <b>{{ chain.chain_name }}</b>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>

                    </div>
                    <div class="col-md-2 info-item">
                        <p class="info-label">Sector</p>
                        <div *ngFor="let sector of CryptoData.sector">
                            <p  class="info-value">
                                {{sector.sector_name}}
                            </p>
                        </div>

                    </div>
                    <div class="col-md-4 ">
                        <p class="info-label">
                          How are you feeling about <strong>{{ symbolName }}</strong> today?
                        </p>
                      
                        <div *ngIf="TickerVoting?.already_voted">
                          <nz-progress
                            *ngFor="let vote of TickerVoting.voting"
                            [nzPercent]="vote.percentage"
                            [nzStrokeColor]="vote.strokeColor"
                            nzStrokeWidth="10"
                            nzType="line">
                          </nz-progress>
                        </div>
                      
                        <div class="info-value">
                          <button (click)="submitTickerVoting(1)" nzShape="round"
                              style="border-color: green; color: green; border-width:2px" nzSize="small" nz-button nzType="default">
                            <span nz-icon nzType="rise"></span> Bull
                          </button>
                          <button class="ms-2" (click)="submitTickerVoting(2)" nzShape="round"
                              style="border-color: red; color: red; border-width:2px" nzSize="small" nz-button nzType="default">
                            <span nz-icon nzType="fall"></span> Bear
                          </button>
                        </div>
                      </div>
                      
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <nz-tabset nzType="card" nzSize="large" (nzSelectedIndexChange)="onTabChange($event)">
                    <nz-tab nzTitle="Latest">
                    </nz-tab>
                    <nz-tab nzTitle="Top">
                    </nz-tab>
                </nz-tabset>

                <app-article-card-design [articleData]="latestArticles" [showSlideButtons]="true"
               (refresh)="fetchLatestArticles(1)">
            </app-article-card-design>
                <div class="row">
                    <nz-tabset nzType="card">
                        <nz-tab *ngFor="let tab of tabs" [nzTitle]="customTitle" (nzClick)="fetchSpecialTerms(tab.id)">
                            <ng-template #customTitle>
                                <b>{{ tab.name }}</b>
                                <ng-container [ngSwitch]="tab.name">
                                    <span *ngSwitchCase="'DostTips'"
                                        nz-tooltip="Key pointers to guide you in the right direction and highlight important aspects of the asset.">
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostSecret'"
                                        nz-tooltip="A valuable, lesser-known piece of information revealed through deep analysis.">
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostSolution'"
                                        nz-tooltip="A strategy to gain an edge on the asset and ensure you're ready to take action when the next opportunity arises.">
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostInsights'"
                                        nz-tooltip="Expert-driven insights providing additional knowledge on the asset, based on real-world experience.">
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostFact'"
                                        nz-tooltip="Essential facts about the asset to give you detailed insights for making informed decisions.">
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                    <span *ngSwitchDefault>
                                        <i nz-icon nzType="exclamation-circle" class="ms-2"></i>
                                    </span>
                                </ng-container>
                                
                            </ng-template>
                            
                            <div *ngIf="specialTermsData && specialTermsData.length > 0; else noData">
                                <div class="card mb-2 border-light" *ngFor="let item of specialTermsData">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-2 ">
                                                <nz-avatar [routerLink]="['/userprofile', item.user_id]" [nzSize]="40"
                                                    [nzSrc]="storageUrl + '/' + item.profile_pic" nzShape="circle">
                                                </nz-avatar>
                                                <div>{{ item.created_by_name }} <small>{{ item.created_at | date:
                                                        'yyyy-MM-dd'
                                                        }}</small></div>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="card-content">
                                                    <h3>{{ item.artical_title }}</h3>
                                                    <div style="font-family: 'paragraph';"
                                                        [innerHTML]="item.section_content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-end">
                                        <button [routerLink]="['/article', item.slug, item.artical_id]" nz-button nzType="text">Read More</button>
                                    </div>
                                </div>
                            </div>
                            <ng-template #noData>
                                <div *ngIf="specialTermsData.length === 0" class="alert alert-light rounded mb-4 p-3">
                                    <p><i>This content is under active research and will be released soon, add
                                            <b>{{symbolName}}</b> to your DostPortfolio so you can stay up to date!</i>
                                    </p>
                                </div>
                            </ng-template>
                        </nz-tab>
                    </nz-tabset>
                </div>

            </div>
            <div class="col-md-4">
                <div class="card border-light mb-4">
                    <div class="card-header ">
                        <h2 class="card-title d-flex justify-content-between align-items-center">
                            <span>DostAlert<sup>™</sup></span>
                            <button nz-button nzType="default"  *ngIf="['1', '2','4', '5'].includes(roleId)" (click)="openAddDostAlertModal(); dostAlertForm.controls['symbol_id'].setValue(CryptoData.symbol_id)" nzShape="circle"><span
                                    nz-icon nzType="plus"></span></button>
                        </h2>
                    </div>

                    <div class="card-content">
                        <div *ngIf="Dostalerts && Dostalerts.length > 0">
                            <nz-tabset nzType="card" (nzSelectedIndexChange)="fetchDostAlerts($event + 1)">
                                <nz-tab nzTitle="Fundamental "></nz-tab>
                                <nz-tab nzTitle="Technical "></nz-tab>
                            </nz-tabset>
                        </div>

                        <div *ngIf="Dostalerts && Dostalerts.length == 0" class="alert alert-light rounded mb-4 p-3">
                            <p><i>Our creators are monitoring <b>{{symbolName}}</b> for you. Keep your notifications
                                    on!</i></p>
                        </div>
                        <div *ngIf="Dostalerts.length > 0">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item" *ngFor="let alert of Dostalerts | slice:0:5">
                                    <p (click)="openDostAlertModal(alert)" class="mb-0">{{ alert.text }}</p>
                                    <div class="d-flex align-items-center" [routerLink]="alert.role_id === 3 ? ['/userprofile', alert.user_id] : ['/author-details', alert.user_id]">
                                        <nz-avatar nzSize="small"
                                            [nzSrc]="alert.profile_pic ? (storageUrl + alert.profile_pic) : 'assets/profile-images/profile-image.png'"
                                            nzIcon="user"></nz-avatar>
                                        <span class="ms-2" >{{
                                            alert.created_by_name }}</span>
                                    </div>

                                </li>
                            </ul>
                            <nz-modal [nzWidth]="500" [nzFooter]="null" [(nzVisible)]="dostAlertVisible"
                                nzTitle="DostAlert™" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                <ng-container *nzModalContent>
                                    <div class="card shadow-sm rounded border-0 mb-4">
                                        <div class="card-header bg-light border-bottom-0">
                                            <p class="card-title mb-0">{{ dostAlert.text }}</p>
                                        </div>
                                        <div class="card-body">

                                            <p [innerHTML]="dostAlert?.description"></p>

                                        </div>
                                        <div class="d-flex justify-content-between align-items-center px-3 py-2 border-top">
                                            <!-- Left Section: Avatar and Created By Name -->
                                            <div class="d-flex align-items-center">
                                              <nz-avatar
                                                nzSize="small"
                                                [nzSrc]="dostAlert.profile_pic ? (storageUrl + dostAlert.profile_pic) : 'assets/profile-images/profile-image.png'"
                                                nzIcon="user"
                                              ></nz-avatar>
                                              <b class="ms-2">{{ dostAlert?.created_by_name }}</b>
                                            </div>
                                            
                                            <!-- Right Section: Bookmark and Exclamation Icons -->
                                            <div class="d-flex align-items-center">
                                                <!-- Bookmark icon with click handler -->
                                                <i (click)="addTobookmark(dostAlert.dost_alert_id)"
                                                [class.fa-solid]="dostAlert.is_bookmark"
                                                [class.fa-regular]="!dostAlert.is_bookmark"
                                                class="fa-bookmark fa-lg me-2">
                                             </i>
                                            
                                                <!-- Conditionally display the report icon based on already_reported -->
                                                <span (click)="reportDostalert(dostAlert.dost_alert_id)" *ngIf="!dostAlert.already_reported" nz-icon
                                                    nzType="exclamation-circle" nzTheme="outline">
                                                </span>
                                            
                                                <span *ngIf="dostAlert.already_reported" nz-icon nzType="exclamation-circle" nzTheme="fill">
                                                </span>
                                            </div>
                                              
                                          </div>
                                          
                                    </div>
                                </ng-container>
                            </nz-modal>

                        </div>
                    </div>
                </div>

                <div class="card border-light mb-4" *ngIf="authorCovering && authorCovering.length > 0">
                    <div class="card-header ">
                        <h2 class="card-title"> <span>Creators Covering</span></h2>
                    </div>
                    <div class="card-content">
                        <div class="row">
                            <div nz-popover nzPopoverTitle="{{authors.name}}" [nzPopoverContent]="contentTemplate"
                                nzPopoverTrigger="hover" class="col-md-3" style="cursor: pointer;"
                                *ngFor="let authors of authorCovering">
                                <nz-avatar [routerLink]="['/userprofile', authors.user_id]" [nzShape]="'circle'"
                                    [nzSize]="'large'" [nzSrc]="storageUrl + '/' + authors.profile_pic">

                                </nz-avatar>
                                <div>
                                    <small> {{authors.name}}</small>
                                </div>
                                <ng-template #contentTemplate>
                                    <div class="row align-items-center">
                                        <!-- Avatar Section -->
                                        <div class="col-auto">
                                            <img [src]="storageUrl + '/' + authors.profile_pic" alt="User Avatar"
                                                class="rounded-circle" style="width: 40px; height: 40px;">
                                        </div>

                                        <!-- Content Section -->
                                        <div class="col">

                                            <div class="d-flex align-items-center">
                                                <p class="mb-0 me-3">{{ authors.created_by_name }}</p>
                                                <div class="me-3">
                                                    <span class="d-block">Followers</span>
                                                    <span>{{ authors.followers }}</span>
                                                </div>
                                                <div>
                                                    <span class="d-block">Following</span>
                                                    <span>{{ authors.following }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>

                            </div>

                        </div>

                    </div>
                </div>
                <div class="card border-light mb-4" *ngIf="quizSymbols && quizSymbols.length > 0">
                    <div class="card-header">
                        <h2 class="card-title">Test Yourself</h2>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li *ngFor="let question of quizSymbols | slice:0:displayLimit; let i = index"
                                class="list-group-item d-flex align-items-center" (click)="openQuestionModal(question)"
                                [ngClass]="{'bg-light': i % 2 !== 0}">
                                <div class="question-icon me-3">
                                    <span nz-icon
                                        [nzType]="question.symbol_quiz_answer === null ? 'question-circle' :
                                              question.is_answer_correct === 'correct' ? 'check-circle' : 'close-circle'"
                                        nzTheme="outline" [style.color]="question.symbol_quiz_answer === null ? 'black' : 
                                                  (question.is_answer_correct === 'correct' ? 'green' : 'red')"></span>
                                </div>
                                <div>
                                    <small class="text-muted">QUESTION ASKED {{question.created_at | date: 'dd MMM
                                        yyyy'}}</small>
                                    <p style="cursor: pointer;" class="mb-0">{{question.question_text}}</p>
                                </div>
                            </li>
                        </ul>
                        <button *ngIf="quizSymbols.length > defaultLimit" nz-button nzType="text"
                            (click)="toggleQuestions()">
                            {{ showAllQuestions ? 'Show Less' : 'More Questions' }}
                        </button>
                        <div *ngIf="quizSymbols.length === 0" class="alert alert-light rounded mb-4 p-3">
                            <p>NA</p>
                        </div>
                    </div>
                    <nz-modal [nzFooter]="null" [(nzVisible)]="isVisible" nzTitle="Test Yourself"
                        (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                        <ng-container *nzModalContent>
                            <div>
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <p><strong>{{ selectedQuestion?.question_text }}</strong></p>
                                    <span>Points: {{ selectedQuestion?.points }}</span>
                                </div>
                                <img *ngIf="selectedQuestion.question_image"
                                    [src]="selectedQuestion.question_image ? (storageUrl + selectedQuestion.question_image) : 'assets/profile-images/profile-image.png'"
                                    alt="{{symbolName }}" class="img-fluid" />


                                <p><strong>Options:</strong></p>
                                <nz-radio-group [(ngModel)]="selectedOption">
                                    <ng-container *ngFor="let option of selectedQuestion?.options">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">

                                                <div nz-radio [nzValue]="option.id">
                                                    <span>{{ option.option_text }}</span>
                                                    <span *ngIf="option.is_correct_option === 1 && selectedOption"
                                                        nz-icon nzTheme="fill" nzType="check-circle" nzTheme="outline"
                                                        style="color: green; margin: 8px; font-size: 20px;"></span>
                                                </div>

                                            </li>
                                        </ul>

                                    </ng-container>

                                    <button nz-button nzType="primary"
                                        [disabled]="!selectedOption || selectedQuestion?.is_answer_correct !== null"
                                        (click)="onOptionSelect(selectedOption)">Submit Answer</button>

                                </nz-radio-group>

                            </div>
                        </ng-container>
                    </nz-modal>

                </div>
                <div class="card border-light mb-4">
                    <div class="card-header ">
                        <h2 class="card-title"> <span>DostExplains<sup>™</sup></span></h2>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li style="cursor: pointer;" class="list-group-item" *ngFor="let explains of dostExplains"
                                [routerLink]="['/course',  'preview', explains.course_slug, explains.course_id]">
                                <p class="mb-0">{{ explains.dost_explains_name }}</p>
                                <small [routerLink]="['/userprofile', explains.created_by]"
                                    class="text-muted fw-bold">{{ explains.created_by_name }}</small>
                            </li>

                        </ul>
                        <div *ngIf="dostExplains.length === 0" class="alert alert-light rounded mb-4 p-3">
                            <p><i>DostExplains is being updated. You’ll have access to lessons soon.</i></p>
                        </div>
                    </div>
                </div>
                <div *ngIf="similarCryptos && similarCryptos.length > 0" class="card border-light mb-4">
                    <div class="card-header">
                        <h2 class="card-title">Similar Crypto
                        </h2>
                    </div>
                    <div class="card-body">
                        <div *ngFor="let  cryptos of similarCryptos" class="d-inline-block me-2">
                            <ng-container>
                                <nz-tag nzColor="pink" class="mx-2 rounded" [routerLink]="['/crypto',  cryptos.name]" style="cursor: pointer;">
                                    {{  cryptos.name }}
                                  </nz-tag>
                         
                            </ng-container>
                        </div>
                    </div>

                </div>
                <div *ngIf="relevantTags && relevantTags.length > 0" class="card border-light mb-4">
                    <div class="card-header">
                        <h2 class="card-title">Relevant Tags
                        </h2>
                    </div>
                    <div class="card-body">
                        <div *ngFor="let relevantTags of relevantTags" class="d-inline-block me-2">
                            <ng-container>
                                <nz-tag nzColor="blue" class="mx-2 rounded" [routerLink]="['/companytags', relevantTags.relevant_tags]">
                                    {{ relevantTags.relevant_tags_name}}
                                  </nz-tag>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="card border-light w-100">
                <div class="card-header">
                    <h2 class="card-title"> <span>DostDiary<sup>™</sup></span> </h2>

                </div>
                <div class="card-body" style="height: 50vh; overflow-y: auto;">
                    <nz-tabset nzType="card">
                        <!-- General Posts Tab -->
                        <nz-tab nzTitle="General Posts" (nzClick)="fetchDostDiaryPost(0)">
                            <button nz-button nzType="text">Latest</button>
                            <button nz-button nzType="text">Trending</button>
                            <div *ngIf="getPosts('trending').length > 0; else noPostsMessage">
                                <div *ngFor="let post of getPosts('trending')">
                                    <div class="card border-light mb-3">
                                        <div class="card-body py-4">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <nz-avatar [nzSize]="40"
                                                        [nzSrc]="storageUrl + '/' + post.profile_pic" nzShape="circle">
                                                    </nz-avatar>
                                                    <div>{{ post.created_by_name }} <small>{{ post.created_at | date:
                                                            'yyyy-MM-dd'
                                                            }}</small></div>
                                                </div>
                                                <div class="col-md-10">
                                                    <p nz-paragraph [innerHTML]="post.caption"></p>

                                                </div>
                                                <button nz-button nzType="text" (click)="viewPost(post.diary_post_id)">View Post</button>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-tab>

                        <!-- Feature Post Tab -->
                        <nz-tab nzTitle="Feature Post" (nzClick)="fetchDostDiaryPost(1)">
                            <div *ngIf="getPosts('featured').length > 0; else noPostsMessage">
                                <div *ngFor="let post of getPosts('featured')">
                                    <div class="card border-light mb-3"
                                        [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
                                        <div class="card-body py-4">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <nz-avatar [nzSize]="40"
                                                        [nzSrc]="storageUrl + '/' + post.profile_pic" nzShape="circle">
                                                    </nz-avatar>
                                                    <div>{{ post.created_by_name }} <small>{{ post.created_at | date:
                                                            'yyyy-MM-dd'
                                                            }}</small></div>
                                                </div>
                                                <div class="col-md-10">
                                                    <p nz-paragraph [innerHTML]="post.caption"></p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-tab>
                    </nz-tabset>

                    <!-- No Posts Message -->
                    <ng-template #noPostsMessage>
                        <div class="alert alert-light rounded mb-4 p-3">
                            <p>Seems like no one is talking about MSFT on DostDiary! Remember to tag <b>{{ symbolName
                                    }}</b> if you share relevant ideas or thoughts!</p>
                        </div>
                    </ng-template>
                </div>


            </div>
        </div>
        <div class="row mt-4" *ngIf="FAQquestions && FAQquestions.length > 0">
            <div class="card border-light">
                <div class="card-content">
                    <div class="row">
                        <div class="col-md-3 d-flex align-items-start">
                            <h3>Frequently Asked Questions</h3>
                        </div>
                        <div class="col-md-9">
                            <nz-collapse [nzBordered]="false">
                                <!-- Dynamic FAQ List -->
                                <nz-collapse-panel *ngFor="let faq of FAQquestions" [nzHeader]="faq.question"
                                    [nzActive]="true">
                                    <ng-container *ngIf="faq.answer; else noAnswer">
                                        {{ faq.answer }}
                                    </ng-container>
                                    <ng-template #noAnswer>
                                        <p><i>Answer not available</i></p>
                                    </ng-template>
                                </nz-collapse-panel>
                            </nz-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="card border-light">
                <div class="card-content">
                    <div class="row">
                        <div class="col-md-4">
                            <h3>Request more content on {{symbolName }}</h3>
                        </div>
                        <div class="col-md-6">
                            <div class="avatar-container d-flex align-items-center">
                                <nz-avatar [nzSize]="60"
                                    [nzSrc]="CryptoData.symbol_logo ? (storageUrl + CryptoData.symbol_logo) : 'assets/profile-images/profile-image.png'"
                                    [nzShape]="'circle'">
                                </nz-avatar>
                                <div class="contact-info ms-3">
                                    <h2>{{ symbolName }}</h2>
                                    <p>{{ primaryChain?.chain_name }}</p>
                                    <div *ngFor="let sector of CryptoData.sector">
                                        <p  class="info-value">
                                            {{sector.sector_name}}
                                        </p>
                                    </div>
            
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex justify-content-end">
                            <button (click)="addRequestVisible = true" nz-button nzType="primary">Request</button>

                        </div>
                    </div>
                </div>
            </div>
            <nz-modal [nzFooter]="null" nzTitle="Add Request" [(nzVisible)]="addRequestVisible"
                (nzOnCancel)="handleCancel()">
                <ng-container *nzModalContent>
                    <P>Request More content on <strong>{{symbolName }}</strong></P>

                    <div class="row">
                        <ul *ngIf="requestTypes && requestTypes.length > 0" class="list-group">
                            <li class="list-group-item" *ngFor="let type of requestTypes">
                                <input class="form-check-input me-1" type="checkbox" [value]="type.request_type_id"
                                    (change)="onCheckboxChange($event, type.request_type_id)" aria-label="...">
                                {{ type.request_type }}
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt-2 text-end">
                            <button (click)="addRequest()" nz-button nzType="primary">Send Request</button>
                        </div>
                    </div>


                </ng-container>
            </nz-modal>
        </div>

    </div>
    <nz-modal [(nzVisible)]="isAddDostAlertModalVisible" nzTitle="Add DostAlert" (nzOnCancel)="handleCancel()"
        (nzOnOk)="handleAdd()"  nzOkText="Notify">

        <ng-container *nzModalContent>
            <form [formGroup]="dostAlertForm">
                <div class="row">
                    <div class="col-md-12">
                        <div nz-form-item>
                            <div nz-form-label>
                                <label for="text">Text</label>
                            </div>
                            <div nz-form-control>
                                <textarea nz-input formControlName="text" placeholder="Enter alert text"
                                    nzAutosize></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div nz-form-label>
                            <label for="text">Description</label>
                        </div>
                        <nz-form-item>
                            <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="description"
                                placeholder="Enter alert description" style="width: 100%;"></quill-editor>

                        </nz-form-item>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <nz-form-item>
                            <nz-form-label><span> Select DostAlert<sup>™</sup> Type</span></nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select DostAlert Type"
                                formControlName="alert_type_id">
                                <nz-option *ngFor="let alertType of alertType" [nzValue]="alertType.type_id"
                                    [nzLabel]="alertType.dost_alert_type"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="col-md-6">
                        <nz-form-item>
                            <nz-form-label>Select Ticker</nz-form-label>
                            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Ticker"
                                formControlName="symbol_id">
                                <nz-option *ngFor="let symbols of allStocks" [nzValue]="symbols.symbol_id"
                                    [nzLabel]="symbols.symbol"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                          <nz-form-item >
                            <nz-form-label>Add to DostDiary</nz-form-label>
                            <nz-radio-group formControlName="is_diarypost">
                                <label nz-radio [nzValue]="1">Yes</label>
                                <label nz-radio [nzValue]="0">No</label>
                            </nz-radio-group>
                        </nz-form-item>
                        </div>
                 
                      </div>
                </div>
            </form>
        </ng-container>
    </nz-modal>
</app-main-fiminize-layout>