<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item>My Bookmark's</nz-breadcrumb-item>
    </nz-breadcrumb>
    <div>
        <h2>Articles</h2>
        <app-article-card-design [articleData]="allBookmarkList" [showSlideButtons]="true"
            (slideLeftEvent)="slideLeft()" (slideRightEvent)="slideRight()" (refresh)="fetchallBookmarks()">
        </app-article-card-design>
    </div>
  
    <div class="posts-container" *ngIf="allbookmakrPostsList && allbookmakrPostsList.length > 0">
        <h2>Posts</h2>
      
        <div class="posts-scroll">
          <div *ngFor="let allPosts of allbookmakrPostsList" class="col-md-12">
            <div class="card shadow-sm border-light d-flex flex-column h-100">
              <div class="card-body">
                <h5 class="card-title">
                  <p nz-paragraph [innerHTML]="allPosts.caption"></p>
                </h5>
                <p class="card-text text-muted">
                  UPDATED {{ allPosts.created_at | date:'dd/MM/yyyy HH:mm' }}
                </p>
              </div>
              <div class="d-flex justify-content-end align-items-center px-3 py-3">
                <div class="d-flex align-items-center">
                  <i 
                    [class.fa-solid]="allPosts.bookmark_status" 
                    (click)="updateBookmark(allPosts.diary_post_id, 'post')" 
                    [class.fa-regular]="!allPosts.bookmark_status" 
                    class="fa-bookmark fa-lg me-2">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
     
        <div class="row g-4" *ngIf="allDostAlertList && allDostAlertList.length > 0">
            <h2><span>DostAlert<sup>™</sup></span></h2>
            <div *ngFor="let alertlist of allDostAlertList" class="col-md-3">
                <div class="card shadow-sm border-light d-flex flex-column h-100"  (click)="openDostAlertModal(alertlist)">
                    <div class="card-body">
                      <nz-tag nzColor="blue" >{{ alertlist.symbol }}</nz-tag>
                        <h5 class="card-title">{{ alertlist.text || 'No Text Available' }}</h5>
                        <p class="card-text text-muted">UPDATED {{ alertlist.created_at | date:'dd/MM/yyyy HH:mm' }}
                        </p>
                   
                        
                    </div>
                    <div class="d-flex justify-content-end align-items-center px-3 py-3" (click)="$event.stopPropagation()">
                      <div class="d-flex align-items-center" >
                        <i 
                          [class.fa-solid]="alertlist.bookmark_status" 
                          (click)="updateBookmark(alertlist.dost_alert_id, 'dost_alert')" 
                          [class.fa-regular]="!alertlist.bookmark_status" 
                          class="fa-bookmark fa-lg me-2">
                        </i>
                      </div>
                    </div>
                </div>
            </div>
        </div>
  
        
        <nz-modal [nzWidth]="500" [nzFooter]="null" [(nzVisible)]="dostAlertVisible" nzTitle="DostAlert™"
            (nzOnCancel)="closeDrawer()">
            <ng-container *nzModalContent>
                <div class="card shadow-sm rounded border-0 mb-4" *ngIf="selectedDostAlert">
                    <div class="card-header bg-light border-bottom-0">
                        <p class="card-title mb-0">{{ selectedDostAlert.text }}</p>
                    </div>
                    <div class="card-body">
                        <p [innerHTML]="selectedDostAlert?.description"></p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-3 py-2 border-top">
                        <div class="d-flex align-items-center">
                            <nz-avatar nzSize="small"
                                [nzSrc]="selectedDostAlert.profile_pic ? (storageUrl + selectedDostAlert.profile_pic) : 'assets/profile-images/profile-image.png'"
                                nzIcon="user"></nz-avatar>
                            <b class="ms-2">{{ selectedDostAlert?.created_by_name }}</b>
                        </div>
                     

                        <div class="d-flex align-items-center" (click)="$event.stopPropagation()">
                            <i [class.fa-solid]="selectedDostAlert.bookmark_status"
                                (click)="updateBookmark(selectedDostAlert.dost_alert_id, 'dost_alert')"
                                [class.fa-regular]="!selectedDostAlert.bookmark_status" class="fa-bookmark fa-lg me-2"></i>
                        
                        </div>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
      
</app-main-fiminize-layout>